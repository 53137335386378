import "./footer.scss";
import React from "react";
import {UIText} from "../../components/ui/text/text.js";
import {useTranslation} from "../../helpers/languange.js";
import {FacebookFilled, InstagramFilled,MailOutlined,PhoneOutlined,PushpinOutlined,TwitterOutlined} from "@ant-design/icons";
import {Teared} from "../../components/ui/teared/teared.js";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";

const FooterComponent = function (props) {
    const text = useTranslation();
    const isHomepage = props.location.pathname === '/';
    const getIcon = (type) => {
        switch(type){
            case "mail": return <MailOutlined className={'contact-item-icon'}/>;
            case "phone": return <PhoneOutlined className={'contact-item-icon'}/>;
            case "address": return <PushpinOutlined className={'contact-item-icon'}/>;
            default: return null
        }
    };

    const getSocialIcon = (type) => {
        switch(type){
            case "facebook": return <FacebookFilled className={'item-icon'}/>;
            case "instagram": return <InstagramFilled className={'item-icon'}/>;
            case "twitter": return <TwitterOutlined className={'item-icon'}/>;
            default: return null
        }
    };

    return <div className={'footer'}>
        {(props.location.pathname === "/arabia-teaching") ?
            null :
            <Teared className={'footer-top-container'} bottom={false} borderOnly={!isHomepage}>
                <div className={'footer-top'}>
                    <UIText variant={'footer-title'}>{text.footer.top_label}</UIText>
                    {text.footer.socials.map((social) => {
                        return <a target="_blank" href={social.url} key={social.type}
                                  rel="noopener noreferrer">
                            <UIText variant={'paragraph-sub-title'} className={'item'}>
                                {getSocialIcon(social.type)}{social.value}
                            </UIText>
                        </a>
                    })}
                </div>
            </Teared>
        }
        <div className={'footer-main-container'}>
            <div className={'footer-main'}>
                <UIText variant={'header-title'} className={'footer-label'}>{text.footer.label}</UIText>
                <div className={'contacts'}>
                    {text.footer.contact.map((item, key)=>{
                        let href = '#';
                        if(item.type === 'phone'){
                            href="tel:+496170961709"
                        }else if(item.type === 'mail'){
                            href= `mailto:${item.value}`
                        }else if(item.type === 'address'){
                            href= '#'
                        }
                        return <a className={'contact-item'} href={href} key={key} rel="noopener noreferrer">
                            {getIcon(item.type)}
                            <UIText className={'contact-item-value'}>{item.value}</UIText>
                            <UIText className={'contact-item-value'}>{item.subvalue}</UIText>
                            <UIText className={'contact-item-value'}>{item.secondsubvalue}</UIText>
                            <UIText className={'section1-label'}>{item.label}</UIText>
                        </a>
                    })}
                </div>
            </div>
        </div>
        <div className={'footer-terms-container'}>
            <Link to={'/algemene-voorwaarden'}>Algemene voorwaarden </Link>
            <div className={'splitter'}>|</div>
            <Link to={'/privacy'}>Privacy</Link>
            <div className={'splitter'}>|</div>
            <span>© 2020 Firstclassumrah</span>
        </div>
    </div>
};

const Footer = withRouter(FooterComponent)

export {Footer}
import React from "react";
import {Collapse, notification, Space, Tag} from "antd";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import { paymentTypes} from "../../../assets/constants.js";
import {UIDescriptions} from "../../../components/ui/descriptions.js";
import constants from "../../../assets/constants.json";
import {ModelPayment} from "../../../model/payment.js";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import moment from "moment";

const AdminPaymentsList = function (props){
    const resultSet = useModelFeedback(ModelPayment);
    if(resultSet.feedback){
        return resultSet.feedback
    }

    const genExtraCategories = (payment) => (
        <Space>
            {([constants.PAYMENT_TYPE.BANK_TRANSFER, constants.PAYMENT_TYPE.CASH].includes(payment.payment_type)) &&
            <DeleteOutlined
                onClick={() => {
                    ModelPayment.deleteItem(payment.id, (response)=>{

                        notification.success({
                            message: 'Betaling is verwijderd.'
                        });
                    })
                }}
            />}
        </Space>
    );

    return <Collapse expandIconPosition={'start'}>
        <Collapse.Panel disabled={true} show header={<span><Tag>Status</Tag>Bestelling nr.</span>} key={100004} extra={<span>Acties</span>}>
        </Collapse.Panel>
        {(resultSet.data?.length > 0) ?
            resultSet.data
                ?.filter((user)=>{
                    if(props.search === null || props.search === undefined){
                        return true
                    }
                    return user[props.search.type].toString().toLowerCase().includes(props.search.value.toString().toLowerCase())
                })
                ?.sort((a,b) => new Date(b.created_at) - new Date(a.created_at))
                .map((order, key) => {
                    const header = <span>Betalingsnummer # {order.id} <u><i>Bestelling # {order.order_id}</i></u></span>;

                    const orderItem = {
                        ...order,
                    };

                    const labels = {
                        order_id: "Bestelling nr.",
                        payment_type: "Betalingsmethode",
                        amount: "Bedrag",
                        created_at: "Betaald op",
                    };

                    const renderLabelFunctions = {
                        payment_type: (value) => paymentTypes.find((p)=>p.id === value)?.label,
                        created_at: (value) => moment(value).format("DD-MM-YYYY hh:mm:ss")

                    }


                    return <Collapse.Panel header={header}
                                           key={key}
                                           extra={genExtraCategories(order)}>
                        <UIDescriptions labels={labels} renderLabelFunctions={renderLabelFunctions} item={orderItem}/>
                    </Collapse.Panel>
                }) :
            <Collapse.Panel disabled={true} showArrow={false} header={'Er zijn geen betalingen'} key={10005}/>
        }
    </Collapse>
};

export {AdminPaymentsList}
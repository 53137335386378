import './details.scss';
import React, {useEffect, useState} from "react";
import {useModelFeedback} from "../../helpers/model/use_model_feedback.js";
import {ModelProducts} from "../../model/products.js";
import {ModelCrowdfunding} from "../../model/crowdfunding.js";
import {toMoney} from "../../helpers/functions.js";
import moment from 'moment';
import {ageTypes, genderRoomTypes, roomTypes, packageExtras} from "../../assets/constants.js";
import {CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, UserOutlined } from "@ant-design/icons";
import {UIText} from "../../components/ui/text/text.js";
import constants from "../../assets/constants.json";
import {Button, Form, Input, ConfigProvider, notification} from "antd";
import { ModelVouchers } from '../../model/vouchers';
import {
    FaWheelchair,
  } from "react-icons/fa";
  

const PageCartDetails = function (props) {
    const resultSetCrowdfunding = useModelFeedback(ModelCrowdfunding);
    const resultSet = useModelFeedback(ModelProducts);
    const [form] = Form.useForm();

 
    useEffect(()=>{}, [props.cart])

    if(resultSet.feedback){
        return resultSet.feedback
    }

    if(resultSetCrowdfunding.feedback){
        return resultSetCrowdfunding.feedback
    }

    const getRegularProduct = (cartItem) => ([cartItem.id]);
    const getUmrahProducts = (cartItem) => (cartItem.guests.map((guest)=>(guest.product_id)));

    const getAllProducts = () => {
        let productArr= [];
        if(props.cart){
            for ( let i=0; i<props.cart?.length; i++){
                let cartItem=props.cart[i];
                if(cartItem.guests){
                    productArr = productArr.concat(getUmrahProducts(cartItem));
                }else{
                    productArr = productArr.concat(getRegularProduct(cartItem));
                }
            }
        }
        return productArr;
    };



    const onSubmit = (values) => {
        if (values.discount && values.discount.length > 0) {
            const orderProducts = getAllProducts();
            ModelVouchers.requestVoucher(JSON.stringify({label: values.discount}), (resp)=>{
                if (resp.error){
                    notification.error({
                        message: "Deze kortingscode bestaat niet."
                    });
                }else{
                    let discount = resp;
                    discount.flex = JSON.parse(discount.flex);
                    discount['amount'] = discount['amount']/100;
                    let discounts = [];
                    form.resetFields();
                    if(moment(discount.expire_date) < moment()){
                        notification.error({
                            message: "Kortingscode is verlopen."
                        });
                        return;
                    }
                    if(props.discounts.length >= orderProducts.length){
                        notification.error({
                            message: "Kortingscode kan niet vaker worden gebruikt."
                        });
                        return;
                    }
                    
                    if(values.discount === discount.label){
                        if(orderProducts.length > 0 ){
                            if(discount.flex.products !== null && discount.flex.products !== undefined){
                                discounts = orderProducts?.map((product)=>(discount.flex.products.indexOf(product) > -1 ? discount : []));
                            }else{
                                discounts = [discount];
                            }
                        }
                        discounts = props.discounts.concat(discounts);
                        props.setDiscounts(discounts);
                    }
                    
                    notification.success({
                        message: "Kortingscode succesvol toegevoegd."
                    })
                }
            })
        }
    }

    const calculateTotal = (type) => {
        const crowdFundingPrice = props.cart.reduce((price, cartItem)=>{
            if(cartItem.type === 'fund'){
                return price + cartItem.value
            }
            return price;
        }, 0)
        if(type === 'vat'){
            return props.cart.reduce((price, cartItem)=>{
                price = cartItem.exclusive ? price+850 : price;
                if(cartItem.available_days){
                    const product = resultSet.data.products.find((p) => p.product_id === cartItem.id);
                    return price + parseFloat(product?.price);
                }else if(cartItem.guests){
                    let subPrice = 0;
                    for(const item of cartItem.guests){
                        const productPrice = resultSet.data.products.find((p)=> p.product_id === item.product_id)?.price;
                        const passportPrice = !item.european_passport ? 350 : 0;
                        const bedDiscountPrice = 0;
                        subPrice += parseFloat((parseInt(productPrice)+passportPrice+bedDiscountPrice));
                    }
                    // if(cartItem.exclusive === true && cartItem.guests.filter((guest)=>guest.age_category !== constants.AGE_CATEGORY.BABY).length < cartItem.max_guests ){
                    //     const guestsLeft = cartItem.max_guests - cartItem.guests.filter((guest)=>guest.age_category !== constants.AGE_CATEGORY.BABY).length
                    //     const adultID = cartItem.guests.find((guest)=>guest.age_category === constants.AGE_CATEGORY.ADULT)
                    //     const extraProduct = resultSet.data.products.find((p)=> p.product_id === adultID?.product_id);
                    //     if(guestsLeft > 0 && adultID){
                    //         for (let i = 0; i < guestsLeft; i++){
                    //             subPrice += parseFloat(extraProduct?.price)
                    //         }
                    //     }
                    // }
                    return price + subPrice;
                }
                return price;
            }, 0) / 121 * 21
        }

        if(type === 'exclusive_vat'){
            return parseFloat(props.cart.reduce((price, cartItem)=>{
                price = cartItem.exclusive ? price+850 : price;
                if(cartItem.available_days){
                    const product = resultSet.data.products.find((p) => p.product_id === cartItem.id);
                    return price + parseFloat(product?.price);
                }else if(cartItem.guests){
                    let subPrice = 0;
                    for(const item of cartItem.guests){
                        const productPrice = resultSet.data.products.find((p)=> p.product_id === item.product_id)?.price;
                        const passportPrice = !item.european_passport ? 350 : 0;
                        const bedDiscountPrice = 0;
                        subPrice += parseFloat((parseInt(productPrice)+passportPrice+bedDiscountPrice));                    }

                    // if(cartItem.exclusive === true && cartItem.guests.filter((guest)=>guest.age_category !== constants.AGE_CATEGORY.BABY).length < cartItem.max_guests ){
                    //     const guestsLeft = cartItem.max_guests - cartItem.guests.filter((guest)=>guest.age_category !== constants.AGE_CATEGORY.BABY).length
                    //     const adultID = cartItem.guests.find((guest)=>guest.age_category === constants.AGE_CATEGORY.ADULT)
                    //     const extraProduct = resultSet.data.products.find((p)=> p.product_id === adultID?.product_id);
                    //     if(guestsLeft > 0 && adultID){
                    //         for (let i = 0; i < guestsLeft; i++){
                    //             subPrice += parseFloat(extraProduct?.price)
                    //         }
                    //     }
                    // }
                    return price + subPrice;
                }
                return price;
            }, 0) / 121 * 100) + parseFloat(crowdFundingPrice)
        }

        return props.cart.reduce((price, cartItem)=>{
            price = cartItem.exclusive ? price+850 : price;
            if(cartItem.type === 'fund'){
                return price + cartItem.value
            }else if(cartItem.available_days){
                const product = resultSet.data.products.find((p) => p.product_id === cartItem.id);
                return price + parseFloat(product?.price);
            }else if(cartItem.guests){
                let subPrice = 0;
                for(const item of cartItem.guests){
                    const productPrice = resultSet.data.products.find((p)=> p.product_id === item.product_id)?.price;
                    const passportPrice = !item.european_passport ? 350 : 0;
                    const bedDiscountPrice = !item?.bed_included ? -250 : 0;
                    subPrice += parseFloat((parseInt(productPrice)+passportPrice+bedDiscountPrice)); 
                }
                // if(cartItem.exclusive === true && cartItem.guests.filter((guest)=>guest.age_category !== constants.AGE_CATEGORY.BABY).length < cartItem.max_guests ){
                //     const guestsLeft = cartItem.max_guests - cartItem.guests.filter((guest)=>guest.age_category !== constants.AGE_CATEGORY.BABY).length
                //     const adultID = cartItem.guests.find((guest)=>guest.age_category === constants.AGE_CATEGORY.ADULT)
                //     const extraProduct = resultSet.data.products.find((p)=> p.product_id === adultID?.product_id);
                //     if(guestsLeft > 0 && adultID){
                //         for (let i = 0; i < guestsLeft; i++){
                //             subPrice += parseFloat(extraProduct?.price)
                //         }
                //     }
                // }
            
                return price + subPrice;
            }
            return price;
        }, 0)
    };


    const createCrowdFunding = (cartItem, key) => {
        const fund = resultSetCrowdfunding.data.find((fund)=> fund.id === cartItem.id);
        const title = `Donatie voor: ${fund.label}`;
        const price = cartItem?.value || 0;
        return <>
            <div className={'cart-detail-title center-space-between'}>
                <h2>{title}</h2>
                <div className={'cart-detail-title-price-container'}>
                    <h2 className={'cart-detail-title-price'}>{toMoney(price)}</h2>
                    {(props.onEdit) && <EditOutlined onClick={()=>{props.onEdit(cartItem, key)}}/>}
                    {(props.onDelete) && <DeleteOutlined onClick={()=>{props.onDelete(cartItem, key)}}/>}
                </div>
            </div>
        </>
    };

    const createArabia = (cartItem, key) => {
        const fund = resultSet.data.products.find((fund)=> fund.product_id === cartItem.id);
        const title = `Arabia teaching: ${fund?.label}`;
        const price = parseFloat(fund?.price);
        return <>
            <div className={'cart-detail-title center-space-between'}>
                <h2>{title}</h2>
                <div className={'cart-detail-title-price-container'}>
                    <h2 className={'cart-detail-title-price'}>{toMoney(price)}</h2>
                    {(props.onEdit) && <EditOutlined onClick={()=>{props.onEdit(cartItem, key)}}/>}
                    {(props.onDelete) && <DeleteOutlined onClick={()=>{props.onDelete(cartItem, key)}}/>}
                </div>
            </div>
            <div className={'tag-container'}>
                <span className={'tag'}>Beschikbaarheid: {cartItem?.available_days?.join(', ')}</span>
                <span className={'tag'}>Beschikbaarheid: {cartItem?.available_times?.join(', ')}</span>
            </div>
        </>
    };

    const createProduct = (cartItem, key) => {
        const item = resultSet.data.products.find((product)=> product.product_id === cartItem.id);
        const title = item?.label;
        const price = parseFloat(item?.price);
        return <>
            <div className={'cart-detail-title center-space-between'}>
                <h2>{title}</h2>
                <div className={'cart-detail-title-price-container'}>
                    <h2 className={'cart-detail-title-price'}>{toMoney(price)}</h2>
                    {(props.onDelete) && <DeleteOutlined onClick={()=>{props.onDelete(cartItem, key)}}/>}
                </div>
            </div>
        </>
    };

    const createUmrah = (cartItem, key) => {
        const product_id = cartItem.guests[0].product_id;
        const product = resultSet.data.products.find((p)=> p.product_id === product_id);
        const roomType = roomTypes.find((t)=>t.id === product?.type)
        const genderType = genderRoomTypes.find((t)=>t.id === cartItem.room_gender)
        let totalPrice = cartItem.guests.reduce((total, guest)=>{
            const passportPrice = guest.european_passport ? 0 : 350;
            const bedIncludedPrice = guest.bed_included ? 0 : -250;
            const guestProduct = resultSet.data.products.find((p)=> p.product_id === guest.product_id);
            return total + parseFloat(parseInt(guestProduct?.price)+passportPrice+bedIncludedPrice);
        }, 0)
        totalPrice = cartItem.exclusive ? totalPrice+850 : totalPrice;

        // if(cartItem.exclusive === true && cartItem.guests.filter((guest)=>guest.age_category !== constants.AGE_CATEGORY.BABY).length < cartItem.max_guests ){
        //     const guestsLeft = cartItem.max_guests - cartItem.guests.filter((guest)=>guest.age_category !== constants.AGE_CATEGORY.BABY).length
        //     const adultID = cartItem.guests.find((guest)=>guest.age_category === constants.AGE_CATEGORY.ADULT)
        //     const extraProduct = resultSet.data.products.find((p)=> p.product_id === adultID?.product_id);
        //     if(guestsLeft > 0 && adultID){
        //         for (let i = 0; i < guestsLeft; i++){
        //             totalPrice += parseFloat(extraProduct?.price)
        //         }
        //     }
        // }

        return <>
            <div className={'cart-detail-title center-space-between'}>
                <h2>{product?.label} - {roomType?.label} kamer</h2>
                <div className={'cart-detail-title-price-container'}>
                    <h2 className={'cart-detail-title-price'}>{toMoney(totalPrice)}</h2>
                    {(props.onDelete) && <DeleteOutlined onClick={()=>{props.onDelete(cartItem, key)}}/>}
                </div>
            </div>
            <div>
                {(cartItem.exclusive) &&  <span>Deze kamer is exclusief voor jou, houd er rekening mee dat er een meerprijs voor in rekening word gebracht.</span>}
            </div>
            <div className={'tag-container'}>
                <span className={'tag'}>{genderType?.label} kamer</span>
                <span className={'tag'}>{moment(product?.start).format('DD-MM-YYYY')} t/m {moment(product?.end).format('DD-MM-YYYY')}</span>
                { cartItem.exclusive && <span className={'tag'}><CheckOutlined/> Kamer voor jezelf</span>}
                {cartItem.extras !== undefined && <span className={'tag extra'}>{packageExtras[cartItem.extras.extra]}</span>}
            </div>

            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                {cartItem.guests.map((guest, key)=>{
                    const product = resultSet.data.products.find((p)=> p.product_id === guest?.product_id);
                    return <div className="columnContainer" key={key}>
                        <div className="column itemIcon">
                            <p></p>
                            <UserOutlined style={{fontSize: '1.25rem'}}/>
                        </div>
                        <div className="column name">
                            <UIText variant={'paragraph-bold'}>Naam</UIText>
                            <UIText>{guest.first_name} {guest.last_name} {JSON.parse(guest?.flex)?.disabled && <FaWheelchair className="disabled"/>}</UIText>

                        </div>
                        <div className="column">
                            <UIText variant={'paragraph-bold'}>Leeftijd</UIText>
                            <UIText>{ageTypes.find((type)=>type.id === guest.age_category).label}</UIText>
                        </div>
                        <div className="column itemPriceCont">
                            <h3>{toMoney(product?.price || 0)}</h3>
                            {(cartItem.exclusive) && <div><i>Kamer voor jezelf +850,-</i></div>}
                            <div><i>{!guest?.european_passport && "Niet-europees paspoort  + \u20ac350,-"}</i></div>
                            <div><i>{!guest?.bed_included && "Zonder bed  - \u20ac250,-"}</i></div> 
                        </div>
                    </div>
                })}

            </div>
        </>
    };


    const getDiscounts = () => {               
        let resultDiscount = 0;
        let propDiscounts = props.discounts ? props.discounts : [];
        for(const item of propDiscounts){
            resultDiscount+= item.amount;
        }
        return resultDiscount;
    };

    return <ul className={'cart-details-container'}>
        {props.cart?.length > 0 ?
            <>
                {props.cart?.map((cartItem, key) => {
                    return <li className={'cart-detail-item'} key={key}>
                        {(cartItem.guests) ?
                            createUmrah(cartItem, key) :
                            (cartItem.type === 'fund') ?
                                createCrowdFunding(cartItem, key) :
                                (cartItem.available_days) ?
                                    createArabia(cartItem, key) :
                                    createProduct(cartItem, key)
                        }
                    </li>
                })}
                {
                    props.discounts?.map((discount, key)=>(
                        <li key={"discount " +key} className={'cart-detail-item'}>
                            <div className="voucherContainer">
                                <UIText variant={'paragraph-bold'}>Korting</UIText>
                                <div className='end'>
                                <UIText>{discount.label}</UIText>
                                <UIText>{toMoney(-discount.amount)}</UIText>
                                <DeleteOutlined className="delIcon" onClick={()=>(props.deleteDiscount(key))}/>
                                </div>
                              
                            </div>
                        </li>
                    ))
                    }
                <li className={'cart-detail-item'}>
                    <div className={'cart-detail-title center-space-between'}>
                        <h3>Totaalbedrag exclusief BTW</h3>
                        <h3 className={'cart-detail-title-price'}>{toMoney((calculateTotal()-getDiscounts())/121*100)}</h3>
                    </div>
                    <div className={'cart-detail-title center-space-between'}>
                        <h3>BTW (21%)</h3>
                        <h3 className={'cart-detail-title-price'}>{toMoney((calculateTotal()-getDiscounts())/121*21)}</h3>
                    </div>
                    <div className={'cart-detail-title center-space-between'}>
                        <h2>Totaalbedrag inclusief BTW</h2>
                        <h2 className={'cart-detail-title-price'}>{toMoney(calculateTotal()-getDiscounts())}</h2>
                    </div>
                </li>
                {
                    props.discounts && 
                    <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: '#00b96b',
                      },
                    }}
                  >
                     <Form  form={form }className="voucherForm" layout={'horizontal'} onFinish={onSubmit}>
                        <Form.Item name="discount"
                          label="Kortingscode">
                        <Input/>
                        </Form.Item>
                        <Button type="primary"  className="contact-form-button" htmlType="submit">Toevoegen</Button>
                    </Form>
                  </ConfigProvider>
                  
                }
            </> :
            <li className={'cart-detail-item'}>
                <UIText>Winkelwagen is leeg.</UIText>
            </li>
        }
    </ul>
};

PageCartDetails.defaultProps = {}

export {PageCartDetails}
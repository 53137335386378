import './article.scss';
import React from "react";
import {UIText} from "../../ui/text/text.js";
import {ArticlesDetailSeeMore} from "./see-more.js";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {Link,Redirect, withRouter} from "react-router-dom";
import parse from 'html-react-parser';
import defaultImage from "../../../assets/images/kennis_item.jpg";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelArticles} from "../../../model/articles.js";

const ArticlesDetailArticleComponent = function (props) {
    const selectedArticlePath = props.match.params.kennisId;
    const resultSet = useModelFeedback(ModelArticles)

    if(resultSet.feedback){
        return resultSet.feedback
    }
    const article = resultSet.data.articles.find((article) => {
        return article.id.toString() === selectedArticlePath.toString()
    });

    if(article){
        return <div className={'articles-detail-article-container'}>
            <div className={'articles-detail-article'}>
                <Link to={'/kennis'}><UIText variant={'paragraph-title'}><ArrowLeftOutlined/>Terug </UIText></Link>
                <img alt={''} className={'articles-image'} src={article.img ? article.img : defaultImage}/>
                <UIText variant={'paragraph-title'}>{article.label}</UIText>
                {parse(article.html)}
            </div>
            <ArticlesDetailSeeMore selectedArticle={article}/>
        </div>
    }

    return <Redirect to={'/kennis'}/>;
};

const ArticlesDetailArticle = withRouter(ArticlesDetailArticleComponent)

export {ArticlesDetailArticle}
import './categories.scss'
import React from "react";
import {UIText} from "../ui/text/text.js";
import {UIButton} from "../ui/button/button.js";

const ArticlesCategories = function (props) {
    const {categories, filter, setFilter} = props

    return <div className={'articles-categories-container'}>
        <UIText variant={'paragraph-title'}>Categorieën</UIText>
       <div className={'articles-categories'}>
           <UIButton onClick={()=>setFilter([])} className={`category-button ${(filter.length === 0) ? 'selected' : ''}`}>
               Alles
           </UIButton>
           {categories.map(((category, key)=>{
               return <UIButton key={key} className={`category-button ${(filter.includes(category.id)) ? 'selected' : ''}`}
                                 onClick={()=>{
                                     let filterClone = [...filter];
                                     if(filterClone.includes(category.id)) {
                                         filterClone = filterClone.filter((cat)=>cat !== category.id)
                                     }else {
                                         filterClone.push(category.id)
                                     }
                                     setFilter(filterClone)
                                 }}>
                   {category.label}
               </UIButton>
           }))}
       </div>
    </div>
};

export {ArticlesCategories}
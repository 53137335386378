import React from "react";
import {ContactBanner} from "./banner.js";
import {ContactSection1} from "./section1.js";
import {ContactSection2} from "./section2.js";

const PageContact = function (props) {
    return <div>
        <ContactBanner/>
        <ContactSection1/>
        <ContactSection2/>
    </div>
};

export {PageContact}
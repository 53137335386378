import './section2.scss'
import React from "react";
import {UIText} from "../../components/ui/text/text.js";
import {Form, Input, Button, notification} from 'antd';
import {ModelContact} from "../../model/contact.js";
import { ReactBingmaps } from 'react-bingmaps';
import ReCAPTCHA from "react-google-recaptcha";

const ContactSection2 = function (props) {
    const recaptchaRef = React.createRef();
    const onSubmit = (values) => {
        if(recaptchaRef.current.getValue().length > 0) {
            ModelContact.send(values, () => {
                notification.success({
                    message: "Mail is verzonden! Binnenkort zal er contact worden opgenomen via " + values.email
                })
            })
        }else{
            notification.error({
                message: "Verifieer dat u geen robot bent!"
            })
        }
    };

    return <div className={'contact-section2'}>
        <div className={'section2-item'}>
            <UIText variant={'header-title'}>Contact formulier</UIText>
            <Form layout={'vertical'} onFinish={onSubmit}>
                <Form.Item name="name" label="Naam" rules={[{ required: true, message: "Naam is verplicht!" }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="email" label="Email" rules={[{ required: true, message: "Email is verplicht!" }, {type: 'email', message: 'Geen geldig email adres.'}]}>
                    <Input />
                </Form.Item>
                <Form.Item name="message" label="Bericht" rules={[{ required: true, message: "Bericht is verplicht" }]}>
                    <Input.TextArea autoSize={{ minRows: 6, maxRows: 9 }}/>
                </Form.Item>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LceuMkjAAAAAEvspC5iGjh-ZQwnwvpB4p5Q8BTX"
                />
                <Button type="primary" htmlType="submit" className="contact-form-button">
                    Verstuur
                </Button>
            </Form>
        </div>
        <div className={'contact-map'}>
            <ReactBingmaps
                bingmapKey = "AmxeRpDSRYHAZZAAG2-RGWjsqoY95M96uur68GpCug2l5SmQCopXdMo6vImQ0298"
                center = {[52.374985946250426, 4.830767717175665]}
                zoom={15}
                infoboxesWithPushPins ={[
                    {"location":[52.374985946250426, 4.830767717175665],
                        "infoboxOption": { title: 'Adres gegevens', description: 'Louis Couperusstraat 41, 1064 CC Amsterdam' },
                        "pushPinOption":{ title: 'Firstclassumrah', description: 'Pushpin' },
                        "infoboxAddHandler": {"type" : "click", callback: ()=>{} },
                        "pushPinAddHandler": {"type" : "click", callback: ()=>{}}}
                ]}
            >
            </ReactBingmaps>
        </div>
    </div>
}

export {ContactSection2}
import "./section.scss"
import React from "react";
import {UIText} from "../../components/ui/text/text.js";
import {Teared} from "../../components/ui/teared/teared.js";
import AboutSectionImage from '../../assets/images/arabia_beginner.jpg'

const AboutSection = function (props) {
    return <Teared color={'white'} className={'about-section-container'}>
        <div className={'about-section'}>
        <div>
        <UIText className={'section-about'}>Wij zijn een gespecialiseerde reisorganisatie die zich volledig richt op het verzorgen van jouw Umrah-ervaring. Wat ons uniek maakt, is onze toewijding om jouw hele reis met de hoogste kwaliteit te organiseren. Graag lichten we toe wat dit precies inhoudt!</UIText>
      <UIText className={'section-about'}>Umrah is een zeer bijzondere gebeurtenis, een prachtige aanbidding die je uitsluitend voor Allah Subhanahu wa Ta'ala verricht. Het omvat verschillende handelingen die je dient te kennen, en aangezien de meesten dit niet frequent doen, vereist het de juiste voorbereiding.</UIText>
      <UIText className={'section-about'}>Bij ons begint je Umrah-reis al in Nederland. Het is essentieel dat je op de hoogte bent van wat je moet doen en zeggen tijdens deze eerbiedwaardige aanbidding. Middels lessen zullen wij je hierop voorbereiden, zodat je precies weet wat je te wachten staat en je optimaal kunt voorbereiden.</UIText>
      <UIText className={'section-about'}>Je reis naar Mekka en Medina zal uiterst aangenaam zijn, omdat wij alles tot in de puntjes voor je hebben uitgestippeld. Vanaf Nederland wordt je opgevangen door een ervaren reisleider, en eenmaal aangekomen hoef je alleen maar hun aanwijzingen te volgen. Je hoeft je geen zorgen te maken over de transfers en het hotel, alles zal perfect geregeld zijn. We hebben dit namelijk zorgvuldig vooraf onderzocht. De reisleider zal je tijdens de Umrah stap voor stap begeleiden, en zowel voor als na de Umrah zijn er tevens mogelijkheden om deel te nemen aan excursies.</UIText>
      <UIText className={'section-about'}>Terug in Nederland zal deze Umrah, in sha Allah, een ervaring zijn die je nooit zult vergeten. Dit is ons streven en de reden waarom wij First Class Umrah hebben opgericht.</UIText>

        </div>

            <img className={'section-image'} alt={'section_image'} src={AboutSectionImage}/>
        </div>
    </Teared>
};


export {AboutSection}

import './products.scss';
import React, {useState} from "react";
import {UIText} from "../../components/ui/text/text.js";
import {TearedTriangle} from "../../components/ui/teared/teared-triangle.js";
import {UIButton} from "../../components/ui/button/button.js";
import beginnerImage from '../../assets/images/arabia_beginner.jpg'
import matigImage from '../../assets/images/arabia_matig.jpg'
import gevorderdImage from '../../assets/images/arabia_gevorderd.jpg'
import nativeImage from '../../assets/images/arabia_native.jpg'
import {toMoney} from "../../helpers/functions.js";
import {useModelFeedback} from "../../helpers/model/use_model_feedback.js";
import {ModelProducts} from "../../model/products.js";
import constants from '../../assets/constants.json'
import {Button, Form, Modal, Input, notification} from "antd";
import {ModelContact} from "../../model/contact.js";
import {PageArabiaTeachingAdd} from "./add.js";
import ReCAPTCHA from "react-google-recaptcha";

const images = {
    11: beginnerImage,
    12: matigImage,
    13: gevorderdImage,
    14: nativeImage
}

const ArabiaTeachingProducts = function (props) {
    const recaptchaRef = React.createRef();
    const [modal, setModal] = useState('');
    const resultSet = useModelFeedback(ModelProducts);

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const selectedProduct = resultSet.data.products.find((product)=>product.type === modal)

    const onContactSubmit = (values) => {
        const mailValues = {
            name: values.name,
            email: values.email,
            message: `BETREFT ARABIA TEACHING INTAKE: ${values.message}`
        };
        if(recaptchaRef.current.getValue().length > 0) {
            ModelContact.send(mailValues, () => {
                notification.success({
                    message: "Mail is verzonden! Binnenkort zal er contact worden opgenomen via " + values.email
                })
            });
            setModal('')
        }else{
            notification.error({
                message: "Verifieer dat u geen robot bent!"
            })
        }
    };



    return <div className={'arabia-products-container'}>

            <TearedTriangle color={'black'}>
                <div className={'arabia-products'}>
                    <UIText variant={'header-title'} className={'arabia-products-label'}>
                        Wat bieden wij?
                    </UIText>
                    <hr className={'arabia-products-line'}/>
                    <UIText variant={'paragraph-sub-title'} className={'arabia-products-description'}>
                    Wij bieden Arabische lessen aan op vier verschillende niveaus:
                    </UIText>

                    <UIText variant={'paragraph-sub-title'} className={'arabia-products-description'}>
                    <li>Beginner: wij nemen u mee in de basisbeginselen van de Arabische taal. Geschikt voor iedereen die nog (bijna) geen kennis heeft met betrekking tot het Arabisch en door een ervaren docent wil starten met het leren van de Arabische taal.</li>
  <li>Matig gevorderd: wanneer u al enige kennis heeft van het Arabisch en deze kennis graag zou willen uitbreiden. In deze cursus ligt de focus op het lezen en het verbeteren van grammaticale kennis..</li>
  <li>Gevorderd: geschikt voor cursisten die al een goede basis hebben met betrekking tot de Arabische taal en graag willen focussen op de Arabische grammatica.</li>
  <li>Near native: deze cursus is alleen geschikt als u het Arabisch al op hoog niveau onder de knie heeft en vloeiend Arabisch kunt lezen, schrijven en spreken. Voor cursisten van dit niveau, wordt de inhoud van de lessen persoonlijk afgestemd op de behoeften van de cursist.</li>
                    </UIText>

                    <UIText variant={'paragraph-sub-title'} className={'arabia-products-description'}>
De lessen op alle vier deze niveaus worden wekelijk gegeven op een vast moment in de week, verspreid over een periode van in totaal drie maanden. Eén les duurt 2,5 uur en het tarief voor alle vier de niveaus is gelijk.
                  </UIText>

                  <UIText variant={'paragraph-sub-title'} className={'arabia-products-description'}>
Gebruik onderstaande blokken uw uzelf direct te registeren voor onze taallessen op het door u gewenste niveau. Wanneer je twijfelt op welk niveau jouw kennis van de Arabische taal het beste kan worden ingedeeld, neem geheel vrijblijvend contact met ons op voor een intake. Tijdens deze intake kunnen wij jouw startniveau is. Gebruik hiervoor de button onderaan deze pagina.                 </UIText>
                </div>
                <div className={'arabia-products-layout'} ref={props.myRef}>
                    {resultSet.data.products
                        .filter( (product)=> [constants.PRODUCT_TYPE.ARABIA_AMATEUR, constants.PRODUCT_TYPE.ARABIA_BEGINNER, constants.PRODUCT_TYPE.ARABIA_NATIVE, constants.PRODUCT_TYPE.ARABIA_EXPERT].includes(product.type))
                        .map((product, key)=>{
                            const flex = JSON.parse(product.flex);
                            return <div className={'arabia-products-item-container'} key={key}>
                                <div className={'arabia-products-item'}>
                                    <UIText variant={'paragraph-title'}>{product.label}</UIText>
                                    <img className={'arabia-products-item-image'} alt={''} src={images[product.type]}/>
                                    <UIText variant={'paragraph-bold'} className={'arabia-products-item-desc'}>{product.description}</UIText>
                                    <hr className={'arabia-product-line'}/>
                                    {flex?.bulletpoints.map((item, key)=>{
                                        return <UIText key={key} className={'arabia-product-text'}>{item.label}</UIText>
                                    })}
                                    <UIText className={'arabia-product-text'}>{toMoney(product.price)}</UIText>
                                </div>
                                <UIButton className={'fcu-ui-purple arabia-product-button'} onClick={()=>setModal(product.type)}>
                                    Registreer
                                </UIButton>
                            </div>
                    })}
                </div>
                <div className={'arabia-meeting'}>
                    <UIText variant={'banner-description'} className={'arabia-meeting-label'}>Weet je niet wat jouw niveau is? <br/> Plan een intake in!</UIText>
                    <UIButton className={'fcu-ui-purple arabia-meeting-button'} onClick={()=>setModal('contact')}>Plan intake</UIButton>
                </div>
                {(modal === 'contact') &&
                    <Modal onCancel={()=>setModal('')} title={`Neem contact op voor een intake`} maskClosable={false} footer={false} className={'modal'} visible={modal}>
                        <Form layout={'vertical'} onFinish={onContactSubmit}>
                            <Form.Item name="name" label="Naam" rules={[{ required: true, message: "Naam is verplicht!" }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="email" label="Email" rules={[{ required: true, message: "Email is verplicht!" }, {type: 'email', message: 'Geen geldig email adres.'}]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="message" label="Bericht" rules={[{ required: true, message: "Bericht is verplicht" }]}>
                                <Input.TextArea autoSize={{ minRows: 6, maxRows: 9 }}/>
                            </Form.Item>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LceuMkjAAAAAEvspC5iGjh-ZQwnwvpB4p5Q8BTX"
                            />
                            <Button type="primary" htmlType="submit" className="contact-form-button">
                                Verstuur
                            </Button>
                        </Form>
                        <div className={'modal-footer'}>
                            <div className={'modal'}><div className={'modal-footer'} /></div>
                        </div>
                    </Modal>

                }
            </TearedTriangle>
        {(modal && modal !== 'contact') &&
                <PageArabiaTeachingAdd selectedProduct={selectedProduct} modal={modal} setModal={setModal}/>
        }
    </div>
};

export {ArabiaTeachingProducts}

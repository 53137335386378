import './section1.scss'
import React from "react";
import {Teared} from "../../components/ui/teared/teared.js";
import {UIText} from "../../components/ui/text/text.js";
import {useTranslation} from "../../helpers/languange.js";
import {PushpinOutlined, PhoneOutlined, MailOutlined} from "@ant-design/icons";

const ContactSection1 = function (props) {
    const text = useTranslation();
    const getIcon = (type) => {
        switch(type){
            case "mail": return <MailOutlined className={'item-icon'}/>;
            case "phone": return <PhoneOutlined className={'item-icon'}/>;
            case "address": return <PushpinOutlined className={'item-icon'}/>;
            default: return null
        }
    };

    return <Teared color={'white'} className={'contact-section1'} bottom={false}>
        <div className={'section1-text'}>
            <UIText className='contact-text'>Indien u nog vragen heeft met betrekking tot onze Umrah-reizen, nodigen wij u vriendelijk uit om contact met ons op te nemen. Wij staan graag voor u klaar en zijn bereikbaar via de onderstaande contactgegevens:</UIText>
            <UIText className='contact-text'>Daarnaast bieden wij u ook de mogelijkheid om ons contactformulier in te vullen. Het enige wat u hoeft te doen, is uw naam, e-mailadres en het bericht dat u naar ons wilt sturen invullen. Zodra wij uw bericht ontvangen, zullen wij zo spoedig mogelijk contact met u opnemen.</UIText>
            <UIText className='contact-text'>Indien u op de hoogte wilt blijven van onze nieuwsberichten, nodigen wij u uit om ons te volgen op Facebook, Instagram en Twitter. Op deze platforms kunt u tevens foto's en berichten bekijken van onze prachtige Umrah-reizen die reeds hebben plaatsgevonden. Op deze manier kunt u zich alvast goed voorbereiden op uw toekomstige Umrah-reis, in shaa Allah.</UIText>
            <UIText className='contact-text'>Wij kijken ernaar uit om van u te horen en u te assisteren bij al uw vragen en behoeften.</UIText>
        </div>
        <div className={'section1-c'}>
            {text.footer.contact.map((item, key)=>{
                let href = ''
                if(item.phone){
                    href="tel:+496170961709"
                }else if(item.mail){
                    href= `mailto:${item.value}`
                }else if(item.address){
                    href= '#'
                }
                return <a className={'section1-item'} href={href} key={key}>
                    {getIcon(item.type)}
                    <UIText>{item.value}</UIText>
                    <UIText>{item.subvalue}</UIText>
                    <UIText className={'section1-label'}>{item.label}</UIText>
                </a>
            })}
        </div>
    </Teared>
};

export {ContactSection1}

import React from "react";
import { Button, DatePicker, Form, Input, Modal, notification, Rate, Radio } from "antd";
import { UICollapseable } from "../../components/ui/collapseable.js";
import { ModelContact } from "../../model/contact.js";
import ReCAPTCHA from "react-google-recaptcha";
import moment from 'moment';
import {Link} from "react-router-dom";
import "./hadjpopup.scss";

const PageHadjSingle = function (props) {
    const recaptchaRef = React.createRef();

    const onSubmit = (values) => {
        if (recaptchaRef.current.getValue().length > 0) {
            const singleDetails = {
                desired_year: values.desired_year,
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                date_of_birth: values.date_of_birth.format("DD-MM-YYYY"),
                nationality: values.nationality, 
                phone_number: values.phone_number,
                address: values.address,
                zip: values.zip,
                city: values.city,
                country: values.country,
            };

            ModelContact.requestSingleHadj(singleDetails, () => {
                props.onClose();
                notification.success({
                    message: "Er zal binnen 5 tot 7 dagen contact opgenomen worden.",
                    duration: 5
                })
            })
        } else {
            notification.error({
                message: "Verifieer dat u geen robot bent!"
            })
        }
        

    }
    const createYearsArray= () => {
        let renderArr=[];
        for (let i = 0; i < 3; i++) {
            renderArr.push(moment().year()+i);
        }
        return renderArr
    }
    const renderYears= () => {
        let renderArr=[];
        for (let i = 0; i < 3; i++) {
            renderArr.push(<Radio.Button value={moment().year()+i}>{moment().year()+i}</Radio.Button>);
        }
        return renderArr;
    }

    const preventSubmitSpam = () =>{
       
    }

    return <Modal onCancel={props.onClose}
        maskClosable={false}
        title={'Reserveren Umrah Marokko'}
        open={props.selected !== null}
        className={'modal'}
        footer={false}>
        <Form layout={'vertical'}
        onFinish={onSubmit}>
            <UICollapseable title={'Umrah from Morocco ' + moment().year()} style={{ margin: 0 }}>
                <p>We willen het voor onze broeders en zusters die vanuit Marokko komen gemakkelijk maken om de Umrah te verrichten. Daarom bieden we de mogelijkheid om rechtstreeks vanuit Marokko naar Mekka en Medina te reizen. Grijp deze unieke kans om samen met je familie vanuit Marokko een onvergetelijke reis te maken. We hebben verschillende pakketreizen samengesteld voor onze klanten uit Marokko. Bovendien kun je eenvoudig zien in welk hotel je zult verblijven, de afstand tot de Haramain en wat er allemaal is inbegrepen. De prijzen van deze pakketten zijn gelijk aan die van Nederland, met uitzondering van de visumkosten van € 350,- die aan het pakket worden toegevoegd. Meld je aan via het onderstaande formulier (vul alleen de gegevens van de hoofdaanvrager in). Na aanmelding zal een medewerker van First Class Umrah binnen 1 werkdag contact met je opnemen om de inschrijving verder af te handelen.</p>
                <Link to={'/pakketten'}>Klik hier voor meer informatie over de pakketen.</Link>
                <br></br>
                <br></br>
            </UICollapseable>
            <UICollapseable title={'Gewenst pakket'} style={{ margin: 0 }}>
                <Form.Item name="desired_year" label="Selecteer het jaar dat je wilt gaan" rules={[{ required: true, message: "De keuze van het jaar is verplicht!" }]} style={{ width: '100%', marginRight: '.5rem' }}>
                    <Radio.Group>
                        {/* {
                            renderYears()
                        } */}
                        <Radio.Button value="september">Umrah September</Radio.Button>
                        <Radio.Button value="herfstvakantie">Umrah Herfstvakantie</Radio.Button>
                        <Radio.Button value="november">Umrah November</Radio.Button>
                        <Radio.Button value="wintervakantie">Umrah Wintervakantie</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </UICollapseable>
            <UICollapseable title={'Gebruiker details'} style={{ margin: 0 }}>
                <Form.Item name="email" label="Email" rules={[{ required: true, message: "Email is verplicht!" }, { type: 'email', message: 'Geen geldig email adres.' }]} style={{ width: '100%', marginRight: '.5rem' }}>
                    <Input />
                </Form.Item>
                <div className={'center-space-between mobile-vertical'}>
                    <Form.Item name="first_name" label="Voornaam" rules={[{ required: true, message: "Voornaam is verplicht!" }]} style={{ width: '100%', marginRight: '.5rem' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="last_name" label="Achternaam" rules={[{ required: true, message: "Achternaam is verplicht!" }]} style={{ width: '100%' }}>
                        <Input />
                    </Form.Item>
                </div>
                <Form.Item name="date_of_birth" label="Geboortedatum" rules={[{ required: true, message: "Geboortedatum is verplicht!" }]} style={{ width: '100%' }}>
                    <DatePicker format={"DD-MM-YYYY"} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="nationality" label="Nationaliteit" rules={[{ required: true, message: "Nationaliteit is verplicht!" }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="phone_number" label="Telefoonnummer" rules={[{ required: true, message: "Telefoonnummer is verplicht!" }]}>
                    <Input />
                </Form.Item>
                <div className={'center-space-between mobile-vertical'}>
                    <Form.Item name="address" label="Adres" rules={[{ required: true, message: "Adres is verplicht!" }]} style={{ width: '100%', marginRight: '.5rem' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="zip" label="Postcode" rules={[{ required: true, message: "Postcode is verplicht!" }]} style={{ width: '100%' }}>
                        <Input />
                    </Form.Item>
                </div>
                <div className={'center-space-between mobile-vertical'}>
                    <Form.Item name="city" label="Plaats" rules={[{ required: true, message: "Plaats is verplicht!" }]} style={{ width: '100%', marginRight: '.5rem' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="country" label="Land" rules={[{ required: true, message: "Land is verplicht!" }]} style={{ width: '100%' }}>
                        <Input />
                    </Form.Item>
                </div>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LceuMkjAAAAAEvspC5iGjh-ZQwnwvpB4p5Q8BTX"
                />
            </UICollapseable>
            <Button type="primary" htmlType="submit" className="contact-form-button">
                Verzoek indienen
            </Button>
        </Form>
        <div className={'modal-footer'}>
            <div className={'modal'}><div className={'modal-footer'} /></div>
        </div>
    </Modal>
};

export { PageHadjSingle }
import React from "react";
import { getGenderById } from "../../assets/constants.js";
import moment from "moment";
import nationalities from "../../assets/nationalities.json";

const RenderApiList = function (props) {
  const visums = props?.visums;
  return (
    <table id="apiListRegular" data-cols-width="4,35,35,15,15,15,15,15,15">
      <tr>
        <td
          data-f-bold={true}
          data-fill-color="ff949494"
          data-f-color="ffffffff"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#949494",
            color: "white",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          NO
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ff949494"
          data-f-color="ffffffff"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#949494",
            color: "white",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          LAST NAME
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ff949494"
          data-f-color="ffffffff"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#949494",
            color: "white",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          NAME
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ff949494"
          data-f-color="ffffffff"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#949494",
            color: "white",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          GENDER
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ff949494"
          data-f-color="ffffffff"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#949494",
            color: "white",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          BIRTH DATE
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ff949494"
          data-f-color="ffffffff"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#949494",
            color: "white",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          NATIONALITY
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ff949494"
          data-f-color="ffffffff"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#949494",
            color: "white",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          CITY OF BIRTH
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ff949494"
          data-f-color="ffffffff"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#949494",
            color: "white",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          COUNTRY OF BIRTH
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ff949494"
          data-f-color="ffffffff"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#949494",
            color: "white",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          PASSPORT CREATION DATE
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ff949494"
          data-f-color="ffffffff"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#949494",
            color: "white",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          PASSPORT NBR
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ff949494"
          data-f-color="ffffffff"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#949494",
            color: "white",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          COUNTRY ISSUE
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ff949494"
          data-f-color="ffffffff"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#949494",
            color: "white",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          VALIDITY
        </td>
      </tr>
      {visums?.map((visum, key) => (
        <tr>
          <td
            data-f-bold={true}
            data-fill-color="ff949494"
            data-f-color="ffffffff"
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{
              backgroundColor: "#949494",
              color: "white",
              fontWeight: 700,
              border: "1px solid black",
              padding: "5px",
            }}
          >
            {key + 1}
          </td>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {visum?.last_name}
          </td>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {visum?.first_name}
          </td>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {getGenderById(visum.gender).short_en}
          </td>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {moment(visum.date_of_birth).format("DD-MM-YYYY")}
          </td>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {visum.nationality}
          </td>
          <td
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {visum?.city_of_birth}
          </td>
          <td
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {visum?.city_of_country}
          </td>
          <td
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {moment(visum?.passport_creation_date).format("DD-MM-YYYY")}
          </td>
          <td
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {visum.passport_number.toUpperCase()}
          </td>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {
              nationalities.find(
                (nat) =>
                  visum.nationality.toLowerCase() ===
                  nat.nationality.toLowerCase()
              ).alpha_2_code
            }
          </td>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {moment(visum.passport_expires).format("DD-MM-YYYY")}
          </td>
        </tr>
      ))}
    </table>
  );
};

export default RenderApiList;

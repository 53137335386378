import React from "react";
import {Modal, Form, Input, Button, notification} from "antd";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {ModelArticleCategories} from "../../../model/article-categories.js";

const PageAdminArticlesNewCategory = function (props) {
    const {initialValues} = props;

    const onSubmit = (values) => {
        const category = {
            flex: JSON.stringify(initialValues?.flex ? initialValues?.flex : {}),
            label: values.label
        };
        if(initialValues){
            ModelArticleCategories.updateItem(initialValues.article_category_id, category, ()=>{
                notification.success({
                    message: 'Categorie is gewijzigd.'
                });
                props.setInitialValues(null);
                props.setVisible();
            })
        }else{
            ModelArticleCategories.createItem(category, ()=>{
                notification.success({
                    message: 'Categorie is succesvol toegevoegd.'
                });
                props.setVisible();
            })

        }
    };

    return <Modal visible={props.visible}
                  maskClosable={false}
                  title={'Voeg nieuwe categorie toe'}
                  okText={'Uploaden'}
                  cancelText={'Annuleren'}
                  footer={false}
                  onCancel={()=>{props.setVisible(false)}}>
        <Form layout={'vertical'} onFinish={onSubmit} initialValues={initialValues}>
            <Form.Item name="label" label="Categorie naam" rules={[{ required: true, message: "Categorie naam is verplicht!" }]}>
                <Input/>
            </Form.Item>
            <Button type="primary" htmlType="submit" className="contact-form-button">
                Toevoegen
            </Button>
        </Form>
    </Modal>
};

export {PageAdminArticlesNewCategory}

import {config} from '../config.js';
import {ModelXhr} from "../helpers/model/xhr.js";

class ModelProducts extends ModelXhr {
    static modelName = "ModelProducts";

    static getSelect(input) {
        return {
        };
    }

    static createItem(data, onSuccess=()=>{}){
        this.saveData(ModelProducts, config.api_endpoint + 'products', 'POST',
            data,
            (response) => {
                if(response){
                    onSuccess(response);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            },
            {
                contentType: 'json'
            }
        );
    }



    static updateItem(id, data, onSuccess=()=>{}){
        this.saveData(ModelProducts, config.api_endpoint + 'product/' + id, 'PUT',
            data,
            (response) => {
                if(response.message === "UPDATED"){
                    onSuccess(response);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            },
            {
                contentType: 'json'
            }
        );
    }


    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'products',
            method: 'GET'
        })

        this.cacheEnabled = true;
    }

    getSelect(input) {
        return ModelProducts.getSelect(input);
    }

    getDataFromResponse(data){
        try{
            const response = JSON.parse(data);
            if(! response){
                let error = 'The server returned an unknown error';
                this.onFailure(error);
                return null;
            }
            return response;
        }catch(e){
            if(this.request && this.request.aborted){
                console.warn('Cannot process request, probably because it was aborted. Exception: ' + e.message);
                this.status = 'waiting';
            }else{
                this.onFailure(e);
            }
        }

        return null;
    }

    getData(){
        return {
            status: this.status,
            data: this.data,
        }
    }
}

export {ModelProducts};

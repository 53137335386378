import React, { useState, useRef } from "react";
import { toMoney } from "../../../helpers/functions.js";
import { LeftOutlined, SearchOutlined } from "@ant-design/icons";
import constants from "../../../assets/constants.json";
import { getAgeTypeById, getGenderById } from "../../../assets/constants.js";
import nationaliteis from "../../../assets/nationalities.json";
import { blobToURL, fromBlob } from "image-resize-compress";
import { ModelOrders } from "../../../model/orders.js";
import { RoomsList } from "./rooms_list.js";
import { RoomPDF } from "./room_pdf.js";
import { saveAs } from "file-saver";

import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";

import { useHistory } from "react-router-dom";

import {
  Statistic,
  Modal,
  Avatar,
  Card,
  List,
  Input,
  Select,
  Button,
  Checkbox,
  Popconfirm,
  Table,
} from "antd";
import { UIButton } from "../../../components/ui/button/button.js";
import { AdminOrderComp } from "./order.js";
import RenderApiList from "../../visum/renderApiList.js";
import RenderApiListTukey from "../../visum/renderApiListTurkey.js";
import imageCompression from "browser-image-compression";

import TableToExcel from "@linways/table-to-excel";
import moment from "moment";
import JSZip from "jszip";

const { TextArea } = Input;

const remindDocLogTypes = [
  constants.LOG_TYPE.REMIND_DOCUMENTS_REQUEST,
  constants.LOG_TYPE.REMIND_DOCUMENTS_1,
  constants.LOG_TYPE.REMIND_DOCUMENTS_2,
  constants.LOG_TYPE.REMIND_DOCUMENTS_CANCEL,
  constants.LOG_TYPE.REMIND_DOCUMENTS_FINISH,
];

const remindPayLogTypes = [
  constants.LOG_TYPE.REMIND_PAYMENTS_REQUEST,
  constants.LOG_TYPE.REMIND_PAYMENTS_1,
  constants.LOG_TYPE.REMIND_PAYMENTS_2,
  constants.LOG_TYPE.REMIND_PAYMENTS_3,
  constants.LOG_TYPE.REMIND_PAYMENTS_CANCEL,
];

const getNatonalityObject = (nationality) =>
  nationaliteis.find((nat) => nat.nationality === nationality?.nationality);

const handleBlob = (blobFile) => {
  // quality value for webp and jpeg formats.
  const quality = 80;
  // output width. 0 will keep its original width and 'auto' will calculate its scale from height.
  const width = 0;
  // output height. 0 will keep its original height and 'auto' will calculate its scale from width.
  const height = 0;
  // file format: png, jpeg, bmp, gif, webp. If null, original format will be used.
  const format = "jpeg";

  // note only the blobFile argument is required
  fromBlob(blobFile, quality, width, height, format).then((blob) => {
    // will output the converted blob file
    // will generate a url to the converted file
    return blob;
  });
};

const { Meta } = Card;
const PageAdminOrdersSecondCat = function (props) {
  const orders = props?.orders;
  const orderLogs = orders?.map((order) => order.logs).flat();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState(null);
  const [showRoomListPopup, setRoomListPopup] = useState(null);

  const [searchType, setSearchType] = useState("none");
  const [listType, setListType] = useState(null);
  const [visumsToRemind, setVisumToRemind] = useState(null);
  const [logTypeToShow, setLogTypeToShow] = useState(null);

  const rooms = orders?.map((order) => order.rooms).flat();
  const guests = rooms?.map((room) => room.guests).flat();
  const visums = guests
    ?.map((guest) =>
      guest?.visums?.map((visum) => ({
        ...visum,
        first_name: guest.first_name,
        last_name: guest.last_name,
        gender: guest.gender,
        room_id: guest.room_id,
        european_passport: guest.european_passport,
        age_category: guest.age_category,
      }))
    )
    .filter((visumArr) => visumArr?.length > 0)
    .flat()
    .sort(function (a, b) {
      return a?.room_id - b?.room_id;
    });

  const logs = orderLogs
    ?.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.created_at) - new Date(a.created_at);
    })
    .filter((log) =>
      visumsToRemind
        ? remindDocLogTypes?.includes(log?.type)
        : remindPayLogTypes?.includes(log?.type)
    )
    .filter((log) => (logTypeToShow ? log.type === logTypeToShow : log));
    // .filter(
    //   (value, index, self) =>
    //     index ===
    //     self.findIndex(
    //       (t) =>
    //         moment(t.created_at).format("DD-MM-YYYY") ===
    //         moment(value.created_at).format("DD-MM-YYYY")
    //     )
    // );

  const products = orders?.map((order) => order.products).flat();
  const payments = orders?.map((order) => order.payments).flat();
  const [showPopup, setShowPopup] = useState(false);
  const [visumToShow, setVisumToShow] = useState(null);
  const [remindLogDate, setRemindLogDate] = useState(
    moment(logs[0]?.created_at).format("DD-MM-YYYY")
  );
  const [paymentsToRemind, setPaymentsToRemind] = useState(null);
  const [reasonToReset, setReasonToReset] = useState("");

  const handleVisumsRemindChange = (val) => {
    const newState = orders.filter((order) => val.indexOf(order.id) > -1);
    setVisumToRemind(newState);
  };

  const handlePaymentsRemindChange = (val) => {
    const newState = orders.filter((order) => val.indexOf(order.id) > -1);
    setPaymentsToRemind(newState);
  };

  const downloadImages = async () => {
    const promises = visums.map(async (visum) => {
      const res = await fetch(visum.visa_photo_url);
      const blob = await res.blob();
      // quality value for webp and jpeg formats.
      const quality = 80;
      // output width. 0 will keep its original width and 'auto' will calculate its scale from height.
      const width = 200;
      // output height. 0 will keep its original height and 'auto' will calculate its scale from width.
      const height = 200;
      // file format: png, jpeg, bmp, gif, webp. If null, original format will be used.
      const format = "jpeg";

      const fixedBlob = await fromBlob(
        blob,
        quality,
        width,
        height,
        format
      ).then((resultBlob) => {
        // will output the converted blob file
        return resultBlob;
      });
      return fixedBlob;
      // return blob;
    });
    let zip = new JSZip();

    const res = await Promise.all(promises);

    const options = {
      maxSizeMB: 0.18, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 200, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    };

    res.forEach((blob, key) => {
      const visum = visums[key];
      function get_url_extension(url) {
        return url.split(/[#?]/)[0].split(".").pop().trim();
      }
      zip.file(
        visum.first_name +
          "_" +
          visum.last_name +
          "-" +
          visum.passport_number +
          "-" +
          visum.id +
          "." +
          get_url_extension(visum.visa_photo_url),
        blob
      );
      // // note only the blobFile argument is required
      // fromBlob(blob, quality, width, height, format).then((resultBlob) => {
      //   // will output the converted blob file
      //   console.log(resultBlob);
      // });
    });

    zip.generateAsync({ type: "blob" }).then(function (content) {
      // see FileSaver.js
      console.log(content);
      saveAs(content, "fotos" + moment().format("DD-MM-YYYY") + ".zip");
    });
  };

  const months = [
    "januari",
    "februari",
    "maart",
    "april",
    "mei",
    "juni",
    "juli",
    "augustus",
    "september",
    "oktober",
    "november",
    "december",
  ];

  const discounts = orders
    ?.filter((order) => JSON.parse(order.flex).discounts)
    .map((order) => JSON.parse(order.flex).discounts)
    .flat()
    .map((label) => props.discounts.find((disc) => disc.label === label));

  const handleInputChange = (evt) => setSearchValue(evt.target.value);
  const handleTypeChange = (value) => setSearchType(value);

  const filterOrderItem = (order) => {
    const orderGuests = order.rooms.map((room) => room.guests).flat();
    switch (searchType) {
      case "name":
        return (
          `${order.user.first_name} ${order.user.last_name}`
            .toLowerCase()
            .indexOf(searchValue.toLowerCase()) > -1
        );
      case "order_id":
        return `#FCU1${order.id}`.indexOf(searchValue) > -1;
      case "created_at":
        return (
          moment(order.created_at)
            .format("DD-MM-YYYY")
            .toString()
            .indexOf(searchValue.toLowerCase()) > -1
        );
      case "created_at_month":
        return (
          months[moment(order.created_at).month()].indexOf(
            searchValue.toLowerCase()
          ) > -1
        );
      case "guest_first_name":
        return (
          orderGuests.find(
            (guest) =>
              guest.first_name
                .toLowerCase()
                .indexOf(searchValue.toLowerCase()) > -1
          ) !== undefined
        );
      case "guest_last_name":
        return (
          orderGuests.find(
            (guest) =>
              guest.last_name.toLowerCase().indexOf(searchValue.toLowerCase()) >
              -1
          ) !== undefined
        );
      case "none":
        return true;
    }
  };

  const typeHolders = {
    none: "Geen",
    name: "Zoeken op naam klant",
    order_id: "Zoeken op factuurnummer",
    created_at: "Zoeken op besteldatum (01-02-1991)",
    created_at_month: "Zoeken op maand bestelling (januari)",
    guest_first_name: "Zoeken op achternaam gast",
    guest_last_name: "Zoeken op voornaam gast",
  };

  const guestsToRemind = guests.filter((guest) => {
    return guest?.visums?.length < 1;
  });
  const guestOrdersToRemind = guestsToRemind.map((guest) =>
    orders.find(
      (order) =>
        order.id === rooms.find((room) => guest.room_id === room.id).order_id
    )
  );
  const filteredGuestOrdersToRemind = guestOrdersToRemind
    .filter(function (item, pos) {
      return guestOrdersToRemind.indexOf(item) == pos;
    })
    .filter(
      (order) =>
        order.logs.find((log) => log.type === logTypeToShow) == undefined
    );

  const handleOrdersToRemind = (amount) => {
    const data = {
      orders: visumsToRemind.map((order) => order.id),
      amount_sent: amount,
    };
    // ModelOrders.sendDocumentsRemind(
    //   data,
    //   (resp) => setVisumToRemind(null),
    //   null
    // );
  };

  const handleDocumentsFaulty = (data) => {
    const dataToSend = {
      ...data,
      reason: reasonToReset,
    };
    // console.log(dataToSend)
    ModelOrders.sendDocumentsFaulty(
      dataToSend,
      (resp) => setVisumToRemind(null),
      null
    );
  };

  const handleOrdersToPay = (amount) => {
    const data = {
      orders: paymentsToRemind.map((order) => order.id),
      amount_sent: amount,
    };
    ModelOrders.sendPaymentsToRemind(
      data,
      (resp) => setPaymentsToRemind(null),
      null
    );
  };

  const ordersToPay = orders
    .filter((order) => order.status !== constants.ORDER_STATUS.COMPLETED)
    .filter(
      (order) =>
        order.logs.find((log) => log.type === logTypeToShow) === undefined
    );

  const handleReason = (e) => {
    setReasonToReset(e.target.value);
  };

  const possibleDates = logs.map((log) => ({
    value: moment(log.created_at).format("DD-MM-YYYY"),
    label: moment(log.created_at).format("DD-MM-YYYY"),
  }));
  
  const logDates = possibleDates.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            moment(t.value).format("DD-MM-YYYY") ===
            moment(value.value).format("DD-MM-YYYY")
        )
    );


  return (
    <div className="catDetails">
      <h2 onClick={() => props.resetSelection()} className="back">
        <LeftOutlined /> Ga terug
      </h2>

      <div className="titleCont">
        <h1>
          {orders && orders.length > 0
            ? "Reisoverzicht - " + props.title
            : "Geen bestellingen voor deze periode..."}
        </h1>
        {orders && orders.length > 0 && (
          <UIButton onClick={() => setShowPopup(true)}>
            Bekijk statistieken
          </UIButton>
        )}
        {visums && orders.length > 1 && (
          <UIButton onClick={() => setListType("general")}>
            Download API-List
          </UIButton>
        )}
        {rooms && rooms.length > 1 && (
          <UIButton onClick={() => setRoomListPopup(true)}>
            Genereer kamerindeling
          </UIButton>
        )}

        {orders.length > 1 && (
          <UIButton
            onClick={() => {
              setLogTypeToShow(constants.LOG_TYPE.REMIND_DOCUMENTS_REQUEST);
              setVisumToRemind(filteredGuestOrdersToRemind);
            }}
          >
            Herrineringen documenten
          </UIButton>
        )}
        {orders.length > 1 && (
          <UIButton
            onClick={() => {
              setLogTypeToShow(constants.LOG_TYPE.REMIND_PAYMENTS_REQUEST);
              setPaymentsToRemind(ordersToPay);
            }}
          >
            Herrineringen betalingen
          </UIButton>
        )}
      </div>
      <div className="itemsCont">
        <div className="filterCont">
          <div className="searchCont">
            <Select
              className="inputSearch"
              size={"large"}
              value={searchType}
              onChange={handleTypeChange}
              options={[
                {
                  value: "none",
                  label: "Geen",
                },
                {
                  value: "name",
                  label: "Factuurhouder",
                },
                {
                  value: "order_id",
                  label: "Ordernummer",
                },
                {
                  value: "created_at",
                  label: "Datum besteld",
                },
                {
                  value: "created_at_month",
                  label: "Maand besteld",
                },
                {
                  value: "guest_first_name",
                  label: "Voornaam gast",
                },
                {
                  value: "guest_last_name",
                  label: "Achternaam gast",
                },
              ]}
            />
            <Input
              addonBefore={<SearchOutlined />}
              onChange={handleInputChange}
              value={searchValue}
              size={"large"}
              placeholder={typeHolders[searchType]}
            />
          </div>
        </div>
        {orders
          .filter((order) => (searchValue ? filterOrderItem(order) : true))
          .map((order, key) => (
            <AdminOrderComp
              discounts={props.discounts}
              order={order}
              keyToUse={key}
              setOrderToCancel={props.setOrderToCancel}
              setOrderToUpdate={(order) => props.setOrderToUpdate(order)}
              setOrderToDownload={(order) => props.setOrderToDownload(order)}
              setModal={(order) => props.setModal(order)}
              setVisumToShow={setVisumToShow}
            />
          ))}
      </div>

      <Modal
        title={"Statistieken reisoverzicht - " + props.title}
        className="statsPopup"
        open={showPopup}
        onCancel={() => setShowPopup(false)}
        footer={null}
      >
        <div>
          <h2>Bestellingen</h2>
          <div className="statistics">
            <Statistic
              className="statistic"
              title="Aantal factuurhouders deze periode"
              value={orders.length}
            />
            <Statistic
              className="statistic"
              title="Aantal aanbetalingen"
              value={
                orders.filter(
                  (order) =>
                    order.status === constants.ORDER_STATUS.DOWN_PAYMENT
                ).length
              }
            />
            <Statistic
              className="statistic"
              title="Aantal volledig betalingen"
              value={
                orders.filter(
                  (order) => order.status === constants.ORDER_STATUS.COMPLETED
                ).length
              }
            />
          </div>
        </div>

        <div>
          <h2>Betalingen</h2>
          <div className="statistics">
            <Statistic
              className="statistic"
              title="Totaal ontvangen"
              value={toMoney(
                payments
                  .map((payment) => parseFloat(payment.amount))
                  .reduce((partialSum, a) => partialSum + a, 0)
              )}
            />
            <Statistic
              className="statistic"
              title="Cash ontvangen"
              value={toMoney(
                payments
                  .filter(
                    (payment) =>
                      payment.payment_type === constants.PAYMENT_TYPE.CASH
                  )
                  .map((payment) => parseFloat(payment.amount))
                  .reduce((partialSum, a) => partialSum + a, 0)
              )}
            />
            <Statistic
              className="statistic"
              title="Giraal ontvangen"
              value={toMoney(
                payments
                  .filter(
                    (payment) =>
                      payment.payment_type !== constants.PAYMENT_TYPE.CASH
                  )
                  .map((payment) => parseFloat(payment.amount))
                  .reduce((partialSum, a) => partialSum + a, 0)
              )}
            />
            <Statistic
              className="statistic"
              title="Totaal nog te ontvangen"
              value={toMoney(
                products
                  .map((product) => parseFloat(product.price))
                  .reduce((partialSum, a) => partialSum + a, 0) -
                  payments
                    .map((payment) => parseFloat(payment.amount))
                    .reduce((partialSum, a) => partialSum + a, 0) -
                  discounts
                    .map((discount) => parseFloat(discount.amount / 100))
                    .reduce((partialSum, a) => partialSum + a, 0)
              )}
            />
            <Statistic
              className="statistic"
              title="Totaal kortingen"
              value={toMoney(
                discounts
                  .map((discount) => parseFloat(discount.amount / 100))
                  .reduce((partialSum, a) => partialSum + a, 0)
              )}
            />
          </div>
        </div>
        <div>
          <h2>Verkochte type kamers</h2>
          <div className="statistics">
            <Statistic
              className="statistic"
              title="Aantal 1 p. kamers"
              value={rooms.filter((item) => item.guests.length === 1).length}
            />
            <Statistic
              className="statistic"
              title="Aantal 2 p. kamers"
              value={rooms.filter((item) => item.guests.length === 2).length}
            />
            <Statistic
              className="statistic"
              title="Aantal 3 p. kamers"
              value={rooms.filter((item) => item.guests.length === 3).length}
            />
            <Statistic
              className="statistic"
              title="Aantal 4 p. kamers"
              value={rooms.filter((item) => item.guests.length === 4).length}
            />
          </div>
        </div>
        <div className="">
          <h2>Aantal meereizende umrah-gangers</h2>
          <div className="statistics">
            <Statistic
              className="statistic"
              title="Aantal volwassenen"
              value={
                products.filter(
                  (item) =>
                    item.type === constants.PRODUCT_TYPE.UMRAH_2PERS_ADULT ||
                    item.type === constants.PRODUCT_TYPE.UMRAH_3PERS_ADULT ||
                    item.type === constants.PRODUCT_TYPE.UMRAH_4PERS_ADULT
                ).length
              }
            />
            <Statistic
              className="statistic"
              title="Aantal kids (2-12jr)"
              value={
                products.filter(
                  (item) =>
                    item.type === constants.PRODUCT_TYPE.UMRAH_2PERS_CHILD ||
                    item.type === constants.PRODUCT_TYPE.UMRAH_3PERS_CHILD ||
                    item.type === constants.PRODUCT_TYPE.UMRAH_4PERS_CHILD
                ).length
              }
            />
            <Statistic
              className="statistic"
              title="Aantal babys (0-12mnd)"
              value={
                products.filter(
                  (item) =>
                    item.type === constants.PRODUCT_TYPE.UMRAH_2PERS_BABY ||
                    item.type === constants.PRODUCT_TYPE.UMRAH_3PERS_BABY ||
                    item.type === constants.PRODUCT_TYPE.UMRAH_4PERS_BABY
                ).length
              }
            />
          </div>
        </div>
      </Modal>

      <Modal
        title={"Download API list - " + props.title}
        className="listPopup"
        open={listType}
        onCancel={() => setListType(null)}
        footer={null}
      >
        <Select
          size={"large"}
          value={listType}
          onChange={(val) => setListType(val)}
          options={[
            {
              value: "general",
              label: "Algemeen",
            },
            {
              value: "turkey",
              label: "Turkish airlines",
            },
          ]}
        />
        {listType === "general" && <RenderApiList visums={visums} />}
        {listType === "general" && (
          <>
            <UIButton
              onClick={() =>
                TableToExcel.convert(
                  document.getElementById("apiListRegular"),
                  {
                    name: "apilist-algemeen.xlsx",
                  }
                )
              }
            >
              Download Excel lijst
            </UIButton>
            <UIButton onClick={() => downloadImages()}>Download fotos</UIButton>
          </>
        )}
        {listType === "turkey" && <RenderApiListTukey visums={visums} />}
        {listType === "turkey" && (
          <UIButton
            onClick={() =>
              TableToExcel.convert(document.getElementById("apiListTurkey"), {
                name: "apilist-turkey.xlsx",
              })
            }
          >
            Download Excel lijst
          </UIButton>
        )}
        <canvas id="canvasElement" />
      </Modal>

      <Modal
        title={
          "Document gegevens " +
          visumToShow?.first_name +
          " " +
          visumToShow?.last_name
        }
        className="visumPopup"
        open={visumToShow}
        onCancel={() => setVisumToShow(null)}
        footer={null}
      >
        <div className="sumCont">
          <>
            <div className="firstColumn">
              <img src={visumToShow?.visa_photo_url}></img>
              {visumToShow?.resetForm ? (
                <UIButton
                  className="buttonBelow"
                  onClick={() =>
                    setVisumToShow({ ...visumToShow, resetForm: false })
                  }
                >
                  Annuleren
                </UIButton>
              ) : (
                <UIButton
                  className="buttonBelow"
                  onClick={() =>
                    setVisumToShow({ ...visumToShow, resetForm: true })
                  }
                >
                  Resetten en versturen mail
                </UIButton>
              )}{" "}
            </div>
            <div className="secondColumn">
              {visumToShow?.resetForm ? (
                <>
                  <h1>Resetten</h1>
                  <p>Reden *:</p>
                  <TextArea
                    onChange={handleReason}
                    value={reasonToReset}
                    rows={4}
                  />
                  <Popconfirm
                    title="Weet je zeker dat je de gegevens wil resetten?"
                    description="Weet je zeker dat je de gegevens wil resetten?"
                    onConfirm={() => {
                      handleDocumentsFaulty({
                        order_id: visumToShow.order_id,
                        guests: [visumToShow.guest_id],
                      });
                      setVisumToShow(null);
                    }}
                    okText="Ja"
                    cancelText="Nee"
                    disabled={reasonToReset.length < 1}
                  >
                    <UIButton disabled={reasonToReset.length < 1}>
                      Resetten
                    </UIButton>
                  </Popconfirm>
                </>
              ) : (
                <>
                  <Card className="contentCont" title={"Volledige naam"}>
                    {visumToShow?.first_name} {visumToShow?.last_name}
                  </Card>
                  <Card className="contentCont" title={"Geslacht"}>
                    {getGenderById(visumToShow?.gender)?.label}
                  </Card>
                  <Card className="contentCont" title={"Nationaliteit"}>
                    {visumToShow?.nationality}
                  </Card>
                  <Card className="contentCont" title={"Geboorteland"}>
                    {visumToShow?.city_of_country}
                  </Card>
                  <Card className="contentCont" title={"Geboortestad"}>
                    {visumToShow?.city_of_birth}
                  </Card>
                  <Card className="contentCont" title={"Geboortedatum"}>
                    {moment(visumToShow?.date_of_birth)?.format("DD-MM-YYYY")}
                  </Card>
                  <Card className="contentCont" title={"Paspoortnummer"}>
                    {visumToShow?.passport_number}
                  </Card>
                  <Card className="contentCont" title={"Aanmaakdatum paspoort"}>
                    {moment(visumToShow?.passport_creation_date).format("DD-MM-YYYY")}
                  </Card>
                  <Card className="contentCont" title={"Vervaldatum passport"}>
                    {moment(visumToShow?.passport_expires)?.format(
                      "DD-MM-YYYY"
                    )}
                  </Card>
                  <Card className="contentCont" title={"Europese paspoort"}>
                    {visumToShow?.european_passport ? "Ja" : "Nee"}
                  </Card>

                 
    
                </>
              )}
            </div>
          </>
        </div>
      </Modal>

      <Modal
        title={"Vestuur herinnering documenten"}
        className="remindVisumPopup"
        open={visumsToRemind}
        onCancel={() => setVisumToRemind(null)}
        footer={null}
      >

        <p className="remindText">
          Verstuur herrineringen voor het invullen van de documenten,
          onderstaande personen hebben nog niet alles ingeleverd.
        </p>
        <Select
          className="select"
          style={{ width: "100%" }}
          size={"large"}
          value={logTypeToShow}
          onChange={setLogTypeToShow}
          defaultValue={logTypeToShow}
          options={[
            {
              value: constants.LOG_TYPE.REMIND_DOCUMENTS_REQUEST,
              label: "Verzoek invullen documenten",
            },
            {
              value: constants.LOG_TYPE.REMIND_DOCUMENTS_1,
              label: "1e herrinering invullen documenten",
            },
            {
              value: constants.LOG_TYPE.REMIND_DOCUMENTS_2,
              label: "2e herrinering invullen documenten",
            },
            {
              value: constants.LOG_TYPE.REMIND_DOCUMENTS_CANCEL,
              label: "Annulering telaat invullen documenten",
            },
          ]}
        />
        <Select
          mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          value={visumsToRemind
            ?.filter(
              (order) =>
                order.logs.find((log) => log.type === logTypeToShow) ==
                undefined
            )
            .map((order) => order.id)}
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) > -1;
          }}
          options={filteredGuestOrdersToRemind?.map((order) => ({
            value: order.id,
            label: "#FCU1" + order.id + " " + order.user.email.toLowerCase(),
          }))}
          defaultValue={filteredGuestOrdersToRemind?.map((order) => order.id)}
          onChange={handleVisumsRemindChange}
        />
        {logTypeToShow === constants.LOG_TYPE.REMIND_DOCUMENTS_REQUEST && (
          <Popconfirm
            title="Weet je zeker dat je de mail wil versturen?"
            onConfirm={() => handleOrdersToRemind(0)}
            okText="Ja"
            cancelText="Nee"
          >
            <UIButton className="remindButton">Verstuur verzoek</UIButton>
          </Popconfirm>
        )}
        {logTypeToShow === constants.LOG_TYPE.REMIND_DOCUMENTS_1 && (
          <Popconfirm
            title="Weet je zeker dat je de mail wil versturen?"
            onConfirm={() => handleOrdersToRemind(1)}
            okText="Ja"
            cancelText="Nee"
          >
            <UIButton className="remindButton">
              Verstuur 1e herinnering
            </UIButton>
          </Popconfirm>
        )}
        {logTypeToShow === constants.LOG_TYPE.REMIND_DOCUMENTS_2 && (
          <Popconfirm
            title="Weet je zeker dat je de mail wil versturen?"
            onConfirm={() => handleOrdersToRemind(2)}
            okText="Ja"
            cancelText="Nee"
          >
            <UIButton className="remindButton">
              Verstuur 2e herinnering
            </UIButton>
          </Popconfirm>
        )}
        {logTypeToShow === constants.LOG_TYPE.REMIND_DOCUMENTS_CANCEL && (
          <Popconfirm
            title="Weet je zeker dat je de mail wil versturen?"
            onConfirm={() => handleOrdersToRemind(3)}
            okText="Ja"
            cancelText="Nee"
          >
            <UIButton className="remindButton">Verstuur annulering</UIButton>
          </Popconfirm>
        )}
        <p className="remindText logs">Logs</p>
        {logs.length > 0 ? (
          <div>
            <Select
              className="logSelect"
              style={{ width: "100%" }}
              size={"large"}
              value={remindLogDate}
              onChange={setRemindLogDate}
              // defaultValue={logDates[0]?.value}
              options={logDates}
            />
            <Table
              columns={[
                {
                  title: "Datum",
                  dataIndex: "created_at",
                  key: "created_at",
                },
                {
                  title: "Order #",
                  dataIndex: "order_id",
                  key: "order_id",
                },
                {
                  title: "Bericht",
                  dataIndex: "message",
                  key: "message",
                },
                {
                  title: "Uitgevoerd door:",
                  dataIndex: "user_executed_mail",
                  key: "user_executed_mail",
                },
              ]}
              dataSource={logs.map((log, key) => ({
                key: "log" + key,
                created_at: moment(log.created_at).format("DD-MM-YYYY HH:mm"),
                order_id: "#FCU1" + log.order_id,
                message: log.message,
                user_executed_mail: log.user_executed_mail,
              })).filter((log)=>(moment(log.created_at).format("DD-MM-YYYY") === moment(remindLogDate).format("DD-MM-YYYY")))}
            />
          </div>
        ) : (
          <p className="remindText logs">Er zijn geen logs...</p>
        )}
      </Modal>

      <Modal
        title={"Vestuur herinnering betalingen"}
        className="remindVisumPopup"
        open={paymentsToRemind}
        onCancel={() => setPaymentsToRemind(null)}
        footer={null}
      >
        <p className="remindText">
          Verstuur herrineringen voor de betalingen, onderstaande personen
          hebben nog niet alles ingeleverd.
        </p>

        <Select
          className="select"
          style={{ width: "100%" }}
          size={"large"}
          value={logTypeToShow}
          onChange={setLogTypeToShow}
          defaultValue={logTypeToShow}
          options={[
            {
              value: constants.LOG_TYPE.REMIND_PAYMENTS_REQUEST,
              label: "Verzoek restbetaling",
            },
            {
              value: constants.LOG_TYPE.REMIND_PAYMENTS_1,
              label: "1e herrinering restbetaling",
            },
            {
              value: constants.LOG_TYPE.REMIND_PAYMENTS_2,
              label: "2e herrinering restbetaling",
            },
            {
              value: constants.LOG_TYPE.REMIND_PAYMENTS_3,
              label: "3e herrinering restbetaling",
            },
            {
              value: constants.LOG_TYPE.REMIND_PAYMENTS_CANCEL,
              label: "Annulering telaat restbetaling",
            },
          ]}
        />
        <Select
          mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          value={paymentsToRemind
            ?.filter(
              (order) =>
                order.logs.find((log) => log.type === logTypeToShow) ===
                undefined
            )
            .map((order) => order.id)}
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) > -1;
          }}
          options={ordersToPay?.map((order) => ({
            value: order.id,
            label: "#FCU1" + order.id + " " + order.user.email.toLowerCase(),
          }))}
          defaultValue={ordersToPay?.map((order) => order.id)}
          onChange={handlePaymentsRemindChange}
        />

        {logTypeToShow === constants.LOG_TYPE.REMIND_PAYMENTS_REQUEST && (
          <Popconfirm
            title="Weet je zeker dat je de mail wil versturen?"
            onConfirm={() => handleOrdersToPay(0)}
            okText="Ja"
            cancelText="Nee"
          >
            <UIButton className="remindButton">
              Verstuur verzoek afbetaling
            </UIButton>
          </Popconfirm>
        )}

        {logTypeToShow === constants.LOG_TYPE.REMIND_PAYMENTS_1 && (
          <Popconfirm
            title="Weet je zeker dat je de mail wil versturen?"
            onConfirm={() => handleOrdersToPay(1)}
            okText="Ja"
            cancelText="Nee"
          >
            <UIButton className="remindButton">
              Verstuur 1e herinnering
            </UIButton>
          </Popconfirm>
        )}
        {logTypeToShow === constants.LOG_TYPE.REMIND_PAYMENTS_2 && (
          <Popconfirm
            title="Weet je zeker dat je de mail wil versturen?"
            onConfirm={() => handleOrdersToPay(2)}
            okText="Ja"
            cancelText="Nee"
          >
            <UIButton className="remindButton">
              Verstuur 2e herinnering
            </UIButton>
          </Popconfirm>
        )}
        {logTypeToShow === constants.LOG_TYPE.REMIND_PAYMENTS_3 && (
          <Popconfirm
            title="Weet je zeker dat je de mail wil versturen?"
            onConfirm={() => handleOrdersToPay(3)}
            okText="Ja"
            cancelText="Nee"
          >
            <UIButton className="remindButton">
              Verstuur 3e herinnering
            </UIButton>
          </Popconfirm>
        )}
        {logTypeToShow === constants.LOG_TYPE.REMIND_PAYMENTS_CANCEL && (
          <Popconfirm
            title="Weet je zeker dat je de mail wil versturen?"
            onConfirm={() => handleOrdersToPay(4)}
            okText="Ja"
            cancelText="Nee"
          >
            <UIButton className="remindButton">
              Verstuur annuleringsmail
            </UIButton>
          </Popconfirm>
        )}

        <p className="remindText logs">Logs</p>
        {logs.length > 0 ? (
          <div>
            <Select
              className="logSelect"
              style={{ width: "100%" }}
              size={"large"}
              value={remindLogDate}
              onChange={setRemindLogDate}
              // defaultValue={logDates[0]?.value}
              options={logDates}
            />
            <Table
              columns={[
                {
                  title: "Datum",
                  dataIndex: "created_at",
                  key: "created_at",
                },
                {
                  title: "Order #",
                  dataIndex: "order_id",
                  key: "order_id",
                },
                {
                  title: "Bericht",
                  dataIndex: "message",
                  key: "message",
                },
                {
                  title: "Uitgevoerd door:",
                  dataIndex: "user_executed_mail",
                  key: "user_executed_mail",
                },
              ]}
              dataSource={logs.map((log, key) => ({
                key: "log" + key,
                created_at: moment(log.created_at).format("DD-MM-YYYY HH:mm"),
                order_id: "#FCU1" + log.order_id,
                message: log.message,
                user_executed_mail: log.user_executed_mail,
              })).filter((log)=>(moment(log.created_at).format("DD-MM-YYYY") === moment(remindLogDate).format("DD-MM-YYYY")))}
            />
          </div>
        ) : (
          <p className="remindText logs">Er zijn geen logs...</p>
        )}
      </Modal>

      <Modal
        title={"Genereer kamerindeling"}
        className="roomListPopup"
        open={showRoomListPopup}
        onCancel={() => setRoomListPopup(null)}
        footer={null}
      >
        <RoomsList
          title={props.title}
          guests={guests}
          rooms={rooms}
          products={products}
        />
      </Modal>
    </div>
  );
};

export { PageAdminOrdersSecondCat };

import {config} from '../config.js';
import {ModelXhr} from "../helpers/model/xhr.js";

class ModelMyInvoices extends ModelXhr {
    static modelName = "ModelMyInvoices";

    static preparePayment(body,user_id,  onSuccess=()=>{}, onFailure=()=>{}){
        let endpoint = config.api_endpoint + 'invoicePayment'
        if(user_id){
            endpoint = endpoint + "/" +user_id
        }
        this.saveData(ModelMyInvoices, endpoint , 'POST',
            body,
            (response) => {
                if(! response || response.status === 'error'){
                    onFailure(response.error);
                    return;
                }
                if(response){
                    onSuccess(response);
                    return;
                }
                onFailure("An unknown error occured.");
            },
            {
                contentType: 'json'
            }
        )
    }


    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'invoices',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getDataFromResponse(data){
        try{
            const response = JSON.parse(data);
            if(!response){
                let error = 'The server returned an unknown error';
                this.onFailure(error);
                return null;
            }
            return response.invoices;
        }catch(e){
            if(this.request && this.request.aborted){
                console.warn('Cannot process request, probably because it was aborted. Exception: ' + e.message);
                this.status = 'waiting';
            }else{
                this.onFailure(e);
            }
        }

        return null;
    }


    getData(){
        return {
            status: this.status,
            data: this.data,
        };
    }
}

export {ModelMyInvoices};

import React from "react";
import {ArticlesBanner} from "../components/articles/banner.js";
import {ArticlesDetailArticle} from "../components/articles/detail/article.js";

const PageArticleDetails = function (props) {
    return <div>
        <ArticlesBanner/>
        <ArticlesDetailArticle/>
    </div>
};

export {PageArticleDetails}
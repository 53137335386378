import './vouchers.scss';
import React, {useState} from "react";
import {UIButton} from "../../../components/ui/button/button.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelVouchers} from "../../../model/vouchers.js";

import AdminVouchersList from"./vouchers-lists";
import  PageNewVoucher  from './new-voucher';

const PageAdminVouchers = function (props) {
    const [newArticle, setNewArticle] = useState('');
    const [initialValues, setInitialValues] = useState(null);
    const resultSet = useModelFeedback(ModelVouchers);

    if(resultSet.feedback ){
        return resultSet.feedback
    }

    return <div>
        <div className={'article-buttons'}>
            <UIButton className={'article-button'} onClick={()=>setNewArticle('voucher')}>Voeg kortingscode toe</UIButton>
        </div>

        {(newArticle === 'voucher') &&
        <PageNewVoucher
            visible={(newArticle === 'voucher')}
            setInitialValues={setInitialValues}
            setVisible={()=>setNewArticle('')}
        initialValues={initialValues}/>}

        <AdminVouchersList setNewArticle={setNewArticle} setInitialValues={setInitialValues} />
    </div>
};

export default PageAdminVouchers;
import React, {useState} from "react";
import {UIText} from "../../components/ui/text/text.js";
import {Button, Checkbox, Col, Form, Modal, notification, Row} from "antd";
import {AuthWithUser} from "../../components/auth/auth-with-user.js";
import constants from "../../assets/constants.json";
import {useStateContext} from "../../helpers/state-context/use_state_context.js";
import {useHistory} from "react-router-dom";

const PageArabiaTeachingAdd = function (props) {
    const [cartType, setCartType] = useState('');
    const [, dispatch] = useStateContext(['cart'])
    const history = useHistory()
    const isUserCart = history.location.pathname === '/winkelwagen'
    const isAdminCart = history.location.pathname === '/admin-winkelwagen'

    const days = [
        {id: 'ma', label: 'Maandag'},
        {id: 'di', label: 'Dinsdag'},
        {id: 'wo', label: 'Woensdag'},
        {id: 'do', label: 'Donderdag'},
        {id: 'vr', label: 'Vrijdag'},
        {id: 'za', label: 'Zaterdag'},
        {id: 'zo', label: 'Zondag'}
    ]
    const timeZones = [
        {id: '09:00-12:00', label: '09:00-12:00'},
        {id: '12:00-15:00', label: '12:00-15:00'},
        {id: '15:00-18:00', label: '15:00-18:00'},
        {id: '18:00-21:00', label: '18:00-21:00'},
    ]

    const onSubmit = (values) => {
        const arabiaTeaching = {
            type: 'arabia',
            id: props.modal,
            ...values
        };
        if(cartType === 'admin'){
            if(props.initialValues){
                dispatch({type: 'updateAdminCartItem', value:arabiaTeaching, key: props.cartKey})
            }else{
                dispatch({type: 'addToAdminCart', value: arabiaTeaching})
            }
        }else{
            if(props.initialValues){
                dispatch({type: 'updateCartItem', value: arabiaTeaching, key: props.cartKey})
            }else{
                dispatch({type: 'addToCart', value: arabiaTeaching})
            }
        }
        notification.success({
            message: 'Product is toegevoegd in je winkelwagen!'
        });
        props.setModal('')
    };

    const initialValues = props.initialValues ? props.initialValues : {}

    return <Modal onCancel={()=>props.setModal('')} title={`Selecteer beschikbaarheid - ${props.selectedProduct?.label}`} maskClosable={false} footer={false} className={'modal'} visible={props.modal}>
        <UIText>Selecteer een of meerdere tijden slots, wanneer je beschikbaar bent.</UIText>
        <Form layout={'vertical'} onFinish={onSubmit} initialValues={initialValues}>
            <Form.Item name="available_days"
                       label="Dagen van de week"
                       rules={[{ required: true, message: "Dagen van de week is verplicht!" },]}>
                <Checkbox.Group>
                    <Row>
                        {days.map((day, key)=>{
                            return <Col span={8} key={key}>
                                <Checkbox value={day.label} style={{ lineHeight: '32px' }}>
                                    {day.label}
                                </Checkbox>
                            </Col>
                        })}
                    </Row>
                </Checkbox.Group>
            </Form.Item>
            <Form.Item name="available_times"
                       label="Tijden van de dag"
                       rules={[{ required: true, message: "Tijden van de dag is verplicht!" },]}>
                <Checkbox.Group>
                    <Row>
                        {timeZones.map((day, key)=>{
                            return <Col span={8} key={key}>
                                <Checkbox value={day.label} style={{ lineHeight: '32px' }}>
                                    {day.label}
                                </Checkbox>
                            </Col>
                        })}
                    </Row>
                </Checkbox.Group>
            </Form.Item>
            {(!isAdminCart) && <Button type="primary" htmlType="submit" name={'1'} onClick={()=>{setCartType('user')}} className="contact-form-button">
                {props.initialValues ? 'Updaten' : 'Toevoegen aan winkelwagen'}
            </Button>}
            {(!isUserCart) &&  <AuthWithUser enableFeedback={false}>
                {(user)=>{
                    if(user && user.role === constants.USER_ROLE.ADMIN){
                        return <Button type="primary" htmlType="submit" name={'2'}  onClick={()=>{setCartType('admin')}} className="contact-form-button">
                            {props.initialValues ? 'Updaten' : 'Toevoegen aan admin winkelwagen'}
                        </Button>
                    }
                    return null
                }}
            </AuthWithUser>}
        </Form>
        <div className={'modal-footer'}>
            <div className={'modal'}><div className={'modal-footer'} /></div>
        </div>
    </Modal>
}
export {PageArabiaTeachingAdd}
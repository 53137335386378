import React from "react";
import {PackagesBanner} from "./banner.js";
import {PackagesProducts} from "./products.js";

const PagePackages = function (props) {
    return <div>
        <PackagesBanner/>
        <PackagesProducts/>
    </div>
};

export {PagePackages}
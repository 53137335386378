import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Col,
  Steps,
  List,
  Popover,
  Result,
} from "antd";
import { useStateContext } from "../../../helpers/state-context/use_state_context.js";
import { AuthContext } from "../../../components/auth/auth-provider.js";
import {
  ageTypes,
  genderRoomTypes,
  genderTypes,
  productTypes,
  roomFormTypes,
  roomTypes,
  getAgeTypeById,
  getRoomByProductType,
  areEqual,
} from "../../../assets/constants.js";
import { MinusCircleOutlined } from "@ant-design/icons";
import constants from "../../../assets/constants.json";
import { AuthWithUser } from "../../../components/auth/auth-with-user.js";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { AuthSignUpPopup } from "../../../components/auth/sign-up-popup.js";

import StepperHeader from "../../../components/stepper/StepperHeader.js";

import {
  FaChild,
  FaPlus,
  FaMinus,
  FaInfoCircle,
  FaBed,
  FaPassport,
  FaWheelchair,
} from "react-icons/fa";
import { MdChildFriendly, MdEmojiPeople } from "react-icons/md";

const { Step } = Steps;

const PackagesAddForm = function (props) {
  const { item } = props;
  const userTokenKey = localStorage.getItem("tokenKey");
  //  const itemFlex = JSON.parse(item.flex);
  const [currentStep, setCurrentstep] = useState(0);
  const [cartType, setCartType] = useState("");
  const [roomGender, setRoomGender] = useState(
    props.initialValues?.room_gender || constants.GENDER_TYPE.MIX
  );
  const [guestsCount, setGuestsCount] = useState(
    props.initialValues?.guests.length || 1
  );
  const [ageCount, setAgeCount] = useState({ 0: 1, 1: 0, 2: 0 });
  const [roomType, setRoomType] = useState(item.type);
  const [typeAdded, setTypeAdded] = useState(item.type);
  const [userAuthed, setUserAuthed] = useState(false);
  const [contextState, dispatch] = useStateContext();
  const { user } = React.useContext(AuthContext);
  const [form] = Form.useForm();
  const history = useHistory();
  const isUserCart = history.location.pathname === "/winkelwagen";
  const isAdminCart = history.location.pathname === "/admin-winkelwagen";
  const isLoggedIn = userTokenKey !== undefined && userTokenKey !== null;
  const updateMaxGuests = (values, initial = false, number = false) => {
    // Updates amount of inputs for guests and also updates the product_ids if room changes
    const guests = [];

    if (values.room_type) {
      const product_type = roomTypes.find(
        (roomType) => roomType.id === values.room_type
      );
      if (product_type) {
        const fields = {
          max_guests: product_type.max_guests,
        };
        if (values.guests.length > product_type.max_guests) {
          fields.guests = values.guests.slice(0, fields.max_guests);
        }
        form.setFieldsValue(fields);
      }
    }

    return number ? guests.length : guests;
  };
  const onSubmit = (roomDetails) => {
    const { babys, ...room } = roomDetails;
    const roomObject = { ...room };

    if (roomObject.exclusive) {
      if (roomObject.guests.length > 1) {
        roomObject.exclusive = false;
      }
    }
    for (const guest of roomObject.guests) {
      const productType = productTypes[room.room_type].find(
        (i) => i.age_category === guest.age_category
      ).product_id;
      const product = props.products
        .filter((product) => product.is_active)
        .filter(
          (product) =>
            moment(product.start).format("YYYY-MM-DD") ===
              moment(item.start).format("YYYY-MM-DD") &&
            product.stock > 0 &&
            areEqual(
              JSON.parse(item.flex).hotels,
              JSON.parse(product.flex).hotels
            )
        )
        .find((i) => i.type === productType);

      // Add correct product_id for each guest.
      if (product && guest.age_category !== undefined) {
        guest.product_id = product.product_id;
      }

      // Add correct gender type to guests if roomGender is not mix.
      if (genderTypes.map((t) => t.id).includes(roomGender)) {
        guest.gender = roomGender;
      }

      guest.flex = guest.flex ? JSON.stringify(guest.flex) : "{}";
    }

    setTypeAdded(room.room_type);
    form.resetFields();
    setCurrentstep(currentStep + 1);
    if (cartType === "admin") {
      if (props.initialValues) {
        return dispatch({
          type: "updateAdminCartItem",
          value: { ...roomObject, type: "umrah" },
          key: props.cartKey,
        });
      } else {
        return dispatch({
          type: "addToAdminCart",
          value: { ...roomObject, type: "umrah" },
        });
      }
    } else {
      if (props.initialValues) {
        return dispatch({
          type: "updateCartItem",
          value: { ...roomObject, type: "umrah" },
          key: props.cartKey,
        });
      } else {
        return dispatch({
          type: "addToCart",
          value: { ...roomObject, type: "umrah" },
        });
      }
    }
  };

  const updateGenders = (values) => {
    const guests = [];
    for (let guest of values.guests) {
      if (genderTypes.map((t) => t.id).includes(roomGender)) {
        if (guest !== undefined) {
          if (!roomGender === constants.GENDER_TYPE.MIX) {
            guest.bed_included = true;
          }
          if (guest.gender > 1) {
            guest.gender = 0;
          } else {
            guest.gender = roomGender;
          }
        }
      }
      guests.push(guest);
    }
    form.setFieldsValue({ guests: guests });

    setRoomGender(values.room_gender);
  };

  const checkRoomTypeAllowed = (roomType) => {
    const selectedProducts = props.products.filter(
      (product) => product.start === item.start
    );
    const desiredProduct = selectedProducts.find(
      (product) => product.type === roomType
    );
    return typeof desiredProduct === "object";
  };

  let initialValues = props.initialValues
    ? props.initialValues
    : {
        room_type: item.type,
        guests: [
          {
            european_passport: 1,
            bed_included: 1,
            age_category: 0,
            flex: { disabled: false },
          },
        ],
        max_guests: getRoomByProductType(roomType).max_guests,
        room_gender: 2,
        exclusive: false,
      };
  const minimumGuests =
    roomGender === constants.GENDER_TYPE.MIX
      ? getRoomByProductType(roomType).max_guests
      : 1;
  const forbiddenBackSteps = [2, 3];
  const allowedSingleRoomGenders = [0, 1];
  return (
    <div className="flowForm">
      <Form
        layout={"vertical"}
        form={form}
        onFinish={onSubmit}
        onValuesChange={(value, values) => {
          // Set maximum guests based on selected room type
          const realGuests = values.guests
            .filter((guest) => guest.bed_included)
            .filter(
              (guest) => guest.age_category !== constants.AGE_CATEGORY.BABY
            );
          setAgeCount({
            0: values.guests.filter(
              (guest) => guest.age_category === constants.AGE_CATEGORY.ADULT
            ).length,
            1: values.guests.filter(
              (guest) => guest.age_category === constants.AGE_CATEGORY.CHILD
            ).length,
            2: values.guests.filter(
              (guest) => guest.age_category === constants.AGE_CATEGORY.BABY
            ).length,
          });
          if (value.room_type !== undefined) {
            updateMaxGuests(values);
            setRoomType(value.room_type);
            setGuestsCount(realGuests.length);
          }

          //Update guest length
          if (value.guests !== undefined) {
            setGuestsCount(realGuests.length);
          }

          // Handle gender when single gender room is selected
          if (value.room_gender !== undefined) {
            updateGenders(values);
          }
        }}
        initialValues={initialValues}
      >
        <div className="stepContainer">
          <StepperHeader
            activeStep={currentStep + 1}
            stepNumber={1}
            label={"Wat voor kamer wil jij?"}
          />
          <div className={"roomContainer " + (currentStep === 0 && "show")}>
            <div className="formItemLabel" id="formItemLabel">
              <div className="itemLabel">
                <label className="required">* </label>
                <label className="content">Formaat kamer</label>
              </div>
              <div className="inputDesc">
                Tweepersoonskamer: Deze kamer is uitgerust met twee bedden
                (kingsize of een tweepersoonsbed). Ideaal voor getrouwde
                stellen, broeders/zusters die hun kamer met slechts één persoon
                willen delen.
                <br></br>
                <br></br>
                Driepersoonskamer: Deze kamer is voorzien van drie
                eenpersoonsbedden. Ideaal voor vrienden, gezinnen met een kind
                of broeders/zusters die graag hun kamer met twee anderen willen
                delen.
              </div>
            </div>
            <Form.Item
              className={"form-input"}
              name="room_type"
              rules={[{ required: true, message: "Kamer type is verplicht!" }]}
            >
              <Radio.Group>
                {roomFormTypes.map((type, key) => (
                  <Radio.Button
                    disabled={!checkRoomTypeAllowed(type.id)}
                    value={type.id}
                    key={"roomType" + key}
                  >
                    {type.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>

            <div className="formItemLabel" id="Voor wie is deze kamer bedoeld?">
              <div className="itemLabel">
                <label className="required">* </label>
                <label className="content">Soort kamer</label>
              </div>
              <div className="inputDesc">
                Om de kamers juist te kunnen indelen moet je een keuze maken.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum eget ipsum ante. Donec eget sollicitudin lectus.
                Curabitur aliquam, leo nec.
              </div>
            </div>
            <Form.Item
              className={"form-input"}
              name="room_gender"
              rules={[
                {
                  required: true,
                  message: "Kamer geslacht type is verplicht!",
                },
              ]}
            >
              <Radio.Group>
                {genderRoomTypes.map((type, key) => (
                  <div key={"roomGender" + type.id} className="roomGenderItem">
                    <Radio value={type.id}>
                      <List.Item>
                        <List.Item.Meta
                          avatar={type.icon}
                          title={type.label2}
                          key={key}
                          description={type.desc}
                        />
                      </List.Item>
                    </Radio>
                  </div>
                ))}
              </Radio.Group>
            </Form.Item>
          </div>
          <StepperHeader
            activeStep={currentStep + 1}
            stepNumber={2}
            label={"Wie gaan er mee?"}
          />

          <div className={"guestContainer " + (currentStep === 1 && "show")}>
            <Form.List
              name="guests"
              rules={[
                {
                  validator: async (_, guests) => {
                    if (!guests || guestsCount < minimumGuests) {
                      return Promise.reject(
                        new Error(
                          "Er moet(en) minimaal " +
                            minimumGuests +
                            " bed(den) gevuld zijn in deze kamer."
                        )
                      );
                    }
                  },
                },
              ]}
            >
              {(guests, { add, remove }, { errors }) => {
                let guestsValues = form.getFieldValue("guests");
                const max_people = roomTypes.find(
                  (type) => type.id === roomType
                );
                const isPeopleAllowed =
                  max_people?.max_guests && guestsCount
                    ? max_people?.max_guests > guestsCount
                    : true;
                return (
                  <div className="guestsContainer">
                    <div className="ageCountContainer">
                      <div className="ageCounter">
                        <MdEmojiPeople className="childIcon" />
                        <div className="ageLabel">Volwassenen</div>
                        <div className="counterContainer">
                          <FaMinus
                            className="actionIcon"
                            onClick={() =>
                              remove(
                                guestsValues.findLastIndex(
                                  (guest) =>
                                    guest.age_category ===
                                    constants.AGE_CATEGORY.ADULT
                                )
                              )
                            }
                          />

                          <div className="ageCount">
                            {ageCount[constants.AGE_CATEGORY.ADULT]}
                          </div>
                          <FaPlus
                            onClick={() =>
                              isPeopleAllowed
                                ? add({
                                    age_category: constants.AGE_CATEGORY.ADULT,
                                    european_passport: true,
                                    bed_included: true,
                                  })
                                : null
                            }
                            className="actionIcon right"
                          ></FaPlus>
                        </div>
                      </div>
                      <div className="ageCounter">
                        <FaChild className="childIcon md" />
                        <div className="ageLabel">Kinderen (2-12 jr)</div>
                        <div className="counterContainer">
                          <FaMinus
                            className="actionIcon"
                            onClick={() =>
                              remove(
                                guestsValues.findLastIndex(
                                  (guest) =>
                                    guest.age_category ===
                                    constants.AGE_CATEGORY.CHILD
                                )
                              )
                            }
                          />

                          <div className="ageCount">
                            {ageCount[constants.AGE_CATEGORY.CHILD]}
                          </div>
                          <FaPlus
                            onClick={() =>
                              add({
                                age_category: constants.AGE_CATEGORY.CHILD,
                                european_passport: true,
                                bed_included: false,
                              })
                            }
                            className="actionIcon right"
                          ></FaPlus>
                        </div>
                      </div>
                      <div className="ageCounter">
                        <MdChildFriendly className="childIcon md" />
                        <div className="ageLabel">Baby's (0-24 mnd)</div>
                        <div className="counterContainer">
                          <FaMinus
                            className="actionIcon"
                            onClick={() =>
                              remove(
                                guestsValues.findLastIndex(
                                  (guest) =>
                                    guest.age_category ===
                                    constants.AGE_CATEGORY.BABY
                                )
                              )
                            }
                          />

                          <div className="ageCount">
                            {ageCount[constants.AGE_CATEGORY.BABY]}
                          </div>
                          <FaPlus
                            onClick={() =>
                              add({
                                age_category: constants.AGE_CATEGORY.BABY,
                                european_passport: true,
                                bed_included: true,
                              })
                            }
                            className="actionIcon right"
                          ></FaPlus>
                        </div>
                      </div>
                    </div>

                    {guests.map((field) => {
                      let guestAge = getAgeTypeById(
                        guestsValues[field.name].age_category
                      );
                      return (
                        <div className="guestField" key={field.key}>
                          <div className="fieldheader">
                            <div className="ageHeader">
                              {guestAge && guestAge.icon}
                              <div className="ageLabel">
                                {guestAge && guestAge.label}
                              </div>
                            </div>
                            <div className="removeCont">
                              {guestsValues[field.name]?.flex?.disabled && (
                                <FaWheelchair className="weelChair" />
                              )}
                              {guestsValues[field.name].bed_included && (
                                <FaBed className="bedIcon"></FaBed>
                              )}
                              {guests.length > 1 && (
                                <MinusCircleOutlined
                                  className="remove"
                                  onClick={() => remove(field.name)}
                                />
                              )}
                            </div>
                          </div>
                          <Row>
                            <Col xs={24} sm={14} lg={14}>
                              <div
                                className={"mobile-vertical"}
                                style={{ display: "flex" }}
                              >
                                <Form.Item
                                  className={"form-input"}
                                  name={[field.name, "first_name"]}
                                  label={"Voornaam"}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Voornaam is verplicht!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  className={"form-input"}
                                  name={[field.name, "last_name"]}
                                  label="Achternaam"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Achternaam is verplicht!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} sm={10} lg={10}>
                              <div
                                className={"mobile-vertical"}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Form.Item
                                  hidden
                                  className={"form-input"}
                                  name={[field.name, "age_category"]}
                                  label="Leeftijd"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Leeftijd is verplicht!",
                                    },
                                  ]}
                                >
                                  <Select>
                                    {ageTypes
                                      // .filter((type) => type.id !== constants.AGE_CATEGORY.BABY)
                                      .map((ageType, key) => (
                                        <Select.Option
                                          key={key}
                                          value={ageType.id}
                                        >
                                          {ageType.label}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  className={"form-input"}
                                  hidden={genderTypes
                                    .map((t) => t.id)
                                    .includes(roomGender)}
                                  name={[field.name, "gender"]}
                                  label="Geslacht"
                                  rules={
                                    genderTypes
                                      .map((t) => t.id)
                                      .includes(roomGender)
                                      ? []
                                      : [
                                          {
                                            required: true,
                                            message: "Geslacht is verplicht!",
                                          },
                                        ]
                                  }
                                >
                                  <Select>
                                    {genderTypes.map((ageType, key) => (
                                      <Select.Option
                                        key={key}
                                        value={ageType.id}
                                      >
                                        {ageType.label}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>

                              <Form.Item
                                className={"form-input"}
                                name={[field.name, "product_id"]}
                                hidden
                              >
                                <Input type={"hidden"} />
                              </Form.Item>
                            </Col>
                            <div className="guestChecks">
                              <div className="check">
                                <Form.Item
                                  className={"form-input"}
                                  name={[field.name, "european_passport"]}
                                  valuePropName="checked"
                                >
                                  <Checkbox>
                                    <FaPassport /> Europees paspoort?
                                  </Checkbox>
                                </Form.Item>
                                <Popover
                                  title="Niet-europese paspoorten"
                                  trigger="click"
                                  content={
                                    <label>
                                      Bent u niet in bezit van een
                                      Europees/Amerikaans paspoort? Dan dient er
                                      via de saudische ambassade een
                                      "umrahvisum" worden aangevraagd. De kosten
                                      hiervoor bedragen 300,- die aan de
                                      ambassade wordt betaald.
                                      <br></br>
                                      Deze kosten worden verrekend bovenop uw
                                      pakket. Dit paspoort dient u ook fysiek op
                                      locatie aan te leveren bij ons. Na de
                                      bestelling zal onze collega van
                                      FirstClassUmrah contact met u opnemen
                                      hierover.
                                    </label>
                                  }
                                >
                                  <FaInfoCircle className="infoCircle" />
                                </Popover>
                              </div>
                              <div className="check">
                                <Form.Item
                                  className={"form-input bed"}
                                  hidden={
                                    guestsValues[field.name].age_category !=
                                      1 ||
                                    roomGender !== constants.GENDER_TYPE.MIX
                                  }
                                  name={[field.name, "bed_included"]}
                                  valuePropName="checked"
                                >
                                  <Checkbox
                                    disabled={
                                      !guestsValues[field.name].bed_included &&
                                      guestsCount >=
                                        getRoomByProductType(roomType)
                                          .max_guests
                                        ? true
                                        : false
                                    }
                                  >
                                    <FaBed /> Eigen bed?
                                  </Checkbox>
                                </Form.Item>
                                <Popover
                                  title="Kind zonder bed"
                                  trigger="click"
                                  content={
                                    <label>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit.<br></br>
                                      Maecenas sit amet nulla mauris. Aenean
                                      tincidunt luctus lacus at placerat.
                                      <br></br>
                                      Duis bibendum lacus enim, sit amet
                                      malesuada dui consectetur ac. Praesent.
                                    </label>
                                  }
                                >
                                  <FaInfoCircle
                                    className={
                                      "infoCircle " +
                                      (guestsValues[field.name].age_category !=
                                        1 ||
                                      roomGender !== constants.GENDER_TYPE.MIX
                                        ? "hide"
                                        : "")
                                    }
                                  />
                                </Popover>
                              </div>
                              <div className="check">
                                <Form.Item
                                  className={"form-input bed"}
                                  name={[field.name, "flex", "disabled"]}
                                  valuePropName="checked"
                                >
                                  <Checkbox>
                                    <FaWheelchair /> Rolstoel?
                                  </Checkbox>
                                </Form.Item>
                                <Popover
                                  title="Rolstoel"
                                  trigger="click"
                                  content={
                                    <label>
                                      Indien deze reiziger (vanwege
                                      invaliditeit) een rolstoel en/of speciale
                                      ondersteuning nodig heeft; vink dan
                                      onderstaande vakje aan. Wij zullen na de
                                      bestelling contact met de factuurhouder
                                      opnemen om zijn/haar reis zo comfortabel
                                      mogelijk te maken door de juiste
                                      ondersteuning te organiseren. Let op, het
                                      kan zijn dat hier aanvullende kosten aan
                                      verbonden zijn. Denk hierbij aan
                                      bijvoorbeeld de huur van een rolstoel of
                                      ondersteunend personeel van de Haram.
                                    </label>
                                  }
                                >
                                  <FaInfoCircle className="infoCircle" />
                                </Popover>
                              </div>
                            </div>
                          </Row>
                          <hr />
                        </div>
                      );
                    })}
                    <Form.ErrorList errors={errors} />
                  </div>
                );
              }}
            </Form.List>
            <div
              className={
                "exclusiveRroom " +
                (allowedSingleRoomGenders.indexOf(
                  form.getFieldValue("room_gender")
                ) !== -1 &&
                form.getFieldValue("max_guests") === 2 &&
                form.getFieldValue("guests").length === 1
                  ? "show"
                  : "")
              }
            >
              <Form.Item
                className={"form-input"}
                name={"exclusive"}
                valuePropName="checked"
              >
                <Checkbox>
                  Wil je alleen in de kamer slapen?
                  <i className="priceExcl">(+850,-)</i>
                </Checkbox>
              </Form.Item>
            </div>
          </div>

          <StepperHeader
            activeStep={currentStep + 1}
            stepNumber={3}
            lastStep={isLoggedIn}
            label={"Nog een kamer?"}
          />
          {currentStep === 2 && (
            <div>
              <Result
                status="success"
                title={
                  "Succesvol " +
                  getRoomByProductType(typeAdded).label +
                  " kamer toegevoegd"
                }
                subTitle="Bedankt voor het toevoegen van een kamer, blabalbaba"
              />
              <div className="resultButtonCont">
                {isLoggedIn && (
                  <Button
                    className="contact-form-button next"
                    type="primary"
                    key="console"
                    onClick={() =>
                      cartType === "user"
                        ? history.push("/winkelwagen")
                        : history.push("/admin-winkelwagen")
                    }
                  >
                    Afrekenen
                  </Button>
                )}

                {!isLoggedIn && (
                  <Button
                    onClick={() => setCurrentstep(currentStep + 1)}
                    className="contact-form-button next"
                    type="primary"
                    key="console1"
                  >
                    Doorgaan naar registreren
                  </Button>
                )}
                <Button
                  className="contact-form-button next"
                  key="buy"
                  onClick={() => setCurrentstep(0)}
                >
                  Voeg nog een kamer toe
                </Button>
              </div>
            </div>
          )}
        </div>

        <Form.Item name="max_guests" hidden>
          <Input type={"hidden"} />
        </Form.Item>
      </Form>

      {!isLoggedIn && (
        <StepperHeader
          activeStep={currentStep + 1}
          stepNumber={4}
          lastStep={true}
          label={"Gegevens invullen & afrekenen"}
        />
      )}
      {!isLoggedIn && currentStep === 3 && (
        <div>
          <AuthSignUpPopup
            loginOnRegister={true}
            onSubmit={() => history.push("/winkelwagen")}
          />
        </div>
      )}
      <div className="stepContainer">
        <div className="steps-action">
          {currentStep > 0 &&
            forbiddenBackSteps.indexOf(currentStep) === -1 && (
              <Button
                className="contact-form-button previous"
                type="primary"
                onClick={() => setCurrentstep(currentStep - 1)}
              >
                Terug
              </Button>
            )}
          {currentStep === 1 && !isAdminCart && (
            <Button
              type="primary"
              name={"1"}
              className="contact-form-button next"
              onClick={() => {
                form.submit();
                return setCartType("user");
              }}
            >
              {props.initialValues ? "Updaten" : "Volgende"}
            </Button>
          )}
          {!isUserCart && currentStep === 1 && (
            <AuthWithUser enableFeedback={false}>
              {(user) => {
                if (user && user.role === constants.USER_ROLE.ADMIN) {
                  return (
                    <Button
                      type="primary"
                      name={"2"}
                      onClick={() => {
                        form.submit();
                        return setCartType("admin");
                      }}
                      className="contact-form-button next"
                    >
                      {props.initialValues ? "Updaten" : "Volgende (Admin)"}
                    </Button>
                  );
                }
                return null;
              }}
            </AuthWithUser>
          )}
          {currentStep < 2 && currentStep !== 1 && (
            <Button
              className="contact-form-button next"
              type="primary"
              onClick={() => setCurrentstep(currentStep + 1)}
            >
              Volgende
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export { PackagesAddForm };

import React from "react";
import {AboutBanner} from "./banner.js";
import {AboutQuote} from "./quote.js";
import {AboutTeam} from "./team.js";
import {AboutSection} from "./section.js";

const PageAboutUs = function (props) {
    return <>
        <AboutBanner/>
        <AboutSection/>
        <AboutQuote/>
        <AboutTeam/>
    </>
};

export {PageAboutUs}
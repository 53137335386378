import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  DatePicker,
  InputNumber,
  notification,
  Select,
} from "antd";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FaChild, FaPlus, FaMinus, FaInfoCircle, FaBed } from "react-icons/fa";
import { ModelInvoices } from "../../../model/invoices.js";
import { ModelUsers } from "../../../model/users.js";
import { getProductByType } from "../../../assets/constants.js";
import moment, { isDate } from "moment";

const PageNewInvoice = function (props) {
  const { initialValues } = props;
  const [form] = Form.useForm();

  const onSubmit = (values) => {
    values.flex = values.flex
      ? JSON.stringify(values.flex)
      : JSON.stringify({});
    values.expiration = moment(values.expiration).format("YYYY-MM-DD");
    values.items = values.items.map((item) => {
      item.amount_in_cents = item.amount_in_cents * 100;
      item.type = 0;
      item.flex = item.flex ? JSON.stringify(values.flex) : JSON.stringify({});
      return item;
    });

    const totalArr = values.items.map((item) =>
    parseInt(item.amount_in_cents)
    );

    const totalPrice = totalArr.reduce((partialSum, a) => partialSum + a, 0) || 0;

    if(totalPrice/100 >= 50000){
      return notification.error({
        message: ("Offerte kan maximaal 50k zijn, PAY.nl accepteert max 50k per offerte.")
      });
    }


    ModelInvoices.createItem(values, null , (resp)=>{
        props.setVisible();
        notification.success({
            message: ("Offerte voor gebruiker #" + values.user_id + " is succesvol toegevoegd.")
        });
    });
  };

  return (
    <Modal
      visible={props.visible}
      maskClosable={false}
      width={1200}
      className={"new-kennis-editor"}
      title={"Voeg nieuwe offerte/factuur toe"}
      okText={"Toevoegen"}
      footer={false}
      cancelText={"Annuleren"}
      onCancel={(e) => {
        props.setVisible(false);
        props.setInitialValues(null);
      }}
    >
      <Form
        layout={"vertical"}
        onFinish={onSubmit}
        form={form}
        initialValues={{
          items: [""],
          ...initialValues,
        }}
      >
        <Form.Item required={true} name={"expiration"} label="Vervaldatum">
          <DatePicker />
        </Form.Item>

        <Form.Item required={true} name={"type"} label="Type">
          <Select>
            <Select.Option value={0}>Offerte</Select.Option>
            <Select.Option value={1}>Factuur</Select.Option>
          </Select>
        </Form.Item>

        <Form.List
          name="items"
          rules={[
            {
              validator: async (_, names) => {
                if (names.length < 1) {
                  return Promise.reject(new Error("Minimaal 1 item!"));
                }
              },
            },
          ]}
        >
          {(items, { add, remove }, { errors }) => (
            <>
              {items.map((field) => (
                <div className="product" key={"idk" + field.name}>
                  <FaMinus
                    className="delete"
                    onClick={() => remove(field.name)}
                  />
                  <h4>Item #{field.name + 1}</h4>
                  <Form.Item
                    className={"form-input"}
                    name={[field.name, "label"]}
                    label={"Item naam"}
                    rules={[
                      {
                        required: true,
                        message: "Item naam is verplicht!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    className={"form-input"}
                    name={[field.name, "amount_in_cents"]}
                    label={"Bedrag"}
                    rules={[
                      {
                        required: true,
                        message: "Bedrag is verplicht!",
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>

                  <Form.Item
                    className={"form-input"}
                    name={[field.name, "amount"]}
                    label={"Aantal"}
                    rules={[
                      {
                        required: true,
                        message: "Aantal is verplicht!",
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>

                  <Form.Item
                    className={"form-input"}
                    name={[field.name, "btw_type"]}
                    label={"BTW Type"}
                    rules={[
                      {
                        required: true,
                        message: "Type is verplicht!",
                      },
                    ]}
                  >
                    <Select>
                      <Select.Option value={21}>21</Select.Option>

                      <Select.Option value={9}>9</Select.Option>
                      <Select.Option value={0}>0</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              ))}
              <Button
                onClick={() => add()}
                className="contact-form-button"
                rules={[
                  {
                    required: true,
                    message: "Gebruiker is verplicht!",
                  },
                ]}
              >
                {"Item toevoegen "}
                <FaPlus />
              </Button>
            </>
          )}
        </Form.List>
        <Form.Item required={true} name={"user_id"} label="Gebruiker">
          <Select
            showSearch={true}
            filterOption={(inputValue, option) =>
              option.children.toLowerCase().includes(inputValue.toLowerCase())
            }
          >
            {props.users?.map((user, key) => {
              return (
                <Select.Option key={"product" + key} value={user.user_id}>
                  {user.email}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className="contact-form-button"
        >
          Toevoegen
        </Button>
      </Form>
    </Modal>
  );
};

export default PageNewInvoice;

import React from 'react';

const defaultContext = {
    state: {},
    reducer: (state, action) => {
        // If an action is not handled by any reducer, it will eventually end up here.
        console.warn('No handler found for dispatched action: ' + JSON.stringify(action) + '\n' + 
                'If you are handling the action, make sure you return a copy of the state object and not ' +
                'the same instance.');
        console.trace();
        return state;
    }
};

const ReactStateContext = React.createContext(defaultContext);
ReactStateContext.displayName = 'StateContext';

export {ReactStateContext};
import React, {useState} from "react";
import {Modal, Form, Input, Button ,DatePicker, InputNumber, notification, Select} from "antd";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {ModelVouchers} from "../../../model/vouchers.js";
import {ModelProducts} from "../../../model/products.js";
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import {getProductByType} from "../../../assets/constants.js"
import moment, { isDate } from "moment";

const PageNewVoucher = function (props) {
    const resultSet = useModelFeedback(ModelProducts);
    const {initialValues} = props;
    const [form] = Form.useForm();

    const onSubmit = (values) => {
        values['active'] = true;
        values['amount'] =  values['amount'] * 100;
        values['expire_date'] = moment(values['expire_date']).format("YYYY-MM-DD HH:mm:ss")
        values['flex'] = values['flex'] ?  JSON.stringify(values['flex']) : "{}";
        ModelVouchers.createItem(values, (resp)=>{
            props.setVisible();
            notification.success({
                message: ("Kortingscode " + values.label + " is succesvol toegevoegd.")
            });
        });
    };


    return <Modal visible={props.visible}
                  maskClosable={false}
                  width={1200}
                  className={'new-kennis-editor'}
                  title={'Voeg nieuwe kortingscode toe'}
                  okText={'Toevoegen'}
                  footer={false}
                  cancelText={'Annuleren'}
                  onCancel={(e)=>{props.setVisible(false);props.setInitialValues(null)}}>
        <Form layout={'vertical'} onFinish={onSubmit} form={form} initialValues={{
            ...initialValues
        }}>
            <Form.Item name="label" label="Kortingscode" rules={[{ required: true, message: "Kortingscode is verplicht!" }]}>
                <Input/>
            </Form.Item>
            <Form.Item pattern="[0-9]+([,][0-9]{1,2})?" name="amount" label="Bedrag" rules={[{ required: true, message: "Bedrag is verplicht!" }]}>
                <InputNumber style={{width: '100%'}} min={0}/>
            </Form.Item>
            <Form.Item name="expire_date" label="Geldig tot:" rules={[{ required: true, message: "Bedrag is verplicht!" }]}>
                <DatePicker showTime={{ format: 'HH:mm' }} />
            </Form.Item>
            <Form.Item name={['flex', 'products']} label="Producten (korting i.c.m. product)">
                <Select mode={'multiple'}
                 filterOption={(inputValue, option) =>
                    option.children.toLowerCase().includes(inputValue.toLowerCase())
                  }>
                    {resultSet.data?.products?.map((product, key) => {
                            return <Select.Option key={"product"+key} value={product.product_id}>{product.label+ " " + moment(product.created_at).year() + " " + getProductByType(product.type).label}</Select.Option>
                        })
                    }
                </Select>
            </Form.Item>
  
            <Button type="primary" htmlType="submit" className="contact-form-button">
                Toevoegen
            </Button>
        </Form>

    </Modal>
};

export default PageNewVoucher;

import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Header } from "./view/header/header.js";
import { PageHome } from "./pages/home/home.js";
import { PageAboutUs } from "./pages/about/about.js";
import { PageContact } from "./pages/contact/contact.js";
import { PageArticles } from "./pages/articles.js";
import { PagePackages } from "./pages/packages/packages.js";
import { PageAdmin } from "./pages/admin/admin.js";
import { PageArabiaTeaching } from "./pages/arabia-teaching/arabia-teaching.js";
import { PageCart } from "./pages/cart/cart.js";
import { PageArticleDetails } from "./pages/article-details.js";
import { Footer } from "./view/footer/footer.js";
import { CustomRoute } from "./components/auth/custom-route.js";
import constants from "./assets/constants.json";
import { PageAccount } from "./pages/account/account.js";
import { ViewMain } from "./view/main.js";
import { PageCheckout } from "./pages/checkout/checkout.js";
import { PageTermsAndConditions } from "./pages/general/terms-and-conditions.js";
import { PagePrivacy } from "./pages/general/privacy.js";
import { PageAdminCart } from "./pages/cart/admin-cart.js";
import { AuthProvider } from "./components/auth/auth-provider.js";
import { ShowInvoice } from "./pages/invoice/showInvoice.js";
import { InsertVisumDetails } from "./pages/visum/insertVisumDetails.js";
import { ResetComp } from "./pages/reset/reset.js";
import { PaymentProcess } from "./pages/admin/paymentProcess.js";

const App = () => (
  <AuthProvider>
    <BrowserRouter>
      <Header />
      <ViewMain>
        <Switch>
          <CustomRoute path={"/privacy"} component={PagePrivacy} />
          <CustomRoute
            path={"/algemene-voorwaarden"}
            component={PageTermsAndConditions}
          />
          <CustomRoute path={"/contact"} component={PageContact} />
          <CustomRoute
            path={"/kennis/:kennisId"}
            component={PageArticleDetails}
          />
          <CustomRoute path={"/kennis"} component={PageArticles} />
          <CustomRoute path={"/pakketten"} component={PagePackages} />
          <CustomRoute
            path={"/arabia-teaching"}
            component={PageArabiaTeaching}
          />
          <CustomRoute path={"/over-ons"} component={PageAboutUs} />
          <CustomRoute path={"/winkelwagen"} component={PageCart} />
          <CustomRoute
            path={"/admin-winkelwagen"}
            component={PageAdminCart}
            authenticated={true}
            roles={[constants.USER_ROLE.ADMIN]}
          />
          <CustomRoute path={"/afrekenen"} component={PageCheckout} />
          <CustomRoute
            exact
            path={"/account"}
            component={(props) => PageAccount({ ...props, link: "/account" })}
            loginOption={true}
            authenticated={true}
          />
          <CustomRoute
            exact
            path={"/account/bestellingen"}
            component={(props) =>
              PageAccount({ ...props, link: "/account/bestellingen" })
            }
            loginOption={true}
            authenticated={true}
          />
          <CustomRoute
            path={"/account/bestellingen/:orderID"}
            component={(props) =>
              PageAccount({ ...props, link: "/account/bestellingen" })
            }
            loginOption={true}
            authenticated={true}
          />
          <CustomRoute
          exact
            path={"/admin"}
            component={PageAdmin}
            authenticated={true}
            roles={[constants.USER_ROLE.ADMIN]}
          />
          <CustomRoute
            path={"/admin/process_payment/:orderID"}
            component={PaymentProcess}
            authenticated={true}
            roles={[constants.USER_ROLE.ADMIN]}
          />
          <CustomRoute path={"/confirm/:orderId"} component={PageHome} />
          <CustomRoute
            path={"/offerte/:token"}
            component={ShowInvoice}
            authenticated={false}
          />
          <CustomRoute
            path={"/visum/invoeren/:id"}
            component={InsertVisumDetails}
            authenticated={false}
          />
          <CustomRoute
            path={"/reset/:id"}
            component={ResetComp}
            authenticated={false}
          />
          <CustomRoute exact path={"/"} component={PageHome} />
        </Switch>
      </ViewMain>
      <Footer />
    </BrowserRouter>
  </AuthProvider>
);
export default App;

import React from "react";
import defaultImage from "../../assets/images/kennis_item.jpg";
import {UIText} from "../ui/text/text.js";
import {Tag} from "antd";
import {UIButton} from "../ui/button/button.js";
import {useHistory} from "react-router-dom";

const ArticlesArticle = function (props) {
    const {article, categories} = props;
    const history = useHistory();
    return <div className={'articles-item'}>
        <img alt={''} className={'articles-image'} src={article.img && article.img !== '0' ? article.img : defaultImage}/>
        <div className={'articles-about'}>
            <UIText variant={'paragraph-title'}>{article.label}</UIText>
            {article.categories?.map((cat, key)=>{
                const foundCategory = categories.find((category)=>category.article_category_id === cat.article_category_id)
                if(foundCategory){
                    return  <Tag key={key} color={'gold'}>{foundCategory.label}</Tag>
                }
                return null;

            })}
            <UIText className={'articles-about-paragraph'}>{article.description}</UIText>
            <UIButton className={'articles-button'} onClick={()=>{
                history.push(`/kennis/${article.id}`)
            }}>Lees verder</UIButton>
        </div>
    </div>
};

export {ArticlesArticle}
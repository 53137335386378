import React from 'react';
import PropTypes from 'prop-types';

const styleWarning = {
    position: 'relative',
    cssFloat: 'left',
    width: '80px',
    height: '80px',
    marginRight: '20px'
};
const styleBorder = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '0 40px 69.3px 40px',
    borderColor: 'transparent transparent #909090 transparent',
};
const styleFill = {
    position: 'absolute',
    width: '0',
    height: '0',
    top: '12px',
    left: '12px',
    borderStyle: 'solid',
    borderWidth: '0 28px 50px 28px',
    borderColor: 'transparent transparent white transparent'
};
const styleExclamation = {
    position: 'absolute',
    width: '80px',
    height: '50px',
    top: '15px',
    lineHeight: '50px',
    fontSize: '42px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#909090',
}

const ComponentError = function(props){

    return <div style={{height: 500, width: '100%', display: "flex", justifyContent: "center", alignItems: "center"}}>
        <p>
            <span style={styleWarning}>
                <span style={styleBorder}></span>
                <span style={styleFill}></span>
                <span style={styleExclamation}>!</span>
            </span> 
            {props.title || 'Onbekende fout'}
            <br/>
            Neem contact op met ons!
        </p>
    </div>;

}

ComponentError.defaultProps = {
    title: 'Error',
    message: 'Een onbekende fout is opgetreden. ',
}

ComponentError.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string
}

export {ComponentError};
import React from "react";
import { UIText } from "../../../components/ui/text/text.js";
import { Collapse, notification, Tag, Table, Space } from "antd";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import constants from "../../../assets/constants.json";
import { toMoney } from "../../../helpers/functions.js";
import { UIDescriptions } from "../../../components/ui/descriptions.js";
import { ModelInvoices } from "../../../model/invoices.js";
import FormItemLabel from "antd/lib/form/FormItemLabel.js";
import {getPaymentTypes} from "../../../assets/constants.js";

import moment from 'moment';


const AdmininvoicesList = function (props) {
  const { Column, ColumnGroup } = Table;

  const getOrderInvoice = (order_id) => {
    ModelInvoices.orderInvoice(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `factuur-FCU${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const getInvoiceDown = (order_id) => {
    ModelInvoices.downPaymentInvoice(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `aanbetalingsbewijs-FCU${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  


  const genExtra = (invoice) => {
    const totalArr = invoice?.payments?.map((paymentObj) =>
      parseInt(paymentObj.amount)
    );
    const totalPaid =
      totalArr.reduce((partialSum, a) => partialSum + a, 0) || 0;
    const totalToPay = invoice.items
      .map((invoice) => (invoice.amount_in_cents / 100) * invoice.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
    return (
      <Space>
        {invoice?.payments?.length > 0 &&
          totalToPay !== totalPaid &&
          (totalToPay > totalPaid) * 100 > 0 && (
            <Tag
              onClick={() =>
                getInvoiceDown(
                invoice.id
                )
              }
            >
              Aanbetalingsbewijs
            </Tag>
          )}
           {invoice?.payments?.length > 0 &&
          totalToPay !== totalPaid &&
          (totalToPay > totalPaid) * 100 > 0 && (
            <Tag
              onClick={() =>
                getOrderInvoice(
                invoice.id
                )
              }
            >
              Factuur
            </Tag>
          )}

               <Tag
              onClick={() =>
                getOrderInvoice(
                invoice.id
                )
              }
            >
              Offerte
            </Tag>
      </Space>
    );
  };

    const renderPaymentsTable = (items) => {
      let data = items.map((item, key) => {
        const extraObj = {
          key: key + 1,
          amount_money: toMoney(item.amount),
          // type_text: getPaymentTypes(item.type).label,
        };
        Object.assign(item, extraObj);
        return item;
      });
      return (
        <Table dataSource={data} pagination={false}>
          <Column title="#" dataIndex={"key"} key={"ItemKey"} />
          <Column title="Soort" dataIndex="type_text" key={"ItemLabel"} />
          <Column title="Bedrag" dataIndex="amount_money" key={"ItemLabel1"} />
        </Table>
      );
    };

    const renderTable =(items)=>{
      let data = items.map((item,key)=>{
        const extraObj = {
          key: key+1,
          item_amount: item.amount,
          money_amount: toMoney(item.amount_in_cents/100),
        }
        Object.assign(item,extraObj);
        return item;
      })
      return(
        <Table dataSource={data} pagination={false}>
          <Column title="#" dataIndex={"key"} key={"ItemKey"} />
          <Column title="Naam" dataIndex="label" key={"ItemLabel"} />
          <Column title="Stuksprijs" dataIndex="money_amount" key={"ItemLaabel"} />
          <Column title="Aantal" dataIndex="item_amount" key={"ItemAmount"} />
        </Table>

      );
    }

    const renderinvoice = (invoice) => {
      const totalToPay = invoice.items
        .map((invoice) => invoice.amount_in_cents / 100*invoice.amount)
        .reduce((partialSum, a) => partialSum + a, 0);
      const extraObj = {
        total_amount_inc_btw: totalToPay,
        total_amount_exc_btw: (totalToPay / 121) * 100,
        total_amount_btw: (totalToPay / 121) * 21,
      };
      Object.assign(invoice, extraObj);
      const labels = {
        id: "#",
        items: "Producten",
        total_amount_btw: "BTW 21%",
        total_amount_exc_btw: "Totaalbedrag excl. BTW",
        total_amount_inc_btw: "Totaalbedrag inc. 21% BTW",
        expire_date: "Geldig tot:",
      };
      if(invoice.payments){
        if(invoice.payments.length > 0){
          labels.payments="Betalingen";
        }
      }
      return {
        labels: labels,
        renderLabelFunctions: {
          id: (value) => "FCU2"+value,
          items: (items) => renderTable(items),
          payments: (items) => (renderPaymentsTable(items)),
          total_amount_inc_btw: (value) => toMoney(value),
          total_amount_exc_btw: (value) => toMoney(value),
          total_amount_btw: (value) => toMoney(value),
          expire_date: (value) => moment(value).format("DD-MM-YYYY"),
        },
        item: invoice,
      };
    };
  return (
    <div>
      <UIText variant={"paragraph-title"}>Invoice management</UIText>
      <Collapse expandIconPosition={"start"}>
        <Collapse.Panel
          disabled={true}
          header={
            <span>
              <Tag>ID</Tag>
              <Tag>Type</Tag>Naam
            </span>
          }
          key={10000}
          extra={<span>Acties</span>}
        ></Collapse.Panel>
        {props.items?.length > 0 ? (
          props.items?.map((item, key) => {
            const header = (
              <span>
                {(item.type === 0 ? "Offerte nr #FCU-2" : "Factuur nr #FCU-2") +item.id}
              </span>
            );

            return (
              <Collapse.Panel
                header={header}
                key={key}
                extra={genExtra(item)}
              >
                <UIDescriptions {...renderinvoice(item)} />
              </Collapse.Panel>
            );
          })
        ) : (
          <Collapse.Panel
            disabled={true}
            showArrow={false}
            header={"Er zijn geen artikelen"}
            key={10001}
          ></Collapse.Panel>
        )}
      </Collapse>
    </div>
  );
};

export default AdmininvoicesList;

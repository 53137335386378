import React from "react";
const AuthContext = React.createContext(null);

const AuthProvider = function (props) {
    const [user, setUser] = React.useState(null);
    const userContextValue = React.useMemo(() => ({user, setUser}), [user]);
    return <AuthContext.Provider value={userContextValue}>
        {props.children}
    </AuthContext.Provider>
};

export {AuthProvider, AuthContext}
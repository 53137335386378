import './admin.scss'
import React from "react";
import {UIText} from "../../components/ui/text/text.js";
import {Tabs} from "antd";
import {PageAdminUsers} from "./users/users.js";
import {PageAdminDashboard} from "./dashboard.js";
import {PageAdminArticles} from "./articles/articles.js";
import {PageAdminOrders} from "./orders/index.js";
import {PageAdminCrowdfunding} from "./crowdfunding";
import {PageAdminProducts} from "./products";
import {PageAdminPayments} from "./payments";
import PageAdminVouchers from "./vouchers/vouchers";
import PageAdminInvoices from "./invoice/invoices";
import { PageAdminOrdersSecond } from './orders_second';

const PageAdmin = function (props) {
    const tabs = [
        {
            label: "Dashboard",
            component: <PageAdminDashboard/>
        },
        {
            label: "Gebruikers",
            component: <PageAdminUsers/>
        },
        {
            label: "Producten",
            component: <PageAdminProducts/>
        },
        {
            label: "Kennis/Flyers",
            component: <PageAdminArticles/>
        },
        {
            label: "Facturen/aanbetalingsbewijzen",
            component: <PageAdminOrders/>
        },
        {
            label: "Betalingen",
            component: <PageAdminPayments/>
        },
        // {
        //     label: "Crowdfunding",
        //     component: <PageAdminCrowdfunding/>
        // },
        {
            label: "Maak kortingscode aan",
            component: <PageAdminVouchers/>
        },
        {
            label: "Maak offerte aan",
            component: <PageAdminInvoices/>
        },
        {
            label: "Reisoverzichten",
            component: <PageAdminOrdersSecond/>
        },
    ];

    return <div className={'admin-container'}>
        <UIText variant={'title'}>
            Admin dashboard
        </UIText>
        <Tabs className="adminTabs" defaultActiveKey={'0'}>
            {tabs.map((tab, key)=>
                <Tabs.TabPane key={key} tab={tab.label}>
                    {tab.component}
                </Tabs.TabPane>
            )}
        </Tabs>
    </div>
};

export {PageAdmin}
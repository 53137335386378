import { config } from "../config.js";
import { ModelXhr } from "../helpers/model/xhr.js";
import { notification } from "antd";

class ModelAuth extends ModelXhr {
  static modelName = "ModelAuth";

  static getSelect(input) {
    if (input.user) {
      return { user: input.user };
    }
    return {};
  }

  static login(email, password, onSuccess = () => {}, onFailure = () => {}) {
    this.saveData(
      ModelAuth,
      config.api_endpoint + "login",
      "POST",
      {
        email: email,
        password: password,
      },
      (response) => {
        if (!response.status === ModelAuth.Status.WARNING) {
          onFailure(response);
          return;
        }
        if (
          response.status === ModelAuth.Status.SUCCESS ||
          response.token !== undefined
        ) {
          onSuccess(response);
          return;
        }
        onFailure({ message: "Een onbekende fout is opgetreden" });
      },
      {
        contentType: "json",
      }
    );
  }

  static resetPW(data, onSuccess = () => {}, onFailure = () => {}) {
    this.saveData(
      ModelAuth,
      config.api_endpoint + "reset_pw_request",
      "POST",
      data,
      (response) => {
        if (!response.status === ModelAuth.Status.WARNING) {
          onFailure(response);
          return;
        }
        if (
          response.status === ModelAuth.Status.SUCCESS ||
          response.token !== undefined
        ) {
          onSuccess(response);
          return;
        }
        onFailure({ message: "Een onbekende fout is opgetreden" });
      },
      {
        contentType: "json",
      }
    );
  }

  static resetPass(data, onSuccess = () => {}, onFailure = () => {}) {
    this.saveData(
      ModelAuth,
      config.api_endpoint + "reset_pw",
      "POST",
      data,
      (response) => {
        if (!response.status === ModelAuth.Status.WARNING) {
          onFailure(response);
          return;
        }
        if (
          response.status === ModelAuth.Status.SUCCESS ||
          response.token !== undefined
        ) {
          onSuccess(response);
          return;
        }
        onFailure({ message: "Een onbekende fout is opgetreden" });
      },
      {
        contentType: "json",
      }
    );
  }

  static loginByEmail(email, onSuccess = () => {}, onFailure = () => {}) {
    this.saveData(
      ModelAuth,
      config.api_endpoint + "login_by_mail",
      "POST",
      {
        email: email,
      },
      (response) => {
        if (!response.status === ModelAuth.Status.WARNING) {
          onFailure(response);
          return;
        }
        if (
          response.status === ModelAuth.Status.SUCCESS ||
          response.token !== undefined
        ) {
          onSuccess(response);
          return;
        }
        onFailure({ message: "Een onbekende fout is opgetreden" });
      },
      {
        contentType: "json",
      }
    );
  }

  static refresh(onSuccess = () => {}, onFailure = () => {}) {
    this.saveData(
      ModelAuth,
      config.api_endpoint + "refresh_token",
      "POST",
      {},
      (response) => {
        if (!response) {
          onFailure(response.error);
          return;
        }
        if (response.token) {
          onSuccess(response);
          return;
        }
        onFailure("An unknown error occured.");
      },
      {
        contentType: "params",
      }
    );
  }

  static updateItem(data, onSuccess = () => {}) {
    this.saveData(
      ModelAuth,
      config.api_endpoint + "user",
      "PUT",
      data,
      (response) => {
        if (response) {
          onSuccess(response);
        } else {
          // todo: post status to global message channel
          notification.error({
            message: response.error || "Onbekende fout",
          });
        }
      },
      {
        contentType: "json",
      }
    );
  }

  static register(data, onSuccess = () => {}, onFailure = () => {}) {
    this.saveData(
      ModelAuth,
      config.api_endpoint + "register",
      "POST",
      {
        login_user: true,
        ...data,
      },
      (response, error) => {
        if (response.token) {
          onSuccess(response);
        } else {
          onFailure(response.error);
        }
      },
      {
        contentType: "json",
      }
    );
  }

  constructor(selectData) {
    super(selectData, {
      endpoint: config.api_endpoint + "user",
      method: "GET",
      isAuthorized: true,
    });

    this.cacheEnabled = false;
  }

  getDataFromResponse(data) {
    try {
      const response = JSON.parse(data);
      if (!response) {
        let error = "The server returned an unknown error";
        this.onFailure(error);
        return null;
      }
      return response.user;
    } catch (e) {
      if (this.request && this.request.aborted) {
        console.warn(
          "Cannot process request, probably because it was aborted. Exception: " +
            e.message
        );
        this.status = "waiting";
      } else {
        this.onFailure(e);
      }
    }
    return null;
  }

  getSelect(input) {
    return ModelAuth.getSelect(input);
  }
}

export { ModelAuth };

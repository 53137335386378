export const toMoney = (price, withEuroSign = true) => {
    if (price % 1 === 0) {
        return (withEuroSign ? "\u20ac " : "") + parseInt(price) + ",-";
    }
    else {
        return (withEuroSign ? "\u20ac " : "") + price.toFixed(2).replace(".", ",");
    }
};

export const isDate = (d) => {
    return d instanceof Date && !isNaN(d)
}
import "./teared.scss";
import React from "react";

const TearedTriangle = function (props) {
    return <div className={`ui-teared-triangle triangle-${props.color} ${props.className}`} style={props.style}>
        {props.children}
    </div>
};

TearedTriangle.defaultProps = {
    className: '',
    borderOnly: false,
    color: 'white',
    top: true,
    bottom: true
};

export {TearedTriangle}
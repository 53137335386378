import React, {useState} from "react";
import {AdminPaymentsList} from "./list.js";
import {AdminPaymentNew} from "./new.js";
import {UIText} from "../../../components/ui/text/text.js";
import {Input, Select} from "antd";

const PageAdminPayments = function (props) {
    const [modal, setModal] = useState(null);
    const [initialValues, setInitialValues] = useState(null);

    const [searchType, setSearchType] = useState('order_id');
    const [searchValue, setSearch] = useState('');

    return <div>
        <div style={{margin: '1rem 0'}}>
            <UIText>Zoek een betaling</UIText>
            <Input.Group compact>
                <Select defaultValue={searchType} style={{ width: '20%' }} onChange={(value)=>setSearchType(value)}>
                    <Select.Option value="order_id">Bestelling nummer</Select.Option>
                </Select>
                <Input style={{ width: '80%' }} value={searchValue} onChange={(e)=>setSearch(e.target.value)} />
            </Input.Group>
        </div>
        <AdminPaymentsList setModal={setModal} setInitialValues={setInitialValues} search={searchValue.length > 0 ? {type: searchType, value: searchValue} : null}/>
        {(modal !== null) &&
        <AdminPaymentNew
            order_id={modal}
            setInitialValues={setInitialValues}
            setVisible={()=>setModal('')}
            initialValues={initialValues}/>
        }
    </div>
};

export {PageAdminPayments}
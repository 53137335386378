import './banner.scss';
import React from "react";
import {useTranslation} from "../../helpers/languange.js";
import {UIText} from "../ui/text/text.js";

const ArticlesBanner = function (props) {
    const text = useTranslation();
    return <div className={'articles-banner-container'}>
        <div className={'articles-banner'}>
            <UIText variant={'banner-title'}>
                {text.articles.banner.label}
            </UIText>
            <UIText variant={'paragraph'} className={'articles-banner-description'}>
                {text.articles.banner.description}
            </UIText>
        </div>
    </div>
};

export {ArticlesBanner}
import React from "react";
import constants from "./constants.json";

import maqam1 from "../assets/images/hotels/maqam/maqam1.jpeg";
import maqam2 from "../assets/images/hotels/maqam/maqam2.jpeg";
import maqam3 from "../assets/images/hotels/maqam/maqam3.jpeg";
import maqam4 from "../assets/images/hotels/maqam/maqam4.jpeg";
import anjum1 from "../assets/images/hotels/anjum1.jpeg";
import anjum2 from "../assets/images/hotels/anjum2.jpeg";
import anjum3 from "../assets/images/hotels/anjum3.jpeg";
import anjum4 from "../assets/images/hotels/anjum4.jpeg";
import anjumLogo from "../assets/images/hotels/anjumLogo.webp";
import thumb_anjum from "../assets/images/hotels/thumb_anjum.webp";
import zamzam1 from "../assets/images/hotels/zamzam/zamzam1.jpeg";
import zamzam2 from "../assets/images/hotels/zamzam/zamzam2.jpeg";
import zamzam3 from "../assets/images/hotels/zamzam/zamzam3.jpeg";
import zamzam4 from "../assets/images/hotels/zamzam/zamzam4.jpeg";
import marriot1 from "../assets/images/hotels/marriot/marriot1.jpeg";
import marriot2 from "../assets/images/hotels/marriot/marriot2.webp";
import marriot3 from "../assets/images/hotels/marriot/marriot3.webp";
import thumb_maqam from "../assets/images/hotels/thumb_maqam.png";
import thumb_zamzam from "../assets/images/hotels/thumb_zamzam.png";
import thumb_marriot from "../assets/images/hotels/marriot_thumb.jpeg";
import logoPullman from "../assets/images/hotels/maqam/logo-pullman.png";
import logoSwissotel from "../assets/images/hotels/maqam/logo-swissotel.png";
import logoMarriot from "../assets/images/hotels/marriot/logo.webp";
import logoConvection from "../assets/images/hotels/hilton-convection/logo.jpeg";
import convention1 from "../assets/images/hotels/hilton-convection/convention-1.webp";
import convention2 from "../assets/images/hotels/hilton-convection/convention-2.webp";
import convention3 from "../assets/images/hotels/hilton-convection/convention-3.webp";
import convention4 from "../assets/images/hotels/hilton-convection/convention-4.webp";
import logoAlTaqwa from "../assets/images/hotels/elaf-logo.png";
import alTaqwa1 from "../assets/images/hotels/elaf1.jpeg";
import alTaqwa2 from "../assets/images/hotels/elaf2.jpeg";
import alTaqwa3 from "../assets/images/hotels/elaf3.webp";
import thumb_taqwa from "../assets/images/hotels/elafthumb.jpeg";
import thumb_rua from "../assets/images/hotels/rua_al_hijrah_thumb.jpg";
import thumb_alsaha from "../assets/images/alsaha_thumb.jpg";
import thumb_jaydan from "../assets/images/jaydan_thumn.jpg";
import logo_jaydan from "../assets/images/jaydan_logo.svg";
import thumb_alebaa from "../assets/images/alebaa_thumb.jpg";
import thumb_doubletreemakkah from "../assets/images/thumb_doubletreemakkah.jpg";
import logo_doubletreemakkah from "../assets/images/logo_doubletreemakkah.png";
import logo_jumaira from "../assets/images/logo-jumaira.png";
import thumb_jumaira from "../assets/images/thumbnail-jumaria.jpeg";

import thumb_taibamedina from "../assets/images/thumbnail-taibamedina.jpeg";

import thumb_bluediamond from "../assets/images/blue-diamond-thumbnail.webp";
import logo_bluediamond from "../assets/images/blue-diamond-logo.png";

import thumb_address from "../assets/images/address-thumbnail.jpeg";
import logo_address from "../assets/images/address-logo.svg";

import rua1 from "../assets/images/hotels/rua_al_hijrah_1.jpg";
import rua2 from "../assets/images/hotels/rua_al_hijrah_2.jpg";

import { FaFemale, FaMale, FaChild } from "react-icons/fa";
import {
  MdFamilyRestroom,
  MdChildFriendly,
  MdEmojiPeople,
} from "react-icons/md";

export const ageTypes = [
  {
    label: "Baby (0-24 maanden)",
    label_short: "Baby",

    id: constants.AGE_CATEGORY.BABY,
    icon: <MdChildFriendly className="ageIcon md" />,
  },
  {
    label: "Kind (2-12 jaar)",
    label_short: "Kind",
    id: constants.AGE_CATEGORY.CHILD,
    icon: <FaChild className="ageIcon md" />,
  },
  {
    label: "Volwassen",
    label_short: "Volwassen",
    id: constants.AGE_CATEGORY.ADULT,
    icon: <MdEmojiPeople className="ageIcon" />,
  },
];

export const getAgeTypeById = (id) =>
  ageTypes.find((ageType) => ageType.id === id);

export const articleTypes = [
  {
    label: "Kennis",
    id: constants.ARTICLE_TYPE.ARTICLE,
  },
  {
    label: "Flyer",
    id: constants.ARTICLE_TYPE.FLYER,
  },
];

export const paymentTypes = [
  {
    id: constants.PAYMENT_TYPE.CASH,
    label: "Contant",
  },
  {
    id: constants.PAYMENT_TYPE.BANK_TRANSFER,
    label: "Bank overmaking",
  },
  {
    id: constants.PAYMENT_TYPE.MOLLIE_PAID,
    label: "Online",
  },
  {
    id: constants.PAYMENT_TYPE.PIN,
    label: "Pinbetaling",
  },
];

export const getPaymentTypes = (typeID) =>
  paymentTypes.find((type) => type.id === typeID);

export const genderRoomTypes = [
  {
    label: "Mix",
    label2: "Familie",
    icon: <MdFamilyRestroom className="roomGenderIcon" />,
    id: constants.GENDER_TYPE.MIX,
    desc: "Indien je met familie bent selecteer deze",
  },
  {
    label: "Broeders",
    label2: "Broeder(s)",
    icon: <FaMale className="roomGenderIcon" />,
    id: constants.GENDER_TYPE.MALE,
    desc: "Indien je alleen bent als broeder selecteer deze ",
  },
  {
    label: "Zusters",
    label2: "Zuster(s)",
    icon: <FaFemale className="roomGenderIcon" />,
    id: constants.GENDER_TYPE.FEMALE,
    desc: "Indien je alleen bent als zuster selecteer deze",
  },
];

export const genderTypes = [
  {
    label: "Man",
    id: constants.GENDER_TYPE.MALE,
    short_en: "m",
    short_nl: "m",
  },
  {
    label: "Vrouw",
    id: constants.GENDER_TYPE.FEMALE,
    short_en: "f",
    short_nl: "v",
  },
];

export const getGenderById = (id) =>
  genderTypes.find((ageType) => ageType.id === id);

export const roomFormTypes = [
  {
    label: "2 persoons",
    id: constants.PRODUCT_TYPE.UMRAH_2PERS_ADULT,
    max_guests: 2,
  },
  {
    label: "3 persoons",
    id: constants.PRODUCT_TYPE.UMRAH_3PERS_ADULT,
    max_guests: 3,
  },
  {
    label: "4 persoons",
    id: constants.PRODUCT_TYPE.UMRAH_4PERS_ADULT,
    max_guests: 4,
  },
];

export const roomTypes = [
  {
    label: "2 persoons",
    id: constants.PRODUCT_TYPE.UMRAH_2PERS_ADULT,
    max_guests: 2,
  },
  {
    label: "2 persoons",
    id: constants.PRODUCT_TYPE.UMRAH_2PERS_CHILD,
    max_guests: 2,
  },
  {
    label: "2 persoons",
    id: constants.PRODUCT_TYPE.UMRAH_2PERS_BABY,
    max_guests: 2,
  },
  {
    label: "3 persoons",
    id: constants.PRODUCT_TYPE.UMRAH_3PERS_ADULT,
    max_guests: 3,
  },
  {
    label: "3 persoons",
    id: constants.PRODUCT_TYPE.UMRAH_3PERS_BABY,
    max_guests: 3,
  },
  {
    label: "3 persoons",
    id: constants.PRODUCT_TYPE.UMRAH_3PERS_CHILD,
    max_guests: 3,
  },
  {
    label: "4 persoons",
    id: constants.PRODUCT_TYPE.UMRAH_4PERS_ADULT,
    max_guests: 4,
  },
  {
    label: "4 persoons",
    id: constants.PRODUCT_TYPE.UMRAH_4PERS_CHILD,
    max_guests: 4,
  },
  {
    label: "4 persoons",
    id: constants.PRODUCT_TYPE.UMRAH_4PERS_BABY,
    max_guests: 4,
  },
];

export const getRoomByProductType = (typeID) =>
  roomTypes.find((type) => type.id === typeID);

export const productTypes = {
  [constants.PRODUCT_TYPE.UMRAH_2PERS_ADULT]: [
    {
      age_category: constants.AGE_CATEGORY.BABY,
      product_id: constants.PRODUCT_TYPE.UMRAH_2PERS_BABY,
      type_amount_label: "2 p. kamer",
      type_label: "2 p. Baby",
    },
    {
      age_category: constants.AGE_CATEGORY.CHILD,
      product_id: constants.PRODUCT_TYPE.UMRAH_2PERS_CHILD,
      type_amount_label: "2 p. kamer",
      type_label: "2 p. Kind",
    },
    {
      age_category: constants.AGE_CATEGORY.ADULT,
      product_id: constants.PRODUCT_TYPE.UMRAH_2PERS_ADULT,
      type_amount_label: "2 p. kamer",
      type_label: "2 p. Volwassenen",
    },
  ],
  [constants.PRODUCT_TYPE.UMRAH_3PERS_ADULT]: [
    {
      age_category: constants.AGE_CATEGORY.BABY,
      product_id: constants.PRODUCT_TYPE.UMRAH_3PERS_BABY,
      type_label: "3 p. Baby",
      type_amount_label: "3 p. kamer",
    },
    {
      age_category: constants.AGE_CATEGORY.CHILD,
      product_id: constants.PRODUCT_TYPE.UMRAH_3PERS_CHILD,
      type_label: "3 p. Kind",
      type_amount_label: "3 p. kamer",
    },
    {
      age_category: constants.AGE_CATEGORY.ADULT,
      product_id: constants.PRODUCT_TYPE.UMRAH_3PERS_ADULT,
      type_label: "3 p. Volwassenen",
      type_amount_label: "3 p. kamer",
    },
  ],
  [constants.PRODUCT_TYPE.UMRAH_4PERS_ADULT]: [
    {
      age_category: constants.AGE_CATEGORY.BABY,
      product_id: constants.PRODUCT_TYPE.UMRAH_4PERS_BABY,
      type_label: "4 p. Baby",
      type_amount_label: "4 p. kamer",
    },
    {
      age_category: constants.AGE_CATEGORY.CHILD,
      product_id: constants.PRODUCT_TYPE.UMRAH_4PERS_CHILD,
      type_label: "4 p. Kind",
      type_amount_label: "4 p. kamer",
    },
    {
      age_category: constants.AGE_CATEGORY.ADULT,
      product_id: constants.PRODUCT_TYPE.UMRAH_4PERS_ADULT,
      type_label: "4 p. Volwassenen",
      type_amount_label: "4 p. kamer",
    },
  ],
};

export const arabiaProducts = [
  constants.PRODUCT_TYPE.ARABIA_AMATEUR,
  constants.PRODUCT_TYPE.ARABIA_BEGINNER,
  constants.PRODUCT_TYPE.ARABIA_NATIVE,
  constants.PRODUCT_TYPE.ARABIA_EXPERT,
];

export const orderStatusses = [
  {
    id: constants.ORDER_STATUS.PENDING,
    label: "In behandeling",
    color: "orange",
  },
  {
    id: constants.ORDER_STATUS.LEAD,
    label: "Lead",
    color: "gray",
  },
  {
    id: constants.ORDER_STATUS.COMPLETED,
    label: "Voltooid",
    color: "darkgreen",
  },
  {
    id: constants.ORDER_STATUS.CANCELLED,
    label: "Geannuleerd",
    color: "red",
  },
  {
    id: constants.ORDER_STATUS.DECLINED,
    label: "Geweigerd",
    color: "orange",
  },
  {
    id: constants.ORDER_STATUS.REFUNDED,
    label: "Terugbetaald",
    color: "pink",
  },
  {
    id: constants.ORDER_STATUS.MANUAL_VERIFICATION_REQUIRED,
    label: "Handmatige verificatie vereist",
    color: "pink",
  },
  {
    id: constants.ORDER_STATUS.PARTIALLY_REFUNDED,
    label: "Gedeeltelijk terugbetaald",
    color: "orange",
  },
  {
    id: constants.ORDER_STATUS.DOWN_PAYMENT,
    label: "Aanbetaald",
    color: "green",
  },
];

export const allProducts = [
  {
    age_category: constants.AGE_CATEGORY.ADULT,
    product_id: constants.PRODUCT_TYPE.PRODUCT,
    label: "Regulier product",
  },
  {
    age_category: constants.AGE_CATEGORY.BABY,
    product_id: constants.PRODUCT_TYPE.UMRAH_2PERS_BABY,
    label: "Umrah 2 persoonskamer Baby (0-1)",
  },
  {
    age_category: constants.AGE_CATEGORY.CHILD,
    product_id: constants.PRODUCT_TYPE.UMRAH_2PERS_CHILD,
    label: "Umrah 2 persoonskamer Kind (2-12)",
  },
  {
    age_category: constants.AGE_CATEGORY.ADULT,
    product_id: constants.PRODUCT_TYPE.UMRAH_2PERS_ADULT,
    label: "Umrah 2 persoonskamer Volwassen (13+)",
  },
  {
    age_category: constants.AGE_CATEGORY.BABY,
    product_id: constants.PRODUCT_TYPE.UMRAH_3PERS_BABY,
    label: "Umrah 3 persoonskamer Baby (0-1)",
  },
  {
    age_category: constants.AGE_CATEGORY.CHILD,
    product_id: constants.PRODUCT_TYPE.UMRAH_3PERS_CHILD,
    label: "Umrah 3 persoonskamer Kind (2-12)",
  },
  {
    age_category: constants.AGE_CATEGORY.ADULT,
    product_id: constants.PRODUCT_TYPE.UMRAH_3PERS_ADULT,
    label: "Umrah 3 persoonskamer Volwassen (13+)",
  },
  {
    age_category: constants.AGE_CATEGORY.BABY,
    product_id: constants.PRODUCT_TYPE.UMRAH_4PERS_BABY,
    label: "Umrah 4 persoonskamer Baby (0-1)",
  },
  {
    age_category: constants.AGE_CATEGORY.CHILD,
    product_id: constants.PRODUCT_TYPE.UMRAH_4PERS_CHILD,
    label: "Umrah 4 persoonskamer Kind (2-12)",
  },
  {
    age_category: constants.AGE_CATEGORY.ADULT,
    product_id: constants.PRODUCT_TYPE.UMRAH_4PERS_ADULT,
    label: "Umrah 4 persoonskamer Volwassen (13+)",
  },
  {
    age_category: constants.AGE_CATEGORY.ADULT,
    product_id: constants.PRODUCT_TYPE.ARABIA_BEGINNER,
    label: "Arabia Beginner",
  },
  {
    age_category: constants.AGE_CATEGORY.ADULT,
    product_id: constants.PRODUCT_TYPE.ARABIA_AMATEUR,
    label: "Arabia matig Gevorderd",
  },
  {
    age_category: constants.AGE_CATEGORY.ADULT,
    product_id: constants.PRODUCT_TYPE.ARABIA_EXPERT,
    label: "Arabia Gevorderd",
  },
  {
    age_category: constants.AGE_CATEGORY.ADULT,
    product_id: constants.PRODUCT_TYPE.ARABIA_NATIVE,
    label: "Arabia near native",
  },
];

export const getProductByType = (type) =>
  allProducts.find((product) => product.product_id === type);

export const hotels = [
  {
    label: "Swissôtel Al Maqam Makkah",
    location: "Mekka",
    logo: logoSwissotel,
    thumb: thumb_maqam,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget ipsum vulputate eros sagittis tincidunt non vitae velit. Donec ac diam at ante semper tincidunt.",
    harm_distance: 50,
    rating: 5,

    images: [
      { img: maqam1, alt: "Hotel room 1", loc: "Swissôtel Al Maqam Makkah" },
      { img: maqam2, alt: "Hotel room 2", loc: "Swissôtel Al Maqam Makkah" },
      { img: maqam3, alt: "Hotel room 3", loc: "Swissôtel Al Maqam Makkah" },
      { img: maqam4, alt: "Hotel room 4", loc: "Swissôtel Al Maqam Makkah" },
    ],
  },
  {
    label: "Zamzam Pullman Medina",
    location: "Medina",
    logo: logoPullman,
    thumb: thumb_zamzam,
    desc: "Het Pullman Zamzam hotel ligt gelegen op zo’n 150 meter van Al-Masjid al-Haram en beschikt over maar liefst 4 restaurants. ",
    harm_distance: 150,
    rating: 5,
    images: [
      { img: zamzam1, alt: "Hotel room 1", loc: "Zamzam Pullman Medina" },
      { img: zamzam2, alt: "Restaurant", loc: "Zamzam Pullman Medina" },
      { img: zamzam3, alt: "Hotel lobby 2", loc: "Zamzam Pullman Medina" },
      { img: zamzam4, alt: "Hotel lobby 2", loc: "Zamzam Pullman Medina" },
    ],
  },
  {
    label: "Jabal Omar Marriott Hotel Makkah",
    location: "Mekka",
    logo: logoMarriot,
    thumb: thumb_marriot,
    desc: "Dit luxe, vijfsterren Marriott Hotel bevindt zich op 3 minuten lopen van de Haram.",
    harm_distance: 200,
    rating: 5,
    images: [
      {
        img: marriot1,
        alt: "Hotel room 1",
        loc: "Jabal Omar Marriott Hotel Makkah",
      },
      {
        img: marriot2,
        alt: "Hotel room 2",
        loc: "Jabal Omar Marriott Hotel Makkah",
      },
      {
        img: marriot3,
        alt: "Hotel room 3",
        loc: "Jabal Omar Marriott Hotel Makkah",
      },
    ],
  },
  {
    label: "Anjum Hotel Makkah",
    location: "Mekka",
    logo: anjumLogo,
    thumb: thumb_anjum,
    desc: "Dit luxe, vijfsterren Anjum Hotel bevindt zich op 3 minuten lopen van de Haram.",
    harm_distance: 200,
    rating: 5,
    images: [
      { img: anjum1, alt: "Hotel room 1", loc: "Anjum Hotel Makkah" },
      { img: anjum2, alt: "Hotel room 2", loc: "Anjum Hotel Makkah" },
      { img: anjum3, alt: "Hotel room 3", loc: "Anjum Hotel Makkah" },
      { img: anjum4, alt: "Hotel room 3", loc: "Anjum Hotel Makkah" },
    ],
  },
  {
    label: "Hilton Makkah Convention Hotel",
    location: "Mekka",
    logo: logoConvection,
    thumb: convention1,
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget ipsum vulputate eros sagittis tincidunt non vitae velit. Donec ac diam at ante semper tincidunt.",
    harm_distance: 150,
    rating: 5,

    images: [
      {
        img: convention1,
        alt: "Hotel room 1",
        loc: "Hilton Makkah Convention Hotel",
      },
      {
        img: convention2,
        alt: "Hotel room 2",
        loc: "Hilton Makkah Convention Hotel",
      },
      {
        img: convention3,
        alt: "Hotel room 3",
        loc: "Hilton Makkah Convention Hotel",
      },
      {
        img: convention4,
        alt: "Hotel room 4",
        loc: "Hilton Makkah Convention Hotel",
      },
    ],
  },
  {
    label: "Elaf Al Taqwa Medina",
    location: "Medina",
    logo: logoAlTaqwa,
    thumb: thumb_taqwa,
    desc: "5 sterren hotel Medina",
    harm_distance: 150,
    rating: 4,
    images: [
      { img: alTaqwa1, alt: "Hotel room 1", loc: "Elaf Al Taqwa Medina" },
      { img: alTaqwa2, alt: "Restaurant", loc: "Elaf Al Taqwa Medina" },
      { img: alTaqwa3, alt: "Hotel lobby 2", loc: "Elaf Al Taqwa Medina" },
    ],
  },
  {
    label: "Rua Al Hijrah Medina",
    location: "Medina",
    logo: <h2>Logo Rua</h2>,
    thumb: thumb_rua,
    desc: "5 sterren hotel Medina",
    harm_distance: 150,
    rating: 4,
    images: [
      { img: rua1, alt: "Hotel room 1", loc: "Rua Al Hijrah Medina" },
      { img: rua2, alt: "Restaurant", loc: "Rua Al Hijrah Medina" },
    ],
  },
  {
    label: "Al Saha Hotel Al Rawda Medina",
    location: "Medina",
    logo: <h2>Logo Al Saha Hotel Al Rawda</h2>,
    thumb: thumb_alsaha,
    desc: "4 sterren hotel Medina",
    harm_distance: 570,
    rating: 4,
    images: [
    ],
  },
  {
    label: "Al Ebaa Hotel Makkah",
    location: "Mekka",
    logo: <h2>Logo Al Ebaa Hotel</h2>,
    thumb: thumb_alebaa,
    desc: "4 sterren hotel Mekka",
    harm_distance: 1100,
    rating: 4,
    images: [

    ],
  },
  {
    label: "Jaydan Hotel Medina",
    location: "Medina",
    logo: logo_jaydan,
    thumb: thumb_jaydan,
    desc: "5 sterren hotel Medina",
    harm_distance: 500,
    rating: 5,
    images: [

    ],
  },
  {
    label: "Doubletree by Hilton Makkah",
    location: "Mekka",
    logo: logo_doubletreemakkah,
    thumb: thumb_doubletreemakkah,
    desc: "5 sterren hotel Mekka",
    harm_distance: 800,
    rating: 5,
    images: [

    ],
  },
  {
    label: "Address Jabal Omar",
    location: "Mekka",
    logo: logo_address,
    thumb: thumb_address,
    desc: "Dit luxe, vijfsterren Address bevindt zich op 3 minuten lopen van de Haram.",
    harm_distance: 200,
    rating: 5,
    images: [

    ],
  },
  {
    label: "Blue Diamond Hotel Jeddah",
    location: "Jeddah",
    logo: logo_bluediamond,
    thumb: thumb_bluediamond,
    desc: "Dit luxe, viersterren hotel bevindt zich in Jeddah.",
    rating: 4,
    images: [

    ],
  },
  {
    label: "Jumeirah Jabal Omar",
    location: "Mekka",
    logo: logo_jumaira,
    thumb: thumb_jumaira,
    desc: "Dit luxe, vijfsterren hotel Jumeirah bevindt zich op 3 minuten lopen van de Haram.",
    harm_distance: 200,
    rating: 5,
    images: [

    ],
  },
  {
    label: "Taiba Madinah Hotel",
    location: "Medina",
    logo: logo_jaydan,
    thumb: thumb_taibamedina,
    desc: "5 sterren hotel Medina",
    harm_distance: 500,
    rating: 5,
    images: [

    ],
  }
];

export const packageExtras = [
  "Kabaah view",
  "Junior suite",
  "Senior suite",
  "Junior suite inc. Kaabah view",
  "Senior suite inc. Kaabah view",
];

export function areEqual(array1, array2) {
  if (array1.length === array2.length) {
    return array1.every((element, index) => {
      if (element === array2[index]) {
        return true;
      }

      return false;
    });
  }

  return false;
}
// const d = new Date();
// export const seasonArray = [
//     {name: 'Lente', date: new Date(d.getFullYear(),2,(d.getFullYear() % 4 === 0) ? 19 : 20).getTime()},
//     {name: 'Zomer', date: new Date(d.getFullYear(),5,(d.getFullYear() % 4 === 0) ? 20 : 21).getTime()},
//     {name: 'Herfst', date: new Date(d.getFullYear(),8,(d.getFullYear() % 4 === 0) ? 22 : 23).getTime()},
//     {name: 'Winter', date: new Date(d.getFullYear(),11,(d.getFullYear() % 4 === 0) ? 20 : 21).getTime()}
// ];
//
// export const getSeasonTitle = (date) => {
//     const newDate = new Date(date);
//     const seasonArray = [
//         {name: 'Lente', date: new Date(newDate.getFullYear(),2,(newDate.getFullYear() % 4 === 0) ? 19 : 20).getTime()},
//         {name: 'Zomer', date: new Date(newDate.getFullYear(),5,(newDate.getFullYear() % 4 === 0) ? 20 : 21).getTime()},
//         {name: 'Herfst', date: new Date(newDate.getFullYear(),8,(newDate.getFullYear() % 4 === 0) ? 22 : 23).getTime()},
//         {name: 'Winter', date: new Date(newDate.getFullYear(),11,(newDate.getFullYear() % 4 === 0) ? 20 : 21).getTime()}
//     ];
//     const season = seasonArray.filter((season) => season.date <= newDate).slice(-1)[0];
//
//
//     return `Umrah ${season?.name} ${newDate.getFullYear()}`;
// };

import './cart.scss';

import React, {useState} from "react";
import {UIText} from "../../components/ui/text/text.js";
import {useStateContext} from "../../helpers/state-context/use_state_context.js";
import {PageCartDetails} from "./details.js";
import {UIButton} from "../../components/ui/button/button.js";
import {useHistory} from "react-router-dom";
import {PageArabiaTeachingAdd} from "../arabia-teaching/add.js";
import {useModelFeedback} from "../../helpers/model/use_model_feedback.js";
import {ModelProducts} from "../../model/products.js";
import {Button, Form, InputNumber, Modal, notification} from "antd";
import {PackagesAddForm} from "../packages/add/form.js";
import {toMoney} from "../../helpers/functions.js";
import {ModelCrowdfunding} from "../../model/crowdfunding.js";

const PageCart = function () {
    const [modal, setModal] = useState('');
    const history = useHistory();
    const [state, dispatch] = useStateContext()
    const [cartType, setCartType] = useState('');
    const resultSet = useModelFeedback(ModelProducts);
    const resultSetCrowdfunding = useModelFeedback(ModelCrowdfunding);

    if(resultSet.feedback){
        return resultSet.feedback;
    }
    if(resultSetCrowdfunding.feedback){
        return resultSetCrowdfunding.feedback;
    }

    const selectedProduct = resultSet.data.products.find((product)=>{
        if(modal !== ''){
            if(state?.cart[modal].type === 'umrah'){
                return product.product_id === state.cart[modal].guests[0].product_id
            }
            if(state?.cart[modal].type === 'arabia'){
                return product.id === state.cart[modal].id
            }
            if(state?.cart[modal].type === 'product'){
                return product.id === state.cart[modal].id
            }
            return null
        }
        return null
    })
    const selectedFund = resultSetCrowdfunding.data.find((crowdfund)=>crowdfund.id === state.cart[modal]?.id)
    const selectedFundValueLeft = parseFloat(selectedFund?.goal_amount) - parseFloat(selectedFund?.orders.reduce((total, order)=>{
        return total + order.funding_amount;
    }, 0))

    const onSubmit = (values) => {
        const fund = {
            type: 'fund',
            id: state.cart[modal].id,
            value: values.value
        };

        notification.success({
            message: `${toMoney(values.value)} aan donatie toegoevoegd aan ${selectedFund.label} in je winkelwagen`
        });
        if(cartType === 'admin'){
            if(state.cart[modal]){
                dispatch({type: 'updateAdminCartItem', value: fund, key: modal})
            }else{
                dispatch({type: 'addToAdminCart', value: fund})
            }
        }else{
            if(state.cart[modal]){
                dispatch({type: 'updateCartItem', value: fund, key: modal})
            }else{
                dispatch({type: 'addToCart', value: fund})
            }
        }
        setModal('')
    }

   return <div className={'shopping-cart-container'}>
        <UIText variant={'title'}>
            Winkelwagen
        </UIText>
        <div className={'shopping-cart-layout'}>
            <div className={'shopping-cart-table'}>
                <PageCartDetails cart={state.cart} onEdit={(item, key)=>{setModal(key)}} onDelete={(item, key)=>{dispatch({type: 'removeCartItem', value: key})}}/>
            </div>
        </div>
       {(state.cart.length > 0) && <UIButton onClick={()=>{history.push('/afrekenen')}}>
           Bestellen
       </UIButton>}
       {(modal !== '' && state.cart[modal]?.type === 'arabia') &&
            <PageArabiaTeachingAdd modal={state.cart[modal]?.id} selectedProduct={selectedProduct} initialValues={state.cart[modal]} setModal={setModal} cartKey={modal}/>
       }
       {modal !== '' && state.cart[modal]?.type === 'umrah' &&
       <Modal onCancel={()=>setModal('')}
              maskClosable={false}
              title={selectedProduct.label}
              visible={modal !== ''&& state.cart[modal]?.type === 'umrah'}
              className={'modal'}
              footer={false}>
            <PackagesAddForm onSubmit={()=>setModal('')} initialValues={state.cart[modal]} item={selectedProduct} cartKey={modal}/>
           <div className={'modal-footer'}>
               <div className={'modal'}><div className={'modal-footer'}/></div>
           </div>
       </Modal>
       }
       {(modal !== '' && state.cart[modal]?.type === 'fund') &&
       <Modal onCancel={()=>setModal('')} title={`Doneer voor '${selectedFund?.label}'`} maskClosable={false} footer={false} className={'modal'} visible={state.cart[modal]?.type === 'fund'}>
           <UIText>Er is nog {toMoney(selectedFundValueLeft)} nodig om dit te behalen!</UIText>
           <Form layout={'vertical'} onFinish={onSubmit} initialValues={state.cart[modal] ? state.cart[modal] : {}}>
               <Form.Item name="value"
                          label="Bedrag"
                          rules={[
                              { required: true, message: "Bedrag is verplicht!" },
                              {type: 'number',min: 0, message: "Bedrag moet boven 0 zijn"},
                              ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                      if (!value || getFieldValue('value') <=selectedFundValueLeft) {
                                          return Promise.resolve();
                                      }
                                      return Promise.reject(`Bedrag moet lager zijn dan ${toMoney(selectedFundValueLeft)}`);
                                  },
                              }),]}>
                   <InputNumber style={{width: '100%'}} min={0}/>
               </Form.Item>
               <Button type="primary" htmlType="submit" name={'1'} onClick={()=>{setCartType('user')}} className="contact-form-button">
                   Updaten
               </Button>
           </Form>
           <div className={'modal-footer'}>
               <div className={'modal'}><div className={'modal-footer'} /></div>
           </div>
       </Modal>
       }

    </div>
};



export {PageCart}

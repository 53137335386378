import React from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Rate,
  Radio,
} from "antd";

import { RightOutlined } from "@ant-design/icons";
import { UIButton } from "../../components/ui/button/button.js";
import moment from "moment";

const PageHadjInfo = function (props) {
  const recaptchaRef = React.createRef();
  const redirectToHajj = () => {
    props.setSelectedHadj(moment().year());
    props.setHajjInfo(false);
  };

  return (
    <Modal
      onCancel={props.onClose}
      maskClosable={false}
      title={"Meer informatie Umrah from Morocco " + moment().year()}
      open={props.showHajjInfo}
      className={"modal"}
      footer={false}
    >
      <div>
        <p>
          <strong>Waarom kiezen voor ons?</strong>
        </p>
        <p>
        First Class Umrah presenteert met trots haar nieuwe afdeling in Marokko. We zijn verheugd om te kunnen zeggen dat we als reisorganisatie er alles aan doen – wa lillaahil hamd – om onze Umrah-reizigers een volledig verzorgde reis te bieden. Ons streven is om deze bijzondere ervaring voor elke reiziger zo soepel en aangenaam mogelijk te maken. We geloven dat een comfortabel en luxe hotel, duidelijke communicatie, goede begeleiding en voldoende ontspanning hieraan bijdragen. &nbsp;
        </p>
        <p>
        We hopen dat elke reiziger zich volledig kan richten op de Islamitische verplichtingen en andere aanbiddingen, terwijl wij ervoor zorgen dat het een zorgeloze reis wordt, in sha’Allah.
        </p>
        <p>
        First Class Umrah kijkt er naar uit om jou te mogen begeleiden tijdens onze volgende reis!
        </p>
        <div className="infoBulletPoints">
          <div className="bulletpoint">
            <div className="pointTitle">
              <RightOutlined className={"products-item-list-icon"} />
              <div>VLIEGTICKET</div>
            </div>
            <div className="pointDesc">We vertrekken vanuit Mohammed V International Airport (Casablanca) met een directe vlucht richting Medina. Op de terugweg vertrekken we van Jeddah met een directe vlucht naar Casablanca.</div>
          </div>
          <div className="bulletpoint">
            <div className="pointTitle">
              <RightOutlined className={"products-item-list-icon"} />
              <div>TRANSPORT</div>
            </div>
            <div className="pointDesc">Wij verzorgen de busreizen in Saoedi-Arabië van en naar het vliegveld, van Medina naar Mekka en het vervoer tijdens de excursies.</div>
          </div>
          <div className="bulletpoint">
            <div className="pointTitle">
              <RightOutlined className={"products-item-list-icon"} />
              <div>VISUM</div>
            </div>
            <div className="pointDesc">Wij zorgen voor de aanvraag van jouw Umrah visum, voor onze klanten die reizen met een Marokkaans paspoort die worden voorzien van een one-time entry visum die na uitgifte een jaar geldig is met een maximaal verblijf van 30 dagen. De kosten hiervoor zijn inbegrepen. Voor onze klanten die reizen met een Europees paspoort en vertrekken vanuit Marokko, voor hen geldt dezelfde prijzen als die van Nederland. Die worden voorzien van een visum met Multiple entry met 90 dagen verblijfsduur en geldigheid van een jaar.</div>
          </div>
        </div>
        <div className="infoBulletPoints">
          <div className="bulletpoint">
            <div className="pointTitle">
              <RightOutlined className={"products-item-list-icon"} />
              <div>ONTBIJT</div>
            </div>
            <div className="pointDesc">Tijdens ons verblijf kun je gebruik maken van het onbeperkte ontbijtbuffet in de hotels, dit is inbegrepen bij het Umrah-pakket.</div>
          </div>
    
          <div className="bulletpoint">
            <div className="pointTitle">
              <RightOutlined className={"products-item-list-icon"} />
              <div>BEGELEIDING</div>
            </div>
            <div className="pointDesc">Onze begeleiders hebben jarenlange ervaring met zowel de Hadj als de Umrah en streven ernaar om te allen tijde bereikbaar te zijn voor vragen. Dit geldt vanaf de dag van vertrek tot en met terugkomst in Marokko</div>
          </div>
          <div className="bulletpoint">
            <div className="pointTitle">
              <RightOutlined className={"products-item-list-icon"} />
              <div>EXCURSIES</div>
            </div>
            <div className="pointDesc">Tijdens de Umrah-reis houden we graag tijd vrij voor het bezoeken van historische plaatsen, moskeeën en zoeken we de rust op in een palmbomenveld of bij een kampvuur in de woestijn.</div>
          </div>
        
        </div>

        <p>
          <strong>Prijzen</strong>
        </p>
        <p>
        Voor meer informatie over de prijzen gelieve contact op te nemen met marokko@firstclassumrah.nl of +31648901909
        </p>
      </div>

      <UIButton onClick={redirectToHajj}>Reserveer nu vrijblijvend!</UIButton>
      <div className={"modal-footer"}>
        <div className={"modal"}>
          <div className={"modal-footer"} />
        </div>
      </div>
    </Modal>
  );
};

export { PageHadjInfo };

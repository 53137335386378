import React, {useState} from "react";
import {Modal, Upload, message, notification} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import {ModelArticles} from "../../../model/articles.js";
import moment from 'moment';
import {ModelImgbb} from "../../../model/imgbb.js";

const PageAdminArticlesNewFlyer = function (props) {
    const [fileList, setFileList] = useState([])
    const draggerProps = {
        fileList: fileList,
        // multiple: true,
        customRequest: ()=>{},
        onRemove: file => {
            setFileList(state => {
                const index = state.indexOf(file);
                const newFileList = state.slice();
                newFileList.splice(index, 1);
                return newFileList
            });
        },
        onChange: (info) => {
            let fileList = [...info.fileList];
            if (info.file.type.toLowerCase() !== 'image/jpg' &&
                info.file.type.toLowerCase() !== 'image/jpeg') {
                message.error(`${info.file.name} is geen a jpg of jpeg bestand.`);
            }else{
                fileList.forEach((file) =>{

                    let reader = new FileReader();
                    reader.onload = (e) => {
                        file.base64 = e.target.result;
                        file.status = 'done'
                    };
                    reader.readAsDataURL(file.originFileObj);
                });
                setFileList(fileList)
            }
        },
        beforeUpload: (file) => {
            return true;
        },
        listType: 'picture',
        showUploadList: {
            showPreviewIcon: false
        }
    };
    return <Modal visible={props.visible}
                  maskClosable={false}
                  title={'Voeg nieuwe flyer(s) toe'}
                  okText={'Uploaden'}
                  cancelText={'Annuleren'}
                  onOk={()=>{
                      const images = fileList.map((f)=>f.base64)
                      for (const [key, image] of images.entries()){
                          const img = image.substr(image.indexOf(',') + 1)
                          ModelImgbb.createItem({image: img}, (response)=>{
                              const article = {
                                  html: "<p></p>",
                                  img: response.data.url,
                                  flex: "{}",
                                  type: 1,
                                  label: moment().format('DD-MM-YYYY, h:mm:ss'),
                                  description: "a",
                              };
                              ModelArticles.createItem(article, ()=>{
                                  notification.success({
                                      message: `Flyer ${key+1} is toegevoegd.`
                                  });
                                  props.setVisible();
                              })
                          })

                      }
                  }}
                  onCancel={()=>{props.setVisible(false)}}>
        <ImgCrop grid aspect={113/160} modalTitle={'Bewerk'} modalOk={'Opslaan'} modalCancel={'Annuleren'}>
            <Upload.Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Klik of sleep het bestand naar dit gebied om het te uploaden</p>
                <p className="ant-upload-hint">
                    Let op, alleen jpg en jpeg bestanden zijn toegestaan.
                </p>
            </Upload.Dragger>
        </ImgCrop>
    </Modal>
};

export {PageAdminArticlesNewFlyer}

import './info.scss';
import React from "react";
import {DisconnectOutlined, GlobalOutlined, LikeOutlined, TeamOutlined} from "@ant-design/icons";
import {Teared} from "../../components/ui/teared/teared.js";
import {useTranslation} from "../../helpers/languange.js";
import {UIText} from "../../components/ui/text/text.js";

const infoIcons = [
    <LikeOutlined className={'home-info-icon'}/>,
    <DisconnectOutlined className={'home-info-icon'}/>,
    <TeamOutlined className={'home-info-icon'}/>,
    <GlobalOutlined className={'home-info-icon'}/>
]

const HomeInfo = function (props) {
    const text = useTranslation();
    return <Teared color={'white'} className={'home-info'}>
        {text.homepage.section_1.infos.map((i, key)=>{
            return <div className={'home-info-item'} key={key}>
                {infoIcons[key]}
                <UIText variant={'paragraph-title'}>{i.label}</UIText>
                <UIText>{i.description}</UIText>
            </div>
        })}

    </Teared>
}

export {HomeInfo}
import {useState, useEffect} from 'react';
import { useStateContext } from '../state-context/use_state_context.js';

const useModel = function(ModelClass, selectionCriteriaInput=null, callback=(model) => (model.getData())){
    const [state, ] = useStateContext();
    let selectionCriteria = null;
    if(typeof selectionCriteriaInput === 'function'){
        selectionCriteria = selectionCriteriaInput(state);
    }else{
        selectionCriteria = selectionCriteriaInput===null? state: selectionCriteriaInput;
    }
     
    const [previousCallback, setPreviousCallback] = useState(
        ModelClass.modelName + ': ' + callback.toString());

    const [data, setData] = useState({
        status: ModelClass.Status.INACTIVE, 
        data: [],
        _callbackName: previousCallback,
        _lastModified: 0
    });

    const [lastModified, setLastModified] = useState(0);

    // sets up the model with callback
    useEffect(()=> {
        const cb = (model) => {
            // only update if there's something new (or older, it's possible that the model switched back to
            // an older instance)
            if(lastModified !== model.lastModified){
                setLastModified(model.lastModified);
                const resultSet = callback(model); 
                if(resultSet === null || typeof resultSet !== 'object'){
                    console.error("Data callback from model " + ModelClass.modelName + 
                            " did not return an object. We need an object.");
                }
                const currentCallback = ModelClass.modelName + ': ' + callback.toString();
                resultSet._callbackName = currentCallback;
                resultSet._lastModified = model.lastModified;
                
                // When the model is loading, we make available any previous data to work with in the meantime
                if(resultSet.status && previousCallback === currentCallback && 
                        (resultSet.status === ModelClass.Status.INACTIVE || 
                        resultSet.status === ModelClass.Status.WAITING)){
                    resultSet._previousResultSet = data._previousResultSet? data._previousResultSet: data;
                }

                setData(resultSet);
                if(previousCallback !== currentCallback){
                    setPreviousCallback(currentCallback);
                }
            }
        };

        const {instanceId, listenerId} = ModelClass.addConsumer(ModelClass, selectionCriteria, cb);
        return () => {
            ModelClass.removeConsumer(ModelClass, instanceId, listenerId);
        }
    }, [ModelClass, selectionCriteria, callback, lastModified, data, previousCallback]);

    return data;

}

export {useModel};
import React from "react";
import {ArticlesBanner} from "../components/articles/banner.js";
import {ArticlesArticles} from "../components/articles/articles.js";

const PageArticles = function (props) {
    return <div>
        <ArticlesBanner/>
        <ArticlesArticles/>
    </div>
};

export {PageArticles}
import {config} from '../config.js';
import {ModelXhr} from "../helpers/model/xhr.js";

class ModelCrowdfunding extends ModelXhr {
    static modelName = "ModelCrowdfunding";

    static getSelect(input) {
        return {
        };
    }

    static createItem(data, onSuccess=()=>{}){
        this.saveData(ModelCrowdfunding, config.api_endpoint + 'crowdfund', 'POST',
            data,
            (response) => {
                if(response.success){
                    const user = response.result? (response.result[0] || null): null;
                    onSuccess(user);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            }
        );
    }

    static updateItem(id, data, onSuccess=()=>{}){
        this.saveData(ModelCrowdfunding, config.api_endpoint + 'crowdfund/' + id, 'PUT',
            data,
            (response) => {
                if(response.message === "UPDATED"){
                    onSuccess(response);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            }
        );
    }


    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'crowdfunds',
            method: 'GET'
        });

        this.cacheEnabled = true;
        this.update();
    }

    getSelect(input) {
        return ModelCrowdfunding.getSelect(input);
    }

    getDataFromResponse(data){
        try{
            const response = JSON.parse(data);
            if(! response){
                let error = 'The server returned an unknown error';
                this.onFailure(error);
                return null;
            }
            return response;
        }catch(e){
            if(this.request && this.request.aborted){
                console.warn('Cannot process request, probably because it was aborted. Exception: ' + e.message);
                this.status = 'waiting';
            }else{
                this.onFailure(e);
            }
        }

        return null;
    }


    getData(){
        return {
            status: this.status,
            data: this.data,
        };
    }
}

export {ModelCrowdfunding};

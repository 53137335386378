import "./teared.scss";
import React from "react";

const Teared = function (props) {
    return <div className={`ui-teared ${(props.borderOnly ? 'border' : props.color)}`}>
        <div className={`teared-main ${props.className}`} style={{backgroundColor: (props.color=== 'orange') ? '#EEC862' : '#fff'}}>
            {props.children}
        </div>
    </div>
};

Teared.defaultProps = {
    className: '',
    borderOnly: false,
    color: 'white',
    top: true,
    bottom: true
};

export {Teared}
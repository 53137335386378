import React from "react";
import { UIText } from "../../../components/ui/text/text.js";
import { Collapse, notification, Rate, } from "antd";
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { UIDescriptions } from "../../../components/ui/descriptions.js";
import { ModelProducts } from "../../../model/products.js";
import { allProducts, arabiaProducts, hotels, packageExtras, productTypes} from "../../../assets/constants.js";
import moment from 'moment';
import { toMoney } from "../../../helpers/functions.js";

const regions = ['Noord','Midden en Zuid', "Midden en noord","Midden","Zuid"];

const AdminPackagesList = function (props) {
    const resultSet = useModelFeedback(ModelProducts);

    if (resultSet.feedback) {
        return resultSet.feedback
    }

    const genExtraCategories = (product) => (
        <>
            <EditOutlined
                onClick={() => {
                    props.setInitialValues(product)
                    props.setModal('products')
                }}
            />
            {!arabiaProducts.includes(product.type) &&
                <CloseOutlined
                    onClick={() => {
                        ModelProducts.deleteItem(product.product_id, () => {
                            notification.success({
                                message: "Product succesvol verwijderd"
                            })
                        })
                    }}
                />
            }
        </>
    );

    return <div>
        <UIText variant={'header-title'}>Producten</UIText>
        <Collapse expandIconPosition={'start'}>
            <Collapse.Panel collapsible={'disabled'}  header={<span>Naam</span>} key={100004} extra={<span>Acties</span>}>
            </Collapse.Panel>
            {(resultSet.data.products?.length > 0) ?
                resultSet.data.products
                    ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    ?.filter((product) => {
                        if (props.search === null) {
                            return true
                        }
                        return product[props.search.type].toString().toLowerCase().includes(props.search.value.toString().toLowerCase())
                    })
                    ?.map((product, key) => {
                        product = {
                            ...product,
                            payment_deadline: moment(product.payment_deadline)
                        }
                        const productType = allProducts.find((p) => p.product_id === product.type);
                        const flex = JSON.parse(product.flex)
                        const productItem = {
                            ...product,
                            price: parseFloat(product.price),
                            hotels: flex?.hotels,
                            region: flex?.region,
                        
                        };

                        const labels = {
                            product_id: "id",
                            label: "Titel",
                            description: "Omschrijving",
                            price: "Prijs",
                            payment_deadline:  "Uiterste betaaldatum",
                            stock: "Voorraad",
                            is_active: "Actief",
                            start: "Start datum",
                            end: "Eind datum",
                            type: "Type",
                            hotels: 'Hotels',
                            region: "Regio",
                            created_at: "Aangemaakt op"
                        };

                        const renderLabelFunctions = {
                            label: (value) => value,
                            price: (value) => toMoney(value),
                            payment_deadline: (value) => value.format('DD-MM-YYYY HH:mm'),
                            start: (value) => moment(value).format('DD-MM-YYYY'),
                            end: (value) => moment(value).format('DD-MM-YYYY'),
                            type: () => productType?.label,
                            is_active: (value) => value === 1 ? 'Ja' : 'nee',
                            hotels: (value) => (<ul>{value && Object.keys(value, key).map((hotel) => (<li key={'hotel'+key}>{hotels[hotel].label}</li>))}</ul>),
                            region: (value) => value && regions[value],
                        };
                        const header = <span>{renderLabelFunctions.label(productItem.label)} - {productType?.label}</span>;

                        return <Collapse.Panel header={header}
                            key={key}
                            extra={genExtraCategories(product)}>
                            <UIDescriptions labels={labels} renderLabelFunctions={renderLabelFunctions} item={productItem} />
                        </Collapse.Panel>
                    }) :
                <Collapse.Panel collapsible={'disabled'} showArrow={false} header={'Er zijn geen producten'} key={10005} />
            }

        </Collapse>
    </div>
};

export { AdminPackagesList }
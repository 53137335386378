import './whatsapp.scss';
import React, {useState} from "react";
import { WhatsAppOutlined, CloseOutlined} from "@ant-design/icons";
import {Input, Form, Button} from "antd";

const WidgetWhatsapp = function (props) {
    const [windowOpen, setWindowOpen] = useState(false)

    const onMessageSend = (values) => {
        setWindowOpen(false)
        const link = `https://wa.me/31681025698?text=${values.message}`;
        window.open(link, "_blank", "");

    };

    return <>
        {windowOpen ?
            <div className={'widget-whatsapp-chat'}>
                <div className={'header'}>
                <div className='headerContent'>
                <strong>Neem contact op via whatsapp.</strong>
                <p><em>Reageert meestal binnen een dag</em></p>
                </div>
                <div className='headerContent close' onClick={()=>(setWindowOpen(false))}>
              <CloseOutlined/>
                </div>

                </div>
                <hr/>
                <div className="yours messages">
                    <div className="message">
                        Hallo! <span role="img" aria-label={''}>👋🏼</span>
                    </div>
                    <div className="message">
                        Hoe kan ik helpen?
                    </div>
                </div>
                <div className={'input'}>
                    <Form
                        name="customized_form_controls"
                        layout="inline"
                        onFinish={onMessageSend}

                    >
                        <Form.Item name="message">
                            <Input placeholder={'Typ een bericht'}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                >
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                {/*<div className="message-box">*/}
                {/*    /!*<textarea className="message-input" placeholder="Typ een bericht"/>*!/*/}
                {/*    /!*<button type="submit" className="message-submit">Versturen</button>*!/*/}
                {/*</div>*/}
            </div> :
            null
        }
        <div className={'widget-whatsapp'} onClick={()=>setWindowOpen((prevState)=>!prevState)}>
            <WhatsAppOutlined/>
        </div>
    </>
};

export {WidgetWhatsapp}

import './button.scss'
import React from "react";
import {PropTypes} from "prop-types";

const UIButton = function (props) {

    const className = 'fcu-ui-button' + (props.className ? ' ' + props.className : '') + (props.fullWidth ? ' full-width' : '');

    return <span className={className} onClick={props.onClick} style={props.style}>
        {props.children}
    </span>
}

UIButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object
}

UIButton.defaultProps = {
    className: 'dice-primary',
    onClick: () => {}
};


export {UIButton}
import React, {useState} from "react";
import {UIText} from "../../components/ui/text/text.js";
import {UIButton} from "../../components/ui/button/button.js";
import {Button, Collapse, DatePicker, Form,  Modal, notification} from "antd";
import constants from '../../assets/constants.json'

import {UIDescriptions} from "../../components/ui/descriptions.js";
import {useModelFeedback} from "../../helpers/model/use_model_feedback.js";
import {ModelOrders} from "../../model/orders.js";

const PageAdminDashboard = function (props) {
    const resultSet = useModelFeedback(ModelOrders)
    const [modal, setModal] = useState('')

    if(resultSet.feedback){
        return resultSet.feedback
    }

    const onMergeRooms = (values) => {
        const body = {
            start: values['range-picker'][0].format("YYYY-MM-DD"),
            end: values['range-picker'][1].format("YYYY-MM-DD"),
        }
        notification.success({
            message: `Kamers zijn samengevoegd tussen ${body.start} en ${body.end}. Een mail is verstuurd aan 'info@firstclassumrah.nl'.`
        })
        setModal('')
    };

    return <div>
        <div>
            <UIButton onClick={()=>{
                setModal('merge-rooms')
            }}>Kamers samenvoegen</UIButton>
        </div>
        <UIText variant={'paragraph-title'}>Leads</UIText>
        <Collapse expandIconPosition={'start'}>
            <Collapse.Panel disabled={true} show header={<span>Naam</span>} key={100004}>
            </Collapse.Panel>
            {(resultSet.data?.length > 0) ?
                resultSet.data
                    ?.filter(order=>order.status === constants.ORDER_STATUS.LEAD)
                    ?.map((order, key) => {
                        const header = <span>Bestelling nummer {order.id} - {order.user.email}</span>;

                        const values = {
                            user_email: order.user.email,
                            user_phone_number: order.user.phone_number,
                            order_id: order.id,
                        }

                        const labels = {
                            user_email: "Lead email",
                            user_phone_number: "Lead nummer",
                            order_id: "Bestelling nummer",
                        };

                        return <Collapse.Panel header={header}
                                               key={key}>
                            <UIDescriptions labels={labels}  item={values}/>
                        </Collapse.Panel>
                }) :
                <Collapse.Panel disabled={true} showArrow={false} header={'Er zijn geen leads'} key={10005}/>
            }

        </Collapse>

        {(modal === 'merge-rooms') &&
        <Modal onCancel={()=>setModal('')} title={'Selecteer periode waarin je kamers wil samenvoegen'} maskClosable={false} footer={false} className={'modal'} visible={(modal === 'merge-rooms')}>
            <Form layout={'vertical'} onFinish={onMergeRooms}>
                <Form.Item name="range-picker" label="Start - eind datum" rules={[{ required: true, message: "Periode is verplicht!" }]}>
                    <DatePicker.RangePicker />
                </Form.Item>
                <Button type="primary" htmlType="submit" className="contact-form-button">
                    Samenvoegen
                </Button>
            </Form>
            <div className={'modal-footer'}>
                <div className={'modal'}><div className={'modal-footer'} /></div>
            </div>
        </Modal>
        }
    </div>
};

PageAdminDashboard.defaultProps = {
    resultSet : {
        feedback: null,
        data: [
            {
                user_email: "mail@live.nl",
                user_phone_number: "0612345678",
                order_id: 4,
                order_status: 10
            }
        ]
    }
}

export {PageAdminDashboard}
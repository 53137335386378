import React from "react";
import {UIText} from "../../../components/ui/text/text.js";
import {Collapse, notification} from "antd";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelArticleCategories} from "../../../model/article-categories.js";
import {CloseOutlined, EditOutlined} from "@ant-design/icons";

const AdminArticlesCategoriesList = function (props) {
    const resultSetCategories = useModelFeedback(ModelArticleCategories)

    if(resultSetCategories.feedback){
        return resultSetCategories.feedback
    }

    const genExtraCategories = (article) => (
        <>
            <EditOutlined
                onClick={event => {
                    props.setInitialValues(article)
                    props.setNewArticle('category')
                }}
            />
            <CloseOutlined
                onClick={event => {
                    ModelArticleCategories.deleteItem(article.article_category_id, ()=>{
                        notification.success({
                            message: 'Categorie is verwijderd.'
                        });
                    })
                }}
            />
        </>
    );

    return <div>
        <UIText variant={'paragraph-title'}>Kennis categorieen</UIText>
        <Collapse expandIconPosition={'start'}>
            <Collapse.Panel disabled={true}  showArrow={false} header={<span>Naam</span>} key={100004} extra={<span>Acties</span>}>
            </Collapse.Panel >
            {(resultSetCategories.data.articleCategories?.length > 0) ?
                resultSetCategories.data.articleCategories.map((article, key)=>{
                    const header = <span>
                        {article.label}
                    </span>;

                    return <Collapse.Panel  header={header} key={key} extra={genExtraCategories(article)} showArrow={false}/>
                }) :
                <Collapse.Panel disabled={true} showArrow={false} header={'Er zijn geen artikel categorieen'} key={10005}/>
            }

        </Collapse>
    </div>
};

export {AdminArticlesCategoriesList}
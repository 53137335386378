import { config } from "../config.js";
import { ModelXhr } from "../helpers/model/xhr.js";

class ModelMergedRooms extends ModelXhr {
  static modelName = "ModelMergedRooms";

  static getSelect(input) {
    return {
      ...input,
    };
  }


  static addRooms(rooms, onSuccess = () => {}, onFailure = () => {}) {
    let endpoint = config.api_endpoint + "merged_rooms";
    this.saveData(
      ModelMergedRooms,
      endpoint,
      "POST",
      rooms,
      (response) => {
        if (!response || response.status === "error") {
          onFailure(response.error);
          return;
        }
        if (response) {
          onSuccess(response);
          return;
        }
        onFailure("An unknown error occured.");
      },
      {
        contentType: "json",
      }
    );
  }

  getSelect(input) {
    ModelMergedRooms.getSelect(input);
  }

  constructor(selectData) {
    super(selectData, {
      endpoint:
        config.api_endpoint +
        "merged_rooms" +
        (selectData?.start_date
          ? "?start_date=" + selectData?.start_date
          : ""),
      method: "GET",
    });

    this.cacheEnabled = true;
  }

  getDataFromResponse(data) {
    try {
      const response = JSON.parse(data);
      if (!response) {
        let error = "The server returned an unknown error";
        this.onFailure(error);
        return null;
      }
      return response.rooms;
    } catch (e) {
      if (this.request && this.request.aborted) {
        console.warn(
          "Cannot process request, probably because it was aborted. Exception: " +
            e.message
        );
        this.status = "waiting";
      } else {
        this.onFailure(e);
      }
    }

    return null;
  }

  getData() {
    return {
      status: this.status,
      data: this.data,
    };
  }
}

export { ModelMergedRooms };

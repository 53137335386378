import {ModelAuth} from "../../model/auth.js";
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import React from "react";
import {AuthContext} from "./auth-provider.js";

const useUser = function(){
    const {user} = React.useContext(AuthContext);
    const resultSet = useModelFeedback(ModelAuth, {id: 'user', user: user});

    if(resultSet.status === ModelAuth.Status.SUCCESS){
        const user = resultSet.data;
        return user;
    }

    return null;

}

export {useUser};
import React, { useState } from "react";
import "./styles/insert_visum.scss";
import visaExample from "../../assets/images/visa_voorbeeld.png";
import {
  Button,
  Form,
  Select,
  Card,
  Steps,
  Input,
  DatePicker,
  ConfigProvider,
  Upload,
} from "antd";
import { UIButton } from "../../components/ui/button/button.js";
import { useHistory } from "react-router-dom";

import { ModelMyOrders } from "../../model/my_orders.js";
import { ModelAuth } from "../../model/auth.js";

import { AuthWithUser } from "../../components/auth/auth-with-user.js";
import { AuthContext } from "../../components/auth/auth-provider.js";

import { useModelFeedback } from "../../helpers/model/use_model_feedback.js";
import constants from "../../assets/constants.json";
import { genderTypes } from "../../assets/constants";
import nationalities from "../../assets/nationalities.json";

import { AuthSignIn } from "../../components/auth/sign-in";
import { useUser } from "../../components/auth/use-user.js";
import moment from "moment";

import "moment/locale/nl";
import nl_NL from "antd/lib/locale/nl_NL";

moment.locale("nl");

const { Step } = Steps;
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const InsertVisumDetails = function (props) {
  let orderID = props.match.params.id;
  const [current, setCurrent] = useState(0);
  const [fileList, setFileList] = useState([]);
  const history = useHistory();

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };


  const allowedStatuses = [
    constants.ORDER_STATUS.COMPLETED,
    constants.ORDER_STATUS.DOWN_PAYMENT,
  ];

  const onSubmit = (values) => {
    values.passport_expires = values.passport_expires.format("YYYY-MM-DD");
    values.date_of_birth = values.date_of_birth.format("YYYY-MM-DD");
    values.visa_photo = values.visa_photo[0].base64;
    console.log(values);

    ModelMyOrders.changeGuestName(
      {
        order_id: orderID,
        id: values.guest_id,
        first_name: values.first_name,
        last_name: values.last_name,
        gender: values.gender,
      },
      (resp) => {
        console.log(resp);
        if (resp.success) {
          setFileList([]);
          next();
        }
      },
      null
    );
    ModelMyOrders.addGuestVisa(
      values,
      (resp) => {
        console.log(resp);
        if (resp.success) {
          setFileList([]);
          next();
        }
      },
      null
    );

    // next();
  };

  const resultSet = useModelFeedback(ModelMyOrders);
  const orders = resultSet?.data?.orders?.filter(
    (order) => allowedStatuses.indexOf(order.status) > -1
  );
  const orderToAddDetailsTo = orders?.find(
    (orderToFilter) => orderToFilter.id === parseInt(orderID)
  );

  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const base64 = await getBase64(file);
      file.base64 = base64;
      setFileList([...fileList, file]);
      return false;
    },
    listType: "picture-card",
    accept: "image/*",
    fileList,
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  fileList.length > 0 && console.log(fileList[0]);

  const guests = orderToAddDetailsTo?.rooms?.map((room) => room.guests).flat();

  const renderFirstSlide = () => (
    <div className="firstSlide">
      <h2>Welkom op de beveiligde documentomgeving van FCU!</h2>
      <p className="firstSlideDesc">
        Om ervoor te zorgen dat het aanmeldproces correct verloopt, hebben wij
        de paspoortgegevens en digitale selfie van alle reizigers nodig. Het is
        van groot belang om deze direct na de aanmelding in orde te maken.
        Alleen dan kunnen wij verder met het organiseren van de reis. Doe dit
        daarom direct.
      </p>
      <p className="firstSlideDesc">
        Wij hebben deze gegevens nodig om bij de online ambassade van de
        Saudische overheid de visums aan te vragen. Ook gebruiken wij de
        gegevens voor het boeken van de vliegtuigtickets. Uiteraard gaan wij
        uiterst zorgvuldig om met de aangeleverde persoonlijke informatie. Deze
        worden daarom ook niet door derden ingezien en niet gebruikt voor
        alternatieve doeleinden.
      </p>
      <UIButton className={"full-width"} onClick={() => next()}>
        Doorgaan
      </UIButton>
    </div>
  );

  const renderLastSlide = () => {
    ModelMyOrders.sendFinishVisum(
      {order_id: orderID},
      (resp) => {
        console.log(resp);
        if (resp.success) {
          setFileList([]);
          next();
        }
      },
      null
    );

    return (
      <div className="firstSlide">
        <h2>Bedankt voor het invullen !</h2>
        <p className="firstSlideDesc">
          Gefeliciteerd! Je hebt nu alle benodigde stappen doorlopen om jouw
          umrahreis te realiseren. Nu zijn wij aan zet. In de aankomende periode
          zullen wij hard aan de slag gaan om de reisdocumenten,
          hotelreserveringen en vliegtuigtickets in orde te maken. Ongeveer twee
          weken voor de vertrekdatum zullen wij via Whatsapp een Umrahgroep
          aanmaken. Via deze weg zullen wij jou dagelijks voorzien van
          belangrijke informatie omtrent de reis. Ook zullen wij een paar dagen
          voor vertrek een voorlichtingsavond organiseren bij ons op locatie.
          Deze zal ook online via Zoom worden uitgezonden. Heb je tegen die tijd
          nog vragen? Schroom dan niet om onze klantenservice te benaderen via
          Whatsapp. Zij staan te allen tijden klaar om jouw vragen te
          beantwoorden! Doorgaans reageren we binnen vier uur.
        </p>
        <p className="firstSlideDesc">
          Wij kijken ernaar uit om deze geweldige reis met jou mee te maken. Jij
          ook?
        </p>
        <p className="firstSlideDesc">
          Moge Allah het van een ieder accepteren. Ameen.
        </p>
        <p className="firstSlideDesc">Walaykom assalaam,</p>
        <p className="firstSlideDesc">
          Yassine Charrout<br></br>
          Eigenaar Firstclassumrah
        </p>
        <div className="footer">
          <UIButton className={"full-width"} onClick={() => history.push("/")}>
            Doorgaan naar Home
          </UIButton>
          <UIButton
            className={"full-width"}
            onClick={() => history.push("/account")}
          >
            Doorgaan naar mijn account
          </UIButton>
        </div>
      </div>
    );
  };

  const renderGuestVisaForm = (guest, key) => {
    const initialValues = {
      gender: guest.gender,
      first_name: guest.first_name,
      last_name: guest.last_name,
      nationality: "Netherlands",
      guest_id: guest.id,
    };
    console.log(guest);
    const guestProduct = orderToAddDetailsTo?.products?.find(
      (orderProduct) => orderProduct.product_id === guest.product_id
    );
    if (guest.visums.length > 0) {
      return (
        <div className="guestVisaCont">
          <Card className="firstcolumnCard" title="Invullen gegevens">
            <p className="existsCard">
              Voor deze klant zijn er al gegevens bekend, bedankt voor het
              invullen !
            </p>

            <UIButton className={"full-width"} onClick={() => next()}>
              Doorgaan
            </UIButton>
          </Card>
        </div>
      );
    } else {
      return (
        <div className="guestVisaCont">
          <h2>{`Gast #${key + 1} - ${guest.first_name}  ${
            guest.last_name
          }`}</h2>
          <Form initialValues={initialValues} onFinish={onSubmit}>
            <ConfigProvider locale={nl_NL}>
              <div className="formCont">
                <div className="firstColumn">
                  <Card className="firstcolumnCard" title="Invullen gegevens">
                    <p>
                      In de onderstaande velden dien je de paspoortgegevens in
                      te vullen van alle reizigers. LET OP! Zorg ervoor dat je
                      de gegevens heel nauwkeurig overneemt. Als de gegevens
                      eenmaal zijn ingediend bij de officiële instanties, dan
                      kunnen wij die achteraf helaas niet meer wijzigen.
                    </p>
                    <p className="smallText">
                      <i>
                        *Tip: Nederlandse paspoorten omvatten nooit een “o”
                        (uitgesproken als: oh) als documentnummer. Zie je een
                        cirkel-achtige vorm staan op jouw documentnummer? Dan is
                        dat altijd een “0” (uitgesproken als: nul).
                      </i>
                    </p>
                    <p>
                      Ook hebben wij een digitale selfie nodig van alle
                      reizigers. Dit is vrij simpel. Je dient voor een witte
                      muur te staan en een duidelijke foto te maken van het
                      volledige gezicht. Deze upload je vervolgens
                      rechtsonderaan op deze pagina. Zie onderstaande afbeelding
                      als voorbeeld.{" "}
                    </p>
                    <p>
                      Kom je er niet uit? Geen probleem. Neem dan contact op met
                      onze klantenservice en zij zullen jou van dienst zijn.
                    </p>
                    <Form.Item
                      name="guest_id"
                      label="Gast #"
                      rules={[
                        {
                          required: true,
                          message: "Gastnummer is verplicht !",
                        },
                      ]}
                      className="guestID"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="first_name"
                      label="Voornaam"
                      rules={[
                        {
                          required: true,
                          message: "Naam is verplicht !",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="last_name"
                      label="Achternaam"
                      rules={[
                        {
                          required: true,
                          message: "Naam is verplicht !",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="gender"
                      label="Geslacht"
                      rules={[
                        {
                          required: true,
                          message: "Geboortedatum is verplicht !",
                        },
                      ]}
                    >
                      <Select showSearch>
                        {genderTypes.map((gender) => (
                          <Select.Option
                            key={"gender" + gender.id}
                            value={gender.id}
                          >
                            {gender.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="date_of_birth"
                      label="Geboortedatum"
                      rules={[
                        {
                          required: true,
                          message: "Geboortedatum is verplicht !",
                        },
                      ]}
                    >
                      <DatePicker className="datePicker" />
                    </Form.Item>
                    <Form.Item
                      name="nationality"
                      label="Nationaliteit"
                      defaultValue={"Netherlands"}
                      rules={[
                        {
                          required: true,
                          message: "Nationaliteit is verplicht !",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(inputValue, option) =>
                          option.children
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                      >
                        {nationalities.map((nationality) => (
                          <Select.Option
                            key={nationality.num_code}
                            value={nationality.nationality}
                          >
                            {nationality.nl_short_name ||
                              nationality.en_short_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="city_of_country"
                      label="Geboorteland"
                      defaultValue={"Netherlands"}
                      rules={[
                        {
                          required: true,
                          message: "Geboorteland is verplicht !",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(inputValue, option) =>
                          option.children
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                      >
                        {nationalities.map((nationality) => (
                          <Select.Option
                            key={nationality.num_code}
                            value={nationality.nationality}
                          >
                            {nationality.nl_short_name ||
                              nationality.en_short_name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="city_of_birth"
                      label="Geboortestad"
                      rules={[
                        {
                          required: true,
                          message: "Geboortestad is verplicht !",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="passport_number"
                      label="Paspoortnummer"
                      rules={[
                        {
                          required: true,
                          message: "Paspoortnummer is verplicht !",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="passport_creation_date"
                      label="Aanmaakdatum paspoort"
                      rules={[
                        {
                          required: true,
                          message: "Aanmaakdatum paspoort is verplicht !",
                        },
                      ]}
                    >
                      <DatePicker className="datePicker" />
                    </Form.Item>
                    <Form.Item
                      name="passport_expires"
                      label="Vervaldatum paspoort"
                      rules={[
                        {
                          required: true,
                          message: "Paspoortnummer is verplicht !",
                        },
                        {
                          message:
                            "Vervaldatum zal niet meer geldig zijn bij het aanvragen van een visum, bestel alstublieft een nieuwe met SPOED.",
                          validator: (_, value) => {
                            const insertedDate = moment(value);
                            const minimalDate = moment(
                              guestProduct.start
                            ).subtract(6, "months");
                            if (insertedDate.isSameOrAfter(minimalDate)) {
                              return Promise.resolve();
                            } else {
                              return Promise.reject(
                                "Vervaldatum zal niet meer geldig zijn bij het aanvragen van een visum, bestel alstublieft een nieuwe met SPOED."
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <DatePicker className="datePicker" />
                    </Form.Item>

                    <Button className={"full-width"} htmlType="submit">
                      Uploaden en doorgaan
                    </Button>
                  </Card>
                </div>
                <div className="secondColumn">
                  <Card className="passphotoCard" title="Foto uploaden pasfoto">
                    <div className="imgPreviewCont">
                      {fileList.length > 0 && (
                        <img
                          className="passFoto"
                          src={fileList[0].base64}
                        ></img>
                      )}
                      {fileList.length < 1 && (
                        <img
                          className="passFoto example"
                          src={visaExample}
                        ></img>
                      )}
                      {fileList.length < 1 && (
                        <div className="exmaple">VOORBEELD</div>
                      )}
                    </div>
                    <Form.Item
                      label="Upload"
                      className="uploadItem"
                      name="visa_photo"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      rules={[
                        {
                          required: true,
                          message: "Pasfoto is verplicht !",
                        },
                      ]}
                    >
                      <Upload {...uploadProps}>
                        {fileList.length < 1 && <div>Foto toevoegen</div>}
                      </Upload>
                    </Form.Item>
                  </Card>
                </div>
              </div>
            </ConfigProvider>
          </Form>
        </div>
      );
    }
  };
  const items =
    [
      {
        title: "Inleiding",
        // description: "Om de umrah reis te kunnen organiseren...",
        content: renderFirstSlide(),
      },
    ].concat(
      guests?.map((guest, key) => ({
        title: `Gast #${key + 1} - ${guest.first_name}  ${guest.last_name}`,
        // description: `Invullen benodigde informatie ${
        //   orderToAddDetailsTo?.products?.find(
        //     (orderProduct) => orderProduct.product_id === guest.product_id
        //   ).label
        // } ${moment(
        //   orderToAddDetailsTo?.products?.find(
        //     (orderProduct) => orderProduct.product_id === guest.product_id
        //   ).start
        // ).format("YYYY")}`,
        content: renderGuestVisaForm(guest, key),
      }))
    ) || [];

  items.push({
    title: "Afronden",
    // description: "Bedankt voor het invullen van de gegevens",
    content: renderLastSlide(),
  });

  return (
    <AuthWithUser enableFeedback={false}>
      {(user) => {
        return (
          <div className="requestVisumCont">
            <Card className="requestVisumCard" title="Documentenomgeving">
              {user ? (
                <>
                  <Steps current={current} items={items} />
                  <Step className="">Test111</Step>
                  {items[current]?.content}
                </>
              ) : (
                <>
                  <p className="loginDesc"></p>
                  <AuthSignIn onSuccess={() => history.go(0)} />
                </>
              )}
            </Card>
          </div>
        );
      }}
    </AuthWithUser>
  );
};

export { InsertVisumDetails };

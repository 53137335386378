import React from "react";
import { Result, Button } from 'antd';
import {useStateContext} from "../../../helpers/state-context/use_state_context.js";
import {UIText} from "../../../components/ui/text/text.js";
import {useHistory} from "react-router-dom";
import {roomTypes} from '../../../assets/constants.js';

const PackagesAddSuccess = function (props) {
    const history = useHistory()
    const {amount} = props;
    const [state] = useStateContext();
    let guestCount = 0;
    for (const cartItem of state.cart){
        if(cartItem.guests){
            guestCount += cartItem.guests.length
        }
    }

    let guestCountLeft = amount - guestCount;
    const text = (guestCountLeft > 0) ? `${guestCount} / ${amount} gasten zijn over` : 'Alle gasten zijn toegevoegd. Ga naar de winkelwagen';
    const lastCartItem = state.cart[state.cart.length-1];
    const roomType = roomTypes[lastCartItem?.room_type];
    const extras = [
        <Button type="primary" key="cart" onClick={()=>history.push('/winkelwagen')}>
            Afrekenen
        </Button>
    ]
    if(guestCountLeft > 0){
        extras.push(<Button key={'new'} onClick={()=>{props.addNewRoom()}}>
            Voeg nog een kamer toe
        </Button>)
    }

    if (lastCartItem == null || undefined){
        return null;
    }
    
    return <Result
        status="success"
        title={`${roomType.label} kamer toegevoegd`}
        subTitle={<div>
            <UIText>Er zijn {lastCartItem?.guests.length} personen toegevoegd in deze kamer.</UIText>
        </div>}
        extra={extras}
    />
};


export {PackagesAddSuccess}
import React, { useState, useEffect, useCallback } from "react";
import LOGO from "../../../assets/images/logo_vector.svg";
import BACKGROUND from "../../../assets/images/frame29.jpg";
import UMRAH_TEXT from "../../../assets/images/umrah.png";
import { FaWheelchair} from "react-icons/fa";
import {FaUserGroup, FaPerson } from "react-icons/fa6";
import constants from "../../../assets/constants.json";


import WebFont from "webfontloader";

import "./roomPDF.scss";
import { genderRoomTypes, getAgeTypeById } from "../../../assets/constants";

const RoomPDF = function (props) {
  const roomsToRender = [];
  const rooms = props.rooms.filter((room) => room.guests.length > 0) || [];

  const firstTwoPersons = props.rooms?.find((room) => room.maxGuests === 2);
  const firstThreePersons = props.rooms?.find((room) => room.maxGuests === 3);
  const firstFourPersons = props.rooms?.find((room) => room.maxGuests === 4);
  const getColorByCountGuests = (count) => {
    switch (count) {
      case 2:
        return "red";
      case 3:
        return "green";
      case 4:
        return "gold";
    }
  };

  return (
    <tr>
    <div
      key={props.key + "test"}
      className="roomsPDF"
      id="roomPDF"
      style={{
        backgroundImage: "url($background)".replace("$background", BACKGROUND),
      }}
    >
      <div className="pageCont">
        <div className="header">
          <h1>
            {" "}
            <img className="umrahText" src={UMRAH_TEXT} />
          </h1>
          <h1>{props?.title.replace("Umrah", "")}</h1>
          <h3 className="room">Kamerindelingen</h3>
          <img className="logo" src={LOGO} />
        </div>

        <div className="rooms">
          <div className="headerRooms">
            <h3>Medina en Mekka</h3>
            <div className="goldBar" />
            <div className="columns">
              {rooms?.map((room, key) => (
                <div className="roomContt">
                  <div className="roomHeader">
                    {room.maxGuests === 2 && <div>Tweepersoonskamer</div>}
                    {room.maxGuests === 3 && <div>Driepersoonskamer</div>}
                    {room.maxGuests === 4 && <div>Vierpersoonskamer</div>}

                    {room?.flex?.room_medina && (
                      <div>Kamernummer Medina: {room?.flex?.room_medina}</div>
                    )}
                    {room?.flex?.room_mekka && (
                      <div>Kamernummer Mekka: {room?.flex?.room_mekka}</div>
                    )}
                  </div>

                  <div className="columns">
                    <div className="item name">Naam</div>
                    <div className="item age">Leeftijdscategorie</div>
                    <div className="item bed">Eigen bed</div>
                    <div className="item weelchair">Eigenschappen</div>
                  </div>

                  <div className="rows">
                    {room.guests.map((guest, key) => (
                      <div
                        className={
                          "row " + getColorByCountGuests(room.maxGuests)
                        }
                        key={"guest" + key}
                      >
                        <div className="item name">
                          #{key + 1} {guest.first_name} {guest.last_name}
                        </div>
                        <div className="item age">
                          {getAgeTypeById(guest.age_category).label_short}
                        </div>
                        <div className="item bed">
                          {guest.bed_included && (guest.age_category !== constants.AGE_CATEGORY.BABY) ? "Ja" : "Nee"}
                        </div>
                        <div className="item weelchair">
                          {room.exclusive ? <FaPerson /> : <FaUserGroup />}
                          {JSON.parse(guest?.flex)?.disabled && <FaWheelchair />}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="html2pdf__page-break" />
      </div>
    </div>

    </tr>

  );
};

export { RoomPDF };

import "./quote.scss";
import React from "react";
import {Teared} from "../../components/ui/teared/teared.js";
import {UIText} from "../../components/ui/text/text.js";

const AboutQuote = function (props) {
    return <Teared color={'orange'} className={'about-quote'}>
        <UIText className={'about-quote-text'}>Hoge kwaliteit Umrah reizen met optimale zorg, dat is waar wij voor staan.</UIText>
    </Teared>
};

export {AboutQuote}

import React from "react";
import { toMoney } from "../../../helpers/functions.js";
import { MdDiscount, MdCancel, MdPayments } from "react-icons/md";
import { AiOutlineEllipsis, AiOutlineDownload } from "react-icons/ai";
import useWindowWidthBreakpoints from "use-window-width-breakpoints";

import {
  UserOutlined,
  GroupOutlined,
  EuroCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Card, Dropdown, Tooltip } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import UmrahThumb from "../../../assets/images/umrah_thumb.jpg";
import UmrahThumb2 from "../../../assets/images/umrah_thumb2.jpg";

const AdminOrderComp = function (props) {
  const order = props.order;

  const flex = JSON.parse(order.flex);
  const key = props.keyToUse;

  const breakpoint = useWindowWidthBreakpoints({
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  });

  const guests = order.rooms.map((room) => room.guests).flat();
  const visums = guests
    .map((guest) => guest?.visums?.map((visum) => ({ order_id:order.id, ...visum, ...guest })))
    .flat();
  const rooms = order.rooms;
  const payments = order.payments;

  const discounts = flex?.discounts?.map((label) =>
    props.discounts.find((discount) => discount.label === label)
  ) || [{ amount: 0, label: "N.v.t." }];

  const roomsSortedByGreatness = [];
  const totalDiscount = discounts
    .map((discount) => discount.amount / 100)
    .reduce((partialSum, a) => partialSum + a, 0);
  const totalToPay =
    order.products
      .map((product) => parseFloat(product.price))
      .reduce((partialSum, a) => partialSum + a, 0) - totalDiscount;
  const totalPaid = order.payments
    .map((product) => parseFloat(product.amount))
    .reduce((partialSum, a) => partialSum + a, 0);

  rooms.forEach((element) => {
    const lengthKey = element.guests.length;
    if (roomsSortedByGreatness[lengthKey]) {
      roomsSortedByGreatness[lengthKey] = roomsSortedByGreatness[
        lengthKey
      ].concat([element]);
    } else {
      roomsSortedByGreatness[lengthKey] = [element];
    }
  });

  const renderCarousel = () => (
    <Carousel className="photoCarouselCont">
      {visums?.map((visum) => (
        <div className="photoCarousel">
          <h3>
            {guests.find((guest) => guest.id === visum?.guest_id)?.first_name}{" "}
            {guests.find((guest) => guest.id === visum?.guest_id)?.last_name}
          </h3>
          <img className="carImg" src={visum?.visa_photo_url}></img>
        </div>
      ))}
    </Carousel>
  );

  return (
    <Card
      key={order.id + key}
      className="cardCont"
      cover={
        visums.length > 0 ? (
          renderCarousel()
        ) : breakpoint.only.xs ? null : order.id % 2 === 0 ? (
          <img alt="example" src={UmrahThumb} />
        ) : (
          <img alt="example" src={UmrahThumb2} />
        )
      }
      actions={[
        <Tooltip
          placement={breakpoint.only.xs ? "top" : "left"}
          title="Betalen"
        >
          <MdPayments
            className="actionIcon"
            onClick={() => props.setModal(order)}
          />
        </Tooltip>,
        <Tooltip
          placement={breakpoint.only.xs ? "top" : "left"}
          title="Documenten downloaden"
        >
          <AiOutlineDownload
            onClick={() => props.setOrderToDownload(order)}
            className="actionIcon"
          />
        </Tooltip>,
        <Tooltip
          placement={breakpoint.only.xs ? "top" : "left"}
          title="Kortingen"
        >
          <MdDiscount
            onClick={() => props.setOrderToUpdate(order)}
            className="actionIcon"
          />
        </Tooltip>,
        <Tooltip
          placement={breakpoint.only.xs ? "top" : "left"}
          title="Reis annuleren"
        >
          <MdCancel
            onClick={() => props.setOrderToCancel(order)}
            className="actionIcon"
          />
        </Tooltip>,
        <Tooltip
          placement={breakpoint.only.xs ? "top" : "left"}
          title="Meer acties"
        >
          <AiOutlineEllipsis className="actionIcon" />
        </Tooltip>,
        // <EllipsisOutlined key="ellipsis" className="icon" />,
      ]}
    >
      <h2>{`#FCU1${order.id} - ${order.user.first_name} ${order.user.last_name}`}</h2>
      <div className="cardDetails">
        <div className="firstColumn">
          <div className="cardContentItem">
            <div className="cardContTitle">Ordernummer: </div>
            <div className="cardContContent">{`#FCU1${order.id}`} </div>
          </div>
          <div className="cardContentItem">
            <div className="cardContTitle">Factuurhouder: </div>
            <div className="cardContContent">
              {`${order.user.first_name} ${order.user.last_name}`}{" "}
            </div>
          </div>
          <div className="cardContentItem">
            <div className="cardContTitle">Aantal pers. op factuur: </div>
            <div className="cardContContent">
              {" "}
              <Dropdown.Button
                key={order.id + "guests"}
                menu={{
                  items: guests.map((guest, key) => ({
                    key: "guest" + guest.id,
                    label: `#${key + 1} - ${guest.first_name} ${
                      guest.last_name
                    }`,
                    icon: <UserOutlined />,
                  })),
                }}
                onClick={() => null}
              >
                {guests.length}
              </Dropdown.Button>
            </div>
          </div>
          <div className="cardContentItem">
            <div className="cardContTitle">Aantal kamers op factuur: </div>
            <div className="cardContContent">
              <Dropdown.Button
                key={order.id + "rooms"}
                menu={{
                  items: roomsSortedByGreatness.map((item, key) => {
                    return {
                      key: "room" + item[0].id,
                      label: `${roomsSortedByGreatness[key].length} x ${item[0].guests.length} persoons kamer`,
                      icon: <GroupOutlined />,
                    };
                  }),
                }}
                onClick={() => null}
              >
                {rooms.length}
              </Dropdown.Button>
            </div>
          </div>

          <div className="cardContentItem">
            <div className="cardContTitle">Datum besteld: </div>
            <div className="cardContContent">
              {moment(order.created_at).format("DD-MM-YYYY")}{" "}
            </div>
          </div>
        </div>

        <div className="secondColumn">
          <div className="cardContentItem">
            <div className="cardContTitle">Openstaand bedrag: </div>
            <div
              className={
                "cardContContent " + (totalPaid < totalToPay ? "red" : "")
              }
            >
              {toMoney(totalToPay - totalPaid)}{" "}
            </div>
          </div>
          <div className="cardContentItem">
            <div className="cardContTitle">Totaalbedrag factuur: </div>
            <div className="cardContContent">{toMoney(totalToPay)} </div>
          </div>
          <div className="cardContentItem">
            <div className="cardContTitle">Betaald bedrag: </div>
            <div className="cardContContent">
              <Dropdown.Button
                className={
                  "totalPaid " + (totalPaid < totalToPay ? "orange" : "")
                }
                key={order.id + "payments"}
                menu={{
                  items: payments.map((item, key) => {
                    return {
                      key: "payment" + key,
                      label: `${toMoney(parseFloat(item.amount))}  |  ${moment(
                        item.created_at
                      ).format("DD-MM-YYYY hh:mm")}`,
                      icon: <EuroCircleOutlined />,
                    };
                  }),
                }}
                onClick={() => null}
              >
                {toMoney(totalPaid)}
              </Dropdown.Button>
            </div>
          </div>
          <div className="cardContentItem">
            <div className="cardContTitle">Korting: </div>
            <div className="cardContContent">
              <Dropdown.Button
                key={order.id + "discounts"}
                menu={{
                  items: discounts.map((item, key) => {
                    return {
                      key: "discount" + key,
                      label: `${item.label} - ${toMoney(
                        parseFloat(item.amount / 100)
                      )}`,
                      icon: <EuroCircleOutlined />,
                    };
                  }),
                }}
                onClick={() => null}
              >
                {toMoney(totalDiscount)}
              </Dropdown.Button>
            </div>
          </div>
          <div className="cardContentItem">
            <div className="cardContTitle">Documenten aangeleverd: </div>
            <div className="cardContContent">
              <Dropdown.Button
                key={order.id + "visums"}
                className={"totalPaid " + ( visums.length < guests.length ? "orange" : "")}
                menu={{
                  items: visums.map((item, key) => {
                    return {
                      key: key,
                      label: `#${key+1} - ${item?.first_name} ${item?.last_name}`,
                      icon: <UserOutlined />,
                      onClick : (evt)=>(props.setVisumToShow(visums[evt.key]))
                    };
                  }),
                }}

              >
                {visums.length}/{guests.length}
              </Dropdown.Button>
            </div>
          </div>
        </div>
        <div className="thirdColumn"></div>
      </div>
    </Card>
  );
};

export { AdminOrderComp };

import {config} from '../config.js';
import {ModelXhr} from "../helpers/model/xhr.js";

class ModelContact extends ModelXhr {
    static modelName = "ModelContact";

    static send(body, onSuccess=()=>{}, onFailure=()=>{}){
        this.saveData(ModelContact, config.api_endpoint + 'contact', 'POST',
            body,
            (response) => {
                if(! response){
                    onFailure(response.error);
                    return;
                }
                if(response.status === ModelContact.Status.SUCCESS){
                    onSuccess(response);
                    return;
                }
                onFailure("An unknown error occured.");
            },
            {
                contentType: 'params'
            }
        );
    }

    static requestSinglePackage(body, onSuccess=()=>{}, onFailure=()=>{}){
        this.saveData(ModelContact, config.api_endpoint + 'contact/individual', 'POST',
            body,
            (response) => {
                if(! response){
                    onFailure(response.error);
                    return;
                }
                if(response.status === ModelContact.Status.SUCCESS){
                    onSuccess(response);
                    return;
                }
                onFailure("An unknown error occured.");
            },
            {
                contentType: 'json'
            }
        );
    }

    static requestSingleHadj(body, onSuccess=()=>{}, onFailure=()=>{}){
        this.saveData(ModelContact, config.api_endpoint + 'contact/individual_hadj', 'POST',
            body,
            (response) => {
                if(! response){
                    onFailure(response.error);
                    return;
                }
                if(response.status === ModelContact.Status.SUCCESS){
                    onSuccess(response);
                    return;
                }
                onFailure("An unknown error occured.");
            },
            {
                contentType: 'json'
            }
        );
    }
}

export {ModelContact};

import React from "react";
import {Modal, Form, Button, Input, Switch, InputNumber, notification} from "antd";
import {ModelCrowdfunding} from "../../../model/crowdfunding.js";

const AdminCrowdfundingNew = function (props) {
    const {initialValues} = props;

    const onSubmit = (values) => {
        const body = {
            label: values.label,
            description: values.description,
            active: values.active,
            goal_amount: values.goal_amount,
        };

        if(initialValues){
            ModelCrowdfunding.updateItem(initialValues.id, body, () => {
                notification.success({
                    message: 'Crowdfunding is succesvol gewijzigd.'
                })
                props.setInitialValues(null);
                props.setVisible();
            })
        }else{
            ModelCrowdfunding.createItem(body, ()=>{
                notification.success({
                    message: 'Crowdfunding is succesvol aangemaakt.'
                })
                props.setVisible();
            })
        }
    };

    return <Modal visible={props.visible}
                  maskClosable={false}
                  title= {(initialValues)? 'Update product' : 'Voeg nieuwe product toe'}
                  footer={false}
                  onCancel={()=>{
                      props.setInitialValues(null)
                      props.setVisible()
                  }}>
        <Form layout={'vertical'}
              onFinish={onSubmit}
              initialValues={initialValues ? initialValues : {active: false}}>
            <Form.Item name="label" label="Titel" rules={[{ required: true, message: "Titel is verplicht!" }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="description" label="Omschrijving" rules={[{ required: true, message: "Omschrijving is verplicht!" }]}>
                <Input.TextArea/>
            </Form.Item>
            <Form.Item name="goal_amount" label="Doel in euro's" rules={[{ required: true, message: "Doel is verplicht!" }, {type: 'number',min: 0, message: "Bedrag moet boven 0 zijn"},]}>
                <InputNumber min={0} step={1} parser={(number)=> number ? Math.round(parseFloat(number)) : 0}/>
            </Form.Item>
            <Form.Item name="active" label="Actief" valuePropName="checked">
                <Switch />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="contact-form-button">
                {(initialValues)? 'Updaten' : 'Toevoegen'}
            </Button>
        </Form>
    </Modal>
};

export {AdminCrowdfundingNew}

import React from "react";
import {Modal, Form, Input, Button, notification,  DatePicker} from "antd";
import moment, {isDate} from 'moment'
import {ModelUsers} from "../../model/users.js";
import {ModelAuth} from "../../model/auth.js";
import ReCAPTCHA from "react-google-recaptcha";
import {v4} from "uuid";
import {AuthContext} from "./auth-provider.js";

const AuthSignUp = function (props) {

    const recaptchaRef = React.createRef();
    const {initialValues, visible} = props;
    const {setUser} = React.useContext(AuthContext);

    const onSubmit = (values) => {
        const {password, ...restValues} = values;
        const body = {
            ...restValues,
            date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD")
        };
        let recaptchaBool= (recaptchaRef.current.getValue().length > 0);
        if(recaptchaBool){
            if(password !== undefined){
                body.password = password;
            }
            if(props.loginOnRegister !== undefined){
                body.login_user = true
            }

            if(initialValues){
                if(initialValues.updateType === 'admin'){
                    ModelUsers.updateItem(initialValues.user_id, body, ()=>{
                        notification.success({
                            message: 'Gebruiker is gewijzigd.'
                        });
                        props.setInitialValues(null);
                        props.setVisible();
                    })
                }else{
                    ModelAuth.updateItem(body, ()=>{
                        notification.success({
                            message: 'Gebruiker is gewijzigd.'
                        });
                        props.setVisible();
                    })
                }
            }else{
                ModelAuth.register(body, (response) => {
                  if(response.status === 'success'){
                    localStorage.setItem("tokenKey", response.token);
                    setUser(v4())
                      props.setVisible();
                    return notification.success({
                        message: "Account aangemaakt."
                    });
                  }
                      return notification.error({
                        message: "Een onbekende fout heeft zich opgetreden, neem contact met ons op."
                    });
                }, (error)=>{
                    if(localStorage.getItem('tokenKey') !== undefined){
                        localStorage.removeItem("tokenKey");
                        setUser(v4())
                    }
                    if(error.indexOf('Unprocessable') > -1){
                        return notification.error({
                            message: "Dit emailadres wordt al gebruikt."
                        });
                    }
                    return notification.error({
                        message: "Een onbekende fout heeft zich opgetreden, neem contact met ons op."
                    });
                })
            }
        }else{
            notification.error({
                message: "Verifieer dat u geen robot bent!"
            })
        }
    };

    return <Modal visible={visible}
                  title={(initialValues) ? 'Account aanpassen' : 'Voeg account toe'}
                  footer={false}
                  className={'modal'}
                  maskClosable={false}
                  onCancel={(e)=>{
                      props.setVisible('')
                  }}>
        <Form layout={'vertical'}
              onFinish={onSubmit}
              initialValues={
                  initialValues ? {
                      ...initialValues,
                      date_of_birth: isDate(new Date(initialValues.date_of_birth)) ? moment(initialValues.date_of_birth) : null
                  } : {}
              }
              style={{width: '100%'}}>
            <div className={'center-space-between mobile-vertical'}>
                <Form.Item name="email" label="Email" rules={[{ required: true, message: "Email is verplicht!" }, {type: 'email', message: 'Geen geldig email adres.'}]} style={{ width: '100%', marginRight: '.5rem'}}>
                    <Input/>
                </Form.Item>
                <Form.Item name="password" label="Wachtwoord" rules={initialValues ? [] : [{ required: true, message: "Wachtwoord is verplicht!" }]} style={{ width: '100%' }}>
                    <Input.Password/>
                </Form.Item>
            </div>
            <div className={'center-space-between mobile-vertical'}>
                <Form.Item name="first_name" label="Voornaam" rules={[{ required: true, message: "Voornaam is verplicht!" }]} style={{ width: '100%', marginRight: '.5rem'}}>
                    <Input/>
                </Form.Item>
                <Form.Item name="last_name" label="Achternaam" rules={[{ required: true, message: "Achternaam is verplicht!" }]} style={{ width: '100%' }}>
                    <Input/>
                </Form.Item>
            </div>
            <Form.Item name="date_of_birth" label="Geboortedatum" rules={[{ required: true, message: "Geboortedatum is verplicht!" }]} style={{ width: '100%'}}>
                <DatePicker format={"DD-MM-YYYY"} style={{ width: '100%' }}/>
            </Form.Item>
            <Form.Item name="nationality" label="Nationaliteit" rules={[{ required: true, message: "Nationaliteit is verplicht!" }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="phone_number" label="Telefoonnummer" rules={[{ required: true, message: "Telefoonnummer is verplicht!" }]}>
                <Input/>
            </Form.Item>
            <div className={'center-space-between mobile-vertical'}>
                <Form.Item name="address" label="Adres" rules={[{ required: true, message: "Adres is verplicht!" }]} style={{ width: '100%', marginRight: '.5rem'}}>
                    <Input/>
                </Form.Item>
                <Form.Item name="zip" label="Postcode" rules={[{ required: true, message: "Postcode is verplicht!" }]} style={{ width: '100%' }}>
                    <Input/>
                </Form.Item>
            </div>
            <div className={'center-space-between mobile-vertical'}>
                <Form.Item name="city" label="Plaats" rules={[{ required: true, message: "Plaats is verplicht!" }]} style={{ width: '100%', marginRight: '.5rem'}}>
                    <Input/>
                </Form.Item>
                <Form.Item name="country" label="Land" rules={[{ required: true, message: "Land is verplicht!" }]} style={{ width: '100%' }}>
                    <Input/>
                </Form.Item>
            </div>
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LceuMkjAAAAAEvspC5iGjh-ZQwnwvpB4p5Q8BTX"
            />
            <Button type="primary" htmlType="submit" className="contact-form-button">
                {(initialValues) ? 'Updaten' : 'Aanmaken'}
            </Button>
        </Form>
        <div className={'modal-footer'}>
            <div className={'modal'}><div className={'modal-footer'} /></div>
        </div>
    </Modal>
};

export {AuthSignUp}

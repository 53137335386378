import React, { useEffect, useState } from "react";
import "./invoice.scss";
import { UIText } from "../../components/ui/text/text.js";
import { Collapse, notification, Tag, Table, Space } from "antd";
import { ModelMyInvoices } from "../../model/my_invoices.js";
import { ModelInvoices } from "../../model/invoices.js";

import { toMoney } from "../../helpers/functions.js";
import { UIDescriptions } from "../../components/ui/descriptions.js";
import FormItemLabel from "antd/lib/form/FormItemLabel.js";
import { getPaymentTypes } from "../../assets/constants.js";
import moment from "moment";
import {
  useParams
} from "react-router-dom";

const ShowInvoice = function (props) {
  let linkToken = props.match.params.token;
  const { Column, ColumnGroup } = Table;
  const [items, setItem] = useState(null);
  useEffect(() => {
    ModelInvoices.getInvoiceByToken({ token: linkToken }, linkToken, (resp) => {
      if (resp.items) {
        setItem([resp]);
      }
    });
  });
  const onFullPayment = (values) => {
    values.percent_to_pay = 100;
    values.flex = JSON.stringify({});
    ModelMyInvoices.preparePayment(values, values.id, (resp) => {
      if (resp.transaction) {
        if (resp.transaction.paymentURL) {
          window.location.href = resp.transaction.paymentURL;
        }
      }
    });
  };

  const onHalfPayment = (values) => {
    values.percent_to_pay = 50;
    values.flex = JSON.stringify({});

    ModelMyInvoices.preparePayment(values, values.id, (resp) => {
      if (resp.transaction) {
        if (resp.transaction.paymentURL) {
          window.location.href = resp.transaction.paymentURL;
        }
      }
    });
  };

  const onRestPayment = (values, percent) => {
    values.percent_to_pay = percent;
    values.flex = JSON.stringify({});

    ModelMyInvoices.preparePayment(values, values.id, (resp) => {
      if (resp.transaction) {
        if (resp.transaction.paymentURL) {
          window.location.href = resp.transaction.paymentURL;
        }
      }
    });
  };

  const getOrderInvoice = (order_id) => {
    ModelInvoices.orderInvoice(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `factuur-FCU${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const getInvoiceDown = (order_id) => {
    ModelInvoices.downPaymentInvoice(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `aanbetalingsbewijs-FCU${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const genExtra = (invoice) => {
    const totalArr = invoice.payments.map((paymentObj) =>
      parseInt(paymentObj.amount)
    );
    const totalPaid =
      totalArr.reduce((partialSum, a) => partialSum + a, 0) || 0;
    const totalToPay = invoice.items
      .map((invoice) => (invoice.amount_in_cents / 100) * invoice.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
    return (
      <Space>
        {invoice?.payments?.length < 1 && (
          <Tag onClick={() => onFullPayment({ id: invoice.id })}>
            Volledig betalen
          </Tag>
        )}
        {invoice?.payments?.length < 1 && (
          <Tag onClick={() => onHalfPayment({ id: invoice.id })}>
            Aanbetaling 50%
          </Tag>
        )}
        {invoice?.payments?.length > 0 &&
          totalToPay !== totalPaid &&
          (totalToPay > totalPaid) * 100 > 0 && (
            <Tag
              onClick={() =>
                onRestPayment(
                  { id: invoice.id },
                  (totalPaid / totalToPay) * 100
                )
              }
            >
              Restbetaling
            </Tag>
          )}
        {invoice?.payments?.length > 0 &&
          totalToPay !== totalPaid &&
          (totalToPay > totalPaid) * 100 > 0 && (
            <Tag onClick={() => getInvoiceDown(invoice.id)}>
              Aanbetalingsbewijs
            </Tag>
          )}
        {invoice?.payments?.length > 0 &&
          totalToPay !== totalPaid &&
          (totalToPay > totalPaid) * 100 > 0 && (
            <Tag onClick={() => getOrderInvoice(invoice.id)}>Factuur</Tag>
          )}
      </Space>
    );
  };

  const renderTable = (items) => {
    let data = items.map((item, key) => {
      const extraObj = {
        key: key + 1,
        amount_money: toMoney(item.amount_in_cents / 100),
      };
      Object.assign(item, extraObj);
      return item;
    });
    return (
      <Table dataSource={data} pagination={false}>
        <Column title="#" responsive={['lg']}  dataIndex={"key"} key={"ItemKey"} />
        <Column title="Naam" dataIndex="label" key={"ItemLabel"} />
        <Column title="Stuksprijs" responsive={['lg']}   dataIndex="amount_money" key={"ItemLabel"} />
        <Column title="Aantal" responsive={['lg']}   dataIndex="amount" key={"ItemAmount"} />
        {/* <ColumnGroup title="Prijs">
            <Column title="incl. 21% btw" dataIndex="firstName" key="firstName" />
            <Column title="excl. btw" dataIndex="lastName" key="lastName" />
          </ColumnGroup> */}
      </Table>
    );
  };

  const renderPaymentsTable = (items) => {
    let data = items.map((item, key) => {
      const extraObj = {
        key: key + 1,
        amount_money: toMoney(item.amount),
        // type_text: getPaymentTypes(item?.type).label,
      };
      Object.assign(item, extraObj);
      return item;
    });
    return (
      <Table dataSource={data} pagination={false}>
        <Column title="#" responsive={['lg']} dataIndex={"key"} key={"ItemKey"} />
        <Column title="Soort" responsive={['lg']}   dataIndex="type_text" key={"ItemLabel"} />
        <Column title="Bedrag" dataIndex="amount_money" key={"ItemLabel1"} />
      </Table>
    );
  };

  const renderinvoice = (invoice) => {
    const totalToPay = invoice.items
      .map((invoice) => (invoice.amount_in_cents / 100) * invoice.amount)
      .reduce((partialSum, a) => partialSum + a, 0);
    const extraObj = {
      total_amount_inc_btw: totalToPay,
      total_amount_exc_btw: (totalToPay / 121) * 100,
      total_amount_btw: (totalToPay / 121) * 21,
      pay_full: (
        <Tag  className="paymentBtn"onClick={() => onFullPayment({ id: invoice.id })}>
          Volledig betalen
        </Tag>
      ),

      pay_half: (
        <Tag className="paymentBtn"onClick={() => onHalfPayment({ id: invoice.id })}>
          50% aanbetalen
        </Tag>
      ),
    };
    Object.assign(invoice, extraObj);
    const labels = {
      id: "#",
      items: "Producten",
      total_amount_btw: "BTW 21%",
      total_amount_exc_btw: "Totaalbedrag excl. BTW",
      total_amount_inc_btw: "Totaalbedrag inc. 21% BTW",
      expire_date: "Geldig tot:",
      pay_full: "Volledig betalen",
      pay_half: "Aanbetalen",
    };
    if (invoice.payments) {
      if (invoice.payments.length > 0) {
        labels.payments = "Betalingen";
      }
    }
    return {
      labels: labels,
      renderLabelFunctions: {
        id: (value) => "FCU2" + value,
        items: (items) => renderTable(items),
        payments: (items) => renderPaymentsTable(items),
        total_amount_inc_btw: (value) => toMoney(value),
        total_amount_exc_btw: (value) => toMoney(value),
        total_amount_btw: (value) => toMoney(value),
        expire_date: (value) => moment(value).format("DD-MM-YYYY"),
      },
      item: invoice,
    };
  };

  return (
    <div className="invoiceCont">
      <Collapse expandIconPosition={"start"} defaultActiveKey={["invoice2"]}>
        <Collapse.Panel
          disabled={true}
          header={"Bestelling nr."}
          key={10000}
          extra={<span>Acties</span>}
        />
        {items?.length > 0 ? (
          items?.map((item, key) => {
            const header = (
              <span>
                {(item.type === 0 ? "Offerte nr #FCU2" : "Factuur nr #FCU2") +
                  item.id}
              </span>
            );
            return (
              <Collapse.Panel
                header={header}
                key={"invoice2"}
                extra={genExtra(item)}
              >
                <UIDescriptions {...renderinvoice(item)} />
              </Collapse.Panel>
            );
          })
        ) : (
          <Collapse.Panel
            disabled={true}
            showArrow={false}
            header={"Geen geldige token."}
            key={10001}
          />
        )}
      </Collapse>
    </div>
  );
};

export { ShowInvoice };

import React, { useState } from "react";
import { Input, Select, Modal, Button } from "antd";
import { AdminOrdersList } from "./list.js";
import { UIText } from "../../../components/ui/text/text.js";
import { AdminPaymentNew } from "../payments/new.js";
import { WarningOutlined } from "@ant-design/icons";

import { ModelOrders } from "../../../model/orders.js";
import { ModelVouchers } from "../../../model/vouchers.js";
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";

import "./orders.scss";

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const PageAdminOrders = function (props) {
  const [searchType, setSearchType] = useState("id");
  const [searchValue, setSearch] = useState("");
  const [modal, setModal] = useState(null);
  const [orderToCancel, setOrderToCancel] = useState(null);
  const [orderToUpdate, setOrderToUpdate] = useState(null);

  const resultSet = useModelFeedback(ModelOrders);
  const modelResultSet = useModelFeedback(ModelVouchers);

  if (modelResultSet.feedback) {
    return resultSet.feedback;
  }
  if (resultSet.feedback) {
    return resultSet.feedback;
  }

  const updateOrder = (order) => {
    console.log(orderToUpdate);
    ModelOrders.editOrderFlex(orderToUpdate, (response) => {
      console.log(response);
      setOrderToUpdate(null);
    });

  };

  const handleDiscountChange = (discounts) => {
    const order = orderToUpdate;
    const orderFlex = order.flex ? JSON.parse(order.flex) : order.flex || {};
    const newObj = {...order, flex: orderFlex}
    newObj.flex.discounts = discounts;
    newObj.flex = JSON.stringify(newObj.flex);
    setOrderToUpdate(newObj);
  };

  const cancelOrder = (order_id) => {
    ModelOrders.cancelOrder(order_id, (response) => {
      console.log(response);
    });
  };

  const handleOk = () => {
    if (orderToCancel) {
      cancelOrder(orderToCancel);
    }
    setOrderToCancel(null);
  };

  const handleCancel = () => {
    setOrderToCancel(null);
  };

  const orderFlex = orderToUpdate?.flex ? JSON.parse(orderToUpdate?.flex) : undefined || {};
  const orderDiscounts = orderFlex?.discounts || [];
  const vouchers = modelResultSet?.data?.vouchers || [];
  return (
    <div>
      <div style={{ margin: "1rem 0" }}>
        <UIText>Zoek een bestelling</UIText>
        <Input.Group compact>
          <Select
            defaultValue={searchType}
            style={{ width: "20%" }}
            onChange={(value) => setSearchType(value)}
          >
            <Select.Option value="id">Bestelling nummer</Select.Option>
            <Select.Option value="email">Email</Select.Option>
            <Select.Option value="label">Naam</Select.Option>
          </Select>
          <Input
            style={{ width: "80%" }}
            value={searchValue}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Input.Group>
      </div>
      <AdminOrdersList
        resultSet={resultSet}
        modelResultSet={modelResultSet}
        orderToCancel={orderToCancel}
        setOrderToCancel={setOrderToCancel}
        setOrderToUpdate={(order) => setOrderToUpdate(order)}
        setModal={setModal}
        search={
          searchValue.length > 0
            ? { type: searchType, value: searchValue }
            : null
        }
      />
      {modal !== null && (
        <AdminPaymentNew
          order_id={modal}
          setVisible={() => {
            setModal(null);
          }}
        />
      )}
      <Modal
        title="Order annuleren"
        open={orderToCancel !== null}
        onOk={handleOk}
        onCancel={handleCancel}
        className="cancelWarningModal"
        footer={
          <>
            <Button type={"primary"} danger onClick={() => handleCancel()}>
              Annuleren
            </Button>
            <Button type={"primary"} onClick={() => handleOk()}>
              Bevestigen
            </Button>
          </>
        }
      >
        <div className="cancelWarningCont">
          <WarningOutlined className="cancelWarningIcon" />
          <h1>Weet je zeker dat je de order wilt annuleren?</h1>
          <p className="description">
            Bij het bevestigen van deze actie zal er een annuleringsmail worden
            gestuurd naar de klant met daarbij een creditnota (indien er een
            betaling is gekoppeld aan de bestelling).
          </p>
        </div>
      </Modal>
      <Modal
        title="Kortingen bijwerken"
        open={orderToUpdate !== null}
        onOk={handleOk}
        onCancel={() => setOrderToUpdate(null)}
        className="editDiscountsModal"
        footer={
          <>
            <Button
              type={"primary"}
              danger
              onClick={() => setOrderToUpdate(null)}
            >
              Annuleren
            </Button>
            <Button type={"primary"} onClick={() => updateOrder()}>
              Bevestigen
            </Button>
          </>
        }
      >
        <div className="cancelWarningCont">
          <h1>Bestelling #FCU1{orderToUpdate?.id}</h1>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Selecteer kortingen"
            defaultValue={orderDiscounts}
            value={orderDiscounts}
            onChange={handleDiscountChange}
            options={vouchers?.map((voucher) => ({
              title: voucher.label,
              label: voucher.label,
              value: voucher.label,
            }))}
          />{" "}
        </div>
      </Modal>
    </div>
  );
};

export { PageAdminOrders };

import './cart.scss';
import React, {useState} from "react";
import {UIText} from "../../components/ui/text/text.js";
import {Teared} from "../../components/ui/teared/teared.js";
import {UIButton} from "../../components/ui/button/button.js";
import {useStateContext} from "../../helpers/state-context/use_state_context.js";
import {PageCartDetails} from "./details.js";
import {Select, notification, Modal, Form, InputNumber, Button} from "antd";
import {ModelConsumer} from "../../helpers/model/model-consumer.js";
import {ModelUsers} from "../../model/users.js";
import {useModelFeedback} from "../../helpers/model/use_model_feedback.js";
import {ModelProducts} from "../../model/products.js";
import {ModelCrowdfunding} from "../../model/crowdfunding.js";
import {toMoney} from "../../helpers/functions.js";
import {PageArabiaTeachingAdd} from "../arabia-teaching/add.js";
import {PackagesAddForm} from "../packages/add/form.js";
import {AuthWithUser} from "../../components/auth/auth-with-user.js";
import constants from "../../assets/constants.json";
import {ModelOrders} from "../../model/orders.js";

const PageAdminCart = function () {
    const [state, dispatch] = useStateContext();
    const [modal, setModal] = useState('');
    const [cartType, setCartType] = useState('admin');
    const resultSet = useModelFeedback(ModelProducts);
    const resultSetCrowdfunding = useModelFeedback(ModelCrowdfunding);
    const [user, setUser] = useState(null)
    const [orderDiscounts, setDiscounts] = useState([]);

    const deleteDiscount =(key)=>{
        let newArr = [].concat(orderDiscounts);
        newArr.splice(key,1);
        setDiscounts(newArr);
    };

    if(resultSet.feedback){
        return resultSet.feedback;
    }
    if(resultSetCrowdfunding.feedback){
        return resultSetCrowdfunding.feedback;
    }

    const selectedProduct = resultSet.data.products.find((product)=>{
        if(modal !== ''){
            if(state?.admin_cart[modal].type === 'umrah'){
                return product.product_id === state.admin_cart[modal].guests[0].product_id
            }
            if(state?.admin_cart[modal].type === 'arabia'){
                return product.id === state.admin_cart[modal].id
            }
        }
        return null
    })
    const selectedFund = resultSetCrowdfunding.data.find((crowdfund)=>crowdfund.id === state.admin_cart[modal]?.id);
    const selectedFundValueLeft = parseFloat(selectedFund?.goal_amount) - parseFloat(selectedFund?.orders.reduce((total, order) => {
        return total + order.funding_amount;
    }, 0))

    const onSubmit = (values) => {
        const fund = {
            type: 'fund',
            id: state.admin_cart[modal].id,
            value: values.value
        };

        notification.success({
            message: `${toMoney(values.value)} aan donatie toegoevoegd aan ${selectedFund.label} in je winkelwagen`
        });
        if(cartType === 'admin'){
            if(state.admin_cart[modal]){
                dispatch({type: 'updateAdminCartItem', value: fund, key: modal})
            }else{
                dispatch({type: 'addToAdminCart', value: fund})
            }
        }
        setModal('')
    }

    const onOrder = () => {
        if(!user){
            notification.error({
                message: 'Selecteer een gebruiker'
            })
        }
        if(state.admin_cart.length === 0){
            notification.error({
                message: 'Winkelwagen is leeg'
            })
        }

        if(user !== null && state.admin_cart.length > 0){
            let orderObject = {
                user: user,
                order: {
                    flex: {}
                }
            };
            for (const cartItem of state.admin_cart){
                if(cartItem.type === 'fund'){
                    //Add crowdfunds to order
                    if(!orderObject.order.crowdfunds){
                        orderObject.order.crowdfunds = []
                    }
                    orderObject.order.crowdfunds.push({
                        funding_id: parseInt(cartItem.id),
                        funding_amount: parseInt(cartItem.value)
                    })
                }else if(cartItem.type === 'arabia'){
                    orderObject.order.flex.arabia_availibilities = [];
                    orderObject.order.flex.arabia_availibilities.push({
                        days: cartItem.available_days,
                        times: cartItem.available_times,
                        id: cartItem.id
                    });

                    if(!orderObject.order.products){
                        orderObject.order.products = []
                    }


                    orderObject.order.products.push(parseInt(cartItem.id))

                }else if(cartItem.type === 'umrah'){
                    if(!orderObject.order.products){
                        orderObject.order.products = []
                    }
                    cartItem?.exclusive && orderObject.order.products.push(999);
                    if(!orderObject.order.flex.discounts){
                        orderObject.order.flex.discounts = [];
                    } 

                    // Add product_ids to order
                    for(const guest of cartItem.guests){
                        orderObject.order.products.push(parseInt(guest.product_id));
                        !guest?.european_passport && orderObject.order.products.push(1);
                        if(!guest?.bed_included){
                            orderObject.order.flex.discounts.push('kid-without-bed');
                        }                    
                    }

                    // if(cartItem.exclusive === true && cartItem.guests.filter((guest)=>guest.age_category !== constants.AGE_CATEGORY.BABY).length < cartItem.max_guests ){
                    //     const guestsLeft = cartItem.max_guests - cartItem.guests.filter((guest)=>guest.age_category !== constants.AGE_CATEGORY.BABY).length
                    //     const adultID = cartItem.guests.find((guest)=>guest.age_category === constants.AGE_CATEGORY.ADULT)
                    //     if(guestsLeft > 0 && adultID){
                    //         for (let i = 0; i < guestsLeft; i++){
                    //             orderObject.order.products.push(parseInt(adultID?.product_id))
                    //         }
                    //     }else{
                    //         return notification.error({
                    //             message: "Er moet een volwassen aanwezig zijn."
                    //         })
                    //     }
                    // }

                    if(!orderObject.order.rooms){
                        orderObject.order.rooms = []
                    }

                    // Add rooms to order.
                    orderObject.order.rooms.push({
                        exclusive:  cartItem.exclusive,
                        max_guests: cartItem.max_guests,
                        room_gender: cartItem.room_gender,
                        guests: cartItem.guests
                    })
                }
            }
            let correctDiscounts = orderDiscounts.map((discount)=>(discount.label));

            if(orderObject.order.flex){
                if(orderObject.order.flex.discounts !== null && orderObject.order.flex.discounts !== undefined){
                    orderObject.order.flex.discounts = orderObject.order.flex.discounts.concat(correctDiscounts);
                }else{
                    let newObj= {discounts: correctDiscounts}
                    Object.assign(orderObject.order.flex, newObj);
                }
            }
           
            orderObject.order.flex = JSON.stringify(orderObject.order.flex);
            ModelOrders.createItem(orderObject.order, orderObject.user, (order)=>{
                notification.success({
                    message: `Bestelling succesvol aangemaakt. voor gebruiker #${order.order.user_id} met bestelling nummer #${order.order.id}`
                });
                dispatch({type: 'clearAdminCart'})
            }, ()=>{
                notification.error({
                    message: "Er is iets misgegaan tijdens het verwerken van je bestelling."
                })
            })
        }

    };

    return <Teared className={'shopping-cart-container'}>
        <UIText variant={'title'}>
            Admin Winkelwagen
        </UIText>
        <div className={'shopping-cart-layout'}>
            <div className={'shopping-cart-table'}>
                <PageCartDetails  discounts={orderDiscounts} setDiscounts={setDiscounts} deleteDiscount={deleteDiscount} cart={state.admin_cart} onEdit={(item, key)=>{setModal(key)}} onDelete={(item, key)=>{dispatch({type: 'removeAdminCartItem', value: key})}}/>
            </div>

            <div  className={'shopping-cart-checkout-container'}>
                <div className={'shopping-cart-checkout'}>

                    <ModelConsumer model={ModelUsers}>
                        {(resultSet)=>{
                            return <Select

                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Selecteer persoon"
                                optionFilterProp="children"
                                onChange={(value)=>{setUser(value)}}
                                filterOption={(input, option) =>{
                                   return  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                            >
                                {resultSet.data.users.map((user, key)=>{
                                    return <Select.Option key={key} value={user.user_id}>{user.email}</Select.Option>
                                })}
                            </Select>
                        }}
                    </ModelConsumer>
                    {(state.admin_cart.length > 0) && <UIButton onClick={()=>{onOrder()}} className={'full-width'}>
                        Bestellen
                    </UIButton>
                    }
                </div>
            </div>
            {(modal !== '' && state.admin_cart[modal]?.type === 'arabia') &&
            <PageArabiaTeachingAdd modal={state.admin_cart[modal]?.id} selectedProduct={selectedProduct} initialValues={state.admin_cart[modal]} setModal={setModal} cartKey={modal}/>
            }
            {modal !== '' && state.admin_cart[modal]?.type === 'umrah' &&
            <Modal onCancel={()=>setModal('')}
                   maskClosable={false}
                   title={selectedProduct.label}
                   visible={modal !== ''&& state.admin_cart[modal]?.type === 'umrah'}
                   className={'modal'}
                   footer={false}>
                <PackagesAddForm onSubmit={()=>setModal('')} initialValues={state.admin_cart[modal]} item={selectedProduct} cartKey={modal}/>
                <div className={'modal-footer'}>
                    <div className={'modal'}><div className={'modal-footer'}/></div>
                </div>
            </Modal>
            }
            {(modal !== '' && state.admin_cart[modal]?.type === 'fund') &&
            <Modal onCancel={()=>setModal('')} title={`Doneer voor '${selectedFund?.label}'`} maskClosable={false} footer={false} className={'modal'} visible={state.admin_cart[modal]?.type === 'fund'}>
                <UIText>Er is nog {toMoney(selectedFundValueLeft)} nodig om dit te behalen!</UIText>
                <Form layout={'vertical'} onFinish={onSubmit} initialValues={state.admin_cart[modal] ? state.admin_cart[modal] : {}}>
                    <Form.Item name="value"
                               label="Bedrag"
                               rules={[
                                   { required: true, message: "Bedrag is verplicht!" },
                                   {type: 'number',min: 0, message: "Bedrag moet boven 0 zijn"},
                                   ({ getFieldValue }) => ({
                                       validator(rule, value) {
                                           if (!value || getFieldValue('value') <=selectedFundValueLeft) {
                                               return Promise.resolve();
                                           }
                                           return Promise.reject(`Bedrag moet lager zijn dan ${toMoney(selectedFundValueLeft)}`);
                                       },
                                   }),]}>
                        <InputNumber style={{width: '100%'}} min={0}
                        />
                    </Form.Item>
                    <AuthWithUser enableFeedback={false}>
                        {(user)=>{
                            if(user && user.role === constants.USER_ROLE.ADMIN){
                                return <Button type="primary" htmlType="submit" name={'2'}  onClick={()=>{setCartType('admin')}} className="contact-form-button">
                                    Updaten
                                </Button>
                            }
                            return null
                        }}
                    </AuthWithUser>
                </Form>
                <div className={'modal-footer'}>
                    <div className={'modal'}><div className={'modal-footer'} /></div>
                </div>
            </Modal>
            }
        </div>
    </Teared>
};



export {PageAdminCart}
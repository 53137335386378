import './articles.scss';
import React, {useState} from "react";
import {UIButton} from "../../../components/ui/button/button.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelArticles} from "../../../model/articles.js";
import {PageAdminArticlesNewFlyer} from "./new-flyer.js";
import {PageAdminArticlesNewKennis} from "./new-kennis.js";
import {PageAdminArticlesNewCategory} from "./new-category.js";
import {ModelArticleCategories} from "../../../model/article-categories.js";
import {AdminArticlesCategoriesList} from "./categories-list.js";
import {AdminArticlesList} from "./articles-lists.js";

const PageAdminArticles = function (props) {
    const [newArticle, setNewArticle] = useState('');
    const [initialValues, setInitialValues] = useState(null);
    const resultSet = useModelFeedback(ModelArticles);
    const resultSetCategories = useModelFeedback(ModelArticleCategories);

    if(resultSet.feedback ){
        return resultSet.feedback
    }
    if(resultSetCategories.feedback){
        return resultSetCategories.feedback
    }

    return <div>
        <div className={'article-buttons'}>
            <UIButton className={'article-button'} onClick={()=>setNewArticle('flyer')}>Voeg flyer toe</UIButton>
            <UIButton className={'article-button'} onClick={()=>setNewArticle('kennis')}>Voeg kennis toe</UIButton>
            <UIButton className={'article-button'} onClick={()=>setNewArticle('category')}>Voeg kennis categorie toe</UIButton>
        </div>
        <AdminArticlesCategoriesList setNewArticle={setNewArticle} setInitialValues={setInitialValues} />
        <AdminArticlesList setNewArticle={setNewArticle} setInitialValues={setInitialValues} />


        {(newArticle === 'flyer') &&
        <PageAdminArticlesNewFlyer
            visible={(newArticle === 'flyer')}
            setVisible={()=>setNewArticle('')}
            setInitialValues={setInitialValues}
            initialValues={initialValues}/>
        }
        {(newArticle === 'kennis') &&
        <PageAdminArticlesNewKennis
            categories={resultSetCategories.data.articleCategories}
            visible={(newArticle === 'kennis')}
            setVisible={()=>setNewArticle('')}
            setInitialValues={setInitialValues}
            initialValues={initialValues}/>
        }
        {(newArticle === 'category') &&
        <PageAdminArticlesNewCategory
            visible={(newArticle === 'category')}
            setInitialValues={setInitialValues}
            setVisible={()=>setNewArticle('')}
        initialValues={initialValues}/>}
    </div>
};

export {PageAdminArticles}
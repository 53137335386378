import React from "react";
import { getGenderById } from "../../assets/constants.js";
import moment from "moment";
import nationalities from "../../assets/nationalities.json";
import TurkBanner from '../../assets/images/turkbg.png';
const RenderApiList = function (props) {
  const visums = props?.visums;
  return (
    <table id="apiListTurkey" data-cols-width="4,35,18,20,35,20,25,25">
      <tr data-height="60">
        <td
          data-f-bold={true}
          style={{
            border: "1px solid black",
            fontWeight: 700,
          }}
          colspan="8"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
        >
          <img src={TurkBanner}></img>
        </td>
      </tr>
      <tr data-height="30">
        <td
          data-f-bold={true}
          style={{
            border: "1px solid black",
            fontWeight: 700,
          }}
          colspan="1"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
        >
          Pnr :
        </td>
        <td
          data-f-bold={true}
          style={{
            border: "1px solid black",
            fontWeight: 700,
          }}
          colspan="1"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
        ></td>
        <td
          data-f-bold={true}
          style={{
            border: "1px solid black",
            fontWeight: 700,
          }}
          colspan="3"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
        >
          Agency name: MAMLAQATAYN B.V
        </td>
        <td
          data-f-bold={true}
          style={{
            border: "1px solid black",
            fontWeight: 700,
          }}
          colspan="3"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
        ></td>
      </tr>
      <tr data-height="30">
        <td
          data-f-bold={true}
          data-fill-color="fff2f2f2"
          style={{
            border: "1px solid black",
            backgroundColor: "#f2f2f2",
            fontWeight: 700,
          }}
          colspan="8"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
        >
          Please provide us with at least two Saudi telephone numbers of the
          group guides present.
        </td>
      </tr>
      <tr data-height="25">
        <td
          style={{ border: "1px solid black" }}
          colspan="3"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
        >
          Name : Monir Elsayed 00 966 508782887
        </td>
        <td
          colspan="1"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
        ></td>
        <td
          style={{ border: "1px solid black" }}
          colspan="4"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
        >
          Name : …………………………. 00 966 …………………………………….
        </td>
      </tr>
      <tr>
        <td
          data-f-bold={true}
          data-fill-color="ffdbe5f2"
          data-f-color="ff000000"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#dbe5f2",
            color: "black",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          Nr.
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ffdbe5f2"
          data-f-color="ff000000"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#dbe5f2",
            color: "black",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          Date of birth<br></br>
          {"( 01.01.2022 )"}
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ffdbe5f2"
          data-f-color="ff000000"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#dbe5f2",
            color: "black",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          Title<br></br>
          {"( M  male )"}
          <br></br>
          {"( F  female )"}
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ffdbe5f2"
          data-f-color="ff000000"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#dbe5f2",
            color: "black",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          Surname
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ffdbe5f2"
          data-f-color="ff000000"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#dbe5f2",
            color: "black",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          First name
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ffdbe5f2"
          data-f-color="ff000000"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#dbe5f2",
            color: "black",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          Title<br></br>
          {"( MR , MRS , YTH , "}
          <br></br>
          {"MISS , MSTR , INFT )"}
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ffdbe5f2"
          data-f-color="ff000000"
          data-b-a-s="thin"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#dbe5f2",
            color: "black",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          Nationality
        </td>
        <td
          data-f-bold={true}
          data-fill-color="ffdbe5f2"
          data-f-color="ff000000"
          data-a-h="center"
          data-a-v="center"
          data-f-sz="12"
          style={{
            backgroundColor: "#dbe5f2",
            color: "black",
            fontWeight: 700,
            border: "1px solid black",
            padding: "5px",
          }}
        >
          Passport number
        </td>
      </tr>
      {visums?.map((visum, key) => (
        <tr>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{
              backgroundColor: "#dbe5f2",
              color: "black",
              fontWeight: 700,
              border: "1px solid black",
              padding: "5px",
            }}
          >
            {key + 1}
          </td>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {moment(visum.date_of_birth).format("DD-MM-YYYY")}
          </td>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {getGenderById(visum.gender).short_en}
          </td>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {visum.last_name}
          </td>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {visum.first_name}
          </td>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {""}
          </td>
          <td
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {visum.nationality}
          </td>
          <td
            data-b-a-s="thin"
            data-a-h="center"
            data-a-v="center"
            data-f-sz="12"
            style={{ border: "1px solid black", padding: "5px" }}
          >
            {visum.passport_number.toUpperCase()}
          </td>
        </tr>
      ))}
    </table>
  );
};

export default RenderApiList;

import React from "react";
import {List} from "antd";
import {UIText} from "../../components/ui/text/text.js";
import {useHistory} from "react-router-dom";
import constants from '../../assets/constants.json'
import {AuthWithUser} from "../../components/auth/auth-with-user.js";
import {v4} from 'uuid'
import {AuthContext} from "../../components/auth/auth-provider.js";

const HeaderAccountMenu = function (props) {
    const {setUser} = React.useContext(AuthContext)
    const history = useHistory();

    return <AuthWithUser>
        {(user)=>{
            const data = [
                {
                    label: 'Account',
                    onClick: ()=>{
                        return history.push('/account')
                    }
                },
                ...((user && [constants.USER_ROLE.ADMIN].includes(user.role)) ?
                    [{
                        label: 'Admin',
                        onClick: ()=>{
                            return history.push('/admin')
                        }
                    }] :
                    []),
                {
                    label: 'Uitloggen',
                    onClick: ()=>{
                        localStorage.removeItem('tokenKey')
                       setUser(v4())
                    }
                },
            ];
            return <List
                dataSource={data}
                renderItem={item => (
                    item.label ?
                        <List.Item onClick={item.onClick ? item.onClick : ()=>{}} className={'header-menu-clickable'}>
                            <UIText >{item.label}</UIText>
                        </List.Item> :
                        null
                )}
            />
        }}
    </AuthWithUser>
};



export {HeaderAccountMenu}
import './main.scss';
import React, {useEffect} from "react";
import {BackTop} from "antd";
import {ArrowUpOutlined} from "@ant-design/icons";
import {scrollTo} from "../helpers/scroll_to.js";
import {withRouter} from "react-router-dom";
import {WidgetWhatsapp} from "../components/widget/whatsapp.js";
import CookieConsent from "react-cookie-consent";

const ViewMainComponent = function (props) {
    const {location} = props;
    useEffect(()=>{
        // // scroll to top when url changes
        const rootElement = document.getElementById('fcu-root');
        if (rootElement) {
            scrollTo(rootElement, true);
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    }, [location])

    return <div style={{marginTop: 60, minHeight: 'calc(100vh - 30rem)'}} id={'fcu-root'}>
        <BackTop>
            <ArrowUpOutlined className={'back-to-top'}/>
        </BackTop>
        {props.children}
        <div className={'whatsapp-widget'}>
            <WidgetWhatsapp/>
        </div>
        <CookieConsent buttonText={'Accepteren'} flipButtons={true} declineButtonText={'Weigeren'} location={'bottom'} overlay={true} enableDeclineButton={true}>
            Door deze website te gebruiken aanvaardt u het gebruik van cookies in overeenstemming met deze
            mededeling over cookies.
        </CookieConsent>
    </div>
};

const ViewMain = withRouter(ViewMainComponent)

export {ViewMain}
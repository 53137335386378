import React, {useRef} from "react";
import {ArabiaTeachingBanner} from "./banner.js";
import {ArabiaTeachingAbout} from "./about.js";
import {ArabiaTeachingProducts} from "./products.js";
import LazyLoad from "react-lazyload";
import {Link} from "react-router-dom";

const PageArabiaTeaching = function () {
    const myRef = useRef(null)
    const executeScroll = () => (setTimeout((document.location.href='https://arabiateaching.nl',true),250));


    return <div>
       <ArabiaTeachingBanner onClick={executeScroll}/>
       <ArabiaTeachingAbout/>

    </div>
};

export {PageArabiaTeaching}
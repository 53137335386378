import React, { useState } from "react";
import { Modal, Form, Button, InputNumber, notification, Select } from "antd";
import { paymentTypes } from "../../../assets/constants.js";
import constants from "../../../assets/constants.json";
import { ModelPayment } from "../../../model/payment.js";

const AdminPaymentNew = function (props) {
  const { initialValues, order_id } = props;
  const [paymentType, setPaymentType] = useState(null);

  const onSubmit = (values) => {
    console.log(values);
    const body = {
      order_id: order_id,
      payment_type: values.payment_type,
      amount: values.amount,
    };

    if (
      body.payment_type === constants.PAYMENT_TYPE.MOLLIE_PAID ||
      body.payment_type === constants.PAYMENT_TYPE.PIN
    ) {
      return ModelPayment.createItem(
        body.order_id,
        {
          trans:
            values.payment_type === constants.PAYMENT_TYPE.PIN
              ? "pin"
              : "other",
          ...body,
        },
        (payment) => {
          if (payment.transaction.paymentURL) {
            return (window.location = payment.transaction.paymentURL);
          }
        }
      );
    }

    if (initialValues) {
      ModelPayment.updatePayment(initialValues.payment_id, body, () => {
        notification.success({
          message: "Betaling is succesvol gewijzigd.",
        });
        props.setInitialValues(null);
        props.setVisible();
      });
    } else {
      ModelPayment.createPayment(body, () => {
        notification.success({
          message: "Betaling is succesvol aangemaakt.",
        });
        props.setVisible();
      });
    }
  };

  return (
    <Modal
      visible={props.order_id}
      maskClosable={false}
      title={
        initialValues
          ? `Update betaling van bestelling #${order_id}`
          : `Voeg betaling toe voor bestelling #${order_id}`
      }
      footer={false}
      onCancel={() => {
        if (props.setInitialValues) {
          props.setInitialValues(null);
        }
        props.setVisible();
      }}
    >
      <Form
        layout={"vertical"}
        onFinish={onSubmit}
        onValuesChange={(value) => {
          if (value.payment_type !== undefined) {
            setPaymentType(value.payment_type);
          }
        }}
        initialValues={initialValues ? initialValues : {}}
      >
        <Form.Item
          name="payment_type"
          label="Betalingmethode"
          rules={[
            { required: true, message: "Betalingsmethode is verplicht!" },
          ]}
        >
          <Select>
            {paymentTypes.map((payment, key) => (
              <Select.Option key={key} value={payment.id}>
                {payment.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="amount"
          label="Bedrag"
          hidden={paymentType === constants.PAYMENT_TYPE.MOLLIE_PAID}
          rules={
            paymentType === constants.PAYMENT_TYPE.MOLLIE_PAID
              ? []
              : [
                  { required: true, message: "Bedrag is verplicht!" },
                  {
                    type: "number",
                    min: 0,
                    message: "Bedrag moet boven 0 zijn",
                  },
                ]
          }
        >
          <InputNumber style={{ width: "100%" }} min={0} />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="contact-form-button"
        >
          {initialValues ? "Updaten" : "Betaling toevoegen"}
        </Button>
      </Form>
    </Modal>
  );
};

export { AdminPaymentNew };

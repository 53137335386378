const navMapping = [
    {
        path: '/',
        label: 'Home',
    },
    {
        path: '/pakketten',
        label: 'Pakketten',
        state: {
            modal: true
        }
    },
    {
        path: '/kennis',
        label: 'Kennis',
    },
    {
        path: '/arabia-teaching',
        label: 'Arabia teaching',
    },
    {
        path: '/over-ons',
        label: 'Over ons',
    },
    {
        path: '/contact',
        label: 'Contact',
    }
];

export {navMapping}
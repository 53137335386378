import {config} from '../config.js';
import {ModelXhr} from "../helpers/model/xhr.js";
import {notification} from "antd";

class ModelPayment extends ModelXhr {
    static modelName = "ModelPayment";

    static createItem(orderID,data, onSuccess=()=>{}){
        this.saveData(ModelPayment, config.api_endpoint + 'payment/' + orderID, 'POST',
            data,
            (response) => {
                if(response){
                    onSuccess(response);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            },
            {
                contentType: 'json'
            }
        );
    }

    static deleteItem(id, onSuccess=()=>{}){
        this.saveData(ModelPayment, config.api_endpoint + 'payment/' + id, 'DELETE',
            null,
            (response) => {
                if(response){
                    onSuccess(response);
                }else{
                    notification.error({
                        message: response.error || "Onbekende fout"
                    })
                }
            }
        );
    }

    static updatePayment(id, data, onSuccess=()=>{}){
        this.saveData(ModelPayment, config.api_endpoint + 'payment/' + id, 'PUT',
            data,
            (response) => {
                if(response){
                    onSuccess(response);
                }else{
                    // todo: post status to global message channel
                    notification.error({
                        message: response.error || "Onbekende fout"
                    })
                }
            },
        );
    }

    static createPayment(payment, onSuccess=()=>{}){
        this.saveData(ModelPayment, config.api_endpoint + 'payment', 'POST',
            payment,
            (response) => {
                if(response){
                    onSuccess(response);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            },
            {
                contentType: 'json'
            }
        );
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'payments',
            method: 'GET',
            isAuthorized: true
        });

        this.cacheEnabled = true;
    }

    getDataFromResponse(data){
        try{
            const response = JSON.parse(data);
            if(! response){
                let error = 'The server returned an unknown error';
                this.onFailure(error);
                return null;
            }
            return response.payments;
        }catch(e){
            if(this.request && this.request.aborted){
                console.warn('Cannot process request, probably because it was aborted. Exception: ' + e.message);
                this.status = 'waiting';
            }else{
                this.onFailure(e);
            }
        }

        return null;
    }

}

export {ModelPayment};

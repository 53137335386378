import React from "react";
import { UIText } from "../../../components/ui/text/text.js";
import { Collapse, notification, Tag } from "antd";
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import constants from "../../../assets/constants.json";
import { toMoney } from "../../../helpers/functions.js";
import { UIDescriptions } from "../../../components/ui/descriptions.js";
import { ModelVouchers } from "../../../model/vouchers.js";

const AdminVouchersList = function (props) {
  const resultSet = useModelFeedback(ModelVouchers);

  if (resultSet.feedback) {
    return resultSet.feedback;
  }

  const genExtra = (voucher) =>
    voucher.label !== "kid-without-bed" && (
      <>
        <CloseOutlined
          onClick={() => {
            ModelVouchers.deleteItem(voucher.id, () => {
              notification.success({
                message: "Artikel succesvol verwijderd.",
              });
            });
          }}
        />
      </>
    );

  const renderVoucher = (voucher) => {
    return {
      labels: {
        label: "Kortingscode",
        amount: "Bedrag",
        expire_date: "Geldig tot:",
      },
      renderLabelFunctions: {
        label: (value) => value,
        amount: (value) => toMoney(value / 100),
        expire_date: (value) => value,
      },
      item: voucher,
    };
  };

  return (
    <div>
      <UIText variant={"paragraph-title"}>Vouchers management</UIText>
      <Collapse expandIconPosition={"start"}>
        <Collapse.Panel
          disabled={true}
          header={
            <span>
              <Tag>ID</Tag>
              <Tag>Type</Tag>Naam
            </span>
          }
          key={10000}
          extra={<span>Acties</span>}
        ></Collapse.Panel>
        {resultSet.data.vouchers?.length > 0 ? (
          resultSet.data.vouchers?.map((voucher, key) => {
            const header = (
              <span>
                <Tag color={"orange"} style={{ minWidth: 25 }}>
                  {voucher.id}
                </Tag>
                <Tag color={voucher?.active ? "orange" : "grey"}>
                  {voucher?.active ? "Actief" : "Inactief"}
                </Tag>
                {voucher.label}
              </span>
            );

            return (
              <Collapse.Panel
                header={header}
                key={key}
                extra={genExtra(voucher)}
              >
                <UIDescriptions {...renderVoucher(voucher)} />
              </Collapse.Panel>
            );
          })
        ) : (
          <Collapse.Panel
            disabled={true}
            showArrow={false}
            header={"Er zijn geen artikelen"}
            key={10001}
          ></Collapse.Panel>
        )}
      </Collapse>
    </div>
  );
};

export default AdminVouchersList;

import React, { useState, useCallback, useEffect } from "react";
import { Input, Modal, Button, Select } from "antd";
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import { SearchOutlined, WarningOutlined } from "@ant-design/icons";
import { AdminPaymentNew } from "../payments/new.js";
import moment from "moment";
import JSZip from "jszip";
import { blobToURL, fromBlob } from "image-resize-compress";

import { ModelOrders } from "../../../model/orders.js";
import { ModelProductsLimited } from "../../../model/products_limited.js";
//bg images.
import januari from "../../../assets/images/months/januari.png";
import februari from "../../../assets/images/months/februari.png";
import maart from "../../../assets/images/months/maart.png";
import april from "../../../assets/images/months/april.png";
import mei from "../../../assets/images/months/mei.png";
import juni from "../../../assets/images/months/juni.png";
import juli from "../../../assets/images/months/juli.png";
import augustus from "../../../assets/images/months/augustus.png";
import september from "../../../assets/images/months/september.png";
import oktober from "../../../assets/images/months/oktober.png";
import november from "../../../assets/images/months/november.png";
import december from "../../../assets/images/months/december.png";
import ramadan from "../../../assets/images/months/ramadan.png";
import "./orders_second.scss";

import { ModelVouchers } from "../../../model/vouchers.js";
import constants from "../../../assets/constants.json";
import { PageAdminOrdersSecondCat } from "./category.js";

const AdminOrdersListSecond = function (props) {
  const [value, setValue] = useState(null);
  const [optionSearch, setOptionSearch] = useState(null);
  const resetSelection = () => setValue(null);
  const [orderToCancel, setOrderToCancel] = useState(null);
  const [orderToUpdate, setOrderToUpdate] = useState(null);
  const [ordersToFilterProducts, setProductsToFilter] = useState([9999]);

  const [orderToDownload, setOrderToDownload] = useState(null);
  const [allVisums, setVisums] = useState(null);

  const [modalState, setModal] = useState(null);

  const productsResultSet = useModelFeedback(ModelProductsLimited);

  const productsToRender = value
    ? productsResultSet.data
        .filter((item) => item.start === value.start)
        .map((item) => item.product_id)
    : [];

  // useEffect(()=>{
  //   setProductsToFilter(productsToRender);
  // },[ordersToFilterProducts])

  const resultSet = useModelFeedback(ModelOrders, {
    products: value
      ? productsResultSet.data
          .filter((item) => item.start === value.start)
          .map((item) => item.product_id).join(",")
      : [9999],
  });

  const modelResultSet = useModelFeedback(ModelVouchers);

  const accountsToFilter = [
    // "zayd020@gmail.com",
    "klantenservice@digistakk.nl",
    "haitamelm@gmail.com",
  ];
  const monthImages = [
    januari,
    februari,
    maart,
    april,
    mei,
    juni,
    juli,
    augustus,
    september,
    oktober,
    november,
    december,
    ramadan,
  ];

  const allowedStatuses = [
    constants.ORDER_STATUS.COMPLETED,
    constants.ORDER_STATUS.DOWN_PAYMENT,
  ];
  if (modelResultSet.feedback) {
    return resultSet.feedback;
  }
  if (resultSet.feedback) {
    return resultSet.feedback;
  }
  if (productsResultSet.feedback) {
    return productsResultSet.feedback;
  }

  const handleChange = (item) => setValue(item);
  const handleInputChange = (evt) =>
    setOptionSearch(evt.target.value.toLowerCase());

  const ordersToRender = resultSet.data
    ? resultSet.data
        .filter(
          (order) =>
            order.products.filter(
              (item) => productsToRender.indexOf(item.product_id) !== -1
            ).length > 0
        )
        .filter((order) => accountsToFilter.indexOf(order.user.email) === -1)
        .filter((order) => allowedStatuses.indexOf(order.status) !== -1)
    : [];

  const optionsToShow = productsResultSet.data
    .filter((product) => product.product_id !== 1)
    .filter(
      (product) => product.type === constants.PRODUCT_TYPE.UMRAH_2PERS_ADULT
    )
    .filter((product) =>
      optionSearch
        ? `${product.label}  ${moment(product).year()}`
            .toLowerCase()
            .indexOf(optionSearch) > -1
        : true
    )
    .sort(function (a, b) {
      return new Date(a.start) - new Date(b.start);
    });

  const updateOrder = (order) => {
    ModelOrders.editOrderFlex(orderToUpdate, (response) => {
      setOrderToUpdate(null);
    });
  };

  const handleDiscountChange = (discounts) => {
    const order = orderToUpdate;
    const orderFlex = order.flex ? JSON.parse(order.flex) : order.flex || {};
    const newObj = { ...order, flex: orderFlex };
    newObj.flex.discounts = discounts;
    newObj.flex = JSON.stringify(newObj.flex);
    setOrderToUpdate(newObj);
  };

  const handleDownloadTypeChange = (type) => {
    const order = orderToDownload;
    const newObj = { ...order, downloadType: type };
    setOrderToDownload(newObj);
  };

  const cancelOrder = (order_id) => {
    ModelOrders.cancelOrder(order_id, (response) => {
      console.log(response);
    });
  };

  const handleOk = () => {
    if (orderToCancel) {
      // console.log(orderToCancel)
      cancelOrder(orderToCancel.id);
    }
  };

  const handleCancel = () => {
    setOrderToCancel(null);
  };
  const handleDownload = () => {
    switch (true) {
      case orderToDownload.downloadType === 0:
        downloadPDF(orderToDownload.id);
        break;
      case orderToDownload.downloadType === 1:
        downloadDownPayment(orderToDownload.id);
        break;
      case orderToDownload.downloadType === 2:
        downloadCreditPDF(orderToDownload.id);
        break;
      case orderToDownload.downloadType === 3:
        downloadCreditPDF(orderToDownload.id);
        break;
      case orderToDownload.downloadType === 4:
        break;
    }
    useCallback(() => {
      setOrderToDownload(null);
    }, [orderToDownload]);
  };

  const downloadPDF = (order_id) => {
    ModelOrders.orderInvoice(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `factuur-FCU1${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const downloadDownPayment = (order_id) => {
    ModelOrders.orderDownpayment(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `aanbetaling-FCU1${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const downloadCreditPDF = (order_id) => {
    ModelOrders.orderInvoiceCredit(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `creditfactuur-FCU1${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const downloadCreditDownPayment = (order_id) => {
    ModelOrders.orderDownpaymentCredit(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `creditnota-aanbetaling-FCU1${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const orderFlex = orderToUpdate?.flex
    ? JSON.parse(orderToUpdate?.flex)
    : undefined || {};
  const orderDiscounts = orderFlex?.discounts || [];
  const vouchers = modelResultSet?.data?.vouchers || [];

  return (
    <div className="adminOrderss">
      {productsResultSet.data ? (
        <>
          {!value && (
            <>
              {" "}
              <div className="inputCont">
                <div className="searchCont">
                  <Input
                    addonBefore={<SearchOutlined />}
                    onChange={handleInputChange}
                    size={"large"}
                    placeholder="Zoeken op naam"
                  />
                </div>
              </div>
              <div className="optionsCont">
                {optionsToShow
                  .filter(
                    (product, key) =>
                      key ===
                      optionsToShow.findIndex(
                        (elem) => elem.start === product.start
                      )
                  )
                  .map((option, key) => (
                    <div
                      className={"optionCont"}
                      style={{
                        backgroundImage: "url($background)".replace(
                          "$background",
                          monthImages[moment(option.end).month()]
                        ),
                      }}
                      onClick={() => handleChange(option)}
                      key={"option" + key}
                    >
                      <p className="label">
                        {option.label + " " + moment(option.start).year()}
                      </p>
                      <p className="check">Bekijk reisoverzicht</p>
                    </div>
                  ))}
              </div>
            </>
          )}

          {value && (
            <PageAdminOrdersSecondCat
              orders={ordersToRender}
              discounts={modelResultSet.data?.vouchers}
              title={value.label}
              resetSelection={resetSelection}
              setOrderToCancel={setOrderToCancel}
              setOrderToUpdate={(order) => setOrderToUpdate(order)}
              setOrderToDownload={(order) =>
                setOrderToDownload({ id: order.id, downloadType: 0 })
              }
              setProductsToFilter={setProductsToFilter}
              setModal={(order) => setModal(order.id)}
            />
          )}
          <Modal
            title="Order annuleren"
            open={orderToCancel !== null}
            onOk={handleOk}
            onCancel={handleCancel}
            className="cancelWarningModal"
            footer={
              <>
                <Button type={"primary"} danger onClick={() => handleCancel()}>
                  Annuleren
                </Button>
                <Button type={"primary"} onClick={() => handleOk()}>
                  Bevestigen
                </Button>
              </>
            }
          >
            <div className="cancelWarningCont">
              <WarningOutlined className="cancelWarningIcon" />
              <h1>Weet je zeker dat je de order wilt annuleren?</h1>
              <p className="description">
                Bij het bevestigen van deze actie zal er een annuleringsmail
                worden gestuurd naar de klant met daarbij een creditnota (indien
                er een betaling is gekoppeld aan de bestelling).
              </p>
            </div>
          </Modal>
          <Modal
            title="Kortingen bijwerken"
            open={orderToUpdate !== null}
            onOk={handleOk}
            onCancel={() => setOrderToUpdate(null)}
            className="editDiscountsModal"
            footer={
              <>
                <Button
                  type={"primary"}
                  danger
                  onClick={() => setOrderToUpdate(null)}
                >
                  Annuleren
                </Button>
                <Button type={"primary"} onClick={() => updateOrder()}>
                  Bevestigen
                </Button>
              </>
            }
          >
            <div className="cancelWarningCont">
              <h1>Bestelling #FCU1{orderToUpdate?.id}</h1>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Selecteer kortingen"
                defaultValue={orderDiscounts}
                value={orderDiscounts}
                onChange={handleDiscountChange}
                options={vouchers?.map((voucher) => ({
                  title: voucher.label,
                  label: voucher.label,
                  value: voucher.label,
                }))}
              />{" "}
            </div>
          </Modal>
          <Modal
            title="Documenten downloaden"
            open={orderToDownload !== null}
            onCancel={() => setOrderToDownload(null)}
            className="editDiscountsModal"
            footer={
              <>
                <Button
                  type={"primary"}
                  danger
                  onClick={() => setOrderToDownload(null)}
                >
                  Annuleren
                </Button>
                <Button type={"primary"} onClick={() => handleDownload()}>
                  Bevestigen
                </Button>
              </>
            }
          >
            <div className="cancelWarningCont">
              <h1>Bestelling #FCU1{orderToDownload?.id}</h1>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder="Selecteer document om te downloaden"
                value={orderToDownload?.downloadType}
                onChange={handleDownloadTypeChange}
                options={[
                  {
                    title: "Download factuur",
                    label: "Download factuur",
                    value: 0,
                  },
                  {
                    title: "Download aanbetalingsbewijs",
                    label: "Download aanbetalingsbewijs",
                    value: 1,
                  },
                  {
                    title: "Download creditfactuur",
                    label: "Download creditfactuur",
                    value: 2,
                  },
                  {
                    title: "Download creditnota aanbetaling",
                    label: "Download creditnota aanbetaling",
                    value: 3,
                  },
                  {
                    title: "Download fotos gasten bestelling",
                    label: "Download fotos gasten bestelling",
                    value: 4,
                  },
                ]}
              />{" "}
            </div>
          </Modal>
          {modalState !== null && (
            <AdminPaymentNew
              order_id={modalState}
              setVisible={() => {
                setModal(null);
              }}
            />
          )}
        </>
      ) : (
        // <Segmented
        //   value={value}
        //   defaultValue={optionsToShow[0]}
        //   onChange={handleChange}
        //   options={optionsToShow.map((product, key) => ({
        //     label: product.label,
        //     value: product,
        //     key: "product" + key,
        //   }))}
        // />
        "Laden..."
      )}
    </div>
  );
};

export { AdminOrdersListSecond };

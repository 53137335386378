import { config } from "../config.js";
import { ModelXhr } from "../helpers/model/xhr.js";

class ModelOrders extends ModelXhr {
  static modelName = "ModelOrders";

  static getSelect(input) {
    return {
      ...input,
    };
  }

  static createItem(body, user_id, onSuccess = () => {}, onFailure = () => {}) {
    let endpoint = config.api_endpoint + "order";
    if (user_id) {
      endpoint = endpoint + "/" + user_id;
    }
    this.saveData(
      ModelOrders,
      endpoint,
      "POST",
      body,
      (response) => {
        if (!response || response.status === "error") {
          onFailure(response.error);
          return;
        }
        if (response) {
          onSuccess(response);
          return;
        }
        onFailure("An unknown error occured.");
      },
      {
        contentType: "json",
      }
    );
  }

  static sendDocumentsRemind(body, onSuccess = () => {}, onFailure = () => {}) {
    let endpoint = config.api_endpoint + "order/remind/documents";
    this.saveData(
      ModelOrders,
      endpoint,
      "POST",
      body,
      (response) => {
        if (!response || response.status === "error") {
          onFailure(response.error);
          return;
        }
        if (response) {
          onSuccess(response);
          return;
        }
        onFailure("An unknown error occured.");
      },
      {
        contentType: "json",
      }
    );
  }

  static sendDocumentsFaulty(body, onSuccess = () => {}, onFailure = () => {}) {
    let endpoint = config.api_endpoint + "order/remind/documents/faulty";
    this.saveData(
      ModelOrders,
      endpoint,
      "POST",
      body,
      (response) => {
        if (!response || response.status === "error") {
          onFailure(response.error);
          return;
        }
        if (response) {
          onSuccess(response);
          return;
        }
        onFailure("An unknown error occured.");
      },
      {
        contentType: "json",
      }
    );
  }

  static sendPaymentsToRemind(
    body,
    onSuccess = () => {},
    onFailure = () => {}
  ) {
    let endpoint = config.api_endpoint + "order/remind/payments";
    this.saveData(
      ModelOrders,
      endpoint,
      "POST",
      body,
      (response) => {
        if (!response || response.status === "error") {
          onFailure(response.error);
          return;
        }
        if (response) {
          onSuccess(response);
          return;
        }
        onFailure("An unknown error occured.");
      },
      {
        contentType: "json",
      }
    );
  }

  static orderInvoice(order_id, onSuccess = () => {}, onFailure = () => {}) {
    let endpoint = config.api_endpoint + "order/invoice/" + order_id;
    this.saveData(
      ModelOrders,
      endpoint,
      "POST",
      null,
      (response) => {
        if (!response || response.status === "error") {
          onFailure(response.error);
          return;
        }
        if (response) {
          onSuccess(response);
          return;
        }
        onFailure("An unknown error occured.");
      },
      {
        contentType: "params",
      }
    );
  }

  static orderDownpayment(
    order_id,
    onSuccess = () => {},
    onFailure = () => {}
  ) {
    let endpoint = config.api_endpoint + "order/down_payment/" + order_id;
    this.saveData(
      ModelOrders,
      endpoint,
      "POST",
      null,
      (response) => {
        if (!response || response.status === "error") {
          onFailure(response.error);
          return;
        }
        if (response) {
          onSuccess(response);
          return;
        }
        onFailure("An unknown error occured.");
      },
      {
        contentType: "params",
      }
    );
  }

  static orderInvoiceCredit(
    order_id,
    onSuccess = () => {},
    onFailure = () => {}
  ) {
    let endpoint = config.api_endpoint + "order/invoice_credit/" + order_id;
    this.saveData(
      ModelOrders,
      endpoint,
      "POST",
      null,
      (response) => {
        if (!response || response.status === "error") {
          onFailure(response.error);
          return;
        }
        if (response) {
          onSuccess(response);
          return;
        }
        onFailure("An unknown error occured.");
      },
      {
        contentType: "params",
      }
    );
  }

  static orderDownpaymentCredit(
    order_id,
    onSuccess = () => {},
    onFailure = () => {}
  ) {
    let endpoint =
      config.api_endpoint + "order/down_payment_credit/" + order_id;
    this.saveData(
      ModelOrders,
      endpoint,
      "POST",
      null,
      (response) => {
        if (!response || response.status === "error") {
          onFailure(response.error);
          return;
        }
        if (response) {
          onSuccess(response);
          return;
        }
        onFailure("An unknown error occured.");
      },
      {
        contentType: "params",
      }
    );
  }

  static cancelOrder(order_id, onSuccess = () => {}, onFailure = () => {}) {
    let endpoint = config.api_endpoint + "order/cancel/" + order_id;
    this.saveData(
      ModelOrders,
      endpoint,
      "POST",
      null,
      (response) => {
        if (!response || response.status === "error") {
          onFailure(response.error);
          return;
        }
        if (response) {
          onSuccess(response);
          return;
        }
        onFailure("An unknown error occured.");
      },
      {
        contentType: "params",
      }
    );
  }

  static editOrderFlex(order, onSuccess = () => {}, onFailure = () => {}) {
    let endpoint = config.api_endpoint + "order/update_flex/" + order.id;
    this.saveData(
      ModelOrders,
      endpoint,
      "POST",
      order,
      (response) => {
        if (!response || response.status === "error") {
          onFailure(response.error);
          return;
        }
        if (response) {
          onSuccess(response);
          return;
        }
        onFailure("An unknown error occured.");
      },
      {
        contentType: "params",
      }
    );
  }

  getSelect(input) {
    ModelOrders.getSelect(input);
  }

  constructor(selectData) {
    super(selectData, {
      endpoint:
        config.api_endpoint +
        "admin/orders" +
        (selectData?.products && selectData?.products.length > 1
          ? "?products=" + selectData?.products
          : ""),
      method: "GET",
    });

    this.cacheEnabled = true;
  }

  getDataFromResponse(data) {
    try {
      const response = JSON.parse(data);
      if (!response) {
        let error = "The server returned an unknown error";
        this.onFailure(error);
        return null;
      }
      return response.orders;
    } catch (e) {
      if (this.request && this.request.aborted) {
        console.warn(
          "Cannot process request, probably because it was aborted. Exception: " +
            e.message
        );
        this.status = "waiting";
      } else {
        this.onFailure(e);
      }
    }

    return null;
  }

  getData() {
    return {
      status: this.status,
      data: this.data,
    };
  }
}

export { ModelOrders };

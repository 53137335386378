import './text.scss';
import React from "react";

const UIText = function (props) {
    const {variant, style, onClick, color} = props;
    return <p className={`fcu-text fcu-${variant ? variant : ''} ${props.className ? props.className : ''}`}
              style={{color, ...style}}
              onClick={onClick}
    >
        {props.children}
    </p>
};

UIText.defaultProps = {
    variant: 'paragraph'
};

export {UIText}
import React, {useLayoutEffect, useState} from "react";
import {Descriptions} from "antd";
import {UIText} from "./text/text.js";

const UIDescriptions = function (props) {
    const [size, setWidth] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const {labels, item, renderLabelFunctions} = props;
    return <Descriptions bordered layout={size < 800 ? 'vertical' : 'horizontal'} column={{ xs: 1, sm: 1, md: 1}}>
        {Object.keys(labels).map((labelKey)=>{
            const label = labels[labelKey];
            const value = (renderLabelFunctions && renderLabelFunctions[labelKey] ? renderLabelFunctions[labelKey](item[labelKey]) : item[labelKey])
            return <Descriptions.Item key={labelKey} label={<UIText variant={'paragraph-bold'}>{label}</UIText>}>
                {typeof value === 'string' ?
                    <UIText variant={'paragraph'}>
                        {(value) ?
                            value :
                            <i>Geen informatie</i>
                        }
                    </UIText> :
                    value
                }
            </Descriptions.Item>
        })}
    </Descriptions>
};

export {UIDescriptions}
import React from "react";
import {navMapping} from "./nav_mapping.js";
import {Link} from "react-router-dom";
import { withRouter } from "react-router-dom";
import {UIText} from "../../components/ui/text/text.js";

const HeaderMenuComponent = function (props) {
    const currentPath = props.location.pathname;

    return <div className={'fcu-header-menu-container'}>
        <div className={'fcu-header-menu'}>
            {navMapping.map((item, key)=>{
                const isCurrentPath = (currentPath === item.path);
                const isArabiaPath = (currentPath === '/arabia-teaching')
                return <Link key={key}
                             to={item.path}
                             onClick={props.onSelect}
                             className={`fcu-header-menu-item ${isArabiaPath && isCurrentPath ? 'selected-arabia': isCurrentPath ? 'selected' : ''}`}>
                    <UIText variant={'header-title'}>{item.label}</UIText>
                </Link>
            })}
        </div>
    </div>
};

const HeaderMenu = withRouter(HeaderMenuComponent)
export {HeaderMenu}
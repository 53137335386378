import React from "react";
import { Button, Form, Input, Modal, notification } from "antd";
import "./reset.scss";
import { ModelAuth } from "../../model/auth.js";
import {useHistory} from "react-router-dom";


const ResetComp = function (props) {
let token = props.match.params.id;
const history = useHistory();

  const onSubmit = (values) => {
    const onSuccess = (response) => {
        history.push("/")
    };

    const onFailure = () => {
      notification.error({
        message: "Er is iets fouts gegaan...",
      });
    };
    ModelAuth.resetPass(values, onSuccess, onFailure);
  };
  return (
    <div className="resetCont">
      <Form initialValues={{token:token}} layout={"vertical"} className="form" onFinish={onSubmit}>
        <h2>Wachtwoord veranderen</h2>
        <Form.Item
          name="token"
          className="tokenInput"
          label="Token"
          rules={[{ required: true, message: "Token is verplicht!" }]}
        >
          <Input placeholder={"Token"} />
        </Form.Item>
        <Form.Item
          name="password"
          label="Gewenst wachtwoord"
          rules={[{ required: true, message: "Wachtwoord is verplicht" }]}
        >
          <Input.Password placeholder={"Wachtwoord"} />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          label="Bevestig wachtwoord"
          rules={[
            { required: true, message: "Bevestig je wachtwoord!" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "Wachtwoord is niet hetzeflde als het gewenste wachtwoord."
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder={"Wachtwoord"} />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="contact-form-button"
        >
          Wachtwoord veranderen
        </Button>
      </Form>
    </div>
  );
};

export { ResetComp };

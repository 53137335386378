import "./marketing.scss";
import React from "react";
import { UIText } from "../../components/ui/text/text.js";
import { Teared } from "../../components/ui/teared/teared.js";
import { UIButton } from "../../components/ui/button/button.js";
import {ReactComponent as MoroccoFlag} from "../../assets/icons/morocco_flag.svg";
import moment from "moment";

const HomeMarketing = function (props) {
  return (
    <Teared color={"white"}>
      {" "}
      <div className={"orders"}>
        <UIText variant={"footer-title"}>
          Op umrah gaan vanuit Marokko? Bekijk ons aanbod {moment().year()}!
          </UIText>

          {/* <MoroccoFlag className="flagMorocco"/> */}
          <div className="content">
            <UIButton
              className={"fcu-ui-white home-hadj-button"}
              onClick={() => props.setPopupState(moment().year())}
            >
              Reserveer nu !
            </UIButton>
            <UIButton className={"fcu-ui-white home-hadj-button"}
                onClick={()=>(props.setInfoState(true))}
            >
              Meer informatie
            </UIButton>
          </div>
      
      </div>
    </Teared>
  );
};

export { HomeMarketing };

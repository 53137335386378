import React, { useEffect, useState } from "react";
import LazyLoad from 'react-lazyload';
import { HomeBanner } from "./banner.js";
import { HomeInfo } from "./info.js";
import { HomeCarousel } from "./carousel.js";
import { HomeCrowdfunding } from "./crowdfunding.js";
import { HomeMarketing } from "./marketing.js";
import { useHistory, withRouter } from "react-router-dom";
import { Modal } from "antd";
import { UIText } from "../../components/ui/text/text.js";
import constants from '../../assets/constants.json'
import { ModelMyOrders } from "../../model/my_orders.js";
import { useModel } from "../../helpers/model/use_model.js";
import { useUser } from "../../components/auth/use-user.js";
import {PageHadjSingle} from "../hadj/single.js";
import {PageHadjInfo} from "../hadj/info.js";

//comment to fix this

const PageHomeComponent = function (props) {
    const [selectedHadj, setSelectedHadj] = useState(null);
    const [hajjInfo, setHajjInfo] = useState(false);

    const user = useUser()
    const resultSet = useModel(ModelMyOrders, { orderId: props.match.params.orderId })
    const history = useHistory();

    useEffect(() => {
        if (props.location.pathname !== '/') {
            if (props.match.params.orderId && !isNaN(parseInt(props.match.params.orderId))) {
                if (resultSet.status === ModelMyOrders.Status.SUCCESS) {
                    if (resultSet.data.order.status === constants.ORDER_STATUS.COMPLETED) {
                        Modal.success({
                            onOk: () => history.push('/'),
                            header: true,
                            title: "Bedankt voor je bestelling!",
                            content: <UIText>Je hebt succesvol betaald. We hebben je net een mail verzonden met de order.</UIText>
                        })
                    }
                    if (resultSet.data.order.status === constants.ORDER_STATUS.CANCELLED) {
                        Modal.error({
                            onOk: () => history.push('/'),
                            header: true,
                            title: "Jammer dat je je bestelling hebt geannuleerd."
                        })
                    }
                    if (resultSet.data.order.status === constants.ORDER_STATUS.REFUNDED) {
                        Modal.error({
                            onOk: () => history.push('/'),
                            header: true,
                            title: "Je order is terugbetaald.",
                            content: <UIText>Het bedrag zal binnen 5-10 werkdagen worden verwerkt.</UIText>
                        })
                    }
                    if ([constants.ORDER_STATUS.LEAD, constants.ORDER_STATUS.PENDING].includes(resultSet.data.order.status)) {
                        Modal.warning({
                            onOk: () => history.push('/'),
                            header: true,
                            title: "Bedankt voor je bestelling",
                            content: <UIText>Je hebt nog niet betaald, Je kunt je betalen via Account - Mijn bestellingen</UIText>
                        })
                    }
                    return history.push('/')
                }
            } else {
                return history.push('/')
            }
        }
    }, [props.match, props.location, resultSet, user, history])



    return <div>
        <HomeBanner />
        <HomeMarketing setPopupState={setSelectedHadj} setInfoState={setHajjInfo} />
        <HomeInfo />

        <LazyLoad offset={100}>

            <HomeCarousel />
        </LazyLoad>
        {(selectedHadj !== null && selectedHadj) &&
            <PageHadjSingle
                selected={selectedHadj}
                onClose={() => {
                    setSelectedHadj(null)
                }}
            />
        }
        {
            hajjInfo && <PageHadjInfo setHajjInfo={setHajjInfo} setSelectedHadj={setSelectedHadj} showHajjInfo={hajjInfo} onClose={() => {
                setHajjInfo(false)
            }}/>
        }
              
    </div>
};

const PageHome = withRouter(PageHomeComponent)

export { PageHome }
import './vouchers.scss';
import React, {useState} from "react";
import {UIButton} from "../../../components/ui/button/button.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelInvoices} from "../../../model/invoices.js";
import {ModelUsers} from "../../../model/users.js";


import AdminInvoicesList from"./invoices-list";
import  PageNewInvoice  from './new-invoice';

const PageAdminInvoices = function (props) {
    const [newArticle, setNewArticle] = useState('');
    const [initialValues, setInitialValues] = useState(null);
    const resultSet = useModelFeedback(ModelInvoices);
    const resultSetUsers = useModelFeedback(ModelUsers);

    return <div>
        <div className={'article-buttons'}>
            <UIButton className={'article-button'} onClick={()=>setNewArticle('invoice')}>Voeg offerte/factuur toe</UIButton>
        </div>

        {(newArticle === 'invoice') &&
        <PageNewInvoice
            items={resultSet.data?.invoices}
            visible={(newArticle === 'invoice')}
            setInitialValues={setInitialValues}
            setVisible={()=>setNewArticle('')}
        initialValues={initialValues}
            users={resultSetUsers.data?.users}
        />}

        <AdminInvoicesList items={resultSet.data} setNewArticle={setNewArticle} setInitialValues={setInitialValues} />
    </div>
};

export default PageAdminInvoices;
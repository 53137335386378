import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import moment from 'moment';
import 'moment/locale/nl.js';
import {StateContext} from "./helpers/state-context/state-context.js";

moment.locale('nl');

const reducer = (state, action) => {
    if(action.type === 'addToAdminCart'){
        return {
            ...state,
            admin_cart: [...state.admin_cart, action.value],
        }
    }else if(action.type === 'updateAdminCartItem'){
        const cart = [...state.admin_cart];
        cart[action.key] = action.value ? action.value : cart[action.key];
        return {
            ...state,
            admin_cart: cart,
        }
    }else if(action.type === 'removeAdminCartItem'){
        const cart =[];
        for(const [key, item] of state.admin_cart.entries()){
            if(key !== action.value){
                cart.push(item)
            }
        }
        return {
            ...state,
            admin_cart: cart,
        };
    }else if(action.type === 'clearAdminCart'){
        return {
            ...state,
            admin_cart: [],
        }
    }else if(action.type === 'addToCart'){
        return {
            ...state,
            cart: [...state.cart, action.value],
        }
    }else if(action.type === 'updateCartItem'){
        const cart = [...state.cart];
        cart[action.key] = action.value ? action.value : cart[action.key];
        return {
            ...state,
            cart: cart,
        }
    }else if(action.type === 'removeCartItem'){
        const cart =[];
        for(const [key, item] of state.cart.entries()){
            if(key !== action.value){
                cart.push(item)
            }
        }
        return {
            ...state,
            cart: cart,
        };
    }
    return state;
};

const initialState = {
    cart: [
        // {
        //     "room_gender": 2,
        //     "room_type": 8,
        //     "guests": [
        //         {
        //             "first_name": "KADIR",
        //             "last_name": "CIFTCI",
        //             "age_category": 1,
        //             "gender": 0,
        //             "product_id": 38
        //         },
        //         {
        //             "first_name": "KADIR",
        //             "last_name": "CIFTCI",
        //             "age_category": 0,
        //             "gender": 1,
        //             "product_id": 44
        //         },
        //         {
        //             "first_name": "KADIR",
        //             "last_name": "CIFTCI",
        //             "gender": 0,
        //             "age_category": 2,
        //             "product_id": 39
        //         }
        //     ],
        //     "exclusive": true,
        //     "max_guests": 4,
        //     "type": "umrah"
        // },
        // {
        //     "room_gender": 2,
        //     "room_type": 8,
        //     "guests": [
        //         {
        //             "first_name": "KADIR",
        //             "last_name": "CIFTCI",
        //             "age_category": 1,
        //             "gender": 0,
        //             "product_id": 38
        //         },
        //         {
        //             "first_name": "KADIR",
        //             "last_name": "CIFTCI",
        //             "age_category": 0,
        //             "gender": 1,
        //             "product_id": 44
        //         },
        //     ],
        //     "exclusive": true,
        //     "max_guests": 4,
        //     "type": "umrah"
        // },
        // {
        //     "type": "arabia",
        //     "id": 12,
        //     "available_days": [
        //         "Vrijdag"
        //     ],
        //     "available_times": [
        //         "09:00-12:00",
        //         "12:00-15:00"
        //     ]
        // },
    ],
    admin_cart: [
        // {
        //     "room_gender": 2,
        //     "room_type": 8,
        //     "guests": [
        //         {
        //             "first_name": "KADIR",
        //             "last_name": "CIFTCI",
        //             "age_category": 1,
        //             "gender": 0,
        //             "product_id": 38
        //         },
        //         {
        //             "first_name": "KADIR",
        //             "last_name": "CIFTCI",
        //             "age_category": 0,
        //             "gender": 1,
        //             "product_id": 44
        //         },
        //         {
        //             "first_name": "KADIR",
        //             "last_name": "CIFTCI",
        //             "gender": 0,
        //             "age_category": 2,
        //             "product_id": 39
        //         }
        //     ],
        //     "exclusive": true,
        //     "max_guests": 4,
        //     "type": "umrah"
        // },
        // {
        //     "type": "arabia",
        //     "id": 12,
        //     "available_days": [
        //         "Vrijdag"
        //     ],
        //     "available_times": [
        //         "09:00-12:00",
        //         "12:00-15:00"
        //     ]
        // },
    ],
    lanuage: 'nl',
    user: null,
};

const component = (
    <StateContext reducer={reducer} initialState={initialState}>
        <App />
    </StateContext>
);

ReactDOM.render(component, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

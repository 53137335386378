import './details.scss'
import React, { useState} from "react";
import {Form, Input, Button} from "antd";
import {UIText} from "../../components/ui/text/text.js";
import {ModelAuth} from "../../model/auth.js";
import {UIButton} from "../../components/ui/button/button.js";
import {AuthWithUser} from "../../components/auth/auth-with-user.js";
import {UIDescriptions} from "../../components/ui/descriptions.js";
import {AuthSignUp} from "../../components/auth/sign-up.js";
import moment from 'moment';
import {v4} from "uuid";
import {AuthContext} from "../../components/auth/auth-provider.js";

const CheckoutDetails = function (props) {
    const {setUser} = React.useContext(AuthContext)
    const [modal, setModal] = useState('');
    

    const onSubmit = (values) => {
        const onSuccess = (response) => {
            localStorage.setItem("tokenKey", response.token);
            setUser(v4())
        };
        const onFailure = (error) => {
            // Form.setError('password', error);
        };
        ModelAuth.login(
            values.email, values.password, onSuccess, onFailure);
    };

    return <AuthWithUser enableFeedback={false}>
        {(user) => {
            const labels = {
                email: "Email",
                first_name: "Voornaam",
                last_name: "Achternaam",
                phone_number: "Telefoonnummer",
                date_of_birth: "Geboortedatum",
                nationality: "Nationaliteit",
                address: "Adres",
                zip: "Postcode",
                city: "Plaats",
                country: "Land"
            };

            const renderLabelFunctions = {
                date_of_birth: (value) => moment(value).format("DD-MM-YYYY")
            }
            if(user){
                return <div>
                    <UIText>Gegevens kun je aanpassen op de account pagina.</UIText>
                    <UIDescriptions item={user} labels={labels} renderLabelFunctions={renderLabelFunctions}/>
                </div>
            }
            return <div className={'checkout-details-container'}>
                <div className={'checkout-details'}>
                    <div className={'checkout-detail'}>
                        <UIText>Bestaande klant</UIText>
                        <UIText>Log hieronder in met uw accountgegevens.</UIText>
                        <Form layout={'vertical'} onFinish={onSubmit}>
                            <Form.Item name="email" label="Email" rules={[{ required: true, message: "Email is verplicht!" }, {type: 'email', message: 'Geen geldig email adres.'}]}>
                                <Input placeholder={'Email'}/>
                            </Form.Item>
                            <Form.Item name="password" label="Wachtwoord" rules={[{ required: true, message: "Wachtwoord is verplicht" }]}>
                                <Input.Password placeholder={'Wachtwoord'}/>
                            </Form.Item>
                            <Button htmlType="submit" className="contact-form-button">
                                Login
                            </Button>
                        </Form>
                    </div>
                    <div className={'checkout-detail'}>
                        <UIText>Geen account?</UIText>
                        <UIText>Met een account kunt u snel en eenvoudig bestellen en altijd uw bestellingen inzien.</UIText>
                        <UIButton onClick={()=>setModal('new-user')}>
                            Account aanmaken
                        </UIButton>
                    </div>
                </div>
                {(modal === 'new-user') &&
                <AuthSignUp
                    loginOnRegister={true}
                    visible={(modal === 'new-user')}
                    setVisible={()=>{setModal('')}}/>}
            </div>
        }}
    </AuthWithUser>
};

export {CheckoutDetails}

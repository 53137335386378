import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Collapse, Space, Tag } from "antd";
import { useModelFeedback } from "../../helpers/model/use_model_feedback.js";
import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { toMoney } from "../../helpers/functions.js";
import {
  ageTypes,
  arabiaProducts,
  genderRoomTypes,
  genderTypes,
  orderStatusses,
} from "../../assets/constants.js";
import { UIDescriptions } from "../../components/ui/descriptions.js";
import { UIButton } from "../../components/ui/button/button.js";
import constants from "../../assets/constants.json";
import { ModelMyOrders } from "../../model/my_orders.js";
import { ModelPayment } from "../../model/payment.js";
import { ModelOrders } from "../../model/orders.js";
import { ModelVouchers } from "../../model/vouchers.js";
import { ModelMyInvoices } from "../../model/my_invoices.js";

const PageAccountOrders = function (props) {
  const { orderID } = useParams();
  console.log(orderID);

  const resultSet = useModelFeedback(ModelMyOrders);
  const [discounts, setDiscounts] = useState([]);

  // const modelResultSet = useModelFeedback(ModelVouchers);
  const invoicesResultSet = useModelFeedback(ModelMyInvoices);
  const history = useHistory();

  useEffect(() => {
    const data = resultSet.data;
    if (data) {
      const orders = data.orders;
      if (orders) {
        const allDiscounts = orders
          .map((order) => JSON.parse(order.flex)?.discounts)
          .flat();
        const filteredDiscounts = allDiscounts.filter(
          (item, key) => allDiscounts.indexOf(item) === key
        );
        if (filteredDiscounts.length > 1) {
          ModelVouchers.getVouchersByLabel(
            { discounts: filteredDiscounts },
            (response) => setDiscounts(response)
          );
        }
      }
    }
  }, [resultSet.data]);

  if (resultSet.feedback) {
    return resultSet.feedback;
  }

  const downloadPDF = (order_id) => {
    ModelOrders.orderInvoice(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `factuur-FCU1${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const downloadDownPayment = (order_id) => {
    ModelOrders.orderDownpayment(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `factuur-FCU1${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const genExtraCategories = (order) => {
    const guests = order.rooms.map((room) => room.guests).flat();
    const visums = guests
      .map((guest) => guest.visums)
      .filter((data) => data && data?.length > 0)
      .flat();
    return (
      <Space>
        {[
          constants.ORDER_STATUS.PENDING,
          constants.ORDER_STATUS.LEAD,
          constants.ORDER_STATUS.DOWN_PAYMENT,
        ].includes(order.status) && (
          <Tag
            style={{ cursor: "pointer" }}
            onClick={() => {
              payOrder(order.id);
            }}
          >
            {order.status !== 8 ? "Betalen" : "Restbetaling"}
          </Tag>
        )}
        {(order.totalPaid / order.totalToPay) * 100 >= 100 && (
          <Tag
            onClick={() => {
              downloadPDF(order.id);
            }}
          >
            Factuur
            <DownloadOutlined />
          </Tag>
        )}
        {(order.totalPaid / order.totalToPay) * 100 < 100 &&
          (order.totalPaid / order.totalToPay) * 100 > 0 && (
            <Tag
              onClick={() => {
                downloadDownPayment(order.id);
              }}
            >
              Aanbetalingsbewijs
              <DownloadOutlined />
            </Tag>
          )}
        {order.totalPaid > 0 && visums.length < guests.length && (
          <Tag
            onClick={() => {
              return history.push("/visum/invoeren/" + order.id);
            }}
          >
            Documenten aanleveren
            <EditOutlined />
          </Tag>
        )}
      </Space>
    );
  };

  const payOrder = (id) => {
    ModelPayment.createItem(id,{"trans": "none","amount":0}, (payment) => {
      if (payment.transaction) {
        return (window.location = payment.transaction.paymentURL);
      }
    });
  };
  const defaualtKeys = orderID
    ? [
        resultSet.data?.orders?.findIndex(
          (order) => order.id.toString() === orderID
        ),
      ]
    : [];
  return (
    <Collapse expandIconPosition={"start"} activeKey={defaualtKeys}>
      <Collapse.Panel
        disabled={true}
        show
        header={<span>Bestelling nr.</span>}
        key={100004}
        extra={<span>Acties</span>}
      ></Collapse.Panel>
      {resultSet.data?.orders?.length > 0 ? (
        resultSet.data?.orders
          ?.sort(
            (a, b) =>
              a.status - b.status ||
              new Date(b.created_at) - new Date(a.created_at)
          )
          .map((order, key) => {
            const orderStatus = orderStatusses.find(
              (o) => o.id === order.status
            );
            const statusLabel =
              orderStatus?.label === "Lead"
                ? "Niet betaald"
                : orderStatus?.label;
            const header = (
              <span>
                <Tag color={orderStatus?.color}>{statusLabel}</Tag>
                Bestellingnummer #{"FCU1" + order.id}
              </span>
            );
            let discountPrices =
              discounts
                ?.filter((discount) =>
                  JSON.parse(order.flex)?.discounts?.find(
                    (item) => discount.label === item
                  )
                )
                .map((discount) => discount.amount / 100) || [];
            let discountPrice = discountPrices.reduce(
              (partialSum, a) => partialSum + a,
              0
            );
            order.discount = discountPrice;

            let totalPaid = 0;
            if (order.payments) {
              for (const payment of order.payments) {
                totalPaid += parseFloat(payment.amount);
              }
            }
            order.totalPaid = totalPaid;

            const calcPrice = (type) => {
              let price = 0;
              for (const product of order.products) {
                price += parseFloat(product.price);
              }
              if (type === "vat") {
                return (price / 121) * 21;
              }
              if (type === "without_vat") {
                let without_vat = (price / 121) * 100;
                for (const fund of order.crowdfunds) {
                  without_vat += fund?.pivot?.funding_amount;
                }
                return without_vat;
              }
              for (const fund of order.crowdfunds) {
                price += parseFloat(fund?.pivot?.funding_amount);
              }
              return price - discountPrice;
            };

            order.totalToPay = calcPrice() - discountPrice;

            const orderItem = {
              ...order,
              price: calcPrice(),
              details: {
                crowdfunds: order.crowdfunds,
                products: order.products,
                rooms: order.rooms,
              },
              factuur: order.id,
              id: "FCU1" + order.id,
              price_without_vat: (calcPrice() / 121) * 100,
              vat: (calcPrice() / 121) * 21,
            };

            const labels = {
              id: "Bestelling nr.",
              status: "Status",
              created_at: "Besteldatum",
              details: "Bestelling details",
              price_without_vat: "Totaalbedrag excl BTW",
              vat: `BTW (${constants.TAX}%)`,
              price: "Totaalbedrag incl. BTW",
              factuur: "Factuur",
            };

            if (discountPrice > 0) {
              labels["discount"] = "Toegepaste korting";
            }

            const renderLabelFunctions = {
              id: (id) => `#${id}`,
              status: (status) =>
                orderStatusses.find((item) => item.id === status).label,
              created_at: (created_at) =>
                moment(created_at).format("DD-MM-YYYY"),
              discount: (price) => toMoney(price),
              price: (price) => toMoney(price),
              price_without_vat: (price) => toMoney((price / 121) * 100),
              vat: (price) => toMoney(price),
              details: (order) => {
                return (
                  <ul>
                    {/*Show crowdfunding details*/}
                    {order.crowdfunds.map((fund, key) => {
                      return (
                        <li key={key}>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>Donatie aan - {fund.label}</div>
                          </div>
                        </li>
                      );
                    })}
                    {/*Show arabia teaching details*/}
                    {order.products
                      .filter((product) =>
                        arabiaProducts.includes(product.type)
                      )
                      .map((product, key) => {
                        return (
                          <li key={key}>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>Arabia teaching - {product.label}</div>
                            </div>
                          </li>
                        );
                      })}
                    {/*Show umrah details*/}
                    {order.rooms.map((room, key) => {
                      if (room.guests.length > 0) {
                        const product = order.products.find(
                          (p) => p.product_id === room.guests[0].product_id
                        );
                        const roomType = genderRoomTypes.find(
                          (type) => type.id === room.room_gender
                        );
                        const exclusive = room.exclusive ? "niet" : "wel";
                        return (
                          <li key={key}>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>{product?.label || "-"}</div>
                            </div>
                            <ul>
                              <li>
                                Kamer wordt {exclusive} gedeeld met andere
                                personen
                              </li>
                              <li>
                                {roomType?.label} kamer
                                <ul>
                                  {room.guests.map((guest, key) => {
                                    const gender =
                                      roomType.id === constants.GENDER_TYPE.MIX
                                        ? `, ${
                                            genderTypes.find(
                                              (t) => t.id === guest.gender
                                            )?.label
                                          }`
                                        : "";
                                    const ageLabel = ageTypes.find(
                                      (a) => a.id === guest.age_category
                                    );
                                    return (
                                      <li key={key}>
                                        {guest.first_name} {guest.last_name} (
                                        {ageLabel.label}
                                        {gender}){" "}
                                        {!guest.european_passport &&
                                          " - (Niet-europees paspoort)"}{" "}
                                        {!guest.bed_included &&
                                          " - (Zonder bed)"}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            </ul>
                          </li>
                        );
                      }

                      return null;
                    })}
                  </ul>
                );
              },
              factuur: (order_id) => (
                <UIButton onClick={() => downloadPDF(order_id)}>
                  Download factuur
                </UIButton>
              ),
            };

            return (
              <Collapse.Panel
                header={header}
                key={key}
                extra={genExtraCategories(order)}
              >
                <UIDescriptions
                  labels={labels}
                  renderLabelFunctions={renderLabelFunctions}
                  item={orderItem}
                />
              </Collapse.Panel>
            );
          })
      ) : (
        <Collapse.Panel
          disabled={true}
          showArrow={false}
          header={"Er zijn geen bestellingen"}
          key={10005}
        />
      )}
    </Collapse>
  );
};

export { PageAccountOrders };

import "./header.scss";
import React, { useEffect, useState } from "react";
import {
  MenuOutlined,
  UserOutlined,
  CloseOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import logoFCU from "../../assets/images/logo_transparant.png";
import logoAT from "../../assets/images/logo_arabia.png";
import { HeaderMenu } from "./menu.js";
import { Link, withRouter } from "react-router-dom";
import { Teared } from "../../components/ui/teared/teared.js";
import { UIText } from "../../components/ui/text/text.js";
import { Badge, Popover } from "antd";
import { useStateContext } from "../../helpers/state-context/use_state_context.js";
import { AuthSignIn } from "../../components/auth/sign-in.js";
import { HeaderAccountMenu } from "./account-menu.js";
import { AuthSignUp } from "../../components/auth/sign-up.js";
import { AuthResetPassword } from "../../components/auth/password";
import { AuthWithUser } from "../../components/auth/auth-with-user.js";
import constants from "../../assets/constants.json";

const HeaderComponent = function (props) {
  const [state] = useStateContext(["cart", "admin_cart"]);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [modal, setModal] = useState("");
  const isContactPage = props.location.pathname === "/contact";

  useEffect(() => {
    if (isMenuOpen) {
      setMenuOpen(false);
    }
  }, [props.location]);

  const toggleMenu = (to = false) => {
    setMenuOpen((prevState) => {
      if (to) {
        return to;
      }
      return !prevState;
    });
  };

  return (
    <header
      className={"fcu-header-container"}
      style={{ zIndex: isContactPage ? 1005 : 100 }}
    >
      <Teared>
        <div className={"fcu-header"}>
          <div className={"header-menu"} onClick={() => toggleMenu()}>
            {isMenuOpen ? (
              <CloseOutlined className={"menu-icon"} />
            ) : (
              <MenuOutlined className={"menu-icon"} />
            )}
            <UIText variant={"header-title"} className={"menu-title"}>
              {isMenuOpen ? "Sluit" : "Menu"}
            </UIText>
          </div>

          <Link to={"/"}>
            <img
              src={
                props.location.pathname === "/arabia-teaching"
                  ? logoAT
                  : logoFCU
              }
              alt={"logo"}
              className={"fcu-header-logo"}
            />
          </Link>
          <div className={"header-menu"}>
            <AuthWithUser enableFeedback={false}>
              {(user) => {
                if (!user) {
                  return (
                    <>
                      <UIText
                        variant={"header-title"}
                        className={"menu-title"}
                        onClick={() => setModal("login")}
                      >
                        Account
                      </UIText>
                      <UserOutlined
                        className={"menu-icon"}
                        onClick={() => setModal("login")}
                      />
                      <Link to={"/winkelwagen"}>
                        <Badge count={state.cart.length} size={"small"}>
                          <ShoppingOutlined className={"menu-icon"} />
                        </Badge>
                      </Link>
                    </>
                  );
                }
                return (
                  <>
                    <Popover
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      trigger={"click"}
                      placement={"topRight"}
                      content={<HeaderAccountMenu />}
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <UIText
                          variant={"paragraph-sub-title"}
                          className={"menu-title"}
                        >
                          {user.first_name} {user.last_name}
                        </UIText>
                        <UserOutlined className={"menu-icon"} />
                      </span>
                    </Popover>
                    <div>
                      <Link to={"/winkelwagen"} style={{ margin: "0 1rem" }}>
                        <Badge count={state.cart.length} size={"small"}>
                          <ShoppingOutlined className={"menu-icon"} />
                        </Badge>
                      </Link>
                      {user.role === constants.USER_ROLE.ADMIN && (
                        <Link
                          to={"/admin-winkelwagen"}
                          style={{ margin: "0 1rem" }}
                        >
                          <Badge
                            count={state.admin_cart.length}
                            size={"small"}
                            style={{ backgroundColor: "#52c41a" }}
                          >
                            <ShoppingOutlined className={"menu-icon"} />
                          </Badge>
                        </Link>
                      )}
                    </div>
                  </>
                );
              }}
            </AuthWithUser>
          </div>
        </div>
        {isMenuOpen && <HeaderMenu onSelect={() => toggleMenu(false)} />}
      </Teared>
      {modal === "login" && (
        <AuthSignIn
          setModal={setModal}
          onClose={() => {
            setModal("");
          }}
          visible={modal === "login"}
        />
      )}
      {modal === "register" && (
        <AuthSignUp
          visible={modal === "register"}
          setModal={setModal}
          setVisible={() => {
            setModal("");
          }}
        />
      )}

      {modal === "password" && (
        <AuthResetPassword
          setModal={setModal}
          onClose={() => {
            setModal("");
          }}
          visible={modal === "password"}
        />
      )}
    </header>
  );
};

const Header = withRouter(HeaderComponent);
export { Header };

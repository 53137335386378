import './banner.scss';
import React from "react";
import {useTranslation} from "../../helpers/languange.js";
import {UIText} from "../../components/ui/text/text.js";

const PackagesBanner = function (props) {
    const text = useTranslation();
    return <div className={'packages-banner-container'}>
        <div className={'packages-banner'}>
            <UIText variant={'banner-title'}>
                {text.packages.banner.label}
            </UIText>
            <UIText variant={'paragraph'} className={'packages-banner-description'}>
            Om het onze broeders en zusters gemakkelijk te maken, hebben we verschillende pakketreizen voor jullie samengesteld. Je kunt kiezen uit verschillende vertrekdata. Bovendien kun je eenvoudig zien in welk hotel je zult verblijven, de afstand tot de Haramain en wat er allemaal is inbegrepen.
            </UIText>
        </div>
    </div>
};

export {PackagesBanner}

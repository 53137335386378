import "./products.scss";
import React, { useState, useEffect } from "react";
import { Teared } from "../../components/ui/teared/teared.js";
import { ModelProducts } from "../../model/products.js";
import { Popover, Rate, Collapse, Checkbox, Carousel } from "antd";
import {
  InfoCircleOutlined,
  RightOutlined,
  LeftOutlined,
  LineOutlined,
} from "@ant-design/icons";
import { UIButton } from "../../components/ui/button/button.js";
import { ReactComponent as Aeroplane } from "../../assets/icons/aeroplane.svg";
import { ReactComponent as Pin } from "../../assets/icons/pin.svg";
import { ReactComponent as MeccaPin } from "../../assets/icons/mecca_pin.svg";
import { ReactComponent as MedinaPin } from "../../assets/icons/medina_pin.svg";
import { UIText } from "../../components/ui/text/text.js";
import moment from "moment";
import momentHijri from "moment-hijri";
import constants from "../../assets/constants.json";
import { hotels, productTypes } from "../../assets/constants.js";
import { useTranslation } from "../../helpers/languange.js";
import { toMoney } from "../../helpers/functions.js";
import { PackagesAdd } from "./add/add.js";
import { InfoModal } from "./infoModal.js";
import { PagePackagesSingle } from "./single.js";
import { ModelConsumer } from "../../helpers/model/model-consumer.js";
import { useModelFeedback } from "../../helpers/model/use_model_feedback.js";

import { Icon } from "@iconify/react";

//bg images.
import januari from "../../assets/images/months/januari.png";
import februari from "../../assets/images/months/februari.png";
import maart from "../../assets/images/months/maart.png";
import april from "../../assets/images/months/april.png";
import mei from "../../assets/images/months/mei.png";
import juni from "../../assets/images/months/juni.png";
import juli from "../../assets/images/months/juli.png";
import augustus from "../../assets/images/months/augustus.png";
import september from "../../assets/images/months/september.png";
import oktober from "../../assets/images/months/oktober.png";
import november from "../../assets/images/months/november.png";
import december from "../../assets/images/months/december.png";
import ramadan from "../../assets/images/months/ramadan.png";

const monthImages = [
  januari,
  februari,
  maart,
  april,
  mei,
  juni,
  juli,
  augustus,
  september,
  oktober,
  november,
  december,
  ramadan,
];
const monthNames = [
  "Winter umrah",
  "Februari umrah",
  "Ramadan umrah",
  "Meivakantie unrah",
  "Mei umrah",
  "Juni urah",
  "Broeders umrah",
  "Augustus umrah",
  "September umrah",
  "Herfstvakantie umrah",
  "November umrah",
  "Eindejaars umrah",
];
const regions = [
  "Regio Noord",
  "Regio Midden -En Zuid",
  "Regio Midden -En Noord",
  "Regio Midden",
  "Regio Zuid",
];
const regionColors = ["", "zuid", "", "", "zuid"];
const defaultPriority = 0;

const PackagesProducts = function () {
  const [selected, setSelected] = useState(null);
  const [items, setItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [showItems, setShowItems] = useState(null);
  const [currentYearMonth, setCurrentYM] = useState(null);
  const [infoProduct, setInfoProductToShow] = useState(null);

  const resultSet = useModelFeedback(ModelProducts);
  const monthWeekFilterOptions = [];
  const monthsToHide = [4, 5];
  const populatCatsToShow = () => {
    let productsToDisplay = {};
    for (let i = 0; i < 12; i++) {
      let currentDateTime = moment();
      let yearMonth = currentDateTime.add(i, "M");
      let yearMonthString = yearMonth.format("YYYYMM");
      let yearMonthLabel = moment()
        .year(yearMonthString.slice(0, 4))
        .month(parseInt(yearMonthString.slice(-2) - 1))
        .format("MMMM YYYY");
      if (
        monthsToHide.indexOf(yearMonth.month()) === -1 &&
        Object.keys(productsToDisplay).length < 7
      ) {
        productsToDisplay[yearMonthString] = {
          key: yearMonthString,
          label: yearMonthLabel,
          items: [],
          visible: true,
        };
        monthWeekFilterOptions.push({
          label: yearMonthLabel,
          value: yearMonthString,
        });
      }
    }
    return productsToDisplay;
  };
  let [catsToShow, setCatsToShow] = useState(populatCatsToShow);
  const { Panel } = Collapse;
  const text = useTranslation();
  useEffect(() => {
    if (!resultSet.feedback) {
      const sortedItems = resultSet.data.products
        .sort((a, b) => new Date(a.start) - new Date(b.start))
        .filter((product) => {
          return [
            constants.PRODUCT_TYPE.UMRAH_4PERS_ADULT,
            constants.PRODUCT_TYPE.UMRAH_3PERS_ADULT,
            constants.PRODUCT_TYPE.UMRAH_2PERS_ADULT,
          ].includes(product.type);
        })
        .filter((product) => product.is_active)
        .map((product) => {
          product.visible = true;
          return product;
        });
      setProducts(resultSet.data.products);
      setItems([...sortedItems]);
      populateCategoryItems(sortedItems);
    }
  }, [resultSet.status]);

  const populateCategoryItems = (items) => {
    let itemsTotalStock = 0;
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      let itemStart = moment(item.start);
      // if(item.is_active === 0){
      //     return;
      // }
      if (catsToShow[itemStart.format("YYYYMM")] !== undefined) {
        let newObj = catsToShow[itemStart.format("YYYYMM")];
        newObj.items = catsToShow[itemStart.format("YYYYMM")].items.concat([
          item,
        ]);
      }
    }
  };

  const renderCategorie = (catObj, key) => {
    let monthOfYear = moment(catObj.key).month();
    catObj.itemsTotalStock = 0;
    catObj.items.forEach(
      (item) => (catObj.itemsTotalStock = catObj.itemsTotalStock + item.stock)
    );
    let hijriMonth = momentHijri(catObj.key).startOf("iMonth").iMonth();
    let background =
      hijriMonth === 8 ? monthImages["12"] : monthImages[monthOfYear]; // Ramadan
    return (
      catObj.visible && (
        <div
          key={"catContain" + key}
          className={
            "catButtonContainer " +
            (catObj.itemsTotalStock >= 1 ? "" : "soldOut")
          }
          onClick={() => {
            setShowItems(catObj.items);
            return setCurrentYM(catObj.key);
          }}
        >
          <div
            className={"catButton"}
            style={{
              backgroundImage: "url($background)".replace(
                "$background",
                background
              ),
            }}
          >
            <div className="labels">
              <div className="regularLabel">{catObj.label}</div>
              <div className="hijriLabel">{monthNames[monthOfYear]}</div>
            </div>

            <UIButton
              className={
                "CheckAanbod " +
                (catObj.itemsTotalStock > 0 ? "" : "soldOut") +
                (catObj.items.length >= 1 ? "" : " commingSoon")
              }
            >
              {catObj.itemsTotalStock > 0 && "Bekijk aanbod"}
              {catObj.itemsTotalStock === 0 &&
                catObj.items.length > 0 &&
                "Uitverkocht!"}
              {catObj.items.length === 0 &&
                catObj.itemsTotalStock === 0 &&
                "Binnenkort online"}
            </UIButton>
          </div>
          <Teared></Teared>
        </div>
      )
    );
  };

  const categorieKeys = Object.keys(catsToShow);
  const item = items.find((i) => i.product_id === selected);
  return (
    <Teared>
      <div className="categories-container">
        {!showItems && <div className="header">Wanneer wil jij op umrah?</div>}
        <div className="categories">
          {!showItems &&
            categorieKeys.map((product, key) =>
              renderCategorie(catsToShow[product], key)
            )}
          {!showItems && (
            <div
              className="catButtonContainer flex"
              onClick={() => {
                setSelected("single");
              }}
            >
              <div className="catButton flex">
                <div className="labels">
                  <div className="regularLabel">
                    Wil je toch op een andere datum vertrekken?
                  </div>
                  <div className="hijriLabel">
                    Dan kunnen we altijd een pakketreis op maat voor je maken.
                  </div>
                </div>

                <UIButton className="CheckAanbod commingSoon flex">
                  Contact opnemen
                </UIButton>
              </div>
              <Teared></Teared>
            </div>
          )}
        </div>
        {showItems && (
          <div className="totalContainer">
            <div className="header">
              <div className="titleContainer">
                <LineOutlined className="titleIcon"></LineOutlined>
                {moment(currentYearMonth).format("MMMM YYYY")}
              </div>

              <div
                className="goBackContainer"
                onClick={() => {
                  setShowItems(null);
                }}
              >
                <LeftOutlined></LeftOutlined>
                <div className="goBackLabel">Ga terug</div>
              </div>
            </div>

            {showItems.length < 1 && (
              <div className="outOfOrderLabel">
                Voor deze periode zijn er geen pakketen beschikbaar...
              </div>
            )}
            <div className="products-container">
              {showItems
                .sort((a, b) => {
                  const priorityA =
                    JSON.parse(a.flex)?.priority !== undefined && JSON.parse(a.flex)?.priority !== null
                      ? JSON.parse(a.flex)?.priority
                      : defaultPriority;
                  const priorityB =
                  JSON.parse(b.flex)?.priority !== undefined && JSON.parse(b.flex)?.priority !== null
                      ? JSON.parse(b.flex)?.priority
                      : defaultPriority;

                      console.log('a', JSON.parse(a.flex))
                      console.log('b', JSON.parse(b.flex))

                  return priorityB - priorityA;
                })
                .map((product, key) => {
                  const start = moment(product.start);
                  const end = moment(product.end);
                  const title = product.label;
                  const stock = product.stock;
                  const duration = end.diff(start, "days");
                  const flex = JSON.parse(product.flex);
                  return (
                    <div key={'products-item'+key} className={"products-item"}>
                      <div className={"products-item-header"}>
                        {flex.region && (
                          <div
                            className={"region " + regionColors[flex.region[0]]}
                          >
                            {regions[flex.region]}
                          </div>
                        )}
                        <UIText variant={"paragraph-title"}>{title}</UIText>
                        <UIText>{product.description}</UIText>
                        <div
                          className={
                            "products-item-header-price " +
                            (product.stock === 0 ? "soldOut" : "")
                          }
                        >
                          <UIText
                            className="priceText"
                            variant={"paragraph-bold"}
                          >
                            {productTypes[product.type][0].type_amount_label}{" "}
                            vanaf {toMoney(product.price, 2)} P.P.
                          </UIText>
                          <UIText
                            className="ofoText"
                            variant={"paragraph-bold"}
                          >
                            Uitverkocht !
                          </UIText>
                        </div>
                      </div>

                      <div className={"products-item-body"}>
                        {flex?.hotels
                          .sort((a, b) => a.location < b.location)
                          .map((hotel, key) => {
                            return (
                              <div className="hotelContainer" key={key}>
                                <div className="hotelContent">
                                  <div className="hotelTumb"></div>
                                  <div className="hotelDetails">
                                    <div className="hotelLocation">
                                      <div
                                        className="detailIcon"
                                        style={{
                                          backgroundImage:
                                            "url($background)".replace(
                                              "$background",
                                              hotels[hotel].thumb
                                            ),
                                        }}
                                      />
                                      <div>
                                        <div className="detailLabel">
                                          <Icon
                                            className="labelIcon"
                                            icon="eva:pin-outline"
                                          />{" "}
                                          Hotel {hotels[hotel].location}
                                        </div>
                                        <div className="hotelLabel">
                                          {hotels[hotel].label}
                                        </div>
                                        {hotels[hotel].location === "Mekka" && (
                                          <div className="distanceLabel italic">
                                            "Of ander gelijkwaardig{" "}
                                            {hotels[hotel].rating} sterrenhotel"
                                          </div>
                                        )}
                                        {hotels[hotel].harm_distance && (
                                          <div className="distanceLabel">
                                            {hotels[hotel].harm_distance} meter
                                            van{" "}
                                            {hotels[hotel].location === "Mekka"
                                              ? "de Haram"
                                              : "Al-Masjid an-Nabawi"}
                                          </div>
                                        )}
                                        <Rate
                                          disabled
                                          defaultValue={hotels[hotel].rating}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="seperator" />
                              </div>
                            );
                          })}

                        <div className={"products-item-detail"}>
                          <div className="flightDetails">
                            <div className="trip">
                              <Icon
                                className="labelIcon"
                                icon="ic:round-flight-takeoff"
                              />{" "}
                              <span className="tripTitle">Vertrek:</span>{" "}
                              {start.format("LL")}
                            </div>
                            <div className="trip">
                              <Icon
                                className="labelIcon"
                                icon="ic:round-flight-land"
                              />{" "}
                              <span className="tripTitle">Retour:</span>{" "}
                              {end.format("LL")}
                            </div>
                          </div>
                        </div>
                        <div className="seperator" />
                        {flex.bulletpoints && (
                          <div className="bulletPointsContainer">
                            <div className="bulletTitle">Inclusief:</div>
                            <div className="bulletpoints">
                              {flex.bulletpoints.map((item, key) => {
                                return (
                                  <div className="bulletpointCont">
                                    <Icon
                                      className="labelIcon"
                                      icon="bi:check"
                                    />{" "}
                                    <span className="point">{item.label}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        {product.stock >= 1 && (
                          <div
                            className="actionButton"
                            onClick={() => {
                              return setSelected(product.product_id);
                            }}
                          >
                            {text.packages.order_button}
                          </div>
                        )}
                        <div
                          className="actionButton"
                          onClick={() => setInfoProductToShow(product)}
                        >
                          Meer informatie
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>

      {infoProduct !== null && (
        <InfoModal
          products={products}
          onClose={() => {
            setInfoProductToShow(null);
          }}
          product={infoProduct}
        />
      )}
      {selected !== null && selected !== "single" && (
        <PackagesAdd
          selected={selected}
          products={resultSet.data.products}
          onClose={() => {
            setSelected(null);
          }}
          item={item}
        />
      )}

      {selected !== null && selected === "single" && (
        <PagePackagesSingle
          selected={selected}
          onClose={() => {
            setSelected(null);
          }}
        />
      )}
    </Teared>
  );
};

export { PackagesProducts };

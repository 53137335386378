import React, { useState, useRef } from "react";
import { Modal, Carousel, Rate, Table } from "antd";
import moment from "moment";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  RightOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Teared } from "../../components/ui/teared/teared.js";
import { hotels, allProducts, getAgeTypeById, areEqual } from "../../assets/constants.js";
import constants from "../../assets/constants.json";
import { ReactComponent as MeccaPin } from "../../assets/icons/mecca_pin.svg";
import { ReactComponent as MedinaPin } from "../../assets/icons/medina_pin.svg";
import "./infoModal.scss";

const InfoModal = function (props) {
  const { product } = props;
  const { onClose } = props;
  const productsToShow = props.products.filter(
    (item) => item.start === product.start && item.stock > 0 && areEqual(JSON.parse(item.flex).hotels,JSON.parse(product.flex).hotels)
  );
  const flex = JSON.parse(product.flex);
  const selectedHotels = flex.hotels;
  let hotelImages = [];

  for (let i = 0; i < selectedHotels.length; i++) {
    let hotelID = selectedHotels[i];
    hotelImages = hotelImages.concat(hotels[hotelID].images);
  }

  const renderBulletPoint = (bulletpoint, key) => (
    <div key={key} className="bulletpoint">
      <div className="pointTitle">
        <RightOutlined className={"products-item-list-icon"} />
        <div>{bulletpoint.label}</div>
      </div>
      <div className="pointDesc">{bulletpoint.description}</div>
    </div>
  );

  const renderHotel = (hotel, key) => (
    <div key={key} className="hotelContainer">
      <div className="hotelTitle">
        {<img alt="logo" src={hotel.logo} className="hotelLogo" />}
        <div className="hotelDistanceContainer">
          {hotel.location === "Mekka" ? (
            <MeccaPin className="hotelDistanceIcon" />
          ) : (
            <MedinaPin className="hotelDistanceIcon" />
          )}
          <div className="hotelDistanceLabel">{hotel.harm_distance}m</div>
          <Rate
            character={<StarFilled className={"hotelRatingStar"} />}
            className="hotelRating"
            disabled
            defaultValue={hotel.rating}
          />
        </div>
      </div>
      <hr className="line" />
      <div className="hotelDesc">{hotel.desc}</div>
    </div>
  );
  const carouselRef = useRef(null);

  return (
    <Modal
      maskClosable={false}
      closable={true}
      onCancel={() => onClose()}
      footer={false}
      visible={true}
      keyboard={true}
      maskClosable={true}
      title={product.label}
      className={"infoModal"}
    >
      <Carousel
        ref={carouselRef}
        autoplay
        dots={false}
        className="infoCarousel"
      >
        {hotelImages.map((image, key) => (
          <div key={key} className="imgContainer">
            <div className="imgLoc">{image.loc}</div>
            <img className="hotelImg" src={image.img} alt="Logo" />
            <ArrowLeftOutlined
              onClick={() => carouselRef.current.prev()}
              className="carouselArrow left"
            />
            <ArrowRightOutlined
              onClick={() => carouselRef.current.next()}
              className="carouselArrow right"
            />
          </div>
        ))}
      </Carousel>
      <div className="modalBody">
        <div className="startEndDetails">
          <div className="start">
            <i>Vertrek:</i> {moment(product.start).format("DD-MM-YYYY")}
          </div>
          <div className="title">{product.label}</div>
          <div className="end">
            <i>Retour:</i> {moment(product.end).format("DD-MM-YYYY")}
          </div>
        </div>

        <div className="infoSection">
          <div className="hotelsContainer">
            {selectedHotels.map((selected, key) =>
              renderHotel(hotels[selected], key)
            )}
          </div>

          <div className="why-us">
            <div className="title">Waarom kiezen voor ons?</div>
            <hr className="line" />
            <p className="textContent">
              We kunnen met een gerust hart stellen dat wij een reisorganisatie
              zijn -wa lillaahil hamd- die er alles aan doet om de umrahgangers
              een volledig verzorgde reis te verzorgen. We streven ernaar om
              deze bijzondere ervaring voor elke reiziger zo soepel en prettig
              mogelijk te laten verlopen. Een comfortabel en luxe hotel,
              duidelijke communicatie, goede begeleiding en de nodige
              ontspanning dragen hier volgens ons aan bij.{" "}
            </p>
            <p className="textContent">
              Wij hopen dan ook dat elke reiziger zich volledig kan focussen op
              de Islamitische verplichtingen en andere aanbiddingen, terwijl wij
              ervoor zorgen dat het een zorgeloze reis wordt in shaa Allaah.
            </p>
            <p className="textContent">
              First Class Umrah kijkt ernaar uit om jou te mogen begeleiden
              tijdens onze volgende reis!{" "}
            </p>
          </div>
          <div className="infoBulletPoints">
            {flex.bulletpoints !== undefined &&
              flex.bulletpoints.map((bulletpoint, key) =>
                renderBulletPoint(bulletpoint, key)
              )}
          </div>
          <div className="tableCont">
            <div className="title">Prijzen</div>
            <hr className="line" />
            <Table
              pagination={false}
              bordered
              className={"infoSection priceTable"}
              columns={[
                { key: "priceType", title: "", dataIndex: "priceType" },
                {
                  key: "twoPerson",
                  title: "2 P. Kamer",
                  dataIndex: "twoPerson",
                },
                {
                  key: "threePerson",
                  title: "3 P. Kamer",
                  dataIndex: "threePerson",
                },
                {
                  key: "fourPerson",
                  title: "4 P. Kamer",
                  dataIndex: "fourPerson",
                },
              ]}
              dataSource={[
                {
                  key: 0,
                  priceType: (
                    <b>
                      <i>Volwassenen</i>
                    </b>
                  ),
                  twoPerson:
                    "€" +
                    productsToShow.find(
                      (product) =>
                        product.type ===
                        constants.PRODUCT_TYPE.UMRAH_2PERS_ADULT
                    )?.price + " p.p",
                  threePerson:
                    "€" +
                    productsToShow?.find(
                      (product) =>
                        product.type ===
                        constants.PRODUCT_TYPE.UMRAH_3PERS_ADULT
                    )?.price + " p.p",
                  fourPerson: "N.v.t.",
                },
                {
                  key: 1,
                  priceType: (
                    <b>
                      <i>Kind (met bed)</i>
                    </b>
                  ),
                  twoPerson:
                    "€" +
                    productsToShow.find(
                      (product) =>
                        product.type ===
                        constants.PRODUCT_TYPE.UMRAH_2PERS_CHILD
                    )?.price + " p.p",
                  threePerson:
                    "€" +
                    productsToShow.find(
                      (product) =>
                        product.type ===
                        constants.PRODUCT_TYPE.UMRAH_3PERS_CHILD
                    )?.price + " p.p",
                  fourPerson: "N.v.t.",
                },
                {
                  key: 2,
                  priceType: (
                    <b>
                      <i>Kind (zonder bed -€250,-)</i>
                    </b>
                  ),
                  twoPerson:
                    "€" +
                    (
                      parseInt(
                        productsToShow.find(
                          (product) =>
                            product.type ===
                            constants.PRODUCT_TYPE.UMRAH_2PERS_CHILD
                        )?.price
                      ) - 250
                    ).toString() +
                    ",00 p.p",
                  threePerson:
                    "€" +
                    (
                      parseInt(
                        productsToShow.find(
                          (product) =>
                            product.type ===
                            constants.PRODUCT_TYPE.UMRAH_3PERS_CHILD
                        )?.price
                      ) - 250
                    ).toString() +
                    ",00 p.p",

                  fourPerson: "N.v.t.",
                },
                {
                  key: 3,
                  priceType: (
                    <b>
                      <i>Baby (zonder bed)</i>
                    </b>
                  ),
                  twoPerson:
                    "€" +
                    productsToShow.find(
                      (product) =>
                        product.type === constants.PRODUCT_TYPE.UMRAH_2PERS_BABY
                    )?.price + " p.p",
                  threePerson:
                    "€" +
                    productsToShow.find(
                      (product) =>
                        product.type === constants.PRODUCT_TYPE.UMRAH_3PERS_BABY
                    )?.price + " p.p",
                  fourPerson: "N.v.t.",
                },
              ]}
            ></Table>
          </div>
          <hr className="line" />
        </div>
      </div>
    </Modal>
  );
};
export { InfoModal };

import './banner.scss';
import React from "react";
import {useTranslation} from "../../helpers/languange.js";
import {UIText} from "../../components/ui/text/text.js";
import {UIButton} from "../../components/ui/button/button.js";
import {Link} from "react-router-dom";

const HomeBanner = function (props) {
    const text = useTranslation();
    return <div className={'home-banner-container'}>
        <div className={'home-banner'}>
            <UIText variant={'banner-title'} className={'home-banner-label'}>
                {text.homepage.banner.label}
            </UIText>
            <Link to={'/pakketten'}>
                <UIButton className={'fcu-ui-white home-banner-button'}>Druk hier</UIButton>
            </Link>
        </div>
    </div>
};

export {HomeBanner}

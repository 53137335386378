import React, { useState } from "react";
import { Form, Input, Button, notification, DatePicker } from "antd";
import moment, { isDate } from "moment";
import { ModelAuth } from "../../model/auth.js";
import ReCAPTCHA from "react-google-recaptcha";
import { v4 } from "uuid";
import { AuthContext } from "./auth-provider.js";
import { LoadingOutlined } from "@ant-design/icons";
import "./registerPopup.scss";

const AuthSignUpPopup = function (props) {
  const recaptchaRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const { initialValues, visible } = props;
  const { setUser } = React.useContext(AuthContext);

  const cancelDefault = (e) => {
    return e.preventDefault();
  };

  const onSubmit = (values) => {
    const { password, ...restValues } = values;
    const body = {
      ...restValues,
      date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
    };
    let recaptchaBool = recaptchaRef.current.getValue().length > 0;
    if (recaptchaBool) {
      if (password !== undefined) {
        body.password = password;
      }
      if (props.loginOnRegister !== undefined) {
        body.login_user = true;
      }
      setLoading(true);
      setTimeout(()=>{
      ModelAuth.register(
        body,
        (response) => {
          setLoading(false);
          // console.log(response);
          if(body.login_user && response.token){
            localStorage.setItem("tokenKey", response.token);
            setUser(v4());
          }

          props.onSubmit && props.onSubmit();
        },
        (error) => {
          if (localStorage.getItem("tokenKey") !== undefined) {
            localStorage.removeItem("tokenKey");
            setUser(v4());
          }
          if (error.indexOf("Unprocessable") > -1) {
            return notification.error({
              message: "Dit emailadres wordt al gebruikt.",
            });
          }
          return notification.error({
            message:
              "Een onbekende fout heeft zich opgetreden, neem contact met ons op.",
          });
        }
      )}, 2000);
    } else {
      notification.error({
        message: "Verifieer dat u geen robot bent!",
      });
    }
  };

  return loading ? (<div className="loadingCont">
     <LoadingOutlined
     className="loadingIcon"
      spin
    />
  </div>
   
  ) : (
    <Form
      layout={"vertical"}
      onFinish={onSubmit}
      onSubmit={cancelDefault}
      initialValues={
        initialValues
          ? {
              ...initialValues,
              date_of_birth: isDate(new Date(initialValues.date_of_birth))
                ? moment(initialValues.date_of_birth)
                : null,
            }
          : {}
      }
      style={{ width: "100%" }}
    >
      <div className={"center-space-between mobile-vertical"}>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Email is verplicht!" },
            { type: "email", message: "Geen geldig email adres." },
          ]}
          style={{ width: "100%", marginRight: ".5rem" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Wachtwoord"
          rules={
            initialValues
              ? []
              : [{ required: true, message: "Wachtwoord is verplicht!" }]
          }
          style={{ width: "100%" }}
        >
          <Input.Password />
        </Form.Item>
      </div>
      <div className={"center-space-between mobile-vertical"}>
        <Form.Item
          name="first_name"
          label="Voornaam"
          rules={[{ required: true, message: "Voornaam is verplicht!" }]}
          style={{ width: "100%", marginRight: ".5rem" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Achternaam"
          rules={[{ required: true, message: "Achternaam is verplicht!" }]}
          style={{ width: "100%" }}
        >
          <Input />
        </Form.Item>
      </div>
      <Form.Item
        name="date_of_birth"
        label="Geboortedatum"
        rules={[{ required: true, message: "Geboortedatum is verplicht!" }]}
        style={{ width: "100%" }}
      >
        <DatePicker format={"DD-MM-YYYY"} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="nationality"
        label="Nationaliteit"
        rules={[{ required: true, message: "Nationaliteit is verplicht!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone_number"
        label="Telefoonnummer"
        rules={[{ required: true, message: "Telefoonnummer is verplicht!" }]}
      >
        <Input />
      </Form.Item>
      <div className={"center-space-between mobile-vertical"}>
        <Form.Item
          name="address"
          label="Adres"
          rules={[{ required: true, message: "Adres is verplicht!" }]}
          style={{ width: "100%", marginRight: ".5rem" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="zip"
          label="Postcode"
          rules={[{ required: true, message: "Postcode is verplicht!" }]}
          style={{ width: "100%" }}
        >
          <Input />
        </Form.Item>
      </div>
      <div className={"center-space-between mobile-vertical"}>
        <Form.Item
          name="city"
          label="Plaats"
          rules={[{ required: true, message: "Plaats is verplicht!" }]}
          style={{ width: "100%", marginRight: ".5rem" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="country"
          label="Land"
          rules={[{ required: true, message: "Land is verplicht!" }]}
          style={{ width: "100%" }}
        >
          <Input />
        </Form.Item>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6LceuMkjAAAAAEvspC5iGjh-ZQwnwvpB4p5Q8BTX"
      />
      <Button type="primary" htmlType="submit" className="contact-form-button">
        Doorgaan naar afrekenen
      </Button>
    </Form>
  );
};

export { AuthSignUpPopup };

import {config} from '../config.js';
import {ModelXhr} from "../helpers/model/xhr.js";

class ModelProductsLimited extends ModelXhr {
    static modelName = "ModelProductsLimited";

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'products_within_halfyear',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getDataFromResponse(data){
        try{
            const response = JSON.parse(data);
            if(! response){
                let error = 'The server returned an unknown error';
                this.onFailure(error);
                return null;
            }
            return response.products;
        }catch(e){
            if(this.request && this.request.aborted){
                console.warn('Cannot process request, probably because it was aborted. Exception: ' + e.message);
                this.status = 'waiting';
            }else{
                this.onFailure(e);
            }
        }

        return null;
    }


    getData(){
        return {
            status: this.status,
            data: this.data,
        };
    }
}

export {ModelProductsLimited};

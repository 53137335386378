import nlTranslation from "../assets/translations/nl.json";
import {useStateContext} from "./state-context/use_state_context.js";

const useTranslation = () => {
    const [state] = useStateContext();
    // Incase of translation add language to globalstate and parse it here
    switch(state.language){
        case 'nl': return nlTranslation;
        default: return nlTranslation;
    }
};

export {useTranslation}
import './collapseable.scss'
import React, {useState} from "react";
import PropTypes from 'prop-types';
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {UIText} from "./text/text.js";

const UICollapseable = function (props) {
    const [open, setOpen] = useState(true)
    const isOpen = props.open ? props.open : open;
    const iconStyle = {width: 25, fill: 'grey'};
    const toggleText = (isOpen) ? 'Hide' : 'Show';
    const toggleIcon = (isOpen) ? <DownOutlined style={iconStyle}/> : <UpOutlined style={iconStyle}/>;

    const toggle = () => {
        if(!props.open){
            setOpen((prevOpen) => !prevOpen)
        }
    };

    return <div className={'zol-ui-collapseable'} style={props.style}>
        <div className={'zol-ui-collapseable-header'}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <UIText>{props.title}</UIText>

                {props.collapseable && <p className={'zol-ui-collapseable-toggle'} onClick={toggle}>
                    {toggleText} {toggleIcon}
                </p>}
            </div>
            <hr className={'zol-ui-collapseable-line'}/>
        </div>
        {(isOpen) ?
            props.children :
            null
        }
    </div>
};

UICollapseable.defaultProps = {
    collapseable: false
}


UICollapseable.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export {UICollapseable}
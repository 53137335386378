import './add.scss';
import React, { useState } from "react";
import { UIText } from "../../../components/ui/text/text.js";
import { InputNumber, Modal, Spin, Steps } from "antd";
import { PackagesAddForm } from "./form.js";
import { PackagesAddSuccess } from "./success.js";
import { useUser } from "../../../components/auth/use-user.js";
import { useStateContext } from "../../../helpers/state-context/use_state_context.js";
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
const { Step } = Steps;


const PackagesAdd = function (props) {
    const [finished, setFinished] = useState(false);
    const [context, dispatch] = useStateContext()
    const [registering, setRegistering] = useState(false);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [amount, setAmount] = useState(9999);
    const { item } = props;
    const [edit, setEdit] = useState('')
    const user = useUser();



    const prepareRegistering = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            if (user === null) {
                return setRegistering(true);
            }
            setLoading(false);
            setRegistering(false);
            return setFinished(true);
        }, 300)
    };

    return <Modal onCancel={props.onClose}
        maskClosable={false}
        title={item.label}
        visible={true}
        className={'modal'}
        footer={false}>
        {/* <Steps direction="vertical" current={step}>
                            <Step title="Gasten" description="This is a description."/>
                            <Step title="Indeling" description="This is a description." />
                        </Steps> */}
        <div className="aantalGasten">
            <UIText style={{ fontSize: 14 }}>Aantal gasten</UIText>
            <InputNumber min={1} value={amount} onChange={(value) => setAmount(value)} className={'form-input'} defaultValue={999} />
        </div>

        {!loading && !finished && <PackagesAddForm item={props.item} products={props.products} onSubmit={prepareRegistering} amount={amount} /> }
        {(finished) && <PackagesAddSuccess amount={amount} addNewRoom={()=>setFinished(false)}/>
        }
        {loading && <LoadingOutlined
            style={{
                fontSize: 58,

                color: 'gray'
            }}
            spin
        />}
        <div className={'modal-footer'}>
            <div className={'modal'}><div className={'modal-footer'} /></div>
        </div>
    </Modal>
};

export { PackagesAdd }

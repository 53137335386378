import {config} from '../config.js';
import {ModelXhr} from "../helpers/model/xhr.js";

class ModelImgbb extends ModelXhr {
    static modelName = "ModelImgbb";

    static createItem(data, onSuccess=()=>{}){
        this.saveData(ModelImgbb, config.img_bb_endpoint, 'POST',
            data,
            (response) => {
                if(response.success){
                    onSuccess(response);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            },
            {
                contentType: 'params',
                authorization: null
            },
            // false
        );
    }


}

export {ModelImgbb};

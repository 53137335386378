import React, { useEffect, useState } from "react";
import { Collapse, Space, Tag, Modal } from "antd";
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import {
  DownloadOutlined,
  StopOutlined,
  EditOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import {
  FaChild,
  FaPlus,
  FaMinus,
  FaInfoCircle,
  FaBed,
  FaPassport,
  FaWheelchair,
} from "react-icons/fa";
import moment from "moment";
import { toMoney } from "../../../helpers/functions.js";
import { ModelOrders } from "../../../model/orders.js";
import { ModelVouchers } from "../../../model/vouchers.js";
import {
  ageTypes,
  arabiaProducts,
  genderRoomTypes,
  genderTypes,
  orderStatusses,
} from "../../../assets/constants.js";
import { UIDescriptions } from "../../../components/ui/descriptions.js";
import { UIButton } from "../../../components/ui/button/button.js";
import constants from "../../../assets/constants.json";

const AdminOrdersList = function (props) {
  const resultSet = props.resultSet;
  const modelResultSet = props.modelResultSet;

  if (modelResultSet.feedback) {
    return resultSet.feedback;
  }
  if (resultSet.feedback) {
    return resultSet.feedback;
  }

  const downloadPDF = (order_id) => {
    ModelOrders.orderInvoice(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `factuur-FCU1${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const downloadDownPayment = (order_id) => {
    ModelOrders.orderDownpayment(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `aanbetaling-FCU1${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const downloadCreditPDF = (order_id) => {
    ModelOrders.orderInvoiceCredit(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `creditfactuur-FCU1${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const downloadCreditDownPayment = (order_id) => {
    ModelOrders.orderDownpaymentCredit(order_id, (response) => {
      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = `creditnota-aanbetaling-FCU1${order_id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  const genExtraCategories = (order) => (
    <Space>
      {[
        constants.ORDER_STATUS.PENDING,
        constants.ORDER_STATUS.LEAD,
        constants.ORDER_STATUS.DOWN_PAYMENT,
      ].includes(order.status) && (
        <Tag
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.setModal(order.id);
          }}
        >
          Betalen
        </Tag>
      )}
      {!order.status === constants.ORDER_STATUS.CANCELLED && (
        <Tag
          onClick={() => {
            downloadPDF(order.id);
          }}
        >
          {"Factuur "}
          <DownloadOutlined />
        </Tag>
      )}
      {!order.status === constants.ORDER_STATUS.CANCELLED && (
        <Tag
          onClick={() => {
            downloadDownPayment(order.id);
          }}
        >
          {"Aanbetaling "}
          <DownloadOutlined />
        </Tag>
      )}
      {order?.status === constants.ORDER_STATUS.CANCELLED &&
        order.payments.length > 0 &&
        order.total_payments >= order.total_price && (
          <Tag
            onClick={() => {
              downloadCreditPDF(order.id);
            }}
          >
            {"Creditfactuur "}
            <DownloadOutlined />
          </Tag>
        )}
      {order?.status === constants.ORDER_STATUS.CANCELLED &&
        order?.payments.length > 0 &&
        order.total_payments < order.total_price && (
          <Tag
            onClick={() => {
              downloadCreditDownPayment(order.id);
            }}
          >
            {"Creditnota aanb. "}
            <DownloadOutlined />
          </Tag>
        )}
      {!(order.status === constants.ORDER_STATUS.CANCELLED) && (
        <Tag
          onClick={() => {
            props.setOrderToCancel(order.id);
          }}
        >
          {"Annuleer best."}
          <StopOutlined />
        </Tag>
      )}
      {!(order.status === constants.ORDER_STATUS.CANCELLED) && (
        <Tag
          onClick={() => {
            props.setOrderToUpdate(order);
          }}
        >
          {"Wijzig. kort "}
          <EditOutlined />
        </Tag>
      )}
    </Space>
  );

  return (
    <Collapse expandIconPosition={"start"}>
      <Collapse.Panel
        disabled={true}
        show
        header={
          <span>
            <Tag>Status</Tag>Bestelling nr.
          </span>
        }
        key={100004}
        extra={<span>Acties</span>}
      ></Collapse.Panel>
      {resultSet.data?.length > 0 ? (
        resultSet.data
          ?.filter((order) => {
            if (props.search === null) {
              return true;
            }
            if (props.search.type === "email") {
              return order.user[props.search.type]
                ?.toString()
                .toLowerCase()
                .includes(props.search.value.toString().toLowerCase());
            }
            if (props.search.type === "label") {
              return (
                `Bestellingnummer #${order.id} ${
                  order.products.filter(
                    (product) => product.product_id !== 1
                  )[0]?.label
                }`
                  .toLowerCase()
                  .indexOf(props.search.value.toString().toLowerCase()) > -1
              );
            }
            return order[props.search.type]
              .toString()
              .toLowerCase()
              .includes(props.search.value.toString().toLowerCase());
          })
          ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .map((order, key) => {
            const orderStatus = orderStatusses.find(
              (o) => o.id === order.status
            );
            const header = (
              <span>
                <Tag color={orderStatus?.color}>{orderStatus?.label}</Tag>
                Bestellingnummer #{order.id}{" "}
                {
                  order.products.filter(
                    (product) => product.product_id !== 1
                  )[0]?.label
                }
              </span>
            );
            let discountPrices = 0;

            let flex = JSON.parse(order.flex);
            let vouchers = modelResultSet.data.vouchers;
            let discounts = flex?.discounts;
            if (discounts) {
              for (const label of discounts) {
                discountPrices +=
                  vouchers.find((discount) => discount.label === label)
                    ?.amount / 100;
              }
            }
            order["discounts"] = discountPrices;
            const calcPrice = (type) => {
              let price = 0;
              for (const product of order.products) {
                price += parseFloat(product.price);
              }
              if (type === "vat") {
                return (price / 121) * 21;
              }
              if (type === "without_vat") {
                let without_vat = (price / 121) * 100;
                for (const fund of order.crowdfunds) {
                  without_vat += fund?.pivot?.funding_amount;
                }
                return without_vat;
              }
              for (const fund of order.crowdfunds) {
                price += fund?.pivot?.funding_amount;
              }
              return price - discountPrices;
            };

            const orderItem = {
              ...order,
              email: order.user.email,
              price: calcPrice(),
              discount: discountPrices,
              details: {
                crowdfunds: order.crowdfunds,
                products: order.products,
                rooms: order.rooms,
              },
              factuur: order.id,
              price_without_vat: (calcPrice() / 121) * 100,
              vat: (calcPrice() / 121) * 21,
              created_at: order.created_at,
            };

            const labels = {
              id: "Bestelling nr.",
              user_id: "Klantnummer",
              email: "Email klant",
              status: "Status",
              created_at: "Besteldatum",
              details: "Bestelling details",
              price_without_vat: "Totaalbedrag excl BTW",
              vat: `BTW (${constants.TAX}%)`,
              price: "Totaalbedrag incl. BTW",
              factuur: "Factuur",
              created_at: "Aangemaakt",
            };

            if (discountPrices > 0) {
              labels["discount"] = "Toegepaste korting";
            }

            const renderLabelFunctions = {
              id: (id) => `#${id}`,
              status: (status) =>
                orderStatusses.find((item) => item.id === status).label,
              created_at: (created_at) =>
                moment(created_at).format("DD-MM-YYYY"),
              discount: (discount) => toMoney(discount),
              price: (price) => toMoney(price),
              price_without_vat: (price) => toMoney(price),
              vat: (price) => toMoney(price),
              details: (order) => {
                return (
                  <ul>
                    {/*Show crowdfunding details*/}
                    {order.crowdfunds.map((fund, key) => {
                      return (
                        <li key={key}>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>Donatie aan - {fund.label}</div>
                          </div>
                        </li>
                      );
                    })}
                    {/*Show arabia teaching details*/}
                    {order.products
                      .filter((product) =>
                        arabiaProducts.includes(product.type)
                      )
                      .map((product, key) => {
                        return (
                          <li key={key}>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>Arabia teaching - {product.label}</div>
                            </div>
                          </li>
                        );
                      })}
                    {/*Show umrah details*/}
                    {order.rooms.map((room, key) => {
                      if (room.guests.length > 0) {
                        const product = order.products.find(
                          (p) => p.product_id === room.guests[0].product_id
                        );
                        const roomType = genderRoomTypes.find(
                          (type) => type.id === room.room_gender
                        );
                        const exclusive = room.exclusive ? "niet" : "wel";
                        return (
                          <li key={key}>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>{`${product?.label} kamer` || "-"}</div>
                            </div>
                            <ul>
                              <li>
                                Van:{" "}
                                {moment(product?.start).format("DD-MM-YYYY")}
                              </li>
                              <li>
                                Tot: {moment(product?.end).format("DD-MM-YYYY")}
                              </li>
                              <li>
                                Kamer wordt {exclusive} gedeeld met andere
                                personen
                              </li>
                              <li>
                                {roomType?.label} kamer
                                <ul>
                                  {room.guests.map((guest, key) => {
                                    {console.log(guest)}
                                    const gender =
                                      roomType.id === constants.GENDER_TYPE.MIX
                                        ? `, ${
                                            genderTypes.find(
                                              (t) => t.id === guest.gender
                                            )?.label
                                          }`
                                        : "";
                                    const ageLabel = ageTypes.find(
                                      (a) => a.id === guest.age_category
                                    );
                                    return (
                                      <li key={key}>
                                        {guest.first_name} {guest.last_name} (
                                        {ageLabel.label}
                                        {gender}){" "}
                                        {!guest.european_passport &&
                                          " - (Niet-europees paspoort)"}{" "}
                                        {!guest.bed_included &&
                                          " - (Zonder bed)"}
                                        {JSON.parse(guest?.flex)?.disabled &&
                                          <FaWheelchair className="disIcon"/>}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            </ul>
                          </li>
                        );
                      }
                      return null;
                    })}
                  </ul>
                );
              },
              factuur: (order_id) => (
                <UIButton onClick={() => downloadPDF(order_id)}>
                  Download factuur
                </UIButton>
              ),
            };
            order["total_price"] = calcPrice();
            order["total_payments"] = order.payments
              .map((payment) => parseFloat(payment.amount))
              .reduce((partialSum, a) => partialSum + a, 0);
            return (
              <>
                <Collapse.Panel
                  header={header}
                  key={key}
                  extra={genExtraCategories(order)}
                >
                  <UIDescriptions
                    labels={labels}
                    renderLabelFunctions={renderLabelFunctions}
                    item={orderItem}
                  />
                </Collapse.Panel>
              </>
            );
          })
      ) : (
        <Collapse.Panel
          disabled={true}
          showArrow={false}
          header={"Er zijn geen bestellingen"}
          key={10005}
        />
      )}
    </Collapse>
  );
};

export { AdminOrdersList };

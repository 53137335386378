import React, {useState} from "react";
import {UIButton} from "../../../components/ui/button/button.js";
import {AdminPackagesList} from "./list.js";
import {PageAdminPackagesNew} from "./new.js";
import {UIText} from "../../../components/ui/text/text.js";
import {Input, Select} from "antd";

const PageAdminProducts = function (props) {
    const [modal, setModal] = useState('');
    const [initialValues, setInitialValues] = useState(null);
    const [searchType, setSearchType] = useState('label');
    const [searchValue, setSearch] = useState('');

    return <div>
        <UIButton onClick={()=>setModal('products')}>Voeg product toe</UIButton>
        <div style={{margin: '1rem 0'}}>
            <UIText>Zoek een product</UIText>
            <Input.Group compact>
                <Select defaultValue={searchType} style={{ width: '20%' }} onChange={(value)=>setSearchType(value)}>
                    <Select.Option value="label">Naam</Select.Option>
                </Select>
                <Input style={{ width: '80%' }} value={searchValue} onChange={(e)=>setSearch(e.target.value)} />
            </Input.Group>
        </div>
        <AdminPackagesList
            search={searchValue.length > 0 ? {type: searchType, value: searchValue} : null}
            setInitialValues={setInitialValues}
            setModal={setModal}/>

        {(modal === 'products') &&
        <PageAdminPackagesNew
            visible={(modal === 'products')}
            setInitialValues={setInitialValues}
            setVisible={()=>setModal('')}
            initialValues={initialValues}/>
        }
    </div>
};

export {PageAdminProducts}
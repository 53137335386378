import "./carousel.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";

const UICarousel = function (props) {
    const {children, className, ...settingsSlider} = props;
    const settings = settingsSlider


    return <div className={'ui-carousel ' + className}>
        <Slider {...settings}>
            {children}
        </Slider>
    </div>
};

UICarousel.defaultProps = {
    dots: false,
    infinite: false,
    easing: 'linear',
    focusOnSelect: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4500,
    pauseOnHover: true,
    responsive: [
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },{
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },{
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },{
            breakpoint: 1000,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
    ]
};

export {UICarousel}
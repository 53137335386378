import {config} from '../config.js';
import {ModelXhr} from "../helpers/model/xhr.js";
import {notification} from "antd";

class ModelArticleCategories extends ModelXhr {
    static modelName = "ModelArticleCategories";

    static getSelect(input) {
        return {
        };
    }

    static createItem(data, onSuccess=()=>{}){
        this.saveData(ModelArticleCategories, config.api_endpoint + 'articlecat', 'POST',
            data,
            (response) => {
                if(response){
                    onSuccess(response);
                }else{
                    console.error(response.error || "Unknow error");
                }
            }
        );
    }

    static updateItem(id, data,  onSuccess=()=>{}){
            this.saveData(ModelArticleCategories, config.api_endpoint + 'articlecat/' + id, 'PUT',
                data,
                (response) => {
                    if(response){
                        onSuccess(response);
                    }else{
                        // todo: post status to global message channel
                        notification.error({
                            message: response.error || "Onbekende fout"
                        })
                    }
                },
            );
    }

    static deleteItem(id, onSuccess=()=>{}){
        this.saveData(ModelArticleCategories, config.api_endpoint + 'articlecat/' + id, 'DELETE',
            null,
            (response) => {
                if(response){
                    onSuccess(response);
                }else{
                    notification.error({
                        message: response.error || "Onbekende fout"
                    })
                }
            }
        );
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'articlecats',
            method: 'GET'
        });

        this.cacheEnabled = true;
        this.update();
    }

    getSelect(input) {
        return ModelArticleCategories.getSelect(input);
    }

    getDataFromResponse(data){
        try{
            const response = JSON.parse(data);
            if(! response){
                let error = 'The server returned an unknown error';
                this.onFailure(error);
                return null;
            }
            return response;
        }catch(e){
            if(this.request && this.request.aborted){
                console.warn('Cannot process request, probably because it was aborted. Exception: ' + e.message);
                this.status = 'waiting';
            }else{
                this.onFailure(e);
            }
        }

        return null;
    }


    getData(){
        return {
            status: this.status,
            data: this.data,
        };
    }
}

export {ModelArticleCategories};

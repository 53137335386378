import React, {useEffect} from "react";
import {Redirect, Route} from "react-router-dom";
import jwt_decode from 'jwt-decode';
import {notification} from "antd";
import {ModelAuth} from "../../model/auth.js";
import {AuthWithUser} from "./auth-with-user.js";
import {v4} from 'uuid'
import {AuthContext} from "./auth-provider.js";
import { AuthSignIn } from "./sign-in.js";
import "./login.scss";

const isTokenValid = (token) => {
    const myToken = jwt_decode(token)
    const currentTime = new Date().getTime();
    return (currentTime < myToken.exp * 1000);
};

const CustomRoute = function ({ component: Component, authenticated, loginOption, roles, path,  ...rest }) {
    const {setUser} = React.useContext(AuthContext)
    const tokenKey = localStorage.getItem('tokenKey');
    const currentTime = new Date().getTime();

    if(tokenKey !== null && tokenKey !== undefined && !isTokenValid(tokenKey)){
        localStorage.removeItem('tokenKey');
        notification.info({
            message: 'Je bent uitgelogd, omdat je sessie is verlopen'
        });
        setUser(v4())
    }

    if(tokenKey !== null && tokenKey !== undefined  && currentTime > ((jwt_decode(tokenKey).exp - 450) *1000)){
        const onSuccess = (response) => {
            localStorage.setItem("tokenKey", response.token);
            setUser(v4())
        };
        const onFailure = (error) => {
            // Form.setError('password', error);
        };
        ModelAuth.refresh(onSuccess, onFailure);
    }

    useEffect(()=>{},[tokenKey])

    const RenderComponent = (props) => {
        return <AuthWithUser enableFeedback={false}>
            {(user)=>{
                const isAuthenticated = authenticated ? (tokenKey && tokenKey) : true;
                const isInRole = (roles.length > 0 && user) ? (roles.includes(user.role)) : true;
                if((isAuthenticated && isInRole)){
                    return <Component {...props} />
                }

                const message = (isAuthenticated) ?
                    'Je bent niet toegestaan om deze pagina te bekijken.' :
                    'Log in om deze pagina te bekijken';
                notification.error({
                    message: message,
                    placement: 'bottomLeft'
                });

                if (loginOption) {
                    return (
                      <div className="loginDiv">
                        <h2>Inloggen</h2>
                        <p className="loginDesc">Deze pagina is beschermd en moet je voor inlogggen. Log in via het onderstaand formulier.</p>
                        <AuthSignIn onSuccess={() => history.go(0)} />
                      </div>
                    );
                  }
                return <Redirect to="/" />
            }}
        </AuthWithUser>
    }

    return (
        <Route
            {...rest}
            render={props => <RenderComponent {...props}
            />}
        />
    );
};

CustomRoute.defaultProps = {
    authenticated: false,
    roles: [
        // constants.USER_ROLE.ADMIN,
        // constants.USER_ROLE.BOEKHOUDER,
        // constants.USER_ROLE.USER,
    ]
}

export {CustomRoute}
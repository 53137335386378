import './account.scss'
import React, {useState} from "react";
import {UIDescriptions} from "../../components/ui/descriptions.js";
import {UIButton} from "../../components/ui/button/button.js";
import {AuthSignUp} from "../../components/auth/sign-up.js";
import {useModelFeedback} from "../../helpers/model/use_model_feedback.js";
import {ModelAuth} from "../../model/auth.js";
import moment from 'moment';

const PageAccountDetails = function (props) {
    const [edit, setEdit] = useState('')
    const resultSet = useModelFeedback(ModelAuth, {id: 'user'})


    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const labels = {
        email: "Email",
        first_name: "Voornaam",
        last_name: "Achternaam",
        phone_number: "Telefoonnummer",
        date_of_birth: "Geboortedatum",
        nationality: "Nationaliteit",
        address: "Adres",
        zip: "Postcode",
        city: "Plaats",
        country: "Land"
    };

    const renderLabelFunctions ={
        date_of_birth: (value)=> moment(value).format('DD-MM-YYYY')
    }

    return <div>
    <UIButton onClick={()=>{setEdit('user')}}>Gegevens wijzigen</UIButton>
    <UIDescriptions item={resultSet.data} labels={labels} renderLabelFunctions={renderLabelFunctions}/>

    {(edit === 'user') &&
        <AuthSignUp
            visible={(edit === 'user')}
            setVisible={()=>setEdit('')}
            initialValues={resultSet.data}/>}
    </div>
};

export {PageAccountDetails}
import './banner.scss';
import React from "react";
import {UIText} from "../../components/ui/text/text.js";
import {UIButton} from "../../components/ui/button/button.js";
import {TearedTriangle} from "../../components/ui/teared/teared-triangle.js";

const ArabiaTeachingBanner = function (props) {
    return <div className={'arabia-banner-container'}>
        <TearedTriangle>
            <div className={'arabia-banner'}>
                <UIText variant={'banner-title'} className={'arabia-banner-label'}>
                  Arabia<UIText  className="secondPart" variant={'banner-title'} >Teaching</UIText>
                </UIText>
                <UIText variant={'paragraph-sub-title'} className={'arabia-banner-description'}>
                Naast het faciliteren en begeleiden van Umrah-reizen, bieden wij ook hoogwaardige Arabische lessen aan. Bij ons bent u aan het juiste adres voor het verwerven van vaardigheden in de Arabische taal. Onze ervaren docenten staan klaar om u te begeleiden en te ondersteunen bij het verbeteren van uw kennis en beheersing van het Arabisch, ongeacht uw huidige niveau. Of u nu een beginner bent of een gevorderde cursist, onze lessen zijn op maat gemaakt om aan de behoeften van elke individuele student te voldoen.
                </UIText>
                <a  href="https://arabiateaching.nl" target="_blank" className="fcu-ui-button fcu-ui-purple arabia-banner-button">Druk hier</a>
            </div>
        </TearedTriangle>
    </div>
};

export {ArabiaTeachingBanner}

import './team.scss';
import React, {useState} from "react";
import {UIText} from "../../components/ui/text/text.js";
import {useTranslation} from "../../helpers/languange.js";
import {UIButton} from "../../components/ui/button/button.js";
import {ReactComponent as BroederSVG} from '../../assets/icons/broeder.svg'
import {ReactComponent as ZusterSVG} from '../../assets/icons/zuster.svg'


const AboutTeam = function (props) {
    const [filter, setFilter] = useState('');
    const text = useTranslation();
    return <div className={'about-team'}>
        <UIText variant={'title'}>{text.about_us.team_label}</UIText>
        <div className={'about-team-selection'}>
            <UIButton onClick={()=>setFilter('')}
                      className={`about-team-button ${( filter=== '')? 'selected' : ''}`}>
                <UIText>Iedereen</UIText>
            </UIButton>
            <UIButton onClick={()=>setFilter('male')}
                      className={`about-team-button ${( filter=== 'male')? 'selected' : ''}`}>
                <UIText>Broeders</UIText>
            </UIButton>
            <UIButton onClick={()=>setFilter('female')}
                      className={`about-team-button ${( filter=== 'female')? 'selected' : ''}`}>
                <UIText>Zusters</UIText>
            </UIButton>
        </div>
        <div className={'about-team_members'}>
            {text.about_us.team
                .filter((item)=>(filter === '') ? true : (filter === item.type))
                .map((member, key)=>{
                return <div className={'team_members-item'} key={key}>
                    {(member.type === 'female') ? <ZusterSVG className={'item-icon'}/> : <BroederSVG className={'item-icon'} />}
                    <UIText className={'item-about'}>{member.about}</UIText>
                    <UIText>- {member.name}</UIText>
                </div>
            })}
        </div>
    </div>
};

export {AboutTeam}
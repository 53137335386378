import './see-more.scss'
import React from "react";
import moment from 'moment';
import {ArticlesArticle} from "../article.js";
import {UIText} from "../../ui/text/text.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelArticles} from "../../../model/articles.js";
import constants from '../../../assets/constants.json'
import {ModelArticleCategories} from "../../../model/article-categories.js";

const ArticlesDetailSeeMore = function (props) {
    const {selectedArticle} = props;
    const resultSet = useModelFeedback(ModelArticles);
    const resultSetCategories = useModelFeedback(ModelArticleCategories);

    if(resultSet.feedback){
        return resultSet.feedback
    }
    if(resultSetCategories.feedback){
        return resultSetCategories.feedback
    }

    return <div>
        <UIText variant={'paragraph-title'} className={'articles-detail-see-more-label'}>Zie ook</UIText>
        <div className={'articles-detail-see-more'}>
            {resultSet.data.articles
                .sort((a,b) => moment(a.created_at) - moment(b.created_at))
                .filter((article)=>(selectedArticle.id !== article.id && article.type === constants.ARTICLE_TYPE.ARTICLE))
                .slice(0, 3)
                .map((article, key)=>{
                    return <ArticlesArticle key={key} article={article} categories={resultSetCategories.data.articleCategories}/>
                })
            }
        </div>
    </div>
};

export {ArticlesDetailSeeMore}
import {config} from '../config.js';
import {ModelXhr} from "../helpers/model/xhr.js";

class ModelMyOrders extends ModelXhr {
    static modelName = "ModelMyOrders";

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'orders',
            method: 'GET',
            isAuthorized: true
        });

        this.cacheEnabled = true;
    }

    static addGuestVisa(order,  onSuccess=()=>{}, onFailure=()=>{}){
        let endpoint = config.api_endpoint + "order/guest/visa";
        this.saveData(ModelMyOrders, endpoint , 'POST',
            order,
            (response) => {
                if(! response || response.status === 'error'){
                    onFailure(response.error);
                    return;
                }
                if(response){
                    onSuccess(response);
                    return;
                }
                onFailure("An unknown error occured.");
            },
            {
                contentType: 'json'
            }
        )
    }

    static sendFinishVisum(order,  onSuccess=()=>{}, onFailure=()=>{}){
        let endpoint = config.api_endpoint + "order/remind/documents/finish";
        this.saveData(ModelMyOrders, endpoint , 'POST',
            order,
            (response) => {
                if(! response || response.status === 'error'){
                    onFailure(response.error);
                    return;
                }
                if(response){
                    onSuccess(response);
                    return;
                }
                onFailure("An unknown error occured.");
            },
            {
                contentType: 'json'
            }
        )
    }


    static changeGuestName(order,  onSuccess=()=>{}, onFailure=()=>{}){
        let endpoint = config.api_endpoint + "order/guest/update_name/"+order.order_id;
        this.saveData(ModelMyOrders, endpoint , 'POST',
            order,
            (response) => {
                if(! response || response.status === 'error'){
                    onFailure(response.error);
                    return;
                }
                if(response){
                    onSuccess(response);
                    return;
                }
                onFailure("An unknown error occured.");
            },
            {
                contentType: 'json'
            }
        )
    }


    getDataFromResponse(data){
        try{
            const response = JSON.parse(data);
            if(! response){
                let error = 'The server returned an unknown error';
                this.onFailure(error);
                return null;
            }
            return response;
        }catch(e){
            if(this.request && this.request.aborted){
                console.warn('Cannot process request, probably because it was aborted. Exception: ' + e.message);
                this.status = 'waiting';
            }else{
                this.onFailure(e);
            }
        }

        return null;
    }

    getEndpoint() {
        let endpoint = this.endpoint;
        if(this.selectData.orderId){
            endpoint = config.api_endpoint + 'order/' + this.selectData.orderId
        }
        return endpoint
    }


    getData(){
        return {
            status: this.status,
            data: this.data,
        };
    }
}

export {ModelMyOrders};

import React from "react";
import "./style.scss";

function StepperHeader(props) {
    const finished = props.activeStep > props.stepNumber ? ' finished' : '';
    const active = props.activeStep === props.stepNumber ? ' active' : '';
    const lastStep = props.lastStep ? " last" : " ";
  return (
    <div className={"stepHeader"+finished+active+lastStep} >
      <div className="stepColumn">
        <div className="stepIcon">{props.stepNumber}</div>
        <div className="stepLabel">{props.label}</div>
      </div>
      <div className="verticalLine"/>
    </div>
  );
}
export default StepperHeader;

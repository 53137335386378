
import {NetworkXhr} from '../network/xhr.js';
import {Model} from './model.js';
import {getHash, stringify} from '../functions/hash.js';

class ModelXhr extends Model{

    static saveData(dependentModelClasses, endpoint, method, payload, callback=()=>{}, options){
        const token = localStorage.getItem("tokenKey");
        options = {
            method: method,
            data: payload,
            authorization: token === null?
                    null:
                    {
                        type: "Bearer",
                        access_token: token
                    },
            useXMLHttpRequest: true,
            contentType: 'json',
            ...options
        };

        const hash = getHash(method + " " + endpoint + " " + stringify(options));
        if(Model.saveRequestQue.has(hash)){
            // same request is already being executed, ignore this one. It's probably triggered twice.
            return;
        }

        // TODO we want to push messages to a global message system, so we can show feedback to the user.
        const onFailure = (error) => {
            Model.saveRequestQue.delete(hash);
            console.error("Error saving data: " + error);
            // GlobalMessage.push({type: 'error', message: 'Could not save item'});
            callback({success: false, error: error});
        }

        const onSuccess = (rawResponse) => {
            try{
                Model.saveRequestQue.delete(hash);

                const response = JSON.parse(rawResponse);
                // if(! response){
                //     onFailure('test');
                //     return null;
                // }
                Model.invalidateAllInstances(dependentModelClasses);
                callback(response);
                // GlobalMessage.push({type: 'info', message: 'Item saved!'});
            }catch(e){
                onFailure(e.message);
            }
        }

        const request = new NetworkXhr(endpoint, options, onSuccess, onFailure);
        request.start();

        // GlobalMessage.push({type: 'loading'});

        Model.saveRequestQue.set(hash, {
            modelClasses: dependentModelClasses,
            onSuccess: onSuccess,
            onFailure: onFailure,
            options: options
        });

    }

    constructor(selectData, settings={}){

        super(selectData);

        if(! settings.endpoint){
            console.warn('Endpoint must be set for ModelXhr.');
        }

        this.endpoint = settings.endpoint;
        this.method = settings.method || 'GET';
        this.isAuthorized = settings.isAuthorized

        this.request = null;
        this.timerId = null;
        this.useXMLHttpRequest = true;
        const token = localStorage.getItem("tokenKey")
        this.authorization = token === null?
                null:
                {
                    type: "Bearer",
                    access_token: token
                };

        this.execRequest = this.execRequest.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onFailure = this.onFailure.bind(this);

    }

    fetchData(){
        // hold for a moment, in case more settings are going to
        // change that will again trigger a request
        clearTimeout(this.timerId);
        if(this.request != null){
            this.request.abort();
            this.status = Model.Status.WAITING;
        }
       if((this.isAuthorized && this.authorization?.access_token) || !this.isAuthorized){
           this.timerId = setTimeout(this.execRequest, 50);

           this.trigger();
       }

    }

    execRequest(opt_params = null){
        let data = this.formatRequest();

        let params = {
            method: this.method,
            useXMLHttpRequest: this.useXMLHttpRequest,
            data: data,
            body: this.getRequestBody(),
            contentType: this.getContentType(),
            authorization: this.authorization
        };
        if(opt_params != null){
            params = {...params, ...opt_params};
        }

        this.request = new NetworkXhr(this.getEndpoint(), params, this.onSuccess, this.onFailure);
        this.request.start();

    }

    getRequestBody(){
        return '';
    }



    getContentType(){
        return null;
    }

    getEndpoint(){
        return this.endpoint;
    }

    onSuccess(dataRaw){
        this.status = Model.Status.SUCCESS;
        let meta = this.getMetaFromResponse(dataRaw);
        let data = this.getDataFromResponse(dataRaw);
        this.request = null;

        // note status might have changed in getMetaFromResponse of getDataFromResponse
        if(this.status === Model.Status.SUCCESS){
            this.setData(data, meta);
        }

    }

    onFailure(error){
        this.request = null;
        this.setError(error);
    }


    formatRequest(){

        let input = this.select;

        if(! input || typeof input != 'object'){
            return input;
        }

        for(let key of Object.keys(input)){
            if(typeof input[key] == 'object'){
                input[key] = JSON.stringify(input[key]);
            }
        }

        return input;
    }

    getMetaFromResponse(data){
        return null;
    }

    getDataFromResponse(data){

        try{
            const response = JSON.parse(data);


            if(! response['success'] && ! response['status'] && !response){ // status is used in older projects
                let error = response['error'] || response['result'] || 'The server returned an unknown error';
                if(error instanceof Array){
                    error = error.join(' \n');
                }
                // console.error(this.name + ': ' + error)
                this.onFailure(error);
                return null;
            }
            return response.result;
        }catch(e){
            if(this.request && this.request.aborted){
                console.warn('Cannot process request, probably because it was aborted. Exception: ' + e.message);
                this.status = 'waiting';
            }else{
                this.onFailure(e);
            }
        }

        return null;
    }


    getCache(){
        if(this.method === 'POST'){
            return false;
        }
        return super.getCache();
    }

    remove(){
        try{
            clearTimeout(this.timerId);
            if(this.request != null){
                this.request.remove();
            }

            super.remove();

        }catch(e){
            console.warn('Error removing ModelXhr: ' + e.message);
        }
    }



}

export {ModelXhr};
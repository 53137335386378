import React, { useState } from "react";
import { Modal, Form, Button, notification, Input, DatePicker, Switch, Select, Rate, Space, InputNumber } from "antd";

import { allProducts } from "../../../assets/constants.js";
import { MinusCircleOutlined } from "@ant-design/icons";
import { ModelProducts } from "../../../model/products.js";
import {hotels, packageExtras} from '../../../assets/constants.js';
import moment, { isDate } from "moment";



const PageAdminPackagesNew = function (props) {
    const { initialValues } = props;
    const [, setProductType] = useState(null)
    const [form] = Form.useForm();

    const onSubmit = (values) => {
        const products = []
        if (!initialValues?.type) {
            for (const type of values.type) {
                const body = {
                    type: type,
                    label: values.label,
                    description: values.description,
                    stock: values.stock,
                    price: values.price,
                    is_active: values.is_active,
                    start: values['range-picker'][0].format("YYYY-MM-DD"),
                    end: values['range-picker'][1].format("YYYY-MM-DD"),
                    payment_deadline: moment(values.payment_deadline).format("YYYY-MM-DD HH:mm"),
                    flex: {}
                };

                if (values.bulletpoints) {
                    body.flex.bulletpoints = values.bulletpoints
                }

                if (values.priority !== null || values.priority !== undefined) {
                    body.flex.priority = values.priority;
                }
                if (values.hotels) {
                    body.flex.hotels = values.hotels
                }
                if (values.extras) {
                    body.flex.extras = values.extras
                }
                if (values.region) {
                    body.flex.region = values.region
                }
                products.push(body)
            }
        } else {
            const body = {
                type: initialValues.type,
                label: values.label,
                description: values.description,
                stock: values.stock,
                price: values.price,
                is_active: values.is_active,
                start: values['range-picker'][0].format("YYYY-MM-DD"),
                end: values['range-picker'][1].format("YYYY-MM-DD"),
                payment_deadline: moment(values.payment_deadline).format("YYYY-MM-DD HH:mm"),
                flex: {}
            };

            if (values.bulletpoints) {
                body.flex.bulletpoints = values.bulletpoints
            }
            if (values.priority !== null || values.priority !== undefined) {
                body.flex.priority = values.priority;
            }

            if (values.hotels) {
                body.flex.hotels = values.hotels
            }
            if (values.extras) {
                body.flex.extras = values.extras
            }
            if (values.region) {
                body.flex.region = values.region
            }
            products.push(body)
        }


        if (initialValues) {
            ModelProducts.updateItem(initialValues.product_id, products[0], () => {
                notification.success({
                    message: 'Product is succesvol gewijzigd.'
                });
                props.setInitialValues(null);
                props.setVisible();
            })
        } else {
            ModelProducts.createItem(products, () => {
                notification.success({
                    message: 'Product is succesvol aangemaakt.'
                });
                props.setVisible();
            })
        }
    };

    const flex = initialValues ? JSON.parse(initialValues.flex) : null;


    return <Modal visible={props.visible}

        maskClosable={false}
        title={'Voeg nieuwe product toe'}
        footer={false}
        onCancel={() => {
            props.setInitialValues(null)
            props.setVisible()
        }}>
        <Form layout={'vertical'}
            onFinish={onSubmit}
            form={form}
            onValuesChange={(value) => {
                if (value.type) {
                    setProductType(value.type)
                }
            }}
            initialValues={initialValues ?
                {
                    ...initialValues,
                    price: parseInt(initialValues.price),
                    type: [initialValues.type],
                    hotels: flex?.hotels,
                    priority: flex?.priority,
                    region: flex?.region,
                    bulletpoints: flex?.bulletpoints,
                    "range-picker": [
                        isDate(new Date(initialValues.start)) ? moment(initialValues.start) : null,
                        isDate(new Date(initialValues.end)) ? moment(initialValues.end) : null
                    ],
               
                } :
                { is_active: false }}
            style={{ width: '100%' }}>
            <Form.Item name="type" label="Type" rules={[{ required: true, message: "Type is verplicht!" }]}>
                <Select mode={'multiple'}>
                    {allProducts
                        .map((product, key) => {
                            return <Select.Option key={key} value={product.product_id}>{product.label}</Select.Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item name="label" label="Titel" rules={[{ required: true, message: "Titel is verplicht!" }]}>
                <Input />
            </Form.Item>
            <Form.Item name="description" label="Omschrijving" rules={[{ required: true, message: "Omschrijving is verplicht!" }]}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item name="price" label="Prijs" rules={[{ required: true, message: "Titel is verplicht!" }, { type: 'number', min: 0, message: "Bedrag moet boven 0 zijn" },]}>
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="is_active" label="Actief" valuePropName="checked">
                <Switch />
            </Form.Item>
            <Form.Item name="stock" label="Voorraad" rules={[{ required: true, message: "Voorraad is verplicht!" }, { type: 'number', min: 0, message: "Bedrag moet boven 0 zijn" },]}>
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="range-picker" label="Start - eind datum" rules={[{ required: true, message: "Periode is verplicht!" }]}>
                <DatePicker.RangePicker />
            </Form.Item>
            <Form.Item name="payment_deadline" label="Deadline betaaldatum" rules={[{ required: true, message: "Periode is verplicht!" }]}>
                <DatePicker showTime={{ format: 'HH:mm' }} />
            </Form.Item>
            <Form.List name="bulletpoints">
                {(guests, { add, remove }) => {
                    return <>
                        {guests.map(field => (
                            <Space key={field.key} align="baseline">
                                <Form.Item className={'form-input'} name={[field.name, 'label']} label={`Bulletpoint ${field.key + 1}`} rules={[{ required: true, message: "Bulletpoint is verplicht!" }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item className={'form-input'} name={[field.name, 'description']} label={`Bulletpoint tooltip ${field.key + 1}`}>
                                    <Input />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </Space>
                        ))}
                        <Button onClick={() => add()}>
                            + Voeg bulletpoint toe
                        </Button>
                    </>
                }}
            </Form.List>
            <br></br>
            <Form.Item name="hotels" label="Hotels" rules={[{ required: true, message: "Hotels zijn verplicht !" }]}>
                <Select mode={'multiple'}>
                    {hotels.map((hotel, key) => {
                            return <Select.Option key={"hotel"+key} value={key}>{hotel.label}</Select.Option>
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item name="region" label="Regio">
                <Select mode={'multiple'}>
                    <Select.Option value={0}>Noord</Select.Option>
                    <Select.Option value={1}>Midden en zuid</Select.Option>
                    <Select.Option value={2}>Midden en noord</Select.Option>
                    <Select.Option value={3}>Midden</Select.Option>
                    <Select.Option value={4}>Zuid</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="priority" label="Priority">
                <InputNumber min={0} />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="contact-form-button">
                {(initialValues) ? 'Updaten' : 'Toevoegen'}
            </Button>
        </Form>
    </Modal>
};

export { PageAdminPackagesNew }

import React, {useState} from "react";
import {Modal, Upload, message, Form, Input, Button, Select, notification} from "antd";
import { EditorState, ContentState, convertFromHTML} from 'draft-js';
import { stateToHTML} from 'draft-js-export-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {ModelArticles} from "../../../model/articles.js";
import ImgCrop from "antd-img-crop";
import {InboxOutlined} from "@ant-design/icons";
import {ModelImgbb} from "../../../model/imgbb.js";

const PageAdminArticlesNewKennis = function (props) {
    const {initialValues} = props;
    const [form] = Form.useForm();
    const editorState = initialValues?.html ?
            EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(initialValues.html)
                )
            ) :
            EditorState.createEmpty();
    const [editor, setEditor] = useState(editorState);
    const [fileList, setFileList] = useState([]);

    const onSubmit = (values) => {
        const {html, ...restValues} = values;
        const article = {
            ...restValues,
            html: stateToHTML(editor.getCurrentContent()),
            img: fileList[0]?.base64 || initialValues?.img,
            flex: JSON.stringify(initialValues?.flex ? initialValues?.flex : {}),
            type: initialValues?.type ? initialValues?.type : 0,
        };
        const img = article.img.substr(article.img.indexOf(',') + 1)
        ModelImgbb.createItem({image: img}, (response)=>{
            article.img = response.data.url
            if(initialValues){
                ModelArticles.updateItem(initialValues.id, article, ()=>{
                    notification.success({
                        message: 'Kennis is gewijzigd.'
                    });
                    props.setInitialValues(null);
                    props.setVisible();
                })
            }else{
                ModelArticles.createItem(article, ()=>{
                    notification.success({
                        message: 'Kennis is succesvol toegevoegd.'
                    });
                    props.setVisible();
                })
            }

        })



    };

    const checkHTML = (rule, value) => {
        if (stateToHTML(editor.getCurrentContent()) &&
            stateToHTML(editor.getCurrentContent()) !== "<p><br></p>") {
            return Promise.resolve();
        }

        return Promise.reject('Kennis is verplicht!');
    };

    const checkImage = (rule, value) => {
        if (fileList.length === 1 || initialValues.img) {
            return Promise.resolve();
        }

        return Promise.reject('Foto is verplicht!');
    };


    const draggerProps = {
        fileList: fileList,
        customRequest: ()=>{},
        onRemove: file => {
            setFileList([]);
        },
        onChange: (info) => {
            let fileList = [info.file];
            if (info.file.type.toLowerCase() !== 'image/jpg' &&
                info.file.type.toLowerCase() !== 'image/jpeg') {
                message.error(`${info.file.name} is geen a jpg of jpeg bestand.`);
            }else{
                fileList.forEach((file) =>{

                    let reader = new FileReader();
                    reader.onload = (e) => {
                        file.base64 = e.target.result;
                        file.status = 'done'
                    };
                    reader.readAsDataURL(file.originFileObj);
                });
                setFileList(fileList)
            }
        },
        beforeUpload: (file) => {
            return true;
        },
        listType: 'picture',
        showUploadList: {
            showPreviewIcon: false
        }
    };

    return <Modal visible={props.visible}
                  maskClosable={false}
                  width={1200}
                  className={'new-kennis-editor'}
                  title={'Voeg nieuwe kennis toe'}
                  okText={'Uploaden'}
                  footer={false}
                  cancelText={'Annuleren'}
                  onCancel={(e)=>{props.setVisible(false);props.setInitialValues(null)}}>
        <Form layout={'vertical'} onFinish={onSubmit} form={form} initialValues={{
            ...initialValues,
            categories: initialValues?.categories.map((item)=>item.article_category_id)
        }}>
            <Form.Item name="label" label="Titel" rules={[{ required: true, message: "Titel is verplicht!" }]}>
                <Input/>
            </Form.Item>
            <Form.Item name="description" label="Artikel omschrijving" rules={[{ required: true, message: "Omschrijving is verplicht!" }]}>
                <Input.TextArea/>
            </Form.Item>
            <Form.Item name="categories" label="Categorieen">
                <Select mode={'multiple'}>
                    {props.categories.map((cat, key)=>{
                        return <Select.Option key={key} value={cat.article_category_id}>{cat.label}</Select.Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item name="img" label="Foto"  rules={[{ validator: checkImage}]}>
                <ImgCrop grid aspect={25/19} modalTitle={'Bewerk'} modalOk={'Opslaan'} modalCancel={'Annuleren'}>
                    <Upload.Dragger {...draggerProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Klik of sleep het bestand naar dit gebied om het te uploaden</p>
                        <p className="ant-upload-hint">
                            Let op, alleen jpg en jpeg bestanden zijn toegestaan.
                        </p>
                        <p className="ant-upload-hint">
                            Een foto is maximaal toegestaan.
                        </p>
                    </Upload.Dragger>
                </ImgCrop>
            </Form.Item>
            <Form.Item name="html" label="Kennis" rules={[{ validator: checkHTML}]}>
                <Editor

                    toolbar={{
                        inline: {
                            options: ['bold', 'italic', 'underline'],
                        },
                        options: ['inline','blockType','list', 'link', 'history']
                    }}
                    editorState={editor}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName={"editorClassName " + (form.getFieldValue('html') === "<p><br></p>") ? 'error': ''}
                    onEditorStateChange={(editor)=>setEditor(editor)}
                />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="contact-form-button">
                Uploaden
            </Button>
        </Form>

    </Modal>
};

export {PageAdminArticlesNewKennis}

import React from "react";
import { UIText } from "../../../components/ui/text/text.js";
import { Collapse, notification } from "antd";
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import { EditOutlined, LoginOutlined } from "@ant-design/icons";
import { ModelUsers } from "../../../model/users.js";
import { UIDescriptions } from "../../../components/ui/descriptions.js";
import {useHistory} from "react-router-dom";

import moment from "moment";

import { ModelAuth } from "../../../model/auth.js";
import { v4 } from "uuid";
import { AuthContext } from "../../../components/auth/auth-provider.js";

const AdminUsersList = function (props) {
  const resultSet = useModelFeedback(ModelUsers);
  const { setUser } = React.useContext(AuthContext);
  const history = useHistory();


  const handleLoginByEmail = (email) => {
    const onSuccess = (response) => {
      localStorage.setItem("tokenKey", response.token);
      setUser(v4());
      history.push("/");
    };

    const onFailure = () => {
      notification.error({
        message: "Ongeldige combinatie email/wachtwoord. Probeer opniew.",
      });
    };
    ModelAuth.loginByEmail(email, onSuccess, onFailure);
  };

  if (resultSet.feedback) {
    return resultSet.feedback;
  }

  const genExtraCategories = (user) => (
    <>
      <EditOutlined
        onClick={(event) => {
          props.setInitialValues({ ...user, updateType: "admin" });
          props.setModal("users");
        }}
      />
      <LoginOutlined
        onClick={(event) => {
          handleLoginByEmail(user.email);
        }}
      />
    </>
  );

  return (
    <div>
      <UIText variant={"header-title"}>Gebruikers</UIText>
      <Collapse expandIconPosition={"start"}>
        <Collapse.Panel
          disabled={true}
          show
          header={<span>Naam</span>}
          key={100004}
          extra={<span>Acties</span>}
        ></Collapse.Panel>
        {resultSet.data.users?.length > 0 ? (
          resultSet.data.users
            ?.filter((user) => {
              if (props.search === null) {
                return true;
              }
              return user[props.search.type]
                .toString()
                .toLowerCase()
                .includes(props.search.value.toString().toLowerCase());
            })
            ?.map((user, key) => {
              const header = (
                <span>
                  #{user.user_id} {user.first_name} {user.last_name}
                </span>
              );
              const labels = {
                user_id: "Gebruiker id",
                email: "Email",
                first_name: "Voornaam",
                last_name: "Achternaam",
                phone_number: "Telefoonnummer",
                date_of_birth: "Geboortedatum",
                nationality: "Nationaliteit",
                address: "Adres",
                zip: "Postcode",
                city: "Plaats",
                country: "Land",
              };

              const renderLabelFunctions = {
                date_of_birth: (value) => moment(value).format("DD-MM-YYYY"),
              };
              return (
                <Collapse.Panel
                  header={header}
                  key={key}
                  extra={genExtraCategories(user)}
                >
                  <UIDescriptions
                    labels={labels}
                    item={user}
                    renderLabelFunctions={renderLabelFunctions}
                  />
                </Collapse.Panel>
              );
            })
        ) : (
          <Collapse.Panel
            disabled={true}
            showArrow={false}
            header={"Er zijn geen gebruikers"}
            key={10005}
          />
        )}
      </Collapse>
    </div>
  );
};

export { AdminUsersList };

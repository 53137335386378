import './style.scss'
import React from "react";
import {UIText} from "../../components/ui/text/text.js";

const PageTermsAndConditions = function (props) {
    return <div className={'default-page-container'}>
        <UIText variant={'title'}>Algemene voorwaarden</UIText>
        <UIText variant={'paragraph-title'}>Artikel 1 Algemeen</UIText>
        <UIText>Deze gebruiksvoorwaarden (hierna: Gebruiksvoorwaarden) zetten uiteen onder welke voorwaarden gebruik gemaakt mag worden van de website www.firstclassumrah.nl (hierna: Website). De Website wordt beheerd door de besloten venootschap Noor B.V., geregistreerd onder KvK-nummer 78044715 en gevestigd op Louis couperusstraat 41, 1064CA te Amsterdam. Waar in de Gebruiksvoorwaarden gesproken wordt over ‘u’ wordt bedoeld de gebruiker van de Website. Waar in de Gebruiksvoorwaarden wordt gesproken over ‘wij’ of ‘ons’, wordt bedoeld www.firstclassumrah.nl. </UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Artikel 2 Privacyverklaring</UIText>
        <UIText>Op het gebruik van de Website is de privacyverklaring van www.firstclassumrah.nl  van toepassing. Onder gebruik wordt tevens verstaan het downloaden of installeren van de Website..</UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Artikel 3 Toepasselijkheid</UIText>
        <UIText>Door de website te gebruiken gaat u akkoord met deze Gebruiksvoorwaarden en onze privacyverklaring
Wanneer de Website diensten van derden gebruikt, zijn de voorwaarden van deze derden tevens van toepassing. Wij zijn op geen enkele wijze verantwoordelijk voor deze diensten van derden, alsmede hun voorwaarden. Ingeval van een tegenstrijdigheid of meerdere tegenstrijdigheden, prevaleren onze Gebruiksvoorwaarden en onze privacyverklaring
Wij hebben te allen tijde het recht deze Gebruiksvoorwaarden aan te passen, het gebruik van de Website te beperken, of u geheel of gedeeltelijk, tijdelijk of permanent het gebruik van de website te ontzeggen.
Indien u de website gebruikt vanaf de datum van inwerkingtreding van eventuele aangepaste Gebruiksvoorwaarden, dan geldt dit als een acceptatie van deze aangepaste Gebruiksvoorwaarden. 
</UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Artikel 4 Gebruik van de App</UIText>
        <UIText> Wij zorgen met de grootst mogelijke zorg dat de website beschikbaar voor u is. Hoe de website in de praktijk functioneert, is echter afhankelijk van uw apparaat, de software en het besturingssysteem van uw apparaat en uw internetverbinding. 
Het is verboden de website te wijzigen, kopiëren, op enigerlei wijze beschadigen, overbelasten of het gebruik en functioneren van de website op enigerlei wijze te verhinderen. 
Het is tevens verboden om de website op welke manier dan ook voor commerciële doeleinden te gebruiken, tenzij van ons vooraf schriftelijke toestemming is verkregen. 
Door de website te downloaden, te installeren of te gebruiken verklaart u volledig te begrijpen dat: 
het gebruik van de website alsmede het downloaden en installeren ervan geheel voor uw rekening en risico is; 
wij niet kunnen garanderen dat de website op enigerlei wijze vrij zal van fouten of gebreken in de breedste zin van het woord, en dat wij ook niet kunnen garanderen dat de website zonder onderbreking te allen tijde beschikbaar is en/of zal zijn. 
wij niet kunnen garanderen dat de informatie gegeven in de website volledig, juist en actueel is en/of zal zijn. 
U kunt zelf op ieder moment het gebruik van de website beëindigen door de website van uw apparaat te verwijderen of te de-installeren. </UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Artikel 5 Intellectuele eigendomsrechten</UIText>
        <UIText>Alle intellectuele eigendomsrechten met betrekking tot de website, waaronder maar niet uitsluitend de auteursrechten op de website, berusten exclusief en uitsluitend bij ons. 
U zult onze naam en reputatie te allen tijde in acht nemen en de website niet zodanig gebruiken dat deze schade toebrengt aan onze rechten of reputatie of dreigt schade toe te brengen aan onze rechten of reputatie. 
Wij geven u een persoonlijk, niet-exclusief, niet-overdraagbaar, herroepelijk en niet voor sublicenties vatbaar recht om de website en daarin opgenomen inhoud voor persoonlijk gebruik te gebruiken, zulks met inachtneming van deze Gebruiksvoorwaarden.   .</UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Artikel 6 Aansprakelijkheid</UIText>
        <UIText>
Wij zijn niet aansprakelijk voor directe of indirecte schade voortvloeiende uit het downloaden, installeren of gebruik van deze website en/of de onmogelijkheid om deze te downloaden, te (de-)installeren of te gebruiken. Onder indirecte schade wordt onder andere maar niet uitsluitend verstaan gevolgschade, reputatieschade, schade als gevolg van bedrijfsstagnatie, systeem/computerstoringen, verlies en/of verminking van data, gederfde winst en omzet, verlies van klanten, verminderde goodwill en gemiste besparingen.
Evenmin zijn wij aansprakelijk voor de juistheid van de informatie, bijdragen of andere content gedeeld door u of door derden. Wij behouden ons het recht voor om zonder voorafgaande aankondiging informatie, bijdragen of andere content te verwijderen uit de App.
Ook zijn wij niet aansprakelijk voor aanspraken van derden als gevolg van het gebruik van de website,, of wanneer u niet in staat bent (volledig of correct) wettelijke of contractuele verplichtingen jegens ons of een derde na te komen. Alle schade en kosten die wij door dergelijke aanspraken moeten maken, worden door u vergoed. 
Onverminderd het voorgaande, indien in rechte vast komt te staan dat wij ons niet kunnen beroepen op de beperking van onze aansprakelijkheid, dan is de totale aansprakelijkheid van ons, ongeacht de grond voor aansprakelijkheid, jegens u beperkt tot een bedrag van €25,00 (zegge: vijfentwintig euro) per kalenderjaar voor alle gebeurtenissen in dat kalenderjaar.</UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Artikel 7 Substitutie van ongeldige of niet-afdwingbare bepaling</UIText>
        <UIText>
Indien vast komt te staan dat een bepaling in deze Gebruiksvoorwaarden ongeldig of niet-afdwingbaar is of wordt, dan blijven de overige bepalingen onverminderd van kracht. Partijen zullen dan deze ongeldige of niet-afdwingbare bepaling vervangen voor een bepaling die qua aard en strekking zoveel mogelijk dezelfde werking heeft als de ongeldige of niet-afdwingbare bepaling. </UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Artikel 8 Toepasselijk recht</UIText>
        <UIText>Op deze Gebruiksvoorwaarden is Nederlands recht van toepassing.</UIText>
    </div>
};

export {PageTermsAndConditions}
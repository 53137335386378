import "./admin.scss";
import React, { useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import { UIText } from "../../components/ui/text/text.js";

import { CiCircleCheck, CiCircleQuestion } from "react-icons/ci";

const PaymentProcess = function (props) {
  const { orderID } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const orderStatusId = urlParams.get("orderStatusId");
  const orderStatusIdSecond = urlParams.get("order_status_id");
  const history = useHistory();

  setTimeout(() => history.push("/admin"), 3000);

  return (
    <div className={"payment-container"}>
      <UIText variant={"title"}>Order #FCU{orderID}</UIText>
      {orderStatusId === "100" || orderStatusIdSecond === "100" ? (
        <>
          {" "}
          <CiCircleCheck className="circleIcon green" />
          <h2>
            Betaling is geslaagd, je wordt omgeleid naar het admin portaal..
          </h2>
        </>
      ) : (
        <>
          {" "}
          <CiCircleQuestion className="circleIcon orange" />
          <h2>
            Betaling is nog niet gelukt, controleer de betaling en of probeer
            het opnieuw. Je wordt omgeleid naar het admin portaal..
          </h2>
        </>
      )}
    </div>
  );
};

export { PaymentProcess };

import React from "react";
import {UIText} from "../../../components/ui/text/text.js";
import {Collapse, notification, Tag} from "antd";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {CloseOutlined, EditOutlined} from "@ant-design/icons";
import {articleTypes} from "../../../assets/constants.js";
import constants from '../../../assets/constants.json'
import {ModelArticles} from "../../../model/articles.js";
import {UIDescriptions} from "../../../components/ui/descriptions.js";
import parse from 'html-react-parser';

const AdminArticlesList = function (props) {
    const resultSet = useModelFeedback(ModelArticles)

    if(resultSet.feedback){
        return resultSet.feedback
    }

    const genExtra = (article) => (
        <>
            {(article.type === constants.ARTICLE_TYPE.ARTICLE) &&
                    <EditOutlined
                        onClick={event => {
                            props.setInitialValues(article)
                            props.setNewArticle('kennis')
                        }}
                    />
            }
            <CloseOutlined
                onClick={() => {
                    ModelArticles.deleteItem(article.id, ()=>{
                        notification.success({
                            message: "Artikel succesvol verwijderd."
                        })
                    })
                }}
            />
        </>
    );

    const renderFlyer = (article) => {
        return {
            labels: {
                img: "Flyer foto"
            },
            renderLabelFunctions: {
                img: (value) => <img alt={''} src={value} style={{ width: '300px'}}/>
            },
            item: article,
        }
    };

    const renderArticle = (article) => {
        return {
            labels: {
                img: "Artikel foto",
                label: "Titel",
                html: "Kennis",
                description: "Omschrijving",
                categories: "Categories"
            },
            renderLabelFunctions: {
                img: (value) => <img alt={''} src={value} style={{ width: '300px'}}/>,
                categories: (value) => value.map((v, key)=><Tag key={key}>{v.label}</Tag>),
                html: (value) => parse(value),
            },
            item: article,
        }
    };

    return <div>
        <UIText variant={'paragraph-title'}>Kennis / flyers management</UIText>
        <Collapse expandIconPosition={'start'}>
            <Collapse.Panel disabled={true} header={<span><Tag>ID</Tag><Tag>Type</Tag>Naam</span>} key={10000} extra={<span>Acties</span>}>

            </Collapse.Panel>
            {(resultSet.data.articles?.length > 0) ? resultSet.data.articles?.map((article, key)=>{
                    const articleType = articleTypes.find((a)=>a.id === article.type);
                    const header = <span>
                    <Tag color={'orange'} style={{minWidth: 25}}>{article.id}</Tag>
                    <Tag color={'orange'}>{articleType?.label}</Tag>
                        {article.label}
                </span>

                    return <Collapse.Panel  header={header} key={key} extra={genExtra(article)}>
                        <UIDescriptions {...(article.type === constants.ARTICLE_TYPE.ARTICLE) ? renderArticle(article) : renderFlyer(article)}/>
                    </Collapse.Panel>
                }) :
                <Collapse.Panel disabled={true} showArrow={false} header={'Er zijn geen artikelen'} key={10001}>

                </Collapse.Panel>
            }

        </Collapse>
    </div>
}

export {AdminArticlesList}
import './carousel.scss'
import React from "react";
import {Teared} from "../../components/ui/teared/teared.js";
import {UICarousel} from "../../components/ui/carousel/carousel.js";
import {UIText} from "../../components/ui/text/text.js";
import {useTranslation} from "../../helpers/languange.js";
import {Image} from 'antd';
import {useModelFeedback} from "../../helpers/model/use_model_feedback.js";
import {ModelArticles} from "../../model/articles.js";
import constants from "../../assets/constants.json";

const HomeCarousel = function (props) {
    const resultSet = useModelFeedback(ModelArticles)
    const text = useTranslation();
    if(resultSet.feedback){
        return resultSet.feedback
    }

    return  <Teared color={'orange'} >
        <div className={'home-carousel'}>
            <UIText variant={'paragraph-title'} className={'carousel-title'}>{text.homepage.section_2.label}</UIText>
            <UICarousel className={'carousel-container'}>
                {
                    resultSet.data?.articles
                    ?.filter((item=> (item.type === constants.ARTICLE_TYPE.FLYER)))
                    .map((article, key)=>{
                        return <Image key={key} src={article.img} className={'carousel-item'}/>
                    })
                }
            </UICarousel>
        </div>

    </Teared>
};

export {HomeCarousel}
import './style.scss'
import React from "react";
import {UIText} from "../../components/ui/text/text.js";

const PagePrivacy = function (props) {
    return <div className={'default-page-container'}>
        <UIText variant={'title'}>Privacy</UIText>
        <UIText>Als u bestelt via onze website, verwerkt Noor BV (handelend onder de naam First Class Umrah) bepaalde persoonsgegevens van u. Dit is soms noodzakelijk voor de uitvoering van de overeenkomst met u en soms kunt u zelf beslissen of u wel of geen gegevens verstrekt. In het vervolg wordt precies beschreven welke persoonsgegevens voor welk doeleinde gebruikt worden als u gebruikmaakt van de website.</UIText>
        <br/>
        <UIText>Heeft u vragen over deze privacyverklaring? U kunt contact met ons opnemen per e-mail via info@firstclassumrah.nl</UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Waarvoor worden uw persoonsgegevens gebruikt?</UIText>
        <UIText>Wij verwerken uw persoonsgegevens, zodat wij uw bestelling kunnen uitvoeren, maar ook om bijvoorbeeld klantenservice te kunnen verlenen. Ook kunnen wij uw persoonsgegevens gebruiken voor het verzenden van nieuwsbrieven, maar alleen als u daar toestemming voor geeft.</UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Welke persoonsgegevens verwerken wij?</UIText>
        <UIText>Afhankelijk van hoe u de website gebruikt, verwerken wij in elk geval uw naam, geboortedatum, e- mailadres en telefoonnummer. Wij hebben deze gegevens nodig om uw bestelling te verwerken, uw reservering voor een pakketreis in orde te maken, contact met u te kunnen opnemen voor vragen en u informatie te geven over het verloop van de reies. Als u de gegevens die wij hiervoor hebben genoemd niet wilt geven, dan is het niet mogelijk om een bestelling te plaatsen.</UIText>
        <br/>
        <UIText>Wij verwerken geen persoonsgegevens buiten de EU en maken geen gebruik van geautomatiseerde besluitvorming zoals profilering. Wel zullen wij of derden aan hotels in Saudi-Arabië uw persoonsgegevens verstrekken, zodat wij uw reservering bij dit hotel kunnen regelen. Ook kan het zijn dat uw gegevens buiten de EU worden verwerkt door de luchtvaartmaatschappij waarmee u vliegt. U zult altijd van tevoren op de hoogte worden gesteld welke derden uw persoonsgegevens zullen verwerken. Neemt u contact op met de betreffende organisatie over hun privacybeleid.</UIText>
        <br/>
        <UIText>Voor het betaalverkeer via onze website zorgt Tintel B.V. Als u meer informatie wilt weten welke gegevens Tintel B.V. verwerkt, dan verwijzen wij u naar de privacyverklaring van Tintel B.V. Deze kunt u vinden op www.pay.nl.</UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Grondslag voor verwerking</UIText>
        <UIText>De grondslag voor de verwerking is de uitvoering van de overeenkomst die u met ons sluit zodra u een bestelling plaatst. In het geval u zelf kunt beslissen of u wel of geen gegevens verstrekt, dan is de grondslag voor verwerking uw toestemming. U kunt uw toestemming op elk gewenst moment weer intrekken.</UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Bewaartermijn</UIText>
        <UIText>We bewaren de persoonsgegevens alleen indien dit strikt noodzakelijk is of zolang dit wettelijk verplicht is. Verkoop- en transactiegegevens zijn wij verplicht 7 jaar te bewaren in verband met fiscale verplichtingen. Uw naam, geboortedatum, e-mailadres en telefoonnummer bewaren wij maximaal 2 jaar na het einde van de laatste overeenkomst met ons.</UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Verstrekking van persoonsgegevens aan derden</UIText>
        <UIText>Wij zullen de persoonsgegevens van de gebruikers van de website niet verstrekken aan derden, tenzij wij op grond van een wettelijke verplichting gehouden zijn persoonsgegevens door te geven aan bevoegde instanties.</UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Minderjarigen</UIText>
        <UIText>In sommige gevallen vragen wij om toestemming om bepaalde persoonsgegevens te kunnen gebruiken. Als de gebruiker van de website jonger is dan 16 jaar, heeft de gebruiker in die gevallen de toestemming van zijn of haar ouder of voogd nodig om de persoonsgegevens te verstrekken aan ons. De ouder of voogd kan in dat geval ook altijd de aan ons verstrekte gegevens aanpassen of verwijderen</UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Rechten</UIText>
        <UIText>U heeft recht op inzage, rectificatie en verwijdering van zijn of haar persoonsgegevens, overeenkomstig het bepaalde in de Algemene Verordening Gegevensbescherming. U heeft tevens het recht om bezwaar te maken tegen de verwerking van zijn of haar persoonsgegevens.</UIText>
        <br/>
        <UIText>U kunt ons verzoeken om beperking van de verwerking en gegevensoverdraagbaarheid. Dit laatste houdt in dat u het recht heeft om de persoonsgegevens die wij van u hebben, in een gestructureerd, gangbaar en machineleesbare vorm te ontvangen en eventueel door u te laten doorgeven aan een andere verwerkingsverantwoordelijke.</UIText>
        <br/>
        <UIText>Als u toestemming heeft gegeven voor de verwerking van zijn persoonsgegevens, heeft u te allen tijde het recht om deze toestemming in te trekken.</UIText>
        <br/>
        <UIText>Tot slot heeft u het recht om een klacht in te dienen bij de bevoegde toezichthouder. Dit is de Autoriteit Persoonsgegevens. Dit kunt u doen via de website www.autoriteitpersoonsgegevens.nl.</UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Cookies</UIText>
        <UIText>First Class Umrah maakt uitsluitend gebruik van functionele cookies. Deze cookies zijn noodzakelijk voor het goed functioneren van de website, bijvoorbeeld om uw webwinkelwagen bij te kunnen houden.</UIText>
        <br/>
        <UIText variant={'paragraph-title'}>Wijzigingen in deze privacyverklaring</UIText>
        <UIText>Wij behouden ons het recht voor deze privacyverklaring aan te passen. Wij adviseren u dan ook regelmatig of er aanpassingen zijn doorgevoerd. De huidige privacyverklaring is bijgewerkt op 3 januari 2022.</UIText>

    </div>
};

export {PagePrivacy}
import React, { useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Rate,
  InputNumber,
} from "antd";
import moment, { isDate } from "moment";

import { FaChild, FaPlus, FaMinus, FaInfoCircle, FaBed } from "react-icons/fa";

import { UICollapseable } from "../../components/ui/collapseable.js";
import { ModelContact } from "../../model/contact.js";
import { ModelAuth } from "../../model/auth.js";

import ReCAPTCHA from "react-google-recaptcha";
import "./single.scss";

const PagePackagesSingle = function (props) {
  const recaptchaRef = React.createRef();

  const onSubmit = (values) => {
    const { password, ...restValues } = values;
    const body = {
      ...restValues,
      date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
    };

    if (password !== undefined) {
      body.password = password;
    }
    if (props.loginOnRegister !== undefined) {
      body.login_user = false;
    }

    // ModelAuth.register(
    //   body,
    //   (response) => {
    //     console.log(response);
    //   },
    //   (error) => {
    //     if (error.indexOf("Unprocessable") > -1) {
    //       return notification.error({
    //         message: "Dit emailadres wordt al gebruikt.",
    //       });
    //     }
    //     return notification.error({
    //       message:
    //         "Een onbekende fout heeft zich opgetreden, neem contact met ons op.",
    //     });
    //   }
    // );

    if (recaptchaRef.current.getValue().length > 0) {
      const singleDetails = {
        start: values["range-picker"][0].format("DD-MM-YYYY"),
        end: values["range-picker"][1].format("DD-MM-YYYY"),
        hotel_name: values.hotel_name,
        hotel_rating: values.hotel_rating,
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        date_of_birth: values.date_of_birth.format("DD-MM-YYYY"),
        nationality: values.nationality,
        phone_number: values.phone_number,
        address: values.address,
        zip: values.zip,
        city: values.city,
        country: values.country,
        rooms: values.rooms
      };

      ModelContact.requestSinglePackage(singleDetails, () => {
        props.onClose();
        notification.success({
          message: "Er zal binnen 5 tot 7 dagen contact opgenomen worden.",
          duration: 5,
        });
      });
    } else {
      notification.error({
        message: "Verifieer dat u geen robot bent!",
      });
    }
  };

  return (
    <Modal
      onCancel={props.onClose}
      maskClosable={false}
      title={"Umrah op maat"}
      visible={props.selected}
      className={"modal"}
      footer={false}
    >
      <Form
        layout={"vertical"}
        onFinish={onSubmit}
        initialValues={{
          rooms: [{ "babys": 0, "kids": 0, "adults": 0 }],
        }}
      >
        <UICollapseable title={"Gasten"} style={{ margin: 0 }}>
          <Form.List
            name="rooms"
            rules={[
              {
                validator: async (_, names) => {
                  if (names.length < 1) {
                    return Promise.reject(new Error("Minimaal 1 item!"));
                  }
                },
              },
            ]}
          >
            {(items, { add, remove }, { errors }) => (
              <>
                {items.map((field) => (
                  <div className="product" key={"idk" + field.name}>
                    <b>Kamer #{field.key + 1}</b>
                    {items.length > 1 && (
                      <FaMinus
                        className="delete"
                        onClick={() => remove(field.key)}
                      />
                    )}
                    <div className="productCont">
                      <Form.Item
                        className={"form-input"}
                        name={[field.name, "babys"]}
                        label={"Baby's (0-24 mnd)"}
                        rules={[
                          {
                            required: true,
                            message: "Aantal is verplicht!",
                          },
                        ]}
                      >
                        <InputNumber />
                      </Form.Item>
                      <Form.Item
                        className={"form-input"}
                        name={[field.name, "kids"]}
                        label={"Kinderen (2-12 jr)"}
                        rules={[
                          {
                            required: true,
                            message: "Aantal is verplicht!",
                          },
                        ]}
                      >
                        <InputNumber />
                      </Form.Item>
                      <Form.Item
                        className={"form-input"}
                        name={[field.name, "adults"]}
                        label={"Volwassenen"}
                        rules={[
                          {
                            required: true,
                            message: "Aantal is verplicht!",
                          },
                        ]}
                      >
                        <InputNumber />
                      </Form.Item>
                    </div>
                  </div>
                ))}
                <Button
                  onClick={() => add()}
                  className="contact-form-button"
                  rules={[
                    {
                      required: true,
                      message: "Gebruiker is verplicht!",
                    },
                  ]}
                >
                  {"Kamer toevoegen "}
                  <FaPlus />
                </Button>
              </>
            )}
          </Form.List>
        </UICollapseable>
        <UICollapseable title={"Hotel details"} style={{ margin: 0 }}>
          <Form.Item name={"hotel_name"} label={"Gewenste hotel naam"}>
            <Input />
          </Form.Item>
          <div className="rowFlex">
            <Form.Item name={"hotel_rating"} label={"Gewenste hotel rating"}>
              <Rate />
            </Form.Item>
            <Form.Item
              name="range-picker"
              label="Gewenste data"
              rules={[{ required: true, message: "Periode is verplicht!" }]}
            >
              <DatePicker.RangePicker format={"DD-MM-YYYY"} />
            </Form.Item>
          </div>
        </UICollapseable>
        <UICollapseable title={"Gebruiker details"} style={{ margin: 0 }}>
          <div className={"center-space-between mobile-vertical"}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Email is verplicht!" },
                { type: "email", message: "Geen geldig email adres." },
              ]}
              style={{ width: "100%", marginRight: ".5rem" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Wachtwoord"
              rules={[{ required: true, message: "Wachtwoord is verplicht!" }]}
              style={{ width: "100%" }}
            >
              <Input type="password" />
            </Form.Item>
          </div>
          <div className={"center-space-between mobile-vertical"}>
            <Form.Item
              name="first_name"
              label="Voornaam"
              rules={[{ required: true, message: "Voornaam is verplicht!" }]}
              style={{ width: "100%", marginRight: ".5rem" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Achternaam"
              rules={[{ required: true, message: "Achternaam is verplicht!" }]}
              style={{ width: "100%" }}
            >
              <Input />
            </Form.Item>
          </div>
          <Form.Item
            name="date_of_birth"
            label="Geboortedatum"
            rules={[{ required: true, message: "Geboortedatum is verplicht!" }]}
            style={{ width: "100%" }}
          >
            <DatePicker format={"DD-MM-YYYY"} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="nationality"
            label="Nationaliteit"
            rules={[{ required: true, message: "Nationaliteit is verplicht!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone_number"
            label="Telefoonnummer"
            rules={[
              { required: true, message: "Telefoonnummer is verplicht!" },
            ]}
          >
            <Input />
          </Form.Item>
          <div className={"center-space-between mobile-vertical"}>
            <Form.Item
              name="address"
              label="Adres"
              rules={[{ required: true, message: "Adres is verplicht!" }]}
              style={{ width: "100%", marginRight: ".5rem" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="zip"
              label="Postcode"
              rules={[{ required: true, message: "Postcode is verplicht!" }]}
              style={{ width: "100%" }}
            >
              <Input />
            </Form.Item>
          </div>
          <div className={"center-space-between mobile-vertical"}>
            <Form.Item
              name="city"
              label="Plaats"
              rules={[{ required: true, message: "Plaats is verplicht!" }]}
              style={{ width: "100%", marginRight: ".5rem" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="country"
              label="Land"
              rules={[{ required: true, message: "Land is verplicht!" }]}
              style={{ width: "100%" }}
            >
              <Input />
            </Form.Item>
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LceuMkjAAAAAEvspC5iGjh-ZQwnwvpB4p5Q8BTX"
          />
        </UICollapseable>
        <Button
          type="primary"
          htmlType="submit"
          className="contact-form-button"
        >
          Verzoek indienen
        </Button>
      </Form>
      <div className={"modal-footer"}>
        <div className={"modal"}>
          <div className={"modal-footer"} />
        </div>
      </div>
    </Modal>
  );
};

export { PagePackagesSingle };

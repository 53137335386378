import React, {useState} from "react";
import {AdminOrdersListSecond} from "./list.js";
import {AdminPaymentNew} from "../payments/new.js";

const PageAdminOrdersSecond = function (props) {
    const [searchType, setSearchType] = useState('id');
    const [searchValue, setSearch] = useState('');
    const [modal, setModal] = useState(null)

    return <div>
        <AdminOrdersListSecond setModal={setModal} search={searchValue.length > 0 ? {type: searchType, value: searchValue} : null}/>
        {modal !== null && <AdminPaymentNew order_id={modal} setVisible={()=>{setModal(null)}}/>}
    </div>
};

export {PageAdminOrdersSecond}
import React from "react";
import { UIText } from "../ui/text/text.js";
import { Button, Form, Input, Modal, notification } from "antd";
import { ModelAuth } from "../../model/auth.js";
import { v4 } from "uuid";
import { AuthContext } from "./auth-provider.js";

const AuthSignIn = function (props) {
  const { visible, setModal } = props;
  const { setUser } = React.useContext(AuthContext);
  const onSubmit = (values) => {
    const onSuccess = (response) => {
      localStorage.setItem("tokenKey", response.token);
      setUser(v4());
      props.onSuccess && props.onSuccess();
      props.onClose && props.onClose();
    };

    const onFailure = () => {
      notification.error({
        message: "Ongeldige combinatie email/wachtwoord. Probeer opniew.",
      });
    };
    ModelAuth.login(values.email, values.password, onSuccess, onFailure);
  };

  return props.onClose ? (
    <Modal
      onCancel={props.onClose}
      title={"Login"}
      maskClosable={false}
      footer={false}
      className={"modal"}
      visible={visible}
    >
      <Form layout={"vertical"} onFinish={onSubmit} className="form">
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Email is verplicht!" },
            { type: "email", message: "Geen geldig email adres." },
          ]}
        >
          <Input placeholder={"Email"} />
        </Form.Item>
        <Form.Item
          name="password"
          label="Wachtwoord"
          rules={[{ required: true, message: "Wachtwoord is verplicht" }]}
        >
          <Input.Password placeholder={"Wachtwoord"} />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="contact-form-button"
        >
          Login
        </Button>
        {props.onClose && (
          <UIText style={{ textAlign: "center", padding: ".5rem 0 0" }}>
            NOG GEEN ACCOUNT?{" "}
            <span
              style={{ color: "#EEC862" }}
              onClick={() => setModal("register")}
            >
              AANMELDEN
            </span>
          </UIText>
        )}

        {props.onClose && (
          <UIText style={{ textAlign: "center", padding: ".5rem 0 0" }}>
            WACHTWOORD {" "}
            <span
              style={{ color: "#EEC862" }}
              onClick={() => setModal("password")}
            >
              VEGETEN?
            </span>
          </UIText>
        )}
      </Form>
      <div className={"modal-footer"}>
        <div className={"modal"}>
          <div className={"modal-footer"} />
        </div>
      </div>
    </Modal>
  ) : (
    <Form layout={"vertical"} onFinish={onSubmit}>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: "Email is verplicht!" },
          { type: "email", message: "Geen geldig email adres." },
        ]}
      >
        <Input placeholder={"Email"} />
      </Form.Item>
      <Form.Item
        name="password"
        label="Wachtwoord"
        rules={[{ required: true, message: "Wachtwoord is verplicht" }]}
      >
        <Input.Password placeholder={"Wachtwoord"} />
      </Form.Item>
      <Button type="primary" htmlType="submit" className="contact-form-button">
        Login
      </Button>
    </Form>
  );
};

export { AuthSignIn };

import './checkout.scss';
import React, {useEffect, useState} from "react";
import {useStateContext} from "../../helpers/state-context/use_state_context.js";
import { useHistory} from "react-router-dom";
import {CheckoutDetails} from "./details.js";
import {UIText} from "../../components/ui/text/text.js";
import {UIButton} from "../../components/ui/button/button.js";
import {UICollapseable} from "../../components/ui/collapseable.js";
import {PageCartDetails} from "../cart/details.js";
import {useUser} from "../../components/auth/use-user.js";
import {notification} from "antd";
import {ModelOrders} from "../../model/orders.js";
import {ModelPayment} from "../../model/payment.js";
import constants from '../../assets/constants.json';
import {roomTypes} from '../../assets/constants.js'




const PageCheckout = function (props) {
    const history = useHistory();
    const user = useUser()
    const [state] = useStateContext();
    const [orderDiscounts, setDiscounts] = useState([]);

    const deleteDiscount =(key)=>{
        let newArr = [].concat(orderDiscounts);
        newArr.splice(key,1);
        setDiscounts(newArr);
    };

    useEffect(()=>{
        if(state.cart?.length === 0){
            history.push('/')
        }
    }, [state, history]);

    const order = (payPartly) => {
        let discounts = [];
        if(user !== null && state.cart.length > 0){
            const orderObject = {
                flex: {
                    pay_partly: payPartly,
                    extras: [],
                    discounts: [],
                }
            };
            for (const cartItem of state.cart){
                if(cartItem.type === 'fund'){
                    //Add crowdfunds to order
                    if(!orderObject.crowdfunds){
                        orderObject.crowdfunds = []
                    }
                    orderObject.crowdfunds.push({
                        funding_id: parseInt(cartItem.id),
                        funding_amount: parseInt(cartItem.value)
                    })
                }else if(cartItem.type === 'arabia'){
                    orderObject.flex.arabia_availibilities = [];
                    orderObject.flex.arabia_availibilities.push({
                        days: cartItem.available_days,
                        times: cartItem.available_times,
                        id: cartItem.id
                    });
                    if(!orderObject.products){
                        orderObject.products = []
                    }
                    cartItem?.exclusive && orderObject.order.products.push(999);

                }else if(cartItem.type === 'umrah'){
                    if(!orderObject.products){
                        orderObject.products = []
                    }
                    cartItem.exclusive && orderObject.products.push(999);
                    if(cartItem.extras !== undefined){
                        orderObject.flex.extras.push(cartItem.extras);
                    }
                    // Add product_ids to order
                    for(const guest of cartItem.guests){
                        orderObject.products.push(parseInt(guest.product_id));
                        !guest?.european_passport && orderObject.products.push(1);
                        if(!guest?.bed_included){
                            orderObject.flex.discounts.push('kid-without-bed');
                        }
                    }
                    // if(cartItem.exclusive === true && cartItem.guests.filter((guest)=>guest.age_category !== constants.AGE_CATEGORY.BABY).length < cartItem.max_guests ){
                    //     const guestsLeft = cartItem.max_guests - cartItem.guests.filter((guest)=>guest.age_category !== constants.AGE_CATEGORY.BABY).length
                    //     const adultID = cartItem.guests.find((guest)=>guest.age_category === constants.AGE_CATEGORY.ADULT)
                    //     if(guestsLeft > 0 && adultID){
                    //         for (let i = 0; i < guestsLeft; i++){
                    //             orderObject.products.push(parseInt(adultID?.product_id))
                    //         }
                    //     }else{
                    //         return notification.error({
                    //             message: "Er moet een volwassen aanwezig zijn."
                    //         })
                    //     }
                    // }

                    if(!orderObject.rooms){
                        orderObject.rooms = []
                    }

                    if(payPartly !== null && payPartly){
                        orderObject.flex.pay_partly = true;
                    }
                    // Add rooms to order
                    //Exlusive should be : cartItem.exclusive
                    let roomType = roomTypes.find((roomType)=>(roomType.id === cartItem.room_type))
                    orderObject.rooms.push({
                        exclusive: cartItem.exclusive,
                        max_guests: roomType.max_guests,
                        room_gender: cartItem.room_gender,
                        guests: cartItem.guests,
                        discounts: discounts
                    })

                }
            }

            let correctDiscounts = orderDiscounts.map((discount)=>(discount.label));
            orderObject.flex.discounts = orderObject.flex.discounts ? orderObject.flex.discounts.concat(correctDiscounts) : correctDiscounts;
            orderObject.flex = JSON.stringify(orderObject.flex);
            //console.log(orderObject)
            ModelOrders.createItem(orderObject, null, (order)=>{
                notification.success({
                    message: "Bestelling succesvol aangemaakt."
                })

                ModelPayment.createItem(order.order.id,{"trans": "none","amount":0}, (payment)=>{
                    return window.location = payment.transaction.paymentURL
                })
            }, ()=>{
                notification.error({
                    message: "Er is iets misgegaan tijdens het verwerken van je bestelling."
                })
            })
        }
        if(!user){
            notification.error({
                message: 'Log in om je betaling af te ronden.'
            })
        }
        if(state.cart.length === 0){
            notification.error({
                message: 'Voeg producten toe om af te rekenen.'
            })
        }
    };

    return <div className={'cart-checkout-container'}>
        <UIText variant={'title'}>Afrekenen</UIText>
        <div className={'checkout-details'}>
            <div className={'checkout-user-details'}>
                <UICollapseable title={'Gebruiker details'}>
                    <CheckoutDetails/>
                </UICollapseable>
            </div>
            <div className={'checkout-products-details'}>
                <UICollapseable title={'Bestelling details'}>
                    <UIText>Je bestelling</UIText>
                    <PageCartDetails cart={state.cart} discounts={orderDiscounts} setDiscounts={setDiscounts} deleteDiscount={deleteDiscount} />
                    <UIButton onClick={()=>(order(false))} className={'full-width'}>Volledig betalen</UIButton>
                    <UIButton onClick={()=>(order(true))} className={'full-width buttonAanbetaling'}>Aanbetaling (50%)</UIButton>
                </UICollapseable>
            </div>
        </div>
    </div>
}

export {PageCheckout}
import './about.scss';
import React from "react";
import {UIText} from "../../components/ui/text/text.js";

const ArabiaTeachingAbout = function (props) {
    return <div className={'arabia-about-container'}>
        <div className={'arabia-about'}>
            {/* <UIText variant={'header-title'} className={'arabia-about-label'}>
                Wie zijn wij?
            </UIText> */}
            {/* <hr className={'arabia-about-line'}/> */}
            {/* <UIText variant={'paragraph-sub-title'} className={'arabia-about-description'}>
            Wij zijn een reisorganisatie speciaal gericht op Umrah reizen, als extra service bieden wij Arabische lessen aan. Deze lessen hebben een duidelijke focus op de Umrah. U kunt deze Arabische lessen gebruiken als voorbereiding op uw reis. Zo zorgt u dat u zich tijdens de bedevaart goed in het Arabisch kunt uitdrukken en Arabische teksten goed begrijpt. De lessen zijn dus erg praktisch ingesteld met als duidelijk doel het voorbereiden van uw kleine bedevaart. 
            </UIText> */}
        </div>
    </div>
};

export {ArabiaTeachingAbout}

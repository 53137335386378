import './banner.scss';
import React from "react";
import {useTranslation} from "../../helpers/languange.js";
import {UIText} from "../../components/ui/text/text.js";

const ContactBanner = function (props) {
    const text = useTranslation();
    return <div className={'contact-banner-container'}>
        <div className={'contact-banner'}>
            <UIText variant={'banner-title'}>
                {text.contact.banner.label}
            </UIText>
            <UIText variant={'paragraph'} className={'contact-banner-description'}>
                Wij proberen u op onze website zo duidelijk mogelijk informatie te geven met betrekking tot onze Umrah reizen en de Arabische lessen die u bij ons kunt volgen. Het kan natuurlijk zijn dat u toch nog vragen heeft. Wij helpen u graag!
            </UIText>
        </div>
    </div>
};

export {ContactBanner}

import "./account.scss";
import React, { useState, useEffect } from "react";
import { UIText } from "../../components/ui/text/text.js";
import { Tabs } from "antd";
import { PageAccountDetails } from "./details.js";
import { PageAccountOrders } from "./orders.js";
import PageAccountInvoices from "./invoices.js";

import { useModelFeedback } from "../../helpers/model/use_model_feedback.js";
import { ModelMyInvoices } from "../../model/my_invoices.js";
import { useHistory, useLocation } from "react-router-dom";

const PageAccount = function (props) {
  const history = useHistory();
  const resultSet = useModelFeedback(ModelMyInvoices);
  if (resultSet.feedback) {
    return resultSet.feedback;
  }

  const renderOrders = () => (
    <div>
      <UIText variant={"header-title"}>Online bestellingen</UIText>
      <PageAccountOrders />
      {resultSet?.data?.length > 0 && (
        <>
          <UIText variant={"header-title"}>Bestellingen vanuit offerte</UIText>
          <PageAccountInvoices items={resultSet.data} />
        </>
      )}
    </div>
  );
  const tabs = [
    {
      label: "Account",
      link: "/account",
      component: <PageAccountDetails />,
    },
    {
      label: "Mijn bestellingen",
      link: "/account/bestellingen",
      component: renderOrders(),
    },
  ];
  return (
    <div className={"account-container"}>
      <UIText variant={"title"}>Account</UIText>
      <Tabs
        defaultActiveKey={tabs
          .findIndex((tab) => tab.link === props.link)
          .toString()}
        onTabClick={(key, evt) => history.push(tabs[key].link)}
      >
        {tabs.map((tab, key) => (
          <Tabs.TabPane key={key} tab={tab.label}>
            {tab.component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export { PageAccount };

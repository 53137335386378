import React, {useState} from "react";
import {AdminUsersList} from "./list.js";
import {AuthSignUp} from "../../../components/auth/sign-up.js";
import {UIButton} from "../../../components/ui/button/button.js";
import {UIText} from "../../../components/ui/text/text.js";
import {Input, Select} from "antd";

const PageAdminUsers = function (props) {
    const [modal, setModal] = useState('');
    const [initialValues, setInitialValues] = useState(null);
    const [searchType, setSearchType] = useState('email');
    const [searchValue, setSearch] = useState('');

    return <div>
        <div style={{display: 'flex', justifyContent: 'space-between', margin: '1rem 0', alignItems: 'center'}}>
            <span>
                <UIButton onClick={()=>setModal('users')}>Voeg gebruiker toe</UIButton>
            </span>
        </div>
        <div style={{margin: '1rem 0'}}>
            <UIText>Zoek een gebruiker</UIText>
            <Input.Group compact>
                <Select defaultValue={searchType} style={{ width: '20%' }} onChange={(value)=>setSearchType(value)}>
                    <Select.Option value="email">Email</Select.Option>
                    <Select.Option value="last_name">Achternaam</Select.Option>
                    <Select.Option value="phone_number">Telefoonnummer</Select.Option>
                    <Select.Option value="zip">Postcode</Select.Option>
                </Select>
                <Input style={{ width: '80%' }} value={searchValue} onChange={(e)=>setSearch(e.target.value)} />
            </Input.Group>
        </div>
        <AdminUsersList setInitialValues={setInitialValues} setModal={setModal} search={searchValue.length > 0 ? {type: searchType, value: searchValue} : null}/>

        {(modal === 'users') &&
        <AuthSignUp
            visible={(modal === 'users')}
            setInitialValues={setInitialValues}
            setVisible={()=>{setModal('');setInitialValues(null);}}
            initialValues={initialValues}/>}
    </div>
};

export {PageAdminUsers}
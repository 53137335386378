import React from "react";
import {ModelAuth} from "../../model/auth.js";
import {PropTypes} from 'prop-types';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {AuthContext} from "./auth-provider.js";


const AuthWithUser = function(props){
    const {user} = React.useContext(AuthContext);
    const resultSet = useModelFeedback(ModelAuth, {user: user});

    if(resultSet.feedback && props.enableFeedback){
        return resultSet.feedback;
    }

    if(resultSet.status === ModelAuth.Status.SUCCESS){
        const user = resultSet.data;
        return props.children(user);
    }else{
        return props.children(null)
    }

};

AuthWithUser.propTypes = {
    enableFeedback: PropTypes.bool,
    children: PropTypes.func.isRequired
};

AuthWithUser.defaultProps = {
    enableFeedback: true
}

export {AuthWithUser};
import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Dropdown,
  Popconfirm,
  Popover,
  Form,
  Input,
  Select,
} from "antd";
import { saveAs } from "file-saver";

import { ModelMergedRooms } from "../../../model/mergedRooms.js";
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import moment from "moment";
import { FaEdit } from "react-icons/fa";
import { Tb123 } from "react-icons/tb";
import { MdDelete } from "react-icons/md";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import constants from "../../../assets/constants.json";
import {
  getAgeTypeById,
  getGenderById,
  genderRoomTypes,
} from "../../../assets/constants.js";
import { RoomPDF } from "./room_pdf.js";
import html2pdf from "html2pdf.js";
import { jsPDF } from "jspdf";

import "./rooms_list.scss";

//import { ReactSortable } from "react-sortablejs";
import { getRoomByProductType } from "../../../assets/constants.js";

const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

const grid = 8;

const getFirstListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  display: "flex",
  padding: grid,
  width: 400,
});

const getItemStyle = (isDragging, draggableStyle, item) => ({
  ...draggableStyle,
});

const RoomsList = function (props) {
  const roomsToRender = props?.rooms?.map((room) => {
    const guestsThatCount = room.guests.filter(
      (guest) =>
        guest.bed_included && guest.age_category !== constants.AGE_CATEGORY.BABY
    );
    const product = props.products.find(
      (product) => product.product_id === guestsThatCount[0].product_id
    );
    const roomType = getRoomByProductType(product.type);
    return {
      ...room,
      maxGuests: roomType.max_guests,
      merged_room: false,
    };
  });

  const [initalRooms, setInitialRooms] = useState(null);

  const mergedResult = useCallback(() => {
    const mergedRooms = [];

    const roomsToMerge = roomsToRender.filter(
      (room) =>
        room.guests.length < room.maxGuests &&
        room.room_gender !== constants.GENDER_TYPE.MIX &&
        room.exclusive !== true
    );
    const roomsToStay = roomsToRender.filter(
      (room) => roomsToMerge.find((i) => room.id === i.id) === undefined
    );

    roomsToMerge.forEach((item, index) => {
      const nextAvailableMerge =
        roomsToMerge.filter(
          function (r) {
            if (this.count < r.maxGuests - r.guests.length) {
              if (
                r.id !== item.id &&
                r.room_gender === item.room_gender &&
                r.maxGuests === item.maxGuests
              ) {
                this.count++;
                if (r.exclusive || item.exclusive) {
                  return false;
                }

                return true;
              }
            }
            return false;
          },
          { count: 0 }
        ) || [];

      if (nextAvailableMerge.length > 0) {
        const guests = nextAvailableMerge.map((a) => a.guests).flat();
        item.guests = item.guests.concat(guests);
        nextAvailableMerge.forEach((a) =>
          roomsToMerge.splice(
            roomsToMerge.findIndex((r) => r.id === a.id),
            1
          )
        );
      }

      mergedRooms.push({ ...item, merged_room: true });
    });

    return [...roomsToStay, ...mergedRooms];
  });

  useEffect(() => {
    if (!roomsMerged) {
      setMergedRooms(mergedResult);
    }
  }, []);

  useEffect(() => {
    if (initalRooms) {
      const newRooms = [...initalRooms];
      console.log("initial");
      // const exsistingGuests = newRooms.map((n)=>(n.guests)).flat();
      // const roomsToPush = [...roomsToStay, ...mergedRooms].filter((room)=>{
      //   const guestsToPush = room.guests.filter((g)=>(exsistingGuests.find((guest)=>(guest.id === g.id)) === undefined));
      //   return guestsToPush.length > 0;
      // });

      // console.log("initial!");
      // console.log(exsistingGuests)
      // console.log(roomsToPush);
      setMergedRooms(newRooms);
    }
  }, [initalRooms]);

  const [roomsMerged, setMergedRooms] = useState(null);
  const [activeItem, setActiveItem] = useState("edit");

  const [draggableSet, setDraggable] = useState(false);

  const onItemRemove = (evt) => {};

  const onItemAdd = (evt) => {
    const item = evt.item.id;
    if (this) {
      if (evt.clone) {
        this.el.removeChild(evt.clone);
      }
    }
    if (document.querySelectorAll("[id=" + item + "]")[1]) {
      document.querySelectorAll("[id=" + item + "]")[1].remove();
    }
    const guestID = parseInt(item.replace(/\D/g, ""));

    if (roomsMerged) {
      const guestRoom = roomsMerged.find(
        (room) =>
          room.guests.find((guest) => guest.id === guestID) !== undefined
      );
      const newArray = roomsMerged;
      newArray[roomsMerged.indexOf(guestRoom)].guests = newArray[
        roomsMerged.indexOf(guestRoom)
      ].guests.filter((g) => g.id !== guestID);

      const roomItem = evt.item.parentElement.id;
      const roomID = parseInt(roomItem.replace(/\D/g, ""));

      const room = newArray.find((room, i) => room.id === roomID);
      room.guests.push(props.guests.find((guest) => guest.id === guestID));

      setMergedRooms(newArray.map((r) => (r.id === room.id ? room : r)));
    }
  };

  const roomsToPdf =
    roomsMerged?.sort(function (a, b) {
      return a.maxGuests - b.maxGuests;
    }) || [];
  const chunkSize = 3;
  const chunks = [];

  for (let i = 0; i < roomsToPdf.length; i += chunkSize) {
    const chunk = roomsToPdf.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  const onDragEnd = (result) => {
    const sourceRoomID = parseInt(
      result.source.droppableId.replace(/[^\d.]/g, "")
    );
    const destRoomID = parseInt(
      result.destination.droppableId.replace(/[^\d.]/g, "")
    );
    const guestID = parseInt(result.draggableId.replace(/[^\d.]/g, ""));
    const guest = props.guests.find((g) => g.id === guestID);
    const newArray = [...roomsMerged];
    const sourceRoom = newArray.find((room) => room.id === sourceRoomID);
    sourceRoom.guests = sourceRoom.guests.filter(
      (guest) => guest.id !== guestID
    );
    const destRoom = newArray.find((room) => room.id === destRoomID);
    destRoom.guests.push(guest);
    const roomsToReplace = [sourceRoom, destRoom];

    setMergedRooms(
      newArray.map((room) =>
        roomsToReplace.find((r) => r.id === room.id) === undefined
          ? room
          : roomsToReplace.find((r) => r.id === room.id)
      )
    );
  };

  const resultSet = useModelFeedback(ModelMergedRooms, {
    start_date: props.products[0].start,
  });

  const initialRoomsByVersion = {};
  const initialRooms =
    resultSet.status === "success" && resultSet.data
      ? resultSet.data.forEach((mergedRoom) =>
          initialRoomsByVersion[mergedRoom.version]
            ? (initialRoomsByVersion[mergedRoom.version] =
                initialRoomsByVersion[mergedRoom.version].concat([
                  {
                    ...mergedRoom,
                    id: mergedRoom.existing_id,
                    guests: mergedRoom.guests.map((g) => g.guest),
                  },
                ]))
            : (initialRoomsByVersion[mergedRoom.version] = [
                {
                  ...mergedRoom,
                  id: mergedRoom.existing_id,
                  guests: mergedRoom.guests.map((g) => g.guest),
                },
              ])
        )
      : null;
  // initialRoomsByVersion.toReversed();

  if (resultSet.feedback) {
    return resultSet.feedback;
  }

  const getRoomsAddPossible = () => {
    if (roomsMerged) {
      const newRooms = [...roomsMerged];
      const exsistingGuests = newRooms.map((n) => n.guests).flat();
      const roomsToPush = mergedResult().filter((room) => {
        const guestsToPush = room.guests.filter(
          (g) =>
            exsistingGuests.find((guest) => guest.id === g.id) === undefined
        );
        return guestsToPush.length > 0;
      });
      return roomsToPush.map((room) => ({
        value: JSON.stringify(room),
        label: "#FCU1" + room.order_id + " - kamer #" + room.id,
      }));
    }
    return [];
  };

  return (
    <div className="roomsListCont">
      <div className="versHeader">
        <h1>Versies</h1>
        <Button
          size={"large"}
          onClick={() => {
            setInitialRooms(null);
            setMergedRooms(mergedResult());
            setActiveItem("edit");
          }}
        >
          Nieuwe kamerindeling genereren
        </Button>
      </div>
      <div className="initalRoomsCont">
        {Object.keys(initialRoomsByVersion)
          .sort((a, b) => b - a)
          .filter((key, index) => index <= 3)
          .map((key) => {
            key = parseInt(key);
            const initialRooms = initialRoomsByVersion[key];
            return (
              <div className="initialRoom" key={"initialRoom" + key}>
                <h2>
                  Versie {key} -{" "}
                  <span>
                    {moment(initialRooms[0].created_at).format(
                      "DD-MM-YYYY HH:mm"
                    )}
                  </span>
                </h2>
                <div className="actionCont">
                  <Button
                    className="btn"
                    onClick={() => {
                      setActiveItem("edit");
                      setInitialRooms(initialRooms);
                    }}
                  >
                    <FaEdit className="buttonIcon" /> Kopieren en aanpassen
                  </Button>
                  <Button
                    className="btn"
                    onClick={() => {
                      setActiveItem("countVersion");
                      setInitialRooms(initialRooms);
                    }}
                  >
                    <Tb123 className="buttonIcon" /> Kamer aantallen
                  </Button>
                </div>
              </div>
            );
          })}
      </div>
      {activeItem === "countVersion" && (
        <>
          <div>
            <h1>Versie {roomsMerged[0].version + 1}</h1>
          </div>
          <div className="counts">
            <div className="count">
              <h2>Tweepersoonskamers</h2>
              <h3>
                {
                  roomsMerged.filter(
                    (r) => r.guests.length > 0 && r.maxGuests === 2
                  ).length
                }
              </h3>
            </div>
            <div className="count">
              <h2>Driepersoonskamers:</h2>
              <h3>
                {
                  roomsMerged.filter(
                    (r) => r.guests.length > 0 && r.maxGuests === 3
                  ).length
                }
              </h3>
            </div>
            <div className="count">
              <h2>Vierpersoonskamers:</h2>
              <h3>
                {
                  roomsMerged.filter(
                    (r) => r.guests.length > 0 && r.maxGuests === 4
                  ).length
                }
              </h3>
            </div>
          </div>
        </>
      )}
      {activeItem === "edit" && (
        <>
          <div className="headerCont">
            <h1>Kamerindeling</h1>
            {roomsMerged && roomsMerged !== mergedResult() && (
              <div className="addRoom">
                <Form
                  name={"addRoom"}
                  onFinish={(values) => {
                    const rooms = [...roomsMerged];
                    rooms.push(JSON.parse(values.room));
                    setMergedRooms(rooms);
                  }}
                  autoComplete="off"
                >
                  <Form.Item name={"room"}>
                    <Select
                      placeholder="Kamer toevoegen"
                      options={getRoomsAddPossible()}
                    />
                  </Form.Item>
                  <Button htmlType="submit">Toevoegen</Button>
                </Form>
              </div>
            )}
            <Button size={"large"} onClick={() => setActiveItem("download")}>
              DOORGAAN NAAR VOORBEELD
            </Button>
          </div>

          <div className="roomsContainer">
            <DragDropContext onDragEnd={onDragEnd}>
              {roomsMerged
                ?.sort(function (a, b) {
                  return a.maxGuests - b.maxGuests;
                })
                .map((room, key) => (
                  <Droppable
                    className={"test"}
                    isDropDisabled={room.exclusive}
                    droppableId={"room" + room.id}
                    direction="vertical"
                    key={"room" + room.id}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getFirstListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                        className={
                          "dragCont " + (room.exclusive ? "disabled" : "")
                        }
                      >
                        <div className="headerRoom">
                          <span className="headerText">
                            {" "}
                            <span className="roomNumber">#{key + 1}</span>{" "}
                            {room.maxGuests} persoonskamer -{" "}
                            {
                              genderRoomTypes.find(
                                (type) => type.id === room.room_gender
                              ).label
                            }
                          </span>
                          <span className="btnContainer">
                            <Popover
                              title="Invullen hotelkamer Medina"
                              trigger="click"
                              content={
                                <Form
                                  name={"room" + room.id + "medina_mekka"}
                                  initialValues={{
                                    flex: {
                                      room_mekka:
                                        roomsMerged[key]?.room_mekka || null,
                                      room_medina:
                                        roomsMerged[key]?.room_medina || null,
                                    },
                                  }}
                                  onFinish={(values) => {
                                    console.log(values);
                                    room.flex = room.flex
                                      ? Object.assign(room.flex, values.flex)
                                      : values.flex;

                                    console.log(values);
                                    setMergedRooms(
                                      roomsMerged.map((r) =>
                                        r.id === room.id
                                          ? { ...room, ...values }
                                          : r
                                      )
                                    );
                                  }}
                                  autoComplete="off"
                                >
                                  <Form.Item
                                    label={"Kamernummer hotel Medina"}
                                    name={["flex", "room_medina"]}
                                  >
                                    <Input type="number" />
                                  </Form.Item>
                                  <Button htmlType="submit">Bevestigen</Button>
                                </Form>
                              }
                              className="roomSelector"
                            >
                              Kamer Medina
                            </Popover>
                            <Popover
                              title="Invullen hotelkamer Mekka"
                              trigger="click"
                              content={
                                <Form
                                  name={"room" + room.id + "medina_mekka"}
                                  initialValues={{
                                    flex: {
                                      room_mekka:
                                        roomsMerged[key]?.room_mekka || null,
                                      room_medina:
                                        roomsMerged[key]?.room_medina || null,
                                    },
                                  }}
                                  onFinish={(values) => {
                                    room.flex = room.flex
                                      ? Object.assign(room.flex, values.flex)
                                      : values.flex;
                                    setMergedRooms(
                                      roomsMerged.map((r) =>
                                        r.id === room.id ? { ...room } : r
                                      )
                                    );
                                  }}
                                  autoComplete="off"
                                >
                                  <Form.Item
                                    label={"Kamernummer hotel Mekka"}
                                    name={["flex", "room_mekka"]}
                                  >
                                    <Input type="number" />
                                  </Form.Item>
                                  <Button htmlType="submit">Bevestigen</Button>
                                </Form>
                              }
                              className="roomSelector"
                            >
                              Kamer Mekka
                            </Popover>
                            <Popconfirm
                              title="Weet je zeker de kamer wilt verwijderen?"
                              description="Weet je zeker de kamer wilt verwijderen?"
                              onConfirm={() => {
                                const rooms = [...roomsMerged];
                                rooms.splice(rooms.indexOf(room), 1);
                                setMergedRooms(rooms);
                              }}
                              okText="Ja"
                              cancelText="Nee"
                            >
                              <span className="delButton">
                                <MdDelete className="delIcon" />
                              </span>
                            </Popconfirm>
                          </span>
                        </div>
                        {room.guests.map((item_row, index) =>
                          room.exclusive ? (
                            <div className="draggable">
                              {item_row.first_name} {item_row.last_name} -{" "}
                              {getGenderById(item_row.gender).short_nl} -{" "}
                              {
                                getAgeTypeById(item_row.age_category)
                                  .label_short
                              }{" "}
                              {item_row.bed_included ? "" : "Excl. bed"}
                            </div>
                          ) : (
                            <Draggable
                              key={"guest" + item_row.id}
                              draggableId={"guest" + item_row.id}
                              index={index}
                              className="draggable"
                              isDragDisabled={room.disabled}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={provided.draggableProps.style}
                                  isDragDisabled={room.disabled}
                                  className="draggable"
                                >
                                  {item_row.first_name} {item_row.last_name} -{" "}
                                  {getGenderById(item_row.gender).short_nl} -{" "}
                                  {
                                    getAgeTypeById(item_row.age_category)
                                      .label_short
                                  }{" "}
                                  {item_row.bed_included ? "" : "Excl. bed"}
                                </div>
                              )}
                            </Draggable>
                          )
                        )}
                        {room.flex?.room_medina && (
                          <div className="roomNumberHotel">
                            Kamernummer Medina: {room.flex?.room_medina}
                          </div>
                        )}
                        {room.flex?.room_mekka && (
                          <div className="roomNumberHotel">
                            Kamernummer Mekka: {room.flex?.room_mekka}
                          </div>
                        )}
                      </div>
                    )}
                  </Droppable>
                ))}
            </DragDropContext>
          </div>
        </>
      )}
      {activeItem === "download" && (
        <>
          <div className="headerCont">
            <h1>Voorbeeld en afronding</h1>
            <span>
              <Button
                className="leftButton"
                size={"large"}
                onClick={() => setActiveItem("edit")}
              >
                TERUG NAAR KAMERINDELING
              </Button>
              <Button
                className="leftButton"
                onClick={async () => {
                  const opt = {
                    pagebreak: { mode: ["avoid-all", "css", "legacy"] },
                    image: { type: "jpeg", quality: 0.98 },
                    pagebreak: { avoid: "tr", mode: "css" },
                  };
                  const blob = await html2pdf()
                    .set(opt)
                    .from(document.getElementById("roomHTMLPDF"))
                    .outputPdf("blob", "my-invoice.pdf");
                  saveAs(blob, "kamerindeling.pdf");

                  console.log(blob);
                }}
                size={"large"}
              >
                DOWNLOADEN
              </Button>
              <Popconfirm
                title="Weet je zeker dat je de kamerindeling wilt opslaan?"
                description="Weet je zeker dat je de kamerindeling wilt opslaan?"
                onConfirm={() => {
                  html2pdf(document.getElementById("roomHTMLPDF"));

                  ModelMergedRooms.addRooms(
                    {
                      rooms: roomsMerged.map((room) => ({
                        ...room,
                        flex: room.flex ? JSON.stringify(room.flex) : "{}",
                        existing_id: room.id,
                        start_date: props?.products[0]?.start,
                        guests: room.guests.map((guest) => ({
                          ...guest,
                          guest_id: guest.id,
                        })),
                      })),
                    },
                    (resp) => console.log(resp)
                  );
                }}
                okText="Ja"
                cancelText="Nee"
              >
                <Button size={"large"}>
                  DOWNLOADEN EN NIEUWE VERSIE OPSLAAN
                </Button>
              </Popconfirm>
            </span>
          </div>
          <div id="roomHTMLPDF">
            <table>
              {chunks.map((chunk, key) => (
                <RoomPDF title={props.title} rooms={chunk} key={key} />
              ))}
            </table>
          </div>
        </>
      )}{" "}
    </div>
  );
};

export { RoomsList };

import './articles.scss';
import React, {useState} from "react";
import {Teared} from "../ui/teared/teared.js";
import {ArticlesCategories} from "./categories.js";
import constants from '../../assets/constants.json'
import {ArticlesArticle} from "./article.js";
import {useModelFeedback} from "../../helpers/model/use_model_feedback.js";
import {ModelArticles} from "../../model/articles.js";
import {ModelArticleCategories} from "../../model/article-categories.js";
import {UIText} from "../ui/text/text.js";

const ArticlesArticles = function (props) {
    const [filter, setFilter] = useState([])
    const resultSet = useModelFeedback(ModelArticles);
    const resultSetCategories = useModelFeedback(ModelArticleCategories);

    if(resultSet.feedback){
        return resultSet.feedback
    }

    if(resultSetCategories.feedback){
        return resultSetCategories.feedback
    }

    const getCategories = () => {
        let categories = [];
        for(const article of resultSetCategories.data.articleCategories){
            categories.push({
                id: article.article_category_id,
                label: article.label
            })
        }
        return categories
    };
    const articles = resultSet.data?.articles
        .filter((item=> (item.type === constants.ARTICLE_TYPE.ARTICLE && (item.categories?.some((f)=>filter.includes(f.article_category_id)) || filter.length === 0))))

    return <Teared className={'articles-section-container'}>
        <ArticlesCategories categories={getCategories()} filter={filter} setFilter={setFilter}/>
        <div className={'articles'}>
            {
                articles
                    .map((article, key)=>{
                        return <ArticlesArticle article={article} key={key} categories={resultSetCategories.data.articleCategories}/>
                    })
            }
            {articles.length === 0 && <UIText>Geen kennis artikels gevonden.</UIText>}
        </div>
    </Teared>
};

export {ArticlesArticles}
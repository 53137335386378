import './banner.scss';
import React from "react";
import {useTranslation} from "../../helpers/languange.js";
import {UIText} from "../../components/ui/text/text.js";

const AboutBanner = function (props) {
    const text = useTranslation();
    return <div className={'about-banner-container'}>
        <div className={'about-banner'}>
            <UIText variant={'banner-title'}>
                {text.about_us.banner.label}
            </UIText>
            <UIText variant={'paragraph'} className={'about-banner-description'}>
                {text.about_us.banner.description}
            </UIText>
        </div>
    </div>
};

export {AboutBanner}